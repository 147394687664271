import React, { useCallback, useState, useMemo } from 'react';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import {
  DidomiEmptyState,
  DidomiButton,
  DidomiIconButton,
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiSkeleton,
  DidomiErrorState,
  DidomiIcon,
  DidomiListShortener,
  DidomiLoader,
  DidomiTooltip,
  DidomiFiltersBar,
} from '@didomi/ui-atoms-react';
import { useSnackbar, useCustomFeature, useActiveOrganization, useHasAccessPolicies } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { useNavigate, Link } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { ConsentNoticeOptionsMenu, TextWithHighlighterAndTooltip, ConsentNoticePreviewStandalone } from '@components';
import { usePaginationQueryParams, usePaginatedFilteredItems, useConsentNotices, useDuplicateConsentNotice, useArchiveConsentNotice, useRestoreConsentNotice } from '@hooks';
import { LoadingModal, ConfirmArchiveConsentNoticeModal, ConfirmRestoreConsentNoticeModal } from '@modals';
import { SortConfig, ConsentNoticeSortableField, ConsentNotice, ConsentNoticePlatformIcon, CONSENT_NOTICES_PLATFORMS_DESC } from '@types';
import { ACCESS_POLICIES_CONFIG, formatDate, getTableResultsText } from '@utils';

const SEARCH_FIELDS = [
  { field: 'name' },
  { field: 'id' },
  { field: 'config.platform' },
  { field: 'config.targets' },
  { field: 'updated_at', transform: formatDate },
  { field: 'deployed_at', transform: formatDate },
];

/**
 * Consent Notices Default Page
 */
export const ConsentNotices = (): JSX.Element => {
  const navigate = useNavigate();
  const { organizationId } = useActiveOrganization();
  const { displaySnackbar } = useSnackbar();
  const [showArchived, setShowArchived] = useState(false);
  const [noticeToPreview, setNoticeToPreview] = useState(null);
  const [noticeToArchive, setNoticeToArchive] = useState(null);
  const [noticeToRestore, setNoticeToRestore] = useState(null);
  const [sortConfig, setSortConfig] = useLocalStorage<SortConfig<ConsentNoticeSortableField>>('notice-sort-' + organizationId, { field: 'name', dir: 'asc' });
  const { isLoading, isRefetching, data: { data: consentNotices, total: totalConsentNotices } = {}, error: errorLoadingConsentNotices } = useConsentNotices();
  const { search, limit, page: currPage, setSearch, setLimit, setPage: setCurrPage } = usePaginationQueryParams({ maxItems: totalConsentNotices });
  const [canArchive] = useCustomFeature('consent-notices-archive');
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const filterByArchive = useCallback((notice: ConsentNotice) => (showArchived ? !!notice.archived_at : !notice.archived_at), [showArchived]);

  const filters = useMemo(() => [filterByArchive], [filterByArchive]);

  const { data: displayConsentNotices, total: paginationTotal } = usePaginatedFilteredItems<ConsentNotice, ConsentNoticeSortableField>(consentNotices, {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    filters,
    page: currPage,
    sortConfig,
  });

  const { mutateAsync: duplicateNotice, isLoading: duplicatingNotice } = useDuplicateConsentNotice({
    onSuccess: newNotice => {
      displaySnackbar(`${newNotice.data.name} has been created`, { icon: 'success-small' });
      navigate('/' + newNotice.data.id);
    },
    onError: () => {
      displaySnackbar('There was an error duplicating the notice', { variant: 'error' });
    },
  });

  const { mutateAsync: archiveNotice, isLoading: isArchivingNotice } = useArchiveConsentNotice({
    onSuccess: archivedNotice => {
      setNoticeToArchive(null);
      displaySnackbar(`${archivedNotice.data.name} has been archived`, { icon: 'success-small' });
    },
    onError: () => {
      setNoticeToArchive(null);
      displaySnackbar('There was an error archiving the notice', { variant: 'error' });
    },
  });

  const { mutateAsync: restoreNotice, isLoading: isRestoringNotice } = useRestoreConsentNotice({
    onSuccess: restoredNotice => {
      setNoticeToRestore(null);
      displaySnackbar(`${restoredNotice.data.name} has been restored`, { icon: 'success-small' });
    },
    onError: () => {
      setNoticeToRestore(null);
      displaySnackbar('There was an error restoring the notice', { variant: 'error' });
    },
  });

  const totalNotArchived = useMemo(() => consentNotices?.reduce((total, cN) => total + (!cN.archived_at ? 1 : 0), 0), [consentNotices]);
  const totalArchived = totalConsentNotices - totalNotArchived;
  const totalToDisplay = showArchived ? totalArchived : totalNotArchived;

  const noConsentNotices = !consentNotices?.length;
  const noDisplayConsentNotices = !displayConsentNotices?.length;

  const loadingConsentNotices = isLoading || isRefetching;

  const resetToFirstPage = () => {
    setCurrPage(new CustomEvent('page', { detail: { page: 1 } }));
  };

  const handleFilterUpdate = (newFilterChange: DidomiFiltersBarCustomEvent<{ key: string; newValue: string }>) => {
    const modifiedFilter = newFilterChange.detail.key;
    if (modifiedFilter === 'filterByArchive') {
      setShowArchived(newFilterChange.detail.newValue === 'true');
    }

    // Reset pagination when changing filters
    resetToFirstPage();
  };

  const handleClearFilters = () => {
    setSearch('');
    setShowArchived(false);
    // Reset pagination when changing filters
    resetToFirstPage();
  };

  const updateSorting = (e: CustomEvent) => {
    const newSortBy = e.detail.sortId;
    const newSortDir = newSortBy !== sortConfig.field ? 'desc' : e.detail.direction;
    setSortConfig({ dir: newSortDir, field: newSortBy ?? sortConfig.field });
  };

  const resultsText = getTableResultsText(paginationTotal, totalToDisplay);

  return (
    <>
      {!noConsentNotices && (
        <div className="w-full h-full flex flex-col">
          <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-xs">
            <DidomiFiltersBar
              data-testid="filter-bar"
              className="flex-1"
              leftText={resultsText}
              filters={{
                filterByArchive: {
                  placeholder: 'Filter by archive',
                  value: showArchived.toString(),
                  options: [
                    { value: 'false', label: 'All notices' },
                    { value: 'true', label: 'Archived' },
                  ],
                },
              }}
              onClearAllFilters={handleClearFilters}
              onFilterValueChange={handleFilterUpdate}
              showSearch
              searchValue={search}
              collapsibleFilters={false}
              onSearchTextChange={setSearch}
            />

            {isCMPEditor && (
              <DidomiButton data-tracking="add-notice-button" iconRight="new-create" onClick={() => navigate(`add`)}>
                Create a notice
              </DidomiButton>
            )}
          </div>
          {noDisplayConsentNotices && (
            <DidomiEmptyState illustration-name="traces-no-match-found" className="h-full">
              {showArchived ? 'No archived results' : 'No results'}
            </DidomiEmptyState>
          )}
          {!noDisplayConsentNotices && (
            <>
              <DidomiSkeleton variant="layout" isLoading={loadingConsentNotices}>
                <DidomiTable
                  data-testid={loadingConsentNotices ? 'loading-notices-table' : 'notices-table'}
                  fixedLayout
                  className="mb-4"
                  loading={loadingConsentNotices}
                  sortable
                  sortBy={sortConfig.field}
                  sortDirection={sortConfig.dir}
                  onSortChange={updateSorting}
                >
                  <DidomiTableHeading>
                    <DidomiTableHeaderRow>
                      <DidomiTableTh sortId="name" data-testid="sortName" style={{ flexBasis: 80 }}>
                        NAME
                      </DidomiTableTh>
                      <DidomiTableTh sortId="config.platform" data-testid="sortPlatform">
                        PLATFORM
                      </DidomiTableTh>
                      <DidomiTableTh sortId="updated_at" data-testid="sortLastSaved">
                        LAST EDIT
                      </DidomiTableTh>
                      <DidomiTableTh sortId="deployed_at" data-testid="sortLastPublished">
                        LAST PUBLISHED
                      </DidomiTableTh>
                      <DidomiTableTh>TARGETS</DidomiTableTh>
                      <DidomiTableTh cellAlign="right" style={{ flexBasis: showArchived ? 0 : 96 }}></DidomiTableTh>
                    </DidomiTableHeaderRow>
                  </DidomiTableHeading>
                  <DidomiTableBody>
                    {/** There is a bug in the ui-library that breaks re-rendering the rows dynamically - wrapping the content in a div fixes the issue */}
                    {/** TODO: Investigate what is the problem, also found in VendorsList.tsx */}
                    <div>
                      {displayConsentNotices?.map(({ id, name, config, updated_at, deployed_at, archived_at }) => (
                        <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={id}>
                          <DidomiTableTd style={{ flexBasis: 80 }}>
                            <div data-skeleton={loadingConsentNotices} className="w-full">
                              <Link to={id} className="underline !outline-none inline-block focus-visible:(ring-[3px] ring-primary-blue-2)">
                                <TextWithHighlighterAndTooltip searchText={search} text={name} />
                              </Link>
                              <div className="flex items-center gap-xxxs text-primary-blue-4 text-[11px] leading-[14px] mt-2">
                                ID: <TextWithHighlighterAndTooltip searchText={search} text={id} />
                              </div>
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div data-skeleton={loadingConsentNotices} className="flex truncate w-full">
                              <div className={tx('mr-xxs inline text-primary-blue-4', { 'text-primary-pink-4': search && config?.platform?.includes(search) })}>
                                <DidomiIcon className="" data-skeleton={loadingConsentNotices} name={ConsentNoticePlatformIcon[config?.platform]} />
                              </div>
                              <TextWithHighlighterAndTooltip searchText={search} text={CONSENT_NOTICES_PLATFORMS_DESC.get(config?.platform)?.displayName || ''} />
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div data-skeleton={loadingConsentNotices} className="truncate w-full">
                              <TextWithHighlighterAndTooltip searchText={search} text={formatDate(updated_at)} />
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div data-skeleton={loadingConsentNotices} className="truncate w-full">
                              <TextWithHighlighterAndTooltip searchText={search} text={formatDate(deployed_at)} />
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div
                              data-skeleton={loadingConsentNotices}
                              className={tx('w-full', { 'text-primary-pink-4': search && config?.targets?.toString()?.toLowerCase()?.includes(search) })}
                            >
                              {config?.targets?.length > 0 ? <DidomiListShortener itemsToShow={2} items={config?.targets} /> : '-'}
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd cellAlign="right" style={{ flexBasis: showArchived ? 0 : 102 }}>
                            <div className="flex px-xxxs gap-xxs">
                              {!archived_at && (
                                <>
                                  <DidomiTooltip content="Preview notice" placement="top">
                                    <DidomiIconButton type="button" title="Preview notice" variant="rounded" icon="preview" onClick={() => setNoticeToPreview({ id, name })} />
                                  </DidomiTooltip>
                                  <DidomiTooltip content="Edit notice" placement="top">
                                    <DidomiIconButton type="button" title="Edit notice" variant="rounded" icon="edit" onClick={() => navigate(`/${id}`)} />
                                  </DidomiTooltip>
                                </>
                              )}

                              {isCMPEditor && (
                                <ConsentNoticeOptionsMenu
                                  id={id}
                                  isArchived={!!archived_at}
                                  onDuplicate={() => duplicateNotice(id)}
                                  hasTargets={config?.targets?.length > 0}
                                  onArchive={canArchive ? () => setNoticeToArchive({ id, name }) : undefined}
                                  onRestore={canArchive ? () => setNoticeToRestore({ id, name }) : undefined}
                                />
                              )}
                            </div>
                          </DidomiTableTd>
                        </DidomiTableRow>
                      ))}
                    </div>
                  </DidomiTableBody>
                </DidomiTable>
              </DidomiSkeleton>
              <DidomiPaginator
                data-testid="consent-notices-paginator"
                className="self-end"
                page={currPage}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={setLimit}
                onPageChange={setCurrPage}
                disabled={loadingConsentNotices}
              />
            </>
          )}
        </div>
      )}
      {noConsentNotices && errorLoadingConsentNotices && (
        <div className="w-full h-full mt-s">
          <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
            <div slot="title">There was an error loading the notice list</div>
          </DidomiErrorState>
        </div>
      )}
      {noConsentNotices && isLoading && !errorLoadingConsentNotices && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading notices</div>
        </div>
      )}
      {noConsentNotices && !isLoading && !errorLoadingConsentNotices && (
        <div className="w-full h-full mt-s">
          <DidomiEmptyState
            illustration-name="traces-no-result-found"
            className="h-full"
            actionName={isCMPEditor ? 'Create notice' : ''}
            actionIconRight={isCMPEditor ? 'new-create' : ''}
            onActionClick={() => navigate(`/add`)}
          >
            <div slot="title">It&apos;s empty here!</div>
            You don&apos;t have any notice created.
          </DidomiEmptyState>
        </div>
      )}
      <ConfirmArchiveConsentNoticeModal
        noticeName={noticeToArchive?.name}
        isOpen={!!noticeToArchive}
        isLoading={isArchivingNotice}
        onCancel={() => setNoticeToArchive(null)}
        onProceed={() => archiveNotice(noticeToArchive.id)}
      />
      <ConfirmRestoreConsentNoticeModal
        noticeName={noticeToRestore?.name}
        isOpen={!!noticeToRestore}
        isLoading={isRestoringNotice}
        onCancel={() => setNoticeToRestore(null)}
        onProceed={() => restoreNotice(noticeToRestore.id)}
      />
      <LoadingModal isOpen={duplicatingNotice} title="We are generating your Consent Notice..." />

      <ConsentNoticePreviewStandalone id="standalone-notices-page" noticeId={noticeToPreview?.id} onClose={() => setNoticeToPreview(null)} isOpen={Boolean(noticeToPreview)} />
    </>
  );
};
