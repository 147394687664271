import { ConsentNoticeConfig, ConsentNoticeTemplate, RegulationTemplateData } from '@types';
import { isActiveDefaultRegulation } from '../isActiveDefaultRegulation';

// Check if `notices_id` field should be updated in the template - if so, return updated template
export const getTemplateWithUpdatedNoticesIdField = (template: ConsentNoticeTemplate, patchedNoticeConfig: ConsentNoticeConfig) => {
  const noticeId = patchedNoticeConfig.notice_id;
  const noticeRegulations = patchedNoticeConfig.regulation_configurations;
  const isTemplateCacheHasNotice = template.notices_id.includes(noticeId);

  if (isTemplateCacheHasNotice && noticeRegulations.every(reg => reg.template_id !== template.id)) {
    // If notice id is in the template `notices_id` but not in the notice regulation_configurations - remove it
    return { ...template, notices_id: template.notices_id.filter(id => id !== noticeId) };
  } else if (noticeRegulations.some(reg => reg.template_id === template.id) && !isTemplateCacheHasNotice) {
    // If notice id is not in the template `notices_id` but in the notice regulation_configurations - add it
    return { ...template, notices_id: [...template.notices_id, noticeId] };
  } else {
    // `notices_id` field is up to data - return template without any changes
    return template;
  }
};

// Generate regulations template metadata from the notice config
export const getNoticeTemplateMetadataFromConfig = (noticeConfig: ConsentNoticeConfig) => {
  return noticeConfig.regulation_configurations.reduce<Record<string, RegulationTemplateData>>((acc, reg) => {
    if (isActiveDefaultRegulation(reg)) {
      acc[reg.regulation_id] = { regulation_config_id: reg.id, template_id: reg.template_id };
    }
    return acc;
  }, {});
};
