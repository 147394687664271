import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';
import { ExpandableSection, ExpandableSectionToggle, SelectionCardCheckbox } from '@components';

interface IabAllVendorsProps {
  allIabVendors: boolean;
  viewOnly?: boolean;
  onChangeAllIabVendors?: (data: boolean) => void;
}

export const IabAllVendors = ({ allIabVendors, viewOnly = false, onChangeAllIabVendors }: IabAllVendorsProps) => {
  return (
    <ExpandableSection
      id="all-iab-vendors-collapse"
      variant="sub-level"
      header={
        <div className="flex items-center gap-xs">
          <ExpandableSectionToggle a11yLabel="All IAB Vendors" />
          <h3 className="font-semibold text-h4">All IAB Vendors</h3>
        </div>
      }
    >
      <DidomiHintbox className="mt-xs">
        <div className="flex gap-6">
          <div className="flex-1">
            <div className="text-h4 text-primary-blue-6 font-semibold mb-2">Enable all IAB Vendors</div>
            <div>Collect consent for all IAB vendors and automatically each week the IAB updates the TCF V2 list with the addition of new vendors.</div>
          </div>

          <div>
            <SelectionCardCheckbox
              title="Enable all IAB vendors"
              disabled={viewOnly}
              checked={allIabVendors}
              onValueChange={value => {
                onChangeAllIabVendors(value);
              }}
              illustration="integrations-iab"
              data-testid="all-iab-vendors"
            />
          </div>
        </div>
        <DidomiHintbox className="mt-5" iconName="warning" variant="warning">
          IAB TCF Europe warning: Consider the number of vendors you work with. Providing transparency for a large number of vendors may impact users&apos; ability to make informed
          choices, increase legal risk and slow down performance. Your Didomi Customer Success Manager is here to help if you have any questions.
        </DidomiHintbox>
      </DidomiHintbox>
    </ExpandableSection>
  );
};
