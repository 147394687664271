import React, { createContext } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { tx } from '@twind/core';

export const SortableItemContext = createContext(null);

type SortableListItemProps = {
  'data-testid'?: string;
  sortingId: string;
  sortingData?: Object;
  children: React.ReactChild;
};

export const SortableListItem = ({ 'data-testid': dataTestId, sortingId, sortingData, children }: SortableListItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: sortingId,
    data: sortingData,
  });

  const style = transform
    ? {
        transform: CSS.Transform.toString(transform),
        transition,
      }
    : undefined;

  return (
    <li data-testid={dataTestId} ref={setNodeRef} style={style}>
      <div className={tx('p-5 rounded border-1 border-dashed border-neutral-gray-8 text-center text-body-extra-small font-semibold', { hidden: !isDragging })}>Drop here</div>

      <SortableItemContext.Provider value={{ handlerProps: { ...attributes, ...listeners } }}>
        <div className={tx({ hidden: isDragging })}>{children}</div>
      </SortableItemContext.Provider>
    </li>
  );
};
