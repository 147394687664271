import React, { useMemo, useState } from 'react';
import { DidomiCollapse, DidomiCopyText, DidomiRadio, DidomiRadioGroup, DidomiTextInput } from '@didomi/ui-atoms-react';
import { useCustomFeature, useSnackbar } from '@didomi/utility-react';
import { useLocalStorage } from 'usehooks-ts';
import * as Yup from 'yup';
import { useLocalConsentNoticeEmbeddedScript } from '@hooks';
import { CodeType } from '@types';

const sdkCdnUrlSchema = Yup.string().url().required();

interface EmbeddedSdkCodeProps {
  noticeId: string;
}

export const EmbeddedSdkCode = ({ noticeId }: EmbeddedSdkCodeProps) => {
  const { displaySnackbar } = useSnackbar();
  const [canAddTargetDomain] = useCustomFeature('new-cmp-target-domain');
  const [canUseStaticCode] = useCustomFeature('sdk-static-loader');
  const [sdkCdnUrl, setSdkCdnUrl] = useLocalStorage(`embed-code-sdk-cdn-${noticeId}`, '');
  const [codeType, setCodeType] = useState<CodeType>(() => (canAddTargetDomain ? 'generic' : 'specific'));

  const { data: embeddedCode, isLoading } = useLocalConsentNoticeEmbeddedScript(noticeId, codeType, { sdkCdnUrl });

  const isSdkCdnUrlValid = useMemo(() => sdkCdnUrlSchema.isValidSync(sdkCdnUrl), [sdkCdnUrl]);
  const embeddedCodeError = codeType === 'static' && !isSdkCdnUrlValid ? 'Please enter a valid CDN URL' : null;
  const isEmbeddedCodeTypesSelectionAvailable = canAddTargetDomain || canUseStaticCode;

  const handleEmbeddedCodeCopy = () => {
    if (embeddedCodeError) {
      return displaySnackbar(embeddedCodeError, { variant: 'error' });
    } else {
      displaySnackbar('Code copied', { icon: 'success-small' });
    }
  };

  return (
    <div className="w-full flex flex-col" data-skeleton>
      {isEmbeddedCodeTypesSelectionAvailable && (
        <DidomiRadioGroup
          data-testid="code-type-radio-group"
          className="mb-xs text-primary-blue-6 text-body-small"
          value={codeType}
          hideErrorMessage
          onValueChange={e => setCodeType(e.detail)}
        >
          {canAddTargetDomain && (
            <DidomiRadio data-testid="code-generic" value="generic">
              Get generic code&nbsp;<span className="text-neutral-gray-0">(based on the domain)</span>
            </DidomiRadio>
          )}
          <DidomiRadio data-testid="code-specific" value="specific">
            Get specific code&nbsp;<span className="text-neutral-gray-0">(based on the notice ID)</span>
          </DidomiRadio>
          {canUseStaticCode && (
            <DidomiRadio data-testid="code-static" value="static">
              Get static code&nbsp;<span className="text-neutral-gray-0">(using self-hosted SDK assets)</span>
            </DidomiRadio>
          )}
        </DidomiRadioGroup>
      )}

      <DidomiCollapse className="max-w-[400px]" isExpanded={codeType === 'static' && !isLoading}>
        <DidomiTextInput
          value={sdkCdnUrl}
          label="Content Delivery Network URL"
          placeholder="Enter your CDN URL"
          iconRight={isSdkCdnUrlValid ? 'success-small' : null}
          required
          onValueChange={e => setSdkCdnUrl(e.detail)}
        />
      </DidomiCollapse>

      <DidomiCopyText
        className="w-full max-w-[500px]"
        truncate
        isIconPersistent
        variation="basic-outline"
        text={embeddedCodeError ? '' : embeddedCode}
        data-testid="sdk-code-text"
        onTextCopied={handleEmbeddedCodeCopy}
      >
        <div>{embeddedCode}</div>
      </DidomiCopyText>
    </div>
  );
};
