import React from 'react';
import { DidomiCardContainer } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface PublicationStepProps {
  step: number;
}

const PublicationStep = ({ step }: PublicationStepProps) => (
  <div className="w-[52px] min-w-[52px] overflow-hidden border-r-1 border-solid border-neutral-gray-5">
    <div className="font-sans text-[85px] font-bold leading-[100px] text-primary-blue-6 ml-xs">{step}</div>
  </div>
);

interface PublicationCardProps {
  step: number;
  title: string;
  subTitle?: string;
  inline?: boolean;
  'data-testid'?: string;
  children?: React.ReactChild;
}

export const PublicationCard = ({ step, title, subTitle, inline, 'data-testid': dataTestId, children }: PublicationCardProps) => (
  <DidomiCardContainer data-testid={dataTestId}>
    <div className="w-full flex items-center gap-xl text-primary-blue-6">
      <PublicationStep step={step} />
      <div className={tx('flex-1 flex h-full', { 'flex-col': !inline, 'justify-between items-center': inline })}>
        <div className="flex flex-col">
          <h3 className="text-h3 font-semibold font-serif">{title}</h3>
          {subTitle && <span className="text-body-small">{subTitle}</span>}
        </div>
        <div className={tx({ 'mt-xs w-full': !inline, 'flex items-center': inline })}>{children}</div>
      </div>
    </div>
  </DidomiCardContainer>
);
