import React, { useState, lazy, Suspense, useEffect } from 'react';
import { produce } from '@didomi/helpers';
import { DidomiSkeleton, DidomiHintbox, DidomiLargePush, DidomiLoader } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { PageHeader } from '@components';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig } from '@hooks';
import { EditPageOutletContext } from '@types';
import { ACCESS_POLICIES_CONFIG } from '@utils';

/* istanbul ignore next */
const LazyEditor = lazy(() => import('@components').then(module => ({ default: module.Editor })));

/**
 * Edit custom JSON page
 */
export const EditCustomJSONPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [JSONError, setJSONError] = useState(null);

  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { data: localConsentNoticeConfig, isLoading: loadingConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasChanges, saveNoticeChanges, openPreview } = useOutletContext<EditPageOutletContext>();
  const [initialJsonValue, setInitialJsonValue] = useState(null);

  useEffect(() => {
    if (!initialJsonValue && localConsentNoticeConfig) {
      setInitialJsonValue(localConsentNoticeConfig.custom_json);
    }
  }, [localConsentNoticeConfig, initialJsonValue]);

  const handleContinue = async () => {
    const isSaved = await saveNoticeChanges();

    if (isSaved) {
      navigate('/' + noticeId + '/customize');
    }
  };

  const handleJSONValidationChange = validationChanges => {
    if (validationChanges?.length) {
      const error = validationChanges[0];
      setJSONError(error?.text);
    } else {
      setJSONError('');
    }
  };

  const handleJSONChange = newChange => {
    try {
      const newJson = newChange ? JSON.parse(newChange) : {};
      updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'custom_json', newJson));
    } catch (error) {
      // Error is handled by the handleJSONValidationChange function
    }
  };

  return (
    <DidomiSkeleton isLoading={loadingConsentNoticeConfig} variant="layout" className="h-full p-l pb-6 w-full box-border !flex flex-col">
      <PageHeader
        isLoading={loadingConsentNoticeConfig}
        title="Custom json"
        description="Edit the custom json property"
        backText="Back to Customization"
        saveDisabled={!!JSONError || loadingConsentNoticeConfig || !hasChanges}
        withSeparator
        saveVisible={isCMPEditor}
        onPreview={openPreview}
        onSave={handleContinue}
        onCancel={() => navigate('/' + noticeId + '/customize')}
      />
      <div className="pb-s">
        <DidomiHintbox titleText="Custom json object that will be merged into the notice configuration." className="w-full mb-s">
          Any property documented in our documentation can be used here.
          <div slot="info">
            <DidomiLargePush
              className="w-[310px]"
              text="Developer documentation"
              highlightedText="How to custom json from the console"
              icon="export"
              href="https://developers.didomi.io/cmp/introduction"
              target="_blank"
            />
          </div>
        </DidomiHintbox>
        <div data-skeleton className={tx('text-field-label text-primary-blue-6 font-bold mb-xxxs', { 'text-secondary-red-3': !!JSONError })}>
          Custom JSON
        </div>

        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <DidomiLoader />
            </div>
          }
        >
          <LazyEditor
            mode="json"
            isLoading={loadingConsentNoticeConfig}
            defaultValue={JSON.stringify(initialJsonValue || {}, null, 2)}
            onChange={handleJSONChange}
            onValidate={handleJSONValidationChange}
            error={JSONError}
            name="custom_json_editor"
            readOnly={!isCMPEditor || loadingConsentNoticeConfig}
          />
        </Suspense>
      </div>
    </DidomiSkeleton>
  );
};
