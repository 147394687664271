import { useMemo } from 'react';
import { Platform } from '@didomi/cmp-generator';
import { useConsentNoticeApprovedStandardTexts } from '@hooks';
import { ConsentNoticeStandardTextWithContent } from '@types';

/**
 * Fetches all of the standard texts for the organization
 * */
export const useConsentNoticeStandardTextsByPlatform = (platform: Platform) => {
  const { data: standardTexts, ...status } = useConsentNoticeApprovedStandardTexts();

  const standardTextsByPlatform = useMemo(() => {
    if (standardTexts) {
      // Filter by org and by platform
      let filteredByPlatform = standardTexts.data?.filter(sT => sT.platforms.includes(platform)) as ConsentNoticeStandardTextWithContent[];

      return {
        data: filteredByPlatform,
        total: filteredByPlatform?.length,
      };
    }

    return undefined;
  }, [standardTexts, platform]);

  return {
    data: standardTextsByPlatform,
    ...status,
  };
};
