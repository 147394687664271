import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNotice, ConsentNoticeConfig } from '@types';

/**
 * Edits a notice, makes sure to update the list of notices with the edited notice value
 * */
export const useEditConsentNotice = (noticeId: string, options?: UseMutationOptions<AxiosResponse<ConsentNotice>, AxiosError, Partial<ConsentNotice>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (consentNotice: Partial<ConsentNotice>) => {
      // Config is ignored by the request, so no problem with sending it
      const requestBody = {
        ...consentNotice,
        organization_id: organizationId,
      };
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/${noticeId}?$with[]=config&$translations=true`, requestBody);
    },
    {
      ...options,
      onSuccess: (editedConsentNotice: AxiosResponse<ConsentNotice>, variables, context) => {
        const consentNoticesGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notices', organizationId]);
        /* istanbul ignore if */
        if (consentNoticesGet) {
          queryClient.setQueryData(['consent-notices', organizationId], {
            ...consentNoticesGet,
            data: consentNoticesGet.data.map(consentNotice => (consentNotice.id === noticeId ? { ...consentNotice, ...editedConsentNotice.data } : consentNotice)),
            total: consentNoticesGet.total + 1,
          });
        }

        const consentNoticeGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notice', noticeId]);

        /* istanbul ignore if */
        if (consentNoticeGet) {
          queryClient.setQueryData(['consent-notice', noticeId], { ...consentNoticeGet, ...editedConsentNotice.data });
        }

        const consentNoticesDefaultsGet: PaginatedResponse<ConsentNoticeConfig> = queryClient.getQueryData(['consent-notices-defaults', organizationId]);

        // If the edited notice is also a default notice we update the cache for the default notice list
        /* istanbul ignore if */
        if (consentNoticesDefaultsGet) {
          queryClient.setQueryData(['consent-notices-defaults', organizationId], {
            ...consentNoticesDefaultsGet,
            data: consentNoticesDefaultsGet.data.map(defaultNotice =>
              defaultNotice.notice_id === noticeId ? { ...defaultNotice, notice: { ...defaultNotice, ...editedConsentNotice.data } } : defaultNotice,
            ),
          });
        }

        if (options?.onSuccess) {
          options.onSuccess(editedConsentNotice, variables, context);
        }
      },
    },
  );
};
