import React from 'react';
import { DidomiTextSearchInput, DidomiPaginator, DidomiEmptyState } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { IabStackExtended, IabStacksSortableField } from '@types';
import { IabStackSelectionCard } from '../IabStackSelectionCard/IabStackSelectionCard';

const SEARCH_FIELDS = [{ field: 'name' }];

type IabStackSelectionListProps = {
  selectedIds: string[];
  stacks: IabStackExtended[];
  onSelectionChange?: (ids: string[]) => void;
};

export const IabStacksSelectionList = ({ selectedIds, stacks, onSelectionChange }: IabStackSelectionListProps) => {
  const { limit, page, sortConfig, search, changeLimit, changePage, changeSearch } = useTableState<IabStacksSortableField>({
    defaultLimit: 5,
  });

  const { data: displayedStacks, total: paginationTotal } = usePaginatedFilteredItems<IabStackExtended, IabStacksSortableField>(stacks, {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    page,
    sortConfig,
  });

  const handleSelectionChange = (event: CustomEvent<boolean>, stackId: string) => {
    const newSelection = event.detail ? [...selectedIds, stackId] : selectedIds.filter(id => id !== stackId);
    onSelectionChange(newSelection);
  };

  if (!stacks.length) {
    return <DidomiEmptyState illustration-name="traces-no-match-found">All the stacks were added to the selection</DidomiEmptyState>;
  }

  return (
    <section className={tx('flex flex-col')}>
      <DidomiTextSearchInput className={tx('mb-s')} value="" variant="modern" placeholder="Search for Stacks" hideErrorMessage onValueChange={e => changeSearch(e.detail)} />

      {displayedStacks.length > 0 && (
        <>
          <ul className={tx('flex flex-col gap-2 mb-4')} data-testid="stack-selection-list">
            {displayedStacks.map(stack => (
              <li key={stack.id}>
                <IabStackSelectionCard className={tx('w-full')} stack={stack} isSelected={selectedIds.includes(stack.id)} onChange={e => handleSelectionChange(e, stack.id)} />
              </li>
            ))}
          </ul>

          <DidomiPaginator
            data-testid="stacks-selection-paginator"
            className="self-center"
            page={page}
            itemCount={paginationTotal}
            size={limit}
            onPageSizeChange={changeLimit}
            onPageChange={changePage}
          />
        </>
      )}

      {/* Empty State */}
      {displayedStacks.length === 0 && <DidomiEmptyState illustration-name="traces-no-match-found">No results</DidomiEmptyState>}
    </section>
  );
};
