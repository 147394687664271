import React from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { DidomiSelectCustomEvent, DidomiToggleButtonOptionCustomEvent } from '@didomi/ui-atoms';
import { DidomiIcon, DidomiSelect, DidomiSelectOption, DidomiSelectOptions, DidomiToggleButton, DidomiToggleButtonOption } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ConsentNoticeConfig, NoticeLayer, PreviewSize } from '@types';
import { REGULATION_DICTIONARY } from '@utils';
import { PreviewVerticalLine, PreviewTrafficInfo, PREVIEW_SIZES_PER_PLATFORM } from './ConsentNoticePreviewHelpers';
import { ConsentNoticePreviewIframe } from '../ConsentNoticePreviewIframe/ConsentNoticePreviewIframe';
import { LanguageSelection } from '../LanguageSelection/LanguageSelection';

type ConsentNoticePreviewProps = {
  className?: string;
  config: ConsentNoticeConfig;
  layer?: NoticeLayer;
  previewSize: PreviewSize;
  trafficLanguage: string;
  setPreviewSize: (size: PreviewSize) => void;
  setTrafficLanguage: (lang: string) => void;
  onClose?: () => void;
  activeRegulationIds: Regulation[];
  previewRegulation: Regulation;
  setPreviewRegulation: (reg: Regulation) => void;
  id: string;
};

export const ConsentNoticePreview = ({
  className = '',
  config,
  layer,
  previewSize,
  trafficLanguage,
  setPreviewSize,
  setTrafficLanguage,
  onClose,
  activeRegulationIds,
  previewRegulation,
  setPreviewRegulation,
  id,
}: ConsentNoticePreviewProps) => {
  const previewSizes = PREVIEW_SIZES_PER_PLATFORM[config?.platform];

  const handlePreviewRegulationChange = (newRegulationEvent: DidomiSelectCustomEvent<Regulation>) => {
    setPreviewRegulation(newRegulationEvent.detail);
  };

  return (
    <section className={tx(`flex flex-col h-full w-full ${className}`)}>
      <div
        className={tx('flex flex-wrap items-center justify-between mb-xs pr-xxs pl-xs py-xs sm:(!py-0) bg-neutral-gray-1 border-1 border-solid border-neutral-gray-3 rounded-2xl')}
      >
        <div className={tx('mr-auto flex')}>
          {activeRegulationIds?.length && (
            <DidomiSelect
              variant="modern"
              aria-label="Select regulation"
              className={tx('mr-auto flex')}
              value={previewRegulation}
              hideErrorMessage
              onValueChange={handlePreviewRegulationChange}
              allowAutomaticSize
            >
              <DidomiSelectOptions>
                {activeRegulationIds.map(regulationId => (
                  <DidomiSelectOption
                    leftIcon={REGULATION_DICTIONARY[regulationId].iconMd}
                    key={regulationId}
                    label={REGULATION_DICTIONARY[regulationId]?.titleText}
                    value={regulationId}
                  ></DidomiSelectOption>
                ))}
              </DidomiSelectOptions>
            </DidomiSelect>
          )}
        </div>

        {previewSizes?.length && (
          <DidomiToggleButton className={tx('my-xxs mr-xs')} value={previewSize} onValueChange={(e: DidomiToggleButtonOptionCustomEvent<PreviewSize>) => setPreviewSize(e.detail)}>
            {previewSizes.map(({ value, icon }) => (
              <DidomiToggleButtonOption key={value} value={value} data-testid={value}>
                <DidomiIcon name={icon} aria-label={`View on ${value} screen`}></DidomiIcon>
              </DidomiToggleButtonOption>
            ))}
          </DidomiToggleButton>
        )}

        <PreviewVerticalLine className={tx('mr-xs')} />
        <PreviewTrafficInfo />

        <LanguageSelection
          enabledLanguages={config?.config?.languages?.enabled}
          className={tx('!max-w-[96px] mr-xxs')}
          variant="modern"
          value={trafficLanguage}
          onValueChange={lang => setTrafficLanguage(lang)}
        />

        {onClose && (
          <>
            <PreviewVerticalLine className={tx('mr-xxs')} />
            <button
              className={tx(
                'flex items-center font-semibold text-field-label gap-xs px-xxs text-primary-blue-6 rounded !outline-none focus-within:(ring-[3px] !ring-primary-blue-2)',
              )}
              onClick={onClose}
            >
              Close <DidomiIcon name="close-cancel" aria-hidden={true} />
            </button>
          </>
        )}
      </div>

      <ConsentNoticePreviewIframe id={id} size={previewSize} layer={layer} language={trafficLanguage} didomiConfig={config} regulation={previewRegulation} />
    </section>
  );
};
