import React from 'react';
import { DidomiCheckbox, DidomiLargePush } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCard } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { ACCESS_POLICIES_CONFIG } from '@utils';

export const IntegrationGoogleAdManagerACM = () => {
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { isStandardAtpListSelected, changeIsStandardAtpListSelected } = useLocalConsentNoticeConfigFacade();

  const handleGoogleAdditionalConsentMode = (selectionEvent: CustomEvent<boolean>) => {
    selectionEvent.stopImmediatePropagation();

    const isSelected = selectionEvent.detail;
    changeIsStandardAtpListSelected(isSelected);
  };

  return (
    <SelectionCard
      title="Google Ad Manager - Additional Consent Mode"
      data-testid="google-ad-manager"
      description={
        <div>
          If you use Google Ad Manager, Didomi automatically exposes consent to Google Ad Manager for Additional Technology Providers.
          <br />
          Because of restrictions with ad requests sizes, no more than 100 vendors can be passed to Google Ad Manager so we recommend not selecting more than 100 vendors with the
          Google label in Vendors & Purposes.
        </div>
      }
      illustration="integrations-google-add-manager"
    >
      <div className="flex flex-col">
        <DidomiCheckbox
          data-testid="google-ad-manager-full-atp"
          checked={isStandardAtpListSelected}
          onValueChange={handleGoogleAdditionalConsentMode}
          hideErrorMessage
          label="Use a standard list of ATP vendors"
          disabled={!isCMPEditor}
        />
        <small className="w-full text-neutral-gray-7 text-field-label mt-xxxs mb-xs">
          The vendors selected in the Vendors & Purposes will be ignored. This option is deprecated and is supported for backward compatibility. We recommend disabling it.
          (Deprecated)
        </small>

        <DidomiLargePush
          className="w-full children:(!max-w-none) mb-xs"
          highlighted-text="Additional Consent Mode"
          text="Documentation"
          icon="export"
          href="https://support.google.com/admanager/answer/9681920?hl=en"
          target="_blank"
        ></DidomiLargePush>
      </div>
    </SelectionCard>
  );
};
