import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { matchId } from '@didomi/helpers';
import { DragEndEvent, DragOverEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { ExtendedPreferenceCategoryGroup, ExtendedPreferencesCategoriesConfig } from '@types';
import { getItemsIndexes, movePurposeFromGroupToGroup, movePurposeFromGroupToRoot, movePurposeFromRootToGroup, updateCategoryGroupChildren } from './sortablePurposesArrayHelpers';

export const getUpdatedNoticeCategoriesAfterDragEnd = (
  event: DragEndEvent,
  categories: SDKConfigPreferencesCategory[],
  extendedCategories: ExtendedPreferencesCategoriesConfig,
) => {
  const { active, over } = event;

  if (!over?.id) {
    return null;
  }

  const activeId = active.id as string;
  const overId = over.id as string;
  const fromContainerId = active.data.current.sortable.containerId;
  const toContainerId = over.data.current.sortable.containerId;

  // If active item is the same as over item, or if different containers(moving between container handled in the drag over handler) - exit: no changed needed
  if (activeId === overId || fromContainerId !== toContainerId) {
    return null;
  }

  const groupContainer = extendedCategories.find(matchId(fromContainerId)) as ExtendedPreferenceCategoryGroup;

  if (groupContainer) {
    // Handle reordering inside the group
    const { fromIndex, toIndex } = getItemsIndexes(groupContainer.children, activeId, overId);
    const updatedCategories = categories.map(cat => {
      return cat.type === 'category' && cat.id === groupContainer.id ? updateCategoryGroupChildren(cat, fromIndex, toIndex) : cat;
    });
    return updatedCategories;
  } else {
    // Handle reordering at root level
    const { fromIndex, toIndex } = getItemsIndexes(extendedCategories, activeId, overId);
    return arrayMove(categories, fromIndex, toIndex);
  }
};

// Check whether we need to move dragging item between different groups
export const getUpdatedNoticeCategoriesOnDragOver = (event: DragOverEvent, categories: SDKConfigPreferencesCategory[]) => {
  const { active, over } = event;

  if (!over) {
    return;
  }

  const isDraggingGroup = active?.data.current?.isGroup;
  const purposeSdkId = active?.data.current?.purposeSdkId;
  const fromContainerId = active?.data.current.sortable?.containerId;
  const toContainerId = over?.data.current.sortable?.containerId;

  // If fromContainer is the same as toContainer, or if dragging group - exit: no changes needed
  if (fromContainerId === toContainerId || isDraggingGroup || !fromContainerId) {
    return null;
  }

  if (fromContainerId === 'root-sortable') {
    return movePurposeFromRootToGroup(categories, toContainerId, purposeSdkId);
  } else if (toContainerId === 'root-sortable') {
    return movePurposeFromGroupToRoot(categories, fromContainerId, purposeSdkId);
  } else {
    return movePurposeFromGroupToGroup(categories, fromContainerId, toContainerId, purposeSdkId);
  }
};
