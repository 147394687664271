import React from 'react';
import { produce } from '@didomi/helpers';
import { DidomiTextarea } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useCustomizationStepOptions, useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ACCESS_POLICIES_CONFIG, INPUT_DEBOUNCE_TIME } from '@utils';

interface CustomizationSpiLayerProps {
  noticeId: string;
  regulationId: string;
}

export const CustomizationSpiLayer = ({ noticeId, regulationId }: CustomizationSpiLayerProps) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { trafficLanguage } = useCustomizationStepOptions({ defaultTraffic: localConsentNoticeConfig?.config?.languages?.default });
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { regulationConfig, regulationConfigIndex } = useLocalRegulationConfig(noticeId, regulationId);

  const spiContent = regulationConfig?.regulation_settings?.sensitivePersonalInformation;

  const handleMainTextChange = (newText: string) => {
    updateLocalConfig(
      produce(
        localConsentNoticeConfig,
        `regulation_configurations[${regulationConfigIndex}].regulation_settings.sensitivePersonalInformation.description.${trafficLanguage}`,
        newText || undefined,
      ),
    );
  };

  return (
    <div data-testid="customization-spi-layer" className="flex flex-col gap-xs">
      <h3 className="text-body-normal font-semibold text-primary-pink-5">SPI text</h3>

      <DidomiTextarea
        label="Main text"
        disabled={loadingNoticeConfig || !isCMPEditor}
        debounce={INPUT_DEBOUNCE_TIME}
        rows={8}
        placeholder="Collected sensitive personal information includes your precise geolocation and your passport number. These information are retained for no more than 24 months and they are not shared or sold to 3rd parties."
        hideErrorMessage
        value={spiContent?.description?.[trafficLanguage] || ''}
        onValueChange={e => handleMainTextChange(e.detail)}
      />
    </div>
  );
};
