import { QueryClient } from 'react-query';
import { ConsentNoticeConfig, PaginatedResponse } from '@types';

type Entity = { id: string; organization_id: string };

/**
 * Replaces an entity by its ID in the query client data or inserts it if it does not exist.
 * The function handles paginated responses and operates on a single page.
 *
 * @template T - A type that extends Entity.
 * @param {QueryClient} queryClient - The query client to update.
 * @param {string} key - The key of the data to be updated/inserted.
 * @param {T} entity - The new entity to be inserted or replaced.
 */
export function replaceByIdOrInsert<T extends Entity>(queryClient: QueryClient, key: string, entity: T) {
  const response: PaginatedResponse<T> = queryClient.getQueryData([key, entity.organization_id]);
  if (response?.data) {
    const present: boolean = Boolean(response.data.find(cachedEntity => cachedEntity.id === entity.id));
    queryClient.setQueryData([key, entity.organization_id], {
      ...response,
      data: present ? response.data.map(cachedEntity => (cachedEntity.id === entity.id ? { ...cachedEntity, ...entity } : cachedEntity)) : [...response.data, entity],
      total: present ? response.total : response.total + 1,
    });
  }
}

/**
 * Replaces an entity in a queryClient cache with a given entity. If this entity already exists, it merges both entities.
 *
 * @template T - The Entity type that extends the base Entity class.
 *
 * @function replace
 *
 * @param {QueryClient} queryClient - The QueryClient instance where the entity is located.
 * @param {string} key - The key used to store the entity within the queryClient instance.
 * @param {T} entity - The entity to replace or merge with existing entity.
 *
 * @returns {void}
 */
export function mergeOrSet<T extends Entity>(queryClient: QueryClient, key: string, entity: T) {
  const cachedEntity: T = queryClient.getQueryData([key, entity.id]);
  queryClient.setQueryData([key, entity.id], cachedEntity ? { ...cachedEntity, ...entity } : entity);
}

/**
 * Removes entity from the QueryClient's cache by its ID.
 *
 * @template T - The type of the entity. Expected to extend `Entity`.
 *
 * @param {QueryClient} queryClient - An instance of the QueryClient.
 * @param {string} key - The unique key used in the query to identify it later.
 * @param {T} entityToRemove - The entity object that needed to be removed.
 *
 * @returns {void}
 */
export function removeByIdIfExists<T extends Entity>(queryClient: QueryClient, key: string, entityToRemove: T) {
  const response: PaginatedResponse<T> = queryClient.getQueryData([key, entityToRemove.organization_id]);
  if (response?.data) {
    const present: boolean = Boolean(response.data.find(cachedEntity => cachedEntity.id === entityToRemove.id));
    queryClient.setQueryData([key, entityToRemove.organization_id], {
      ...response,
      data: response.data.filter(entity => entity.id !== entityToRemove.id),
      total: present ? response.total - 1 : response.total,
    });
  }
}

/**
 * The Admin-API only exposes a subset of the NoticeConfig via the Notice association:
 *  - config.id
 *  - config.default
 *  - config.targets
 *  - config.platform
 *  - config.template_id
 *  - config.config.app.vendors.iab.enabled
 *
 * @param {ConsentNoticeConfig} noticeConfig - The NoticeConfig to pick properties from.
 *
 * @returns {Partial<ConsentNoticeConfig>} An object with the selected properties from the NoticeConfig
 */

export function pickNoticeConfigForNotice(noticeConfig: ConsentNoticeConfig): Partial<ConsentNoticeConfig> {
  return {
    id: noticeConfig.id,
    default: noticeConfig.default,
    targets: noticeConfig.targets,
    platform: noticeConfig.platform,
    template_id: noticeConfig.template_id,
    ...(noticeConfig.config?.app?.vendors?.iab?.enabled !== undefined ? { config: { app: { vendors: { iab: { enabled: noticeConfig.config.app.vendors.iab.enabled } } } } } : {}),
  };
}
