import React from 'react';
import { DidomiSelect, DidomiSelectOption, DidomiSelectOptions } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ConsentNoticeConfigRegulation } from '@types';
import { REGULATION_DICTIONARY } from '@utils';

type RegulationPickerProps = {
  className?: string;
  selectedRegulationId: string;
  regulations: ConsentNoticeConfigRegulation[];
  onRegulationSelected: (regulationId: string) => void;
};

export const RegulationPicker = ({ className, selectedRegulationId, regulations = [], onRegulationSelected }: RegulationPickerProps): JSX.Element => {
  return (
    <DidomiSelect
      className={tx('!w-[200px]', className)}
      value={selectedRegulationId}
      variant="modern"
      onValueChange={e => onRegulationSelected(e.detail as string)}
      placeholder="Select regulation"
      disabled={regulations.length === 1}
      hideErrorMessage
      data-testid="regulation-select"
      allowAutomaticSize
    >
      <DidomiSelectOptions>
        {regulations.map(({ regulation_id }) => (
          <DidomiSelectOption
            key={regulation_id}
            label={REGULATION_DICTIONARY[regulation_id]?.titleText}
            value={regulation_id}
            leftIcon={REGULATION_DICTIONARY[regulation_id].iconMd}
          />
        ))}
      </DidomiSelectOptions>
    </DidomiSelect>
  );
};
