import React, { useEffect, useMemo, useState } from 'react';
import { matchId } from '@didomi/helpers';
import { DidomiButton, DidomiHintbox, DidomiModal } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { useNavigate } from 'react-router-dom';
import { ConfirmApplyTemplateModal, PublishTemplateModal, UnlinkTemplateModal } from '@modals';
import { ConsentNoticeTemplateConfig } from '@types';
import { getTemplateRegulationSelectionPerNotice } from '@utils';
import { useApplyTemplateToNotices } from '../../hooks/data/consent-notice-config/useApplyTemplateToNotices.hook';
import { useTemplateConsentNotices } from '../../hooks/data/consent-notices/useTemplateConsentNotices.hook';
import { BlockLoader } from '../BlockLoader/BlockLoader';
import { TemplateConsentNoticeList } from '../TemplateConsentNoticeList/TemplateConsentNoticeList';
import { VendorListApplicationProgress } from '../VendorListApplicationProgress/VendorListApplicationProgress';

interface LinkTemplateToNoticesProps {
  templateId: string;
  templateConfig: ConsentNoticeTemplateConfig;
  saveTemplateBeforeApplying: () => Promise<boolean>;
}

/*
 * Container component that is responsible for:
 * - displaying the list of notices and their regulations
 * - showing regulations that are linked to the current template
 * - allowing linking template to the selected regulations - via applying(patch notice config) or applying+publishing(patch notice config + deploy with message)
 * - allowing unlinking template from the regulation
 */
export const LinkTemplateToNotices = ({ templateId, templateConfig, saveTemplateBeforeApplying }: LinkTemplateToNoticesProps) => {
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();
  const { data: consentNotices = [], isLoading: isLoadingNotices } = useTemplateConsentNotices();
  const { applyTemplateToNotices, unlinkRegulationsFromNotice, isApplyingTemplate, isUnlinkingRegulations, linkingProgressState } = useApplyTemplateToNotices({
    onError: error => displaySnackbar(error?.response?.data?.message || 'There was an error saving the changes', { variant: 'error', duration: 5000 }),
    onSuccess: () => displaySnackbar(`Your changes have been saved`, { icon: 'success-small' }),
  });

  // Hash map of linked regulations per notice that are currently selected on UI: Record<noticeId, regulationConfigId[]>
  const [selectedRegConfigIdsPerNotice, setSelectedRegConfigIdsPerNotice] = useState<Record<string, string[]> | undefined>(undefined);

  // Modals state
  const [unlinkRegConfigIdsForNotice, setUnlinkRegConfigIdsForNotice] = useState({ noticeId: null, regConfigIds: [] });
  const [isConfirmApplyTemplateModalOpen, setIsConfirmApplyTemplateModalOpen] = useState(false);
  const [isPublishModalOpen, setIsPublishModalOpen] = useState(false);

  const hasSelectedRegulations = useMemo(() => Object.values(selectedRegConfigIdsPerNotice || {}).some(ids => ids.length), [selectedRegConfigIdsPerNotice]);

  // Hash map of linked regulations configs ids per notice that are saved to DB: Record<noticeId, regulationConfigId[]>.
  const savedSelectedRegConfigIdsPerNotice = useMemo(() => {
    return consentNotices?.length ? getTemplateRegulationSelectionPerNotice(consentNotices, templateId) : {};
  }, [consentNotices, templateId]);

  useEffect(() => {
    // Current selection is set only once - when notices and template have being loaded
    if (consentNotices?.length && selectedRegConfigIdsPerNotice === undefined) {
      const savedSelection = getTemplateRegulationSelectionPerNotice(consentNotices, templateId);
      setSelectedRegConfigIdsPerNotice(savedSelection);
    }
  }, [consentNotices, templateId, selectedRegConfigIdsPerNotice]);

  const handleSelectionChange = (noticeId: string, selectedRegConfigIds: string[]) => {
    setSelectedRegConfigIdsPerNotice({ ...selectedRegConfigIdsPerNotice, [noticeId]: selectedRegConfigIds });

    // If id is present in API data but not in the selectedIds - show unlink confirmation modal
    const regConfigIdsToUnlink = savedSelectedRegConfigIdsPerNotice[noticeId]?.filter(id => !selectedRegConfigIds.includes(id));
    if (regConfigIdsToUnlink?.length) {
      setUnlinkRegConfigIdsForNotice({ noticeId, regConfigIds: regConfigIdsToUnlink });
    }
  };

  const handleUnlinkCancel = () => {
    // Revert back regulations that were attempted to be unlinked
    const currentSelection = selectedRegConfigIdsPerNotice[unlinkRegConfigIdsForNotice.noticeId];
    const selectionWithRevertedBackRegConfigIds = [...currentSelection, ...unlinkRegConfigIdsForNotice.regConfigIds];

    setSelectedRegConfigIdsPerNotice({ ...selectedRegConfigIdsPerNotice, [unlinkRegConfigIdsForNotice.noticeId]: selectionWithRevertedBackRegConfigIds });
    setUnlinkRegConfigIdsForNotice({ noticeId: null, regConfigIds: [] });
  };

  const handleUnlinkConfirm = () => {
    const notice = consentNotices.find(matchId(unlinkRegConfigIdsForNotice.noticeId));
    const regConfigIdsToUnlink = unlinkRegConfigIdsForNotice.regConfigIds;
    setUnlinkRegConfigIdsForNotice({ noticeId: null, regConfigIds: [] });

    unlinkRegulationsFromNotice({ notice, regConfigIdsToUnlink });
  };

  const handleApplyTemplateConfirm = async () => {
    setIsConfirmApplyTemplateModalOpen(false);
    const isSavedTemplate = await saveTemplateBeforeApplying();

    if (isSavedTemplate) {
      applyTemplateToNotices({ templateId, selectedRegConfigIdsPerNotice, notices: consentNotices });
    }
  };

  const handleApplyAndPublishConfirm = async (deploymentMessage: string) => {
    setIsPublishModalOpen(false);
    const isSavedTemplate = await saveTemplateBeforeApplying();

    if (isSavedTemplate) {
      applyTemplateToNotices({ templateId, selectedRegConfigIdsPerNotice, notices: consentNotices, publishingOptions: { message: deploymentMessage } });
    }
  };

  return (
    <section className="w-full">
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-xs">Regulations selected per notice</h2>

      <DidomiHintbox className="mb-m" titleText="Select the regulations you want covered in your notice and its related vendor list.">
        Once you have activated at least one regulation, the notice will be linked to the vendor list and apply the selected template to the regulations you&apos;ve chosen.
      </DidomiHintbox>

      <TemplateConsentNoticeList
        isLoading={isLoadingNotices}
        selectionPerNotice={selectedRegConfigIdsPerNotice || {}}
        notices={consentNotices}
        templateId={templateId}
        templateConfig={templateConfig}
        onChangeSelection={handleSelectionChange}
      />

      <div className="flex gap-xs">
        <DidomiButton className="mr-auto" variant="secondary" onClick={() => navigate(`/vendors-list`)}>
          Cancel
        </DidomiButton>
        <DidomiButton disabled={!hasSelectedRegulations || templateId === 'new'} onClick={() => setIsConfirmApplyTemplateModalOpen(true)}>
          Apply
        </DidomiButton>
        <DidomiButton disabled={!hasSelectedRegulations || templateId === 'new'} variant="top" iconRight="save-publish" onClick={() => setIsPublishModalOpen(true)}>
          Apply & Publish Notices
        </DidomiButton>
      </div>

      {/* Modals */}
      <UnlinkTemplateModal isOpen={unlinkRegConfigIdsForNotice.regConfigIds.length > 0} onCancel={handleUnlinkCancel} onConfirm={handleUnlinkConfirm} />
      <ConfirmApplyTemplateModal isOpen={isConfirmApplyTemplateModalOpen} onCancel={() => setIsConfirmApplyTemplateModalOpen(false)} onConfirm={handleApplyTemplateConfirm} />
      <PublishTemplateModal isOpen={isPublishModalOpen} onCancel={() => setIsPublishModalOpen(false)} onSubmit={handleApplyAndPublishConfirm} />

      <DidomiModal isOpen={isApplyingTemplate || isUnlinkingRegulations} variant="slim" permanent>
        <div>
          {isApplyingTemplate && <VendorListApplicationProgress itemsProcessed={linkingProgressState.processed} itemsTotal={linkingProgressState.total} />}
          {isUnlinkingRegulations && <BlockLoader>Unlinking template...</BlockLoader>}
        </div>
      </DidomiModal>
    </section>
  );
};
