import React from 'react';
import { DidomiBottomBar, DidomiButton, DidomiRadio, DidomiIllustration, DidomiRadioGroup, DidomiSelectionBlock } from '@didomi/ui-atoms-react';
import { useActiveOrganization, useCustomFeature } from '@didomi/utility-react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';
import { useScrollToTop } from '@hooks';
import { CreateNoticeExitPromptModal } from '@modals';
import { CONSENT_NOTICES_PLATFORMS, CONSENT_NOTICES_PLATFORMS_DESC } from '@types';

/**
 * Consent Notice creation - Select platform page
 */
export const CreateNoticeSelectPlatformStep = (): JSX.Element => {
  useScrollToTop();
  const [hasCTVFeature] = useCustomFeature('widgets-notices-ctv');
  const { organizationId } = useActiveOrganization();
  const [selectedPlatform, setSelectedPlatform] = useLocalStorage(`create-notice-platform-${organizationId}`, 'web');
  const navigate = useNavigate();

  const updateValueSelection = newPlatform => {
    setSelectedPlatform(newPlatform);
  };

  return (
    <>
      <div className="px-12 pb-[152px] flex items-center justify-center">
        <DidomiRadioGroup value={selectedPlatform} alignItems="center" className="w-full items-center" onValueChange={v => updateValueSelection(v.detail)}>
          {CONSENT_NOTICES_PLATFORMS.map(platform =>
            platform !== 'ctv' || hasCTVFeature ? (
              <div key={platform} className="w-full max-w-[300px] h-[330px] 2xl:(max-w-[390px] h-[370px])">
                <DidomiSelectionBlock className="w-full h-full" variant="accent">
                  <label className="flex flex-col items-center cursor-pointer text-center text-primary-blue-6 h-full pb-xs 2xl:pt-s">
                    <DidomiIllustration className="mb-2" name={CONSENT_NOTICES_PLATFORMS_DESC.get(platform)?.illustration} />
                    <h2 className="font-semibold mb-2">{CONSENT_NOTICES_PLATFORMS_DESC.get(platform)?.title}</h2>
                    <p className="text-body-small mb-s">{CONSENT_NOTICES_PLATFORMS_DESC.get(platform)?.description}</p>
                    <DidomiRadio data-testid={'select-platform-' + platform} className="mt-auto" value={platform} />
                  </label>
                </DidomiSelectionBlock>
              </div>
            ) : null,
          )}
        </DidomiRadioGroup>
      </div>
      <DidomiBottomBar isOpen={!!selectedPlatform} variant="light" className="!fixed bottom-0 w-full" closable={false}>
        <div slot="actions">
          <DidomiButton data-testid="create-platform-next-step-button" onClick={() => navigate('/add/' + selectedPlatform)}>
            Go to next step
          </DidomiButton>
        </div>
      </DidomiBottomBar>
      <CreateNoticeExitPromptModal
        isBlock
        whitelist={[/\/add/g]}
        subTitle="Please pay attention"
        title="Your Consent Notice will not be saved"
        description="You are about to exit your consent notice setup without completing the flow."
      />
    </>
  );
};
