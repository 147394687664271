import { NoticeConfig, RegulationConfigAggregator } from '@didomi/cmp-generator';
import { ConsentNoticeConfig, ConsentNoticeConfigRegulation } from '@types';
import { matchDefaultRegulationId } from './matchRegulationId';
import { produceListOfChanges } from './produceListOfChanges';

/**
 * This function is used to find regulation configurations that need to be fixed.
 * Regulation configurations are aggregated with to their output configuration, and then filtered
 * It filters out regulation configurations that are not GDPR or do not have IAB TCF integration enabled.
 * It also filters out configurations that already have bulk action on purposes enabled.
 * It the returns the original NoticeRegulationConfig objects that need to be fixed, by looking up their index in the original array.
 *
 * @param noticeConfig The ConsentNoticeConfig to check
 * @returns {ConsentNoticeConfigRegulation[]} An array of regulation configurations that need to be fixed
 */
function findRegConfigsToFix(noticeConfig: ConsentNoticeConfig): ConsentNoticeConfigRegulation[] {
  return noticeConfig.regulation_configurations
    .map((regConfig, index): [ConsentNoticeConfigRegulation, number] => [regConfig, index])
    .filter(([regConfig]) => regConfig.regulation_id === 'gdpr')
    .map(([regConfig, index]): [NoticeConfig, number] => [RegulationConfigAggregator.aggregateRegulationConfigs(noticeConfig, regConfig), index])
    .filter(([aggConfig]) => aggConfig.config?.app?.vendors?.iab?.enabled && !aggConfig.config?.notice?.enableBulkActionOnPurposes)
    .map(([, index]) => noticeConfig.regulation_configurations[index]);
}

/**
 * This function is used to automatically enable bulk action on purposes for the given regulation configurations.
 * It creates a list of changes to be made and applies them to the given ConsentNoticeConfig.
 *
 * @param noticeConfig The ConsentNoticeConfig to edit
 * @param configs An array of regulation configurations where bulk action on purposes should be enabled
 * @returns {ConsentNoticeConfig} The updated ConsentNoticeConfig
 */
function enableBulkActionsOnRegConfigs(noticeConfig: ConsentNoticeConfig, configs: ConsentNoticeConfigRegulation[]): ConsentNoticeConfig {
  return produceListOfChanges(
    configs.map(regConfig => ({
      path: `regulation_configurations[${noticeConfig.regulation_configurations.findIndex(({ id }) => regConfig.id === id)}].config.notice.enableBulkActionOnPurposes`,
      value: true,
    })),
    noticeConfig,
  );
}

/**
 * This hook is used to automatically enable the bulk action on purposes for IAB TCF vendors when the IAB TCF integration is enabled.
 *
 * @param noticeConfig The ConsentNoticeConfig to edit
 * @returns {ConsentNoticeConfig} The updated ConsentNoticeConfig
 */
export const autoEnableIabTcfBulkActionsPatchHook = (noticeConfig: ConsentNoticeConfig): ConsentNoticeConfig => {
  if (findRegConfigsToFix(noticeConfig).length === 0) {
    return noticeConfig;
  }

  // First try fixing the default regulation configuration and see if that is enough
  noticeConfig = enableBulkActionsOnRegConfigs(noticeConfig, [noticeConfig.regulation_configurations.find(matchDefaultRegulationId('gdpr'))]);

  if (findRegConfigsToFix(noticeConfig).length === 0) {
    // There are no non-default GDPR RegConfigs to fix.
    return noticeConfig;
  }

  // Enable bulk actions on any non-default GDPR RegConfigs
  return enableBulkActionsOnRegConfigs(noticeConfig, findRegConfigsToFix(noticeConfig));
};
