import React, { useMemo, useState } from 'react';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { VendorFilterType } from '@types';

interface VendorsListToolbarProps {
  resultsText: string;
  selectedCount: number;
  onSearchChange: (value: string) => void;
  onFilterChange: (value: VendorFilterType) => void;
}

/**
 * Vendors and Purposes list toolbar
 */
export const VendorsListToolbar = ({ resultsText, selectedCount, onSearchChange, onFilterChange }: VendorsListToolbarProps): JSX.Element => {
  const [typeFilter, setTypeFilter] = useState<VendorFilterType>();

  const handleSearchChange = (e: CustomEvent<string>) => onSearchChange(e.detail);
  const handleFilterChange = (e: DidomiFiltersBarCustomEvent<{ key: string; newValue: VendorFilterType }>) => {
    const filterValue = e.detail.newValue;
    setTypeFilter(filterValue);
    onFilterChange(filterValue);
  };

  const filters = useMemo(
    () => ({
      filterByType: {
        placeholder: 'All vendors',
        value: typeFilter,
        maxVisibleItems: 5,
        options: [
          { value: VendorFilterType.ALL, label: 'All vendors' },
          { value: VendorFilterType.CUSTOM, label: 'Custom vendors' },
          { value: VendorFilterType.ATP, label: 'Google ATP vendors' },
          { value: VendorFilterType.IAB, label: 'IAB vendors' },
          { value: VendorFilterType.SELECTED, label: 'Selected vendors' },
        ],
      },
    }),
    [typeFilter],
  );

  return (
    <section className="flex justify-between gap-8 mb-4">
      <div className="flex gap-2 self-end text-body-small">
        <div className="text-primary-blue-6 font-semibold">{resultsText}</div>
        <div className="text-secondary-cobalt-blue-3">{selectedCount} selected</div>
      </div>

      <DidomiFiltersBar
        data-testid="filters-bar"
        searchValue=""
        showSearch
        collapsibleFilters={false}
        filters={filters}
        onSearchTextChange={handleSearchChange}
        onFilterValueChange={handleFilterChange}
      />
    </section>
  );
};
