import React from 'react';
import { DidomiTooltip, DidomiIcon } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

const PREVIEW_SIZES = {
  lg: { value: 'large', icon: 'consent-type-website' },
  sm: { value: 'small', icon: 'consent-type-mobile' },
  smAMP: { value: 'small', icon: 'device-mobile-amp' },
};

export const PREVIEW_SIZES_PER_PLATFORM = {
  web: [PREVIEW_SIZES.lg, PREVIEW_SIZES.sm],
  amp: [PREVIEW_SIZES.lg, PREVIEW_SIZES.smAMP],
  app: [PREVIEW_SIZES.sm],
};

export const PreviewTrafficInfo = () => {
  return (
    <div className={tx('flex items-center gap-xxxs')}>
      <div className={tx('py-xxs text-field-label text-neutral-gray-7')}>Traffic preview</div>
      <div id="trafficTooltipContent" hidden>
        <strong>If the selected language is not activated in the language section,</strong> the consent banner will be displayed in English by default.
      </div>
      <DidomiTooltip className={tx('flex items-center')} placement="top" contentElementId="trafficTooltipContent" variant="helper">
        <DidomiIcon name="helper-text" />
      </DidomiTooltip>
    </div>
  );
};

export const PreviewVerticalLine = ({ className = '' }) => {
  return (
    <div className={tx(`h-full hidden sm:flex ${className}`)}>
      <div className={tx('w-0 h-full border-l-1 border-solid border-neutral-gray-3')} role="separator" aria-orientation="vertical"></div>
      <div className={tx('w-0 h-full border-r-1 border-solid border-white')} role="separator" aria-orientation="vertical"></div>
    </div>
  );
};
