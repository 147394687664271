import { Frameworks } from '@didomi/cmp-generator';

/**
 * Generates a framework selection object based on the provided boolean values.
 *
 * @param {boolean | null} selectedGCM - The selection status for GCM. If `true`, it will be included in the final object.
 * @param {boolean | null} selectedIABTCF - The selection status for IABTCF. If `true`, it will be included in the final object.
 * @param {boolean | null} selectedGPP - The selection status for GPP. If `true`, it will be included in the final object.
 *
 * @returns {Frameworks | null} An object representing the selected frameworks, or `null` if none are selected.
 */
export const getFrawemorkFromPresetSelection = (selectedGCM: boolean | null, selectedIABTCF: boolean | null, selectedGPP: boolean | null): Frameworks | null => {
  const result: Partial<Frameworks> = {};

  if (selectedGCM === true) {
    result.gcm = selectedGCM;
  }

  if (selectedIABTCF === true) {
    result.tcf = selectedIABTCF;
  }

  if (selectedGPP === true) {
    result.gpp = selectedGPP;
  }

  // Return null if the result object is empty
  if (Object.keys(result).length === 0) {
    return null;
  }

  return result as Frameworks;
};
