import React, { useEffect, useMemo, useState } from 'react';
import { reduceToRecord } from '@didomi/helpers';
import { DidomiButton, DidomiModal, DidomiModalActions, DidomiButtonGroup, DidomiModalContent, DidomiCheckbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { customAlphabet as generateNanoId } from 'nanoid';
import nolookalikes from 'nanoid-dictionary/nolookalikes';
import { CategoryLanguageData, PreferenceCategoryGroup } from '@types';
import { purposeCategoryToLanguageDataList } from '@utils';
import { PurposeCategoryLanguageCard } from './PurposeCategoryLanguageCard';
import { PurposeCategoryLanguageForm } from './PurposeCategoryLanguageForm';

type PurposeCategoryFormModalProps = {
  isOpen: boolean;
  noticeName: string;
  category?: PreferenceCategoryGroup;
  withButtonsCustomization?: boolean;
  onCancel?: () => void;
  onSave?: (category: PreferenceCategoryGroup) => void;
};

export const PurposeCategoryFormModal = ({ isOpen, noticeName, category, withButtonsCustomization, onCancel, onSave }: PurposeCategoryFormModalProps) => {
  const [categoryLanguages, setCategoryLanguages] = useState<CategoryLanguageData[]>([]);
  const [activeLanguageForm, setActiveLanguageForm] = useState<'new' | string | null>(null);
  const [isCategoryExpanded, setIsCategoryExpanded] = useState(false);

  const selectedLanguages = useMemo(() => categoryLanguages.map(l => l.language), [categoryLanguages]);

  useEffect(() => {
    setIsCategoryExpanded(category?.expanded || false);
    setActiveLanguageForm(category ? null : 'new');
    setCategoryLanguages(purposeCategoryToLanguageDataList(category, withButtonsCustomization));
  }, [isOpen, category, withButtonsCustomization]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleAddLanguage = (languageData: CategoryLanguageData) => {
    setCategoryLanguages([...categoryLanguages, languageData]);
    setActiveLanguageForm(null);
  };

  const handleDeleteLanguage = (languageToDelete: string) => {
    const filteredLanguages = categoryLanguages.filter(l => l.language !== languageToDelete);
    setCategoryLanguages(filteredLanguages);
  };

  const handleUpdateLanguage = (languageData: CategoryLanguageData, originalLanguage: string) => {
    const updatedLanguages = categoryLanguages.map(l => (l.language === originalLanguage ? languageData : l));
    setCategoryLanguages(updatedLanguages);
    setActiveLanguageForm(null);
  };

  /**
   * Get a language hash object for the specified field.
   * @returns The language hash object with language as key and corresponding field as a value.
   * @example getLangHashForField('name') => { en: 'Name in English', fr: 'Name in French' }
   */
  const getLangHashForField = (field: 'name' | 'description' | 'agree' | 'disagree') => {
    return reduceToRecord<string, CategoryLanguageData>('language', field, categoryLanguages);
  };

  const handleSave = () => {
    // TODO: discuss moving id generation to the API(CNC-162)
    const id = category?.id || generateNanoId(nolookalikes, 8)();
    const children = category?.children || [];
    const name = getLangHashForField('name');
    const description = getLangHashForField('description');

    const categoryData = {
      ...category,
      id,
      children,
      name,
      description,
      type: 'category' as const,
      expanded: isCategoryExpanded,
      ...(withButtonsCustomization ? { agree: getLangHashForField('agree'), disagree: getLangHashForField('disagree') } : {}),
    };

    onSave(categoryData);
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div data-testid="purpose-category-notice-name" className={tx('text-body-extra-small text-primary-blue-6')}>
        {noticeName}
      </div>
      <div data-testid="purpose-category-modal-title" className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}>
        {category ? 'Edit' : 'Add'} category
      </div>

      <DidomiModalContent className={tx('w-full')}>
        <ul className={tx('flex flex-col gap-xxs mb-xs text-left')} data-testid="added-category-languages">
          {categoryLanguages.map(langData => (
            <li key={langData.language}>
              {activeLanguageForm === langData.language ? (
                <PurposeCategoryLanguageForm
                  languageData={langData}
                  disabledLanguages={selectedLanguages.filter(l => l !== langData.language)}
                  withButtonsCustomization={withButtonsCustomization}
                  onSave={updates => handleUpdateLanguage(updates, langData.language)}
                  onCancel={() => setActiveLanguageForm(null)}
                />
              ) : (
                <PurposeCategoryLanguageCard
                  languageData={langData}
                  onEdit={() => setActiveLanguageForm(langData.language)}
                  onDelete={() => handleDeleteLanguage(langData.language)}
                />
              )}
            </li>
          ))}

          {activeLanguageForm === 'new' && (
            <PurposeCategoryLanguageForm
              disabledLanguages={selectedLanguages}
              withButtonsCustomization={withButtonsCustomization}
              onSave={handleAddLanguage}
              onCancel={() => setActiveLanguageForm(null)}
            />
          )}
        </ul>

        <DidomiButton variant="secondary" iconRight="new-create" className={tx('w-full mb-s')} disabled={activeLanguageForm === 'new'} onClick={() => setActiveLanguageForm('new')}>
          Add a language
        </DidomiButton>

        <DidomiCheckbox checked={isCategoryExpanded} label="Expand category when showing the list of purposes" onValueChange={e => setIsCategoryExpanded(e.detail)} />

        <div className={tx('text-left text-xs text-primary-blue-6')}>
          * <span className={tx('italic')}>Fields are required</span>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton data-testid="cancel-category-modal" variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton data-testid="save-category" disabled={categoryLanguages.length === 0} onClick={handleSave}>
            Save
          </DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
