import { useEffect, useMemo, useState } from 'react';
import { SDKConfig } from '@didomi/cmp-generator';
import { isActiveIAB2Vendor } from '@utils';
import { useVendors } from './useVendors.hook';

export const useVendorsSelectionState = ({ config }: { config: SDKConfig }) => {
  const { data: vendors = [], isLoading } = useVendors();
  const [selectedIds, setSelectedIds] = useState([]);
  const [isAllIabSelected, setAllIabSelected] = useState(false);

  useEffect(() => {
    const allVendors = config?.app?.vendors?.include;

    if (isLoading || !allVendors) return;

    // Remove any non-existing vendor ids (can be removed from the data manager but still be present in the config)
    const validVendors = allVendors.filter(vendorId => vendors.some(v => v.id === vendorId));

    setSelectedIds(validVendors || []);
    setAllIabSelected((config?.app?.vendors?.iab?.enabled && config?.app?.vendors?.iab?.all) || false);
  }, [config, vendors, isLoading]);

  const iabVendorsIds = useMemo(() => vendors.filter(isActiveIAB2Vendor).map(({ id }) => id), [vendors]);

  const preselectedIds = useMemo(() => (isAllIabSelected ? iabVendorsIds : []), [isAllIabSelected, iabVendorsIds]);
  const selectedTotalCount = useMemo(() => {
    const uniqueIds = new Set(selectedIds.concat(preselectedIds));
    return uniqueIds.size;
  }, [selectedIds, preselectedIds]);

  const updateSelection = (newSelectedIds: string[]) => {
    setSelectedIds(newSelectedIds);
  };

  return {
    selectedTotalCount,
    selectedIds,
    preselectedIds,
    isAllIabSelected,
    updateSelection,
    setAllIabSelected,
    isLoading,
    iabVendorsIds,
  };
};
