import { useMemo } from 'react';
import { Regulation, RegulationConfigAggregator } from '@didomi/cmp-generator';
import { isRegulationEnabled, matchDefaultRegulationId } from '@utils';
import { useLocalConsentNoticeConfig } from '../data/consent-notice-config-local/useLocalConsentNoticeConfig.hook';

export const useLocalRegulationConfig = (noticeId: string, regulationId?: string) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const regulationConfigIndex = useMemo(() => {
    return localConsentNoticeConfig?.regulation_configurations?.findIndex(matchDefaultRegulationId(regulationId));
  }, [regulationId, localConsentNoticeConfig]);

  const regulationConfig = useMemo(() => {
    if (!localConsentNoticeConfig?.regulation_configurations) {
      return null;
    }
    return localConsentNoticeConfig?.regulation_configurations[regulationConfigIndex];
  }, [localConsentNoticeConfig, regulationConfigIndex]);

  const defaultRegulations = useMemo(() => {
    return localConsentNoticeConfig?.regulation_configurations.filter(r => r.is_default_regulation_config) || [];
  }, [localConsentNoticeConfig]);

  const activeDefaultRegulations = useMemo(() => {
    return defaultRegulations.filter(r => isRegulationEnabled(r)) || [];
  }, [defaultRegulations]);

  const activeRegulationIds = useMemo(() => activeDefaultRegulations.map(r => r.regulation_id as Regulation), [activeDefaultRegulations]);

  const aggregatedRegConfig = useMemo(() => {
    if (localConsentNoticeConfig && regulationConfig) {
      return RegulationConfigAggregator.aggregateRegulationConfigs(localConsentNoticeConfig, regulationConfig);
    }
  }, [localConsentNoticeConfig, regulationConfig]);

  return {
    isLoading: loadingNoticeConfig,
    regulationConfig,
    regulationConfigIndex,
    activeDefaultRegulations,
    activeRegulationIds,
    defaultRegulations,
    aggregatedRegConfig,
  };
};
