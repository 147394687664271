import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { Territory, TERRITORY_CONTENT } from '@types';

interface TerritoryCardProps {
  continent: Territory;
  className?: string;
  children?: React.ReactChild;
  territoryCheckbox?: React.ReactChild;
}

export const TerritoryCard = ({ continent, className, territoryCheckbox, children }: TerritoryCardProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
  return (
    <div
      className={`h-full bg-gradient-to-br from-${continent} via-${continent} via-${continent} to-neutral-white ${className || ''} shadow-platform-card rounded-lg`}
      data-testid={'continent-' + continent}
    >
      <div className="w-full flex overflow-hidden">
        <div className="p-s">{territoryCheckbox}</div>
        <div className="flex-1 "></div>
        <img className="max-h-[126px]" src={`${ASSETS_URL}/assets/illustrations/continents/${continent}.svg`} alt="" />
      </div>
      <div className="flex-col px-s py-xs">
        {continent && (
          <label className="h3 font-bold text-primary-blue-6" htmlFor={`${continent}-checkbox`}>
            {TERRITORY_CONTENT[continent]?.name}
          </label>
        )}
        <div className="mt-s">{children}</div>
      </div>
    </div>
  );
};
