import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalActions, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type VendorsSPIModalProps = {
  isOpen: boolean;
  spiDescription: string;
  vendorsNames: string[];
  onClose: () => void;
};

export const VendorsSpiModal = ({ isOpen, spiDescription, vendorsNames = [], onClose }: VendorsSPIModalProps) => {
  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onClose();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div className={tx('text-body-extra-small text-primary-blue-6')}>Vendors who ask for:</div>
      <div data-testid="modal-spi-name" className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}>
        {spiDescription}
      </div>

      <DidomiModalContent className={tx('text-left w-full')}>
        <ul data-testid="modal-spi-vendors-list" className={tx('flex flex-col gap-2')}>
          {vendorsNames.map(vendorName => (
            <li key={vendorName} className={tx('p-4 rounded-md bg-neutral-gray-1 text-body-small text-primary-blue-6')}>
              {vendorName}
            </li>
          ))}
        </ul>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onClose}>
          Close
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
