import React, { useMemo } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { DidomiSelect, DidomiSelectOption, DidomiSelectOptions } from '@didomi/ui-atoms-react';
import { RegulationTemplateData } from '@types';

interface TemplateNoticeRegulationSelectProps {
  selectedRegConfigIds: string[];
  templateMetadata: Record<Regulation, RegulationTemplateData>;
  onChange: (ids: string[]) => void;
}

export const TemplateNoticeRegulationSelect = ({ selectedRegConfigIds, templateMetadata, onChange }: TemplateNoticeRegulationSelectProps) => {
  const enabledRegulationIds = useMemo(() => Object.keys(templateMetadata) as Regulation[], [templateMetadata]);

  return (
    <DidomiSelect
      className="!max-w-[150px]"
      value={selectedRegConfigIds}
      // Used inside tests to verify the selected regulations - as for now our mock select does not expose any way to check it
      data-value={selectedRegConfigIds}
      multiSelectLabel="Regulations"
      multi
      size="xs"
      hideErrorMessage
      placeholder="Select regulations"
      onValueChange={e => onChange(e.detail as string[])}
    >
      <DidomiSelectOptions>
        {enabledRegulationIds.map(regulationId => (
          <DidomiSelectOption
            data-testid={templateMetadata[regulationId].regulation_config_id}
            key={regulationId}
            label={regulationId.toUpperCase()}
            value={templateMetadata[regulationId].regulation_config_id}
          />
        ))}
      </DidomiSelectOptions>
    </DidomiSelect>
  );
};
