import React, { useEffect, useMemo, useState } from 'react';
import { DidomiSkeleton, DidomiBottomBar, DidomiButton, DidomiButtonGroup, DidomiRadioGroup } from '@didomi/ui-atoms-react';
import { useSnackbar, useCustomFeature } from '@didomi/utility-react';
import { useNavigate, useParams } from 'react-router-dom';
import { PresetSelectionCard } from '@components';
import { useCreateConsentNotice, useRegulations } from '@hooks';
import { CreateNoticeExitPromptModal, LoadingModal } from '@modals';
import { CONSENT_NOTICES_PLATFORMS, Frameworks } from '@types';

/**
 * Consent Notice creation - Select configuration strategy page
 */
export const CreateNoticeSelectConsentStrategy = (): JSX.Element => {
  const [consentStrategy, setConsentStrategy] = useState(Frameworks.CUSTOM);
  const [hasCTVFeature] = useCustomFeature('widgets-notices-ctv');
  const navigate = useNavigate();
  let { platformType } = useParams();
  const { displaySnackbar } = useSnackbar();
  const { data: regulations, isLoading: isLoadingGdprRegulation } = useRegulations(null, null, { filterById: 'gdpr' });

  const gdprGeos = useMemo(() => {
    const gdpr = regulations?.data[0];
    return (gdpr?.geos || []).map(geo => geo.country.id);
  }, [regulations]);

  const { mutate: createNotice, isLoading: creatingNotice } = useCreateConsentNotice({
    onSuccess: newNotice => {
      displaySnackbar(`${newNotice.data.name} has been created`, { icon: 'success-small' });
      navigate('/' + newNotice.data.id);
    },
    onError: () => {
      displaySnackbar('There was an error creating the notice', { variant: 'error' });
    },
  });

  useEffect(() => {
    if (!platformType) return;

    // If CTV but user does not have the feature, go back
    if (platformType === 'ctv' && !hasCTVFeature) {
      navigate('/add');
    }

    // If platform doesn't exist go back to add
    if (CONSENT_NOTICES_PLATFORMS.findIndex(p => p === platformType) === -1) {
      navigate('/add');
    }
  }, [hasCTVFeature, platformType, navigate]);

  const isCustomPreset = consentStrategy === Frameworks.CUSTOM;

  const handleClick = () => {
    if (isCustomPreset) {
      createNotice({ platform: platformType, preset: {}, gdprGeos });
    } else {
      navigate(`/add/${platformType}/frameworks`);
    }
  };

  return (
    <DidomiSkeleton variant="layout" isLoading={isLoadingGdprRegulation}>
      <div className="px-12 pb-[152px] flex items-center justify-center">
        <DidomiRadioGroup value={consentStrategy} alignItems="center" className="w-full" onValueChange={v => setConsentStrategy(v.detail)}>
          <div className="w-full flex flex-wrap justify-center gap-xs">
            <PresetSelectionCard
              className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
              value={Frameworks.CUSTOM}
              titleText="No preset"
              description="No specific framework is applied, full customization is possible."
              illustration="CMP-framework-default"
            />

            <PresetSelectionCard
              className="w-[300px] min-h-[330px] 2xl:(w-[400px] h-[370px])"
              value="available-frameworks"
              titleText="Available Frameworks"
              description="Choose the frameworks that suit your business."
              illustration="CMP-multi-framework"
            ></PresetSelectionCard>
          </div>
        </DidomiRadioGroup>
      </div>

      <DidomiBottomBar isOpen variant="light" className="!fixed bottom-0 w-full" closable={false}>
        <div slot="actions">
          <DidomiButtonGroup>
            <DidomiButton data-testid="create-strategy-previous-step-button" variant="secondary" onClick={() => navigate('/add')}>
              Previous
            </DidomiButton>
            <DidomiButton data-testid="create-strategy-next-step-button" disabled={!consentStrategy || isLoadingGdprRegulation} onClick={handleClick}>
              <span>{isCustomPreset ? 'Generate my Consent Notice' : 'Go to next step'}</span>
            </DidomiButton>
          </DidomiButtonGroup>
        </div>
      </DidomiBottomBar>
      <CreateNoticeExitPromptModal
        isBlock={true}
        /* Allow any path but the main one "/" */
        whitelist={[/\/./g]}
        subTitle="Please pay attention"
        title="Your Consent Notice will not be saved"
        description="You are about to exit your consent notice setup without completing the flow."
      />
      <LoadingModal isOpen={creatingNotice} title="We are generating your Consent Notice..." />
    </DidomiSkeleton>
  );
};
