export const CONSENT_NOTICES_PLATFORMS = ['web', 'amp', 'app', 'ctv'];

export const CONSENT_NOTICES_PLATFORMS_DESC = new Map();

CONSENT_NOTICES_PLATFORMS_DESC.set('web', {
  icon: 'website',
  displayName: 'Website',
  illustration: 'CMP-website',
  title: 'Website',
  description: 'Create a consent notice for a website.',
});

CONSENT_NOTICES_PLATFORMS_DESC.set('amp', {
  icon: 'amp',
  displayName: 'AMP',
  illustration: 'CMP-amp',
  title: 'AMP website',
  description: 'Create a consent notice for a website built with AMP.',
});

CONSENT_NOTICES_PLATFORMS_DESC.set('app', {
  icon: 'mobile',
  displayName: 'App',
  illustration: 'CMP-mobile',
  title: 'Mobile app',
  description: 'Create a consent notice for a mobile app on iOS or Android (native or Unity).',
});

CONSENT_NOTICES_PLATFORMS_DESC.set('ctv', {
  icon: 'ctv',
  displayName: 'CTV',
  illustration: 'CMP-ctv',
  title: 'CTV App',
  description: 'Create a consent notice for a smart tv. Supports tv OS, Android or web-based OS.',
});
