import { NumericPartner } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { fetchAllItems } from '@utils';

/**
 * Fetches the full list of vendor purposes ids
 */
export const useVendorsNumericIds = (options?: UseQueryOptions<NumericPartner[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchVendorsNumericIds = async () => {
    const allVendorsNUmericIds = await fetchAllItems<NumericPartner>(
      axiosWithInterceptors,
      `${CONFIG.environmentConfig.apiBaseUrl}metadata/numeric-partners?organization_id=${organizationId}`,
    );
    return allVendorsNUmericIds;
  };

  return useQuery<NumericPartner[], AxiosError>(['vendors-numeric-ids', organizationId], fetchVendorsNumericIds, options);
};
