import { produce } from '@didomi/helpers';

interface Update {
  path: string;
  value: unknown;
}

/*
 * Helper function to apply list of updates to the object
 *
 * Usage:
 * const updates = [{ path: 'text_id', value: 'test' }, { path: 'config.color', value: '#fff' }]
 * produceListOfChanges(updates, noticeConfig)
 */
export const produceListOfChanges = <T>(updates: Update[], target: T): T => {
  return updates.reduce((acc, item) => produce(acc as object, item.path, item.value) as T, target);
};
