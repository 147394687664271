import { EmbedCodeParams } from '@types';
import * as webEmbedCodes from './web-embed-codes.json';

/**
 * Get an embed code from the webEmbedCodes.json file
 *
 * @param key Key of the embed code to get
 * @param params Parameters to replace in the embed code
 *
 * @returns {string} The embed code
 */
export const getEmbedCode = (key: keyof typeof webEmbedCodes, params: EmbedCodeParams) => {
  const embedCode = webEmbedCodes[key];

  if (embedCode) {
    return embedCode
      .replace(/{{Your-API-Key}}/g, params.apiKey)
      .replace(/{{Notice-ID}}/g, params.noticeId)
      .replace(/{{SDK-URL}}/g, params.source)
      .replace(/{{CONFIG-PATH}}/g, CONFIG.sdk.url) // Set config path depending on the environment(for the embedded code that supports this parameter)
      .replace(/"{{GDPR-Applies-Globally}}"/g, params.gdprAppliesGlobally ? 'true' : 'false');
  }

  return '';
};

/**
 * Get the embed code for Web platform
 * @returns {string}
 * @param params
 */
export const getWebEmbedCode = (params: EmbedCodeParams) => {
  return getEmbedCode('domain_tcfv2', params);
};

/**
 * Get the embed code for the current notice and Web platform
 * @returns {string}
 * @param params
 */
export const getManualWebEmbedCode = (params: EmbedCodeParams) => {
  if (params.apis.usp && params.apis.tcf) {
    return getEmbedCode('noticeid_tcfv2_usp', params);
  }

  if (params.apis.usp) {
    return getEmbedCode('noticeid_usp', params);
  }

  if (params.apis.tcf) {
    return getEmbedCode('noticeid_tcfv2', params);
  }

  return getEmbedCode('noticeid', params);
};

/**
 * Get the embed code for AMP platform
 * @returns {string}
 * @param params
 */
export const getAMPEmbedCode = (params: EmbedCodeParams) => {
  return `<amp-consent id="didomi" layout="nodisplay" type="didomi">
<script type="application/json">
{
  "uiConfig": { "overlay": false },
  "clientConfig": {"gdprAppliesGlobally": ${params.gdprAppliesGlobally}, "config": {"app": {"apiKey": "${params.apiKey}"}}}
}
</script>
</amp-consent>`;
};

/**
 * Get the embed code for the current Notice and AMP platform
 * @returns {string}
 * @param params
 */
export const getManualAMPEmbedCode = (params: EmbedCodeParams) => {
  return `<amp-consent id="didomi" layout="nodisplay" type="didomi">
<script type="application/json">
{
  "uiConfig": { "overlay": false },
  "clientConfig": {"gdprAppliesGlobally": ${params.gdprAppliesGlobally}, "config": {"app": {"apiKey": "${params.apiKey}"}}, "noticeId": "${params.noticeId}"}
}
</script>
</amp-consent>`;
};

export const getStaticEmbedCode = (params: EmbedCodeParams) => {
  return params.apis.tcf ? getEmbedCode('static_tcfv2', params) : getEmbedCode('static', params);
};
