import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNoticeTemplate } from '@types';

/**
 * Fetches the detail of a Consent Notice Template by ID
 * */
export const useConsentNoticeTemplate = (templateId: string, options?: UseQueryOptions<ConsentNoticeTemplate>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchConsentNotice = async () => {
    const response = await axiosWithInterceptors.get<ConsentNoticeTemplate>(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/${templateId}`);

    return response.data;
  };

  return useQuery<ConsentNoticeTemplate, AxiosError>(['consent-notice-template', templateId], fetchConsentNotice, {
    enabled: templateId !== 'new' && !!templateId,
    ...options,
  });
};
