import React, { createContext, useContext, useEffect, useState } from 'react';
import { DidomiCollapse, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

const CLASSES_PER_VARIANT = {
  root: {
    'top-level': 'p-xs bg-neutral-gray-1 rounded-xl',
    'sub-level': '',
  },
};

export const ExpandableSectionContext = createContext<{ isExpanded: boolean; id: string }>({ isExpanded: false, id: null });

interface ExpandableSectionProps {
  id: string;
  className?: string;
  variant: 'top-level' | 'sub-level';
  header: React.ReactChild;
  expanded?: boolean;
  onToggleExpanded?: (expanded: boolean) => void;
}

export const ExpandableSection: React.FunctionComponent<ExpandableSectionProps> = ({ id, className, variant, header, children, expanded, onToggleExpanded }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Allow to control the expanded state from the parent component
  useEffect(() => {
    if (expanded !== undefined) {
      setIsExpanded(expanded);
    }
  }, [expanded]);

  const toggle = () => {
    setIsExpanded(e => !e);
    onToggleExpanded?.(!isExpanded);
  };

  return (
    <div className={tx(className, CLASSES_PER_VARIANT.root[variant])}>
      <ExpandableSectionContext.Provider value={{ isExpanded, id }}>
        {/* Allow to toggle section by clicking on the whole header as an UX enhancement - additionally to the title it includes button that is the main trigger */}
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className={tx('text-primary-blue-6 w-full rounded')} onClick={toggle}>
          {header}
        </div>

        <DidomiCollapse id={`${id}-collapse`} isExpanded={isExpanded}>
          {children}
        </DidomiCollapse>
      </ExpandableSectionContext.Provider>
    </div>
  );
};

// Trigger button that is used to toggle visibility of the section
// Parent component's header(expendable section - header row) is listening for the click event
export const ExpandableSectionToggle = ({ className, a11yLabel, withBorder = true }: { className?: string; a11yLabel: string; withBorder?: boolean }) => {
  const { isExpanded, id } = useContext(ExpandableSectionContext);

  return (
    <DidomiIconButton
      icon="down-on-sm"
      size="small"
      variant={withBorder ? 'rounded-border' : 'rounded'}
      aria-expanded={isExpanded ? 'true' : 'false'}
      aria-controls={`${id}-collapse`}
      aria-label={`Toggle ${a11yLabel}`}
      className={tx(className, 'transition-transform duration-500 ease-in-out', { 'rotate-180': isExpanded })}
    />
  );
};
