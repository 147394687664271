import React from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiIllustration } from '@didomi/ui-atoms-react';

type UnlinkTemplateModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => void;
};

export const UnlinkTemplateModal = ({ isOpen, onCancel, onConfirm }: UnlinkTemplateModalProps) => {
  return (
    <DidomiModal returnFocusAfterClose={false} data-testid="unlink-template-modal" isOpen={isOpen} permanent>
      <DidomiIllustration className="mb-6" name="warning-modal" />

      <div data-testid="stack-notice-name" className="text-body-big-semibold text-primary-pink-5 font-bold mb-xxxs">
        You’re about to unlink your vendors list from the selected notices
      </div>
      <div className="text-h3 text-primary-blue-6 font-serif mb-xs">Do you want to continue?</div>

      <DidomiModalContent className="text-left w-full"></DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>

        <DidomiButton type="button" iconRight="remove" variant="top" onClick={onConfirm}>
          Unlink Vendors list
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
