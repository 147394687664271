import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut, useConsentNotice } from '@hooks';
import { ConsentNoticeConfig } from '@types';

/**
 * Fetches a particular consent notice config
 * */
export const useConsentNoticeConfig = (noticeId: string, options?: UseQueryOptions<ConsentNoticeConfig>) => {
  const queryClient = useQueryClient();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const { data: notice, isLoading: loadingNotice } = useConsentNotice(noticeId);
  const noticeConfigId = notice?.config?.id;

  const fetchConsentNoticeConfig = async (): Promise<ConsentNoticeConfig> => {
    const response = await axiosWithInterceptors.get<ConsentNoticeConfig>(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs/${noticeConfigId}?$translations=true`, {
      headers: {
        v: '2',
      },
    });

    return response.data;
  };

  const result = useQuery<ConsentNoticeConfig, AxiosError>(['consent-notice-config', noticeConfigId], fetchConsentNoticeConfig, {
    enabled: !!notice?.config?.id,
    ...options,
    onSuccess: (fetchedConsentNotice: ConsentNoticeConfig) => {
      queryClient.setQueryData(['consent-notice-config-local', noticeConfigId], fetchedConsentNotice);

      if (options?.onSuccess) {
        options.onSuccess(fetchedConsentNotice);
      }
    },
  });

  return {
    ...result,
    isLoading: result.isLoading || loadingNotice,
  };
};
