import React, { useMemo } from 'react';
import {
  DidomiEmptyState,
  DidomiTable,
  DidomiTableHeading,
  DidomiTableHeaderRow,
  DidomiTableTh,
  DidomiTableBody,
  DidomiTableRow,
  DidomiTableTd,
  DidomiPaginator,
  DidomiIcon,
  DidomiListShortener,
} from '@didomi/ui-atoms-react';
import { TemplateNoticePublicationStatus, TextWithHighlighterAndTooltip } from '@components';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import {
  ConsentNoticeSortableField,
  ConsentNoticePlatformIcon,
  CONSENT_NOTICES_PLATFORMS_DESC,
  ConsentNoticeTemplateConfig,
  NoticeWithTemplatePublicationStatus,
  ConsentNoticeWithTemplateMetaData,
} from '@types';
import { getTableResultsText, matchNoticeWithTemplatePublicationStatus } from '@utils';
import { BlockLoader } from '../BlockLoader/BlockLoader';
import { TemplateConsentNoticeListToolbar } from '../TemplateConsentNoticeListToolbar/TemplateConsentNoticeListToolbar';
import { TemplateNoticeRegulationSelect } from '../TemplateNoticeRegulationSelect/TemplateNoticeRegulationSelect';

const SEARCH_FIELDS = [{ field: 'name' }, { field: 'id' }];

interface TemplateConsentNoticeListProps {
  isLoading?: boolean;
  notices: ConsentNoticeWithTemplateMetaData[];
  selectionPerNotice: Record<string, string[]>;
  onChangeSelection: (noticeId: string, selectedRegConfigIds: string[]) => void;
  templateId: string;
  templateConfig?: ConsentNoticeTemplateConfig;
}

/**
 * List of notices with their regulations that are available for being linked to the template.
 * This component is responsible for:
 * - rendering notices and regulations that are passed as a props
 * - allowing to search/filter notices
 * - showing regulations that are linked to the current template
 * - emitting selection change event when regulation selection is changed
 */
export const TemplateConsentNoticeList = ({
  isLoading,
  notices,
  selectionPerNotice,
  templateId,
  templateConfig,
  onChangeSelection,
}: TemplateConsentNoticeListProps): JSX.Element => {
  const tableState = useTableState<ConsentNoticeSortableField>({ defaultSorting: { field: 'name', dir: 'asc' }, defaultLimit: 5 });
  const filters = useMemo(
    () => [matchNoticeWithTemplatePublicationStatus(tableState.filters?.status as NoticeWithTemplatePublicationStatus, templateId)],
    [tableState.filters?.status, templateId],
  );

  const { data: displayConsentNotices, total: paginationTotal } = usePaginatedFilteredItems<ConsentNoticeWithTemplateMetaData, ConsentNoticeSortableField>(notices, {
    search: tableState.search,
    searchFields: SEARCH_FIELDS,
    limit: tableState.limit,
    filters,
    page: tableState.page,
    sortConfig: tableState.sortConfig as any,
  });

  const handleSelectionChange = (noticeId: string, selectedRegConfigIds: string[]) => {
    onChangeSelection(noticeId, selectedRegConfigIds);
  };

  const noConsentNotices = !notices?.length;
  const noDisplayConsentNotices = !displayConsentNotices?.length;
  const showEmptyState = !isLoading && (noConsentNotices || noDisplayConsentNotices);
  const resultsText = getTableResultsText(paginationTotal, notices.length);

  return (
    <div className="w-full h-full mb-m">
      {!noConsentNotices && (
        <div className="flex flex-col">
          <TemplateConsentNoticeListToolbar
            resultsText={resultsText}
            search={tableState.search}
            statusFilter={tableState.filters?.status as NoticeWithTemplatePublicationStatus}
            onSearchChange={tableState.changeSearch}
            onFilterChange={tableState.changeFilters}
          />

          {!noDisplayConsentNotices && (
            <>
              <DidomiTable
                data-testid="template-notices-table"
                fixedLayout
                className="mb-xs"
                loading={isLoading}
                sortable
                sortBy={tableState.sortConfig.field}
                sortDirection={tableState.sortConfig.dir}
                onSortChange={tableState.changeSorting}
              >
                <DidomiTableHeading>
                  <DidomiTableHeaderRow>
                    <DidomiTableTh sortId="name" data-testid="sortName" style={{ flexBasis: 80 }}>
                      NAME
                    </DidomiTableTh>
                    <DidomiTableTh>TYPE</DidomiTableTh>
                    <DidomiTableTh>TARGETS</DidomiTableTh>
                    <DidomiTableTh>PUBLICATION STATUS</DidomiTableTh>
                    <DidomiTableTh>
                      <div className="flex items-center gap-xxs">
                        <DidomiIcon name="world" />
                        REGULATIONS COVERED
                      </div>
                    </DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>
                <DidomiTableBody>
                  {displayConsentNotices?.map(notice => (
                    <DidomiTableRow key={notice.id} data-testid={notice.id}>
                      <DidomiTableTd style={{ flexBasis: 80 }}>
                        <div className="w-full">
                          <TextWithHighlighterAndTooltip searchText={tableState.search} text={notice.name} />
                          <div className="flex items-center gap-xxxs mt-xxs text-primary-blue-4 text-[11px] leading-[14px]">
                            ID: <TextWithHighlighterAndTooltip searchText={tableState.search} text={notice.id} />
                          </div>
                        </div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div className="flex items-center gap-xxs truncate w-full">
                          <DidomiIcon className="text-primary-blue-4" name={ConsentNoticePlatformIcon[notice.config.platform]} />
                          {CONSENT_NOTICES_PLATFORMS_DESC.get(notice.config.platform)?.displayName}
                        </div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div className="w-full">{notice.config?.targets?.length > 0 ? <DidomiListShortener itemsToShow={2} items={notice.config?.targets} /> : '-'}</div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <TemplateNoticePublicationStatus notice={notice} templateId={templateId} templateConfig={templateConfig} />
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div className="flex items-center gap-xxs">
                          <DidomiIcon name={selectionPerNotice[notice.id]?.length ? 'success-small' : 'status-empty'} />
                          <TemplateNoticeRegulationSelect
                            selectedRegConfigIds={selectionPerNotice[notice.id]}
                            templateMetadata={notice.template_metadata}
                            onChange={selectedIds => handleSelectionChange(notice.id, selectedIds)}
                          />
                        </div>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>
              <DidomiPaginator
                data-testid="consent-notices-paginator"
                className="self-end"
                page={tableState.page}
                itemCount={paginationTotal}
                size={tableState.limit as any}
                onPageSizeChange={tableState.changeLimit}
                onPageChange={tableState.changePage}
              />
            </>
          )}
        </div>
      )}

      {/* Empty State */}
      {showEmptyState && (
        <DidomiEmptyState illustration-name="traces-no-result-found" className="h-full">
          <div slot="title">It&apos;s empty here!</div>
          {noConsentNotices ? "You don't have any notice created." : 'No results'}
        </DidomiEmptyState>
      )}

      {/* Loading State */}
      {isLoading && <BlockLoader>Loading notices</BlockLoader>}
    </div>
  );
};
