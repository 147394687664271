import { useQuery, UseQueryOptions, useQueryClient } from 'react-query';
import { useLocalConsentNotice, useConsentNoticeConfig } from '@hooks';
import { ConsentNoticeConfig } from '@types';

/**
 * Retrieves from the react-query cache the "local" version of a notice config that's being edited
 * */
export const useLocalConsentNoticeConfig = (noticeId: string, options?: UseQueryOptions<ConsentNoticeConfig>) => {
  const { data: localNotice, isLoading: loadingNotice } = useLocalConsentNotice(noticeId);
  const queryClient = useQueryClient();

  const fetchLocalConsentNoticeConfig = async (): Promise<ConsentNoticeConfig> => {
    return Promise.resolve(queryClient.getQueryData(['consent-notice-config-local', localNotice?.config?.id]));
  };

  const result = useQuery<ConsentNoticeConfig>(['consent-notice-config-local', localNotice?.config?.id], fetchLocalConsentNoticeConfig, {
    ...options,
    enabled: !!noticeId && !!localNotice?.id,
  });
  const hasNoResults = !result.data && result.isFetched;

  const realNoticeConfigQuery = useConsentNoticeConfig(noticeId, { enabled: hasNoResults });

  return hasNoResults
    ? realNoticeConfigQuery
    : {
        ...result,
        isLoading: result.isLoading || loadingNotice,
      };
};
