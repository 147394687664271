import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut, useConsentNoticeApprovedStandardTextContents } from '@hooks';
import { ConsentNoticeStandardText, ConsentNoticeStandardTextWithContent, PaginatedResponse } from '@types';
import { getApprovedStandardText } from '@utils';

/**
 * Fetches all of the standard texts for the organization
 * It combines the results with the approved text contents and makes sure to remove those
 * texts that don't have an approved content.
 * */
export const useConsentNoticeApprovedStandardTexts = (options?: UseQueryOptions<PaginatedResponse<ConsentNoticeStandardTextWithContent>>) => {
  const { organizationId } = useActiveOrganization();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { data: approvedStandardTextContents, isFetched: loadedStandardTextContents } = useConsentNoticeApprovedStandardTextContents();

  const fetchConsentNoticeStandardTexts = async (): Promise<PaginatedResponse<ConsentNoticeStandardText>> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeStandardText>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/texts?$translations=true&$limit=5000`,
    );

    if (response.data?.data?.length) {
      // Filter by org (current org and all text that is not bound to an org)
      let filteredByOrg = response.data.data.filter(sT => !sT.organization_id || sT.organization_id === organizationId) as ConsentNoticeStandardTextWithContent[];

      if (filteredByOrg?.length) {
        // Add the approved content matching each standard text and remove those that don't have content
        filteredByOrg = filteredByOrg.map(sT => ({ ...sT, content: getApprovedStandardText(approvedStandardTextContents, sT.id)?.content })).filter(sT => !!sT.content);
      }

      return {
        ...response.data,
        data: filteredByOrg,
        total: filteredByOrg?.length,
      };
    }

    return response.data;
  };

  return useQuery<PaginatedResponse<ConsentNoticeStandardTextWithContent>, AxiosError>(['consent-notice-standard-texts'], fetchConsentNoticeStandardTexts, {
    enabled: options?.enabled !== false && loadedStandardTextContents,
    ...options,
  });
};
