import { Platform } from '@didomi/cmp-generator';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, RegulationState, RegulationUiModel } from '@types';
import { useRegulationReleaseStatusConfig } from '../../regulations/useRegulationReleaseStatusConfig.hook';

/**
 * Fetches the full list of regulations
 * */
export const useRegulations = (platform?: Platform, preset?: 'iab' | null, options?: UseQueryOptions<PaginatedResponse<RegulationUiModel>> & { filterById?: string }) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { releaseStatusConfig } = useRegulationReleaseStatusConfig();

  // Add ui_status field to regulation - this field represents UI visibility of the regulation
  const getRegulationUiFromRegulation = (reg: RegulationState): RegulationUiModel => {
    return { ...reg, ui_status: releaseStatusConfig[reg.release_status] };
  };

  const fetchRegulations = async () => {
    const url = new URL(`${CONFIG.environmentConfig.apiBaseUrl}compliance/v1/regulations`);
    platform && url.searchParams.append(`$search[platforms]`, platform);
    preset && url.searchParams.append(`$search[frameworks]`, 'iab');
    options?.filterById && url.searchParams.append(`$search[id]`, options.filterById);

    const response = await axiosWithInterceptors.get<PaginatedResponse<RegulationState>>(url.toString());

    return {
      ...response.data,
      data: response.data.data.map(getRegulationUiFromRegulation),
    };
  };

  return useQuery<PaginatedResponse<RegulationUiModel>, AxiosError>(['regulations', platform, preset].concat(options?.filterById || []), fetchRegulations, {
    ...options,
    enabled: options?.enabled,
  });
};
