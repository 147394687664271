import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { Purpose, Vendor } from '@types';
import { isIAB2Purpose, isIABPurpose } from './iabPurposeUtils';

// Extract purposes(`default_purposes_id` and `legitimate_interest_purposes_id`) from vendors
export const extractPurposesFromVendors = (vendors: Vendor[]) => {
  return vendors.reduce((acc, vendor) => {
    vendor.default_purposes_id?.forEach(id => acc.add(id));
    vendor.legitimate_interest_purposes_id?.forEach(id => acc.add(id));
    return acc;
  }, new Set<string>());
};

// Filter categories to only items that match purposes
const getFilteredCategoriesByPurposes = (categories: SDKConfigPreferencesCategory[], purposesSdkIds: Set<string>) => {
  return categories.reduce((acc, category) => {
    if (category.type === 'category') {
      const filteredChildren = category.children.filter(({ purposeId: sdk_id }) => purposesSdkIds.has(sdk_id));
      acc.push({ ...category, children: filteredChildren });
    } else if (purposesSdkIds.has(category.purposeId)) {
      acc.push(category);
    }

    return acc;
  }, []);
};

// Check whether purpose is already added to categories
const isPurposeInCategories = (purposeSdkId: string, categories: SDKConfigPreferencesCategory[]) => {
  return !!categories.find(category => {
    if (category.type === 'category') {
      return category.children.find(child => child.purposeId === purposeSdkId);
    } else {
      return category.purposeId === purposeSdkId;
    }
  });
};

/*
 * Get updated Purpose Categories based on the Vendors selection
 */
export const getPurposeCategoriesBasedOnVendors = (currentCategories: SDKConfigPreferencesCategory[], selectedVendors: Vendor[], purposes: Purpose[]) => {
  const purposesRecord = purposes?.reduce((acc, purpose) => {
    acc[purpose.id] = purpose;
    return acc;
  }, {});
  const vendorsPurposesIds = extractPurposesFromVendors(selectedVendors);
  const vendorsPurposesSdkIds = new Set(Array.from(vendorsPurposesIds).map(id => purposesRecord[id]?.sdk_id));
  const filteredCategories = getFilteredCategoriesByPurposes(currentCategories, vendorsPurposesSdkIds);

  const shouldPurposeBeAdded = (purposeId: string) => {
    const purpose = purposesRecord[purposeId];
    return purpose && !isPurposeInCategories(purpose.sdk_id, currentCategories) && (isIAB2Purpose(purpose) || !isIABPurpose(purpose));
  };

  // Get ids of purposes that aren't present in categories and convert them to category type
  const categoriesToAdd = Array.from(vendorsPurposesIds)
    .filter(shouldPurposeBeAdded)
    .map(purposeId => ({ type: 'purpose', purposeId: purposesRecord[purposeId].sdk_id }));

  return filteredCategories.concat(categoriesToAdd);
};
