import { ConsentNoticeConfig } from '@types';
import { getDefaultGdprConfigIab } from './getDefaultGdprConfig';

// Get IAB TCF version of default GDPR regulation from notice config
export const getIabTcfVersion = (noticeConfig: ConsentNoticeConfig) => {
  const gdprIabConfig = getDefaultGdprConfigIab(noticeConfig);
  const majorVersion = gdprIabConfig?.version;
  // If minor version is not set, default to 1
  const minorVersion = gdprIabConfig?.minorVersion === undefined ? 1 : gdprIabConfig?.minorVersion;

  return `${majorVersion}.${minorVersion}`;
};
