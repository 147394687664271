import React, { useEffect, useState } from 'react';
import { Regulations } from '@didomi/cmp-generator';
import { DidomiChip, DidomiSelectionBlock, DidomiButton, DidomiIcon, DidomiSwitch, DidomiTooltip } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useNavigate } from 'react-router-dom';
import { getCounterText, REGULATION_DICTIONARY } from '@utils';

interface RegulationSelectionCardProps {
  description: string;
  regulationId: string;
  checked: boolean;
  comingSoon?: boolean;
  purposesCount?: number;
  vendorsCount?: number;
  spiCount?: number;
  addPurposePath?: string;
  icon?: string;
  onValueChange?: (checked: boolean) => boolean | undefined;
  disabled?: boolean;
}

export const RegulationSelectionCard = ({
  description,
  regulationId,
  checked,
  purposesCount,
  vendorsCount,
  spiCount,
  addPurposePath = '/',
  comingSoon,
  onValueChange,
  disabled,
}: RegulationSelectionCardProps) => {
  const [checkedLocal, setCheckedLocal] = useState(checked);

  useEffect(() => {
    setCheckedLocal(checked);
  }, [checked]);

  const handleSelectionChange = () => {
    if (onValueChange(!checkedLocal) !== false) {
      setCheckedLocal(cL => !cL);
    }
  };

  const isCardDisabled = comingSoon || disabled;

  const isGDPRRegulation = regulationId === Regulations.GDPR;

  const getMaxWidthClassForText = () => {
    if (comingSoon) return 'max-w-[calc(100%-258px)]';
    if (isGDPRRegulation) return 'max-w-[calc(100%-150px)]';
    return 'max-w-[calc(100% - 45px)]';
  };
  const navigate = useNavigate();

  return (
    <div className="group max-h[110px]">
      <DidomiSelectionBlock className="w-full h-full" variant="hybrid" selected={checked} disabled={isCardDisabled}>
        <div className="flex flex-row items-center h-full">
          <div className="flex flex-1 justify-start min-w-[390px]">
            <DidomiSwitch
              data-testid={regulationId + '-switch-input'}
              className="!w-auto !flex-row gap-s cursor-pointer"
              valueSelected={true}
              valueNotSelected={false}
              value={checkedLocal}
              onValueChange={handleSelectionChange}
              disabled={isCardDisabled}
            ></DidomiSwitch>
            <div className={tx('flex mr-xs self-center w-[44px]', { grayscale: comingSoon })}>
              <DidomiIcon name={REGULATION_DICTIONARY[regulationId]?.icon} />
            </div>

            <div className={`flex flex-col justify-center text-primary-blue-6 ${getMaxWidthClassForText()}`}>
              <h3 className="h3-bold font-bold font-serif">
                <label htmlFor={regulationId + '-checkbox'}>{REGULATION_DICTIONARY[regulationId]?.titleText || regulationId?.toUpperCase()}</label>
              </h3>
              <DidomiTooltip only-if-truncated contentElementId={`${regulationId}-description`}>
                <p className="text-body-small text-left truncate" id={`${regulationId}-description`}>
                  {/* Temporal change to override GDPR's regulation name on the Regulation Service. */}
                  {isGDPRRegulation ? <>General Data Protection Regulation (Law 25-compliant)</> : description}
                </p>
              </DidomiTooltip>
            </div>
            {comingSoon && (
              <div className="flex items-center ml-auto mr-s">
                <DidomiChip variation="basic-outline" label="Coming soon" />
              </div>
            )}
          </div>

          <div
            className={tx('w-0 h-full border-l-1 border-solid border-neutral-gray-1 group-hover:border-neutral-gray-3', {
              'border-neutral-gray-3': isCardDisabled || checked,
            })}
            role="separator"
            aria-orientation="vertical"
          ></div>

          <div className="flex flex-1 justify-end items-center 2xl:(flex-col items-end)">
            {/* div is used instead of DidomiChipList - as the last one prone to error when children is removed dynamically (CNC-448) */}
            <div className="flex gap-xxs 2xl:(mb-xxs gap-xxs)">
              <DidomiChip variation={vendorsCount ? 'basic' : 'basic-outline'} basicType={vendorsCount ? 'default' : 'empty'} label={getCounterText(vendorsCount || 0, 'vendor')} />
              <DidomiChip
                variation={purposesCount ? 'basic' : 'basic-outline'}
                basicType={purposesCount ? 'default' : 'empty'}
                label={getCounterText(purposesCount || 0, 'purpose')}
              />
              {spiCount !== undefined && <DidomiChip variation={spiCount ? 'basic' : 'basic-outline'} basicType={spiCount ? 'default' : 'empty'} label={`${spiCount} SPI`} />}
            </div>
            <DidomiButton className="ml-s" size="small" disabled={!checked} data-testid={regulationId + '-vendors-and-purposes-button'} onClick={() => navigate(addPurposePath)}>
              Edit Vendors & Purposes
            </DidomiButton>
          </div>
        </div>
      </DidomiSelectionBlock>
    </div>
  );
};
