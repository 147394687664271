import React, { useEffect, useMemo, useState } from 'react';
import { SDKConfigAppIabRestriction, SDKConfigAppIabStacks, Regulations } from '@didomi/cmp-generator';
import { produce } from '@didomi/helpers';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton, DidomiHintbox, DidomiIconButton, DidomiSkeleton, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useSnackbar } from '@didomi/utility-react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { PageHeader, PublisherRestrictions, IabStacks, VendorsList, NoticePurposes, VendorsSpiList, IabAllVendors, ExpandableSection, ExpandableSectionToggle } from '@components';
import {
  useConsentNotice,
  useEditConsentNoticeConfig,
  useRestrictionsState,
  useVendorsSelectionState,
  useIabStacksState,
  usePurposesCategoriesState,
  useConsentNoticeTemplate,
  useLocalRegulationConfig,
  useCustomizationRegulationConfig,
  useSpiState,
  useLocalConsentNoticeConfig,
  useHasNoticeChanged,
  useEditLocalConsentNoticeConfig,
  useScrollToTop,
  useConsentNoticeTemplates,
} from '@hooks';
import { BlockNavigationModal, LoadingModal, SaveAsTemplateModal, TemplateSelectionModal } from '@modals';
import { PreferencesCategoriesConfig } from '@types';
import { ACCESS_POLICIES_CONFIG, DEFAULT_ERROR_TEXT, getApiErrorText, getDefaultGdprConfigIab, produceListOfChanges, REGULATION_DICTIONARY } from '@utils';

/**
 * Vendors and Purposes page
 */
export const VendorsAndPurposesPage = (): JSX.Element => {
  useScrollToTop();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
  const navigate = useNavigate();
  const { noticeId, regulationId } = useParams();
  const { displaySnackbar } = useSnackbar();
  const [isSaveAsTemplateModalOpen, setSaveAsTemplateModalOpen] = useState(false);
  const [isSelectTemplateModalOpen, setIsSelectTemplateModalOpen] = useState(false);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { regulationConfig, regulationConfigIndex } = useLocalRegulationConfig(noticeId, regulationId);
  const { hasEssentialPurposes, hasSPI, hasIabIntegration, hasButtonsCustomizationPerPurposeCategory } = useCustomizationRegulationConfig(regulationId);

  const { data: localConsentNoticeConfig, isLoading: loadingConsentNoticeConfig, remove: resetLocalConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);

  const isGcmVendorRequired = useMemo(() => regulationConfig?.gcm_enabled && localConsentNoticeConfig?.platform === 'web', [localConsentNoticeConfig, regulationConfig]);

  const restrictionsState = useRestrictionsState({ config: regulationConfig?.config });
  const vendorsSelection = useVendorsSelectionState({ config: regulationConfig?.config });
  const iabStacksState = useIabStacksState({ config: regulationConfig?.config });
  const purposesCategoriesState = usePurposesCategoriesState({ config: regulationConfig?.config });
  const spiState = useSpiState({ selectedVendorsIds: vendorsSelection.selectedIds, regulationId: regulationId, enabled: hasSPI });

  const { data: notice, isLoading: loadingNotice } = useConsentNotice(noticeId);
  const { mutateAsync: updateNoticeConfig, isLoading: savingNoticeConfig } = useEditConsentNoticeConfig();
  const { hasNoticeChanged, hasNoticeConfigChanged } = useHasNoticeChanged(noticeId);

  const isGDPRRegulation = regulationId === Regulations.GDPR;
  const isUsingTemplate = !!regulationConfig?.template_id;

  const { data: template, isLoading: loadingTemplate } = useConsentNoticeTemplate(regulationConfig?.template_id);
  const { data: { data: templates } = { data: [] } } = useConsentNoticeTemplates();

  const isDisabled = isUsingTemplate;

  // navigate to notice page if no regulation config is found for the current regulation
  useEffect(() => {
    if (localConsentNoticeConfig && !regulationConfig) {
      navigate(`/${noticeId}`);
    }
  }, [regulationConfig, localConsentNoticeConfig, navigate, noticeId]);

  const handleVendorsSelectionChange = (selectedIds: string[]) => {
    const updatedCategories = purposesCategoriesState.getPurposesCategoriesBasedOnVendors(selectedIds.concat(vendorsSelection.preselectedIds));
    vendorsSelection.updateSelection(selectedIds);
    purposesCategoriesState.updateCategories(updatedCategories);

    const updatesToApply = [
      { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.include`, value: selectedIds },
      { path: `regulation_configurations[${regulationConfigIndex}].config.preferences.categories`, value: updatedCategories },
    ];
    updateLocalConfig(produceListOfChanges(updatesToApply, localConsentNoticeConfig));
  };

  const handleAddRestriction = (newRestriction: SDKConfigAppIabRestriction) => {
    const updatedRestrictions = [...restrictionsState.restrictions, newRestriction];
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been added`, { icon: 'success-small' });
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.restrictions`, updatedRestrictions));
  };

  const handleUpdateRestriction = (updatedRestriction: SDKConfigAppIabRestriction) => {
    const updatedRestrictions = restrictionsState.restrictions.map(r => (r.id === updatedRestriction.id ? updatedRestriction : r));
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been updated`, { icon: 'success-small' });
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.restrictions`, updatedRestrictions));
  };

  const handleDeleteRestriction = (restrictionId: string) => {
    const updatedRestrictions = restrictionsState.restrictions.filter(r => r.id !== restrictionId);
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been deleted`, { icon: 'success-small' });
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.restrictions`, updatedRestrictions));
  };

  const handleStacksChange = (config: SDKConfigAppIabStacks) => {
    iabStacksState.updateStacksConfig(config);
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.stacks`, config));
  };

  const handleCategoriesChange = (categories: PreferencesCategoriesConfig) => {
    purposesCategoriesState.updateCategories(categories);
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.preferences.categories`, categories));
  };

  const handleEssentialPurposesChange = (purposeSdkId: string, isRequired: boolean) => {
    const updatedEssentialPurposes = isRequired
      ? [...purposesCategoriesState.essentialPurposes, purposeSdkId]
      : purposesCategoriesState.essentialPurposes.filter(sdkId => sdkId !== purposeSdkId);
    purposesCategoriesState.updateEssentialPurpose(updatedEssentialPurposes);
    updateLocalConfig(produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].config.app.essentialPurposes`, updatedEssentialPurposes));
  };

  const handleAllIabVendorsChange = (allIabVendors: boolean) => {
    vendorsSelection.setAllIabSelected(allIabVendors);

    const preselectedIds = allIabVendors ? vendorsSelection.iabVendorsIds : [];
    const allSelectedIds = vendorsSelection.selectedIds.concat(preselectedIds);
    const updatedCategories = purposesCategoriesState.getPurposesCategoriesBasedOnVendors(allSelectedIds);

    const updatesToApply = [
      { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.all`, value: allIabVendors },
      { path: `regulation_configurations[${regulationConfigIndex}].config.preferences.categories`, value: updatedCategories },
    ];

    updateLocalConfig(produceListOfChanges(updatesToApply, localConsentNoticeConfig));
  };

  const saveChanges = async () => {
    if (!hasPendingChanges) {
      return;
    }

    try {
      await updateNoticeConfig(localConsentNoticeConfig);
      displaySnackbar(`Your changes have been saved`, { icon: 'success-small' });
      return true;
    } catch (error) {
      displaySnackbar('There was an error saving the changes', { variant: 'error' });
      return false;
    }
  };

  const handleCancelClicked = () => {
    navigate(`/${noticeId}`);
  };

  const handleSaveClicked = async () => {
    const isSaved = await saveChanges();

    if (isSaved) {
      navigate(`/${noticeId}`);
    }
  };

  // Apply selected template to the current regulation config
  const handleApplyTemplate = async (templateId: string) => {
    const updatedConfig = produce(localConsentNoticeConfig, `regulation_configurations[${regulationConfigIndex}].template_id`, templateId);
    setIsSelectTemplateModalOpen(false);

    try {
      await updateNoticeConfig(updatedConfig);
      displaySnackbar(`Your changes have been saved`, { icon: 'success-small' });
    } catch (error) {
      const errorText = getApiErrorText(error, DEFAULT_ERROR_TEXT.notice.save);
      displaySnackbar(errorText, { variant: 'error' });
    }
  };

  const isAllIABTCFVendorsVisible = getDefaultGdprConfigIab(localConsentNoticeConfig)?.enabled && hasIabIntegration;
  const isRestrictionsVisible = localConsentNoticeConfig && localConsentNoticeConfig.platform !== 'amp' && hasIabIntegration;
  const isIabStacksVisible = localConsentNoticeConfig && localConsentNoticeConfig.platform !== 'app' && localConsentNoticeConfig.platform !== 'ctv' && hasIabIntegration;
  const isTcfSectionVisible = isAllIABTCFVendorsVisible || isRestrictionsVisible || isIabStacksVisible;

  const isLoading = loadingNotice || iabStacksState.isLoading || restrictionsState.isLoading || purposesCategoriesState.isLoading || loadingConsentNoticeConfig || loadingTemplate;
  const hasPendingChanges = hasNoticeChanged || hasNoticeConfigChanged;
  const couldTemplateBeApplied = isCMPEditor && !!templates.length;

  return (
    <>
      <DidomiSkeleton isLoading={isLoading} variant="layout" data-testid="page-skeleton" className="block w-full p-l pb-20">
        <section>
          <PageHeader
            isLoading={loadingNotice}
            title={REGULATION_DICTIONARY[regulationId]?.titleText}
            description="Configure Purposes & Vendors"
            saveDisabled={savingNoticeConfig || !hasPendingChanges}
            saveVisible={isCMPEditor || isDisabled}
            backText="Back to regulations"
            withSeparator
            onSave={handleSaveClicked}
            onCancel={handleCancelClicked}
          />

          {isUsingTemplate && !loadingTemplate && (
            <DidomiHintbox className="w-full mb-xs" iconName="warning-blue">
              <div className="flex items-center" data-testid="template-info-hintbox">
                <div className="h4 font-semibold text-primary-blue-6">This step is disabled because it uses the following template configuration:&nbsp;</div>
                <NavLink to={`/vendors-list/${template.id}`}>
                  <DidomiButton variant="option-filled" size="small" iconLeft="link">
                    {template.name}
                  </DidomiButton>
                </NavLink>
                &nbsp;
                <DidomiTooltip hideOnScroll hideOnClick placement="top" variant="helper" contentElementId="template-hintbox-tooltip">
                  <DidomiIconButton variant="option" icon="help" />
                </DidomiTooltip>
                <div hidden id="template-hintbox-tooltip" className="text-body-small text-primary-blue-6">
                  <div className="font-bold">Want to unlink the template?</div>
                  Click on &quot;Apply an existing template&quot; and select the &quot;Reset&quot; option
                </div>
              </div>
            </DidomiHintbox>
          )}

          <VendorsList
            selectedIds={vendorsSelection.selectedIds}
            preselectedIds={vendorsSelection.preselectedIds}
            selectedTotalCount={vendorsSelection.selectedTotalCount}
            viewOnly={!isCMPEditor || isDisabled}
            isRelatedDataLoading={purposesCategoriesState.isLoading}
            updateSelection={handleVendorsSelectionChange}
            isGcmVendorRequired={isGcmVendorRequired}
            onSaveAsTemplate={async () => {
              await saveChanges();
              setSaveAsTemplateModalOpen(true);
            }}
            onApplyTemplateClicked={couldTemplateBeApplied ? () => setIsSelectTemplateModalOpen(true) : null}
          />

          {/* TODO: consider refactoring TCF section into a separate component */}
          {isTcfSectionVisible && (
            <ExpandableSection
              id="tcf-section"
              expanded={true}
              className="mb-l"
              variant="top-level"
              header={
                <div className="flex items-center gap-xs">
                  <img alt="" src={`${ASSETS_URL}/assets/illustrations/vendors/tcf.svg`} />
                  <h2 className="text-h2 font-bold text-secondary-cobalt-blue-4">TCF settings</h2>
                  <ExpandableSectionToggle a11yLabel="TCF settings" className="ml-auto" />
                </div>
              }
            >
              <div className="flex flex-col gap-s pt-s pl-xxs">
                {isAllIABTCFVendorsVisible && (
                  <IabAllVendors allIabVendors={vendorsSelection.isAllIabSelected} onChangeAllIabVendors={handleAllIabVendorsChange} viewOnly={!isCMPEditor || isDisabled} />
                )}

                {isRestrictionsVisible && (
                  <PublisherRestrictions
                    restrictions={restrictionsState.extendedRestrictions}
                    noticeName={notice?.name}
                    isLoading={restrictionsState.isLoading}
                    viewOnly={!isCMPEditor || isDisabled}
                    onAddRestriction={handleAddRestriction}
                    onUpdateRestriction={handleUpdateRestriction}
                    onDeleteRestriction={handleDeleteRestriction}
                  />
                )}

                {isIabStacksVisible && (
                  <IabStacks
                    isLoading={iabStacksState.isLoading}
                    noticeName={notice?.name}
                    stacksConfig={iabStacksState.stacksConfig}
                    stacks={iabStacksState.extendedStacks}
                    viewOnly={!isCMPEditor || isDisabled}
                    onStacksConfigChange={handleStacksChange}
                  />
                )}
              </div>
            </ExpandableSection>
          )}

          {hasSPI && <VendorsSpiList className="mb-l" isInitiallyExpanded={true} isLoading={loadingConsentNoticeConfig} vendorsSpiGroups={spiState.spiGroups} />}

          <NoticePurposes
            isLoading={purposesCategoriesState.isLoading}
            noticeName={notice?.name}
            categories={purposesCategoriesState.categories}
            extendedCategories={purposesCategoriesState.extendedCategories}
            viewOnly={!isCMPEditor || isDisabled}
            hasEssentialPurposes={hasEssentialPurposes}
            isGDPRRegulation={isGDPRRegulation}
            hasButtonsCustomizationPerPurposeCategory={hasButtonsCustomizationPerPurposeCategory}
            onCategoriesChanges={handleCategoriesChange}
            onChangeEssentialPurpose={handleEssentialPurposesChange}
          />
        </section>
      </DidomiSkeleton>

      <SaveAsTemplateModal
        isOpen={isSaveAsTemplateModalOpen}
        noticeId={noticeId}
        regulationId={regulationId}
        onSave={async () => {
          displaySnackbar(`Template created and applied successfully`, { icon: 'success-small' });
          setSaveAsTemplateModalOpen(false);
        }}
        onCancel={() => {
          setSaveAsTemplateModalOpen(false);
        }}
        onError={() => {
          displaySnackbar('There was an error creating template and applying it to the notice', { variant: 'error' });
          setSaveAsTemplateModalOpen(false);
        }}
      />

      <TemplateSelectionModal
        isOpen={isSelectTemplateModalOpen}
        currentTemplateId={regulationConfig?.template_id}
        regulationName={regulationId.toUpperCase()}
        templates={templates}
        onCancel={() => setIsSelectTemplateModalOpen(false)}
        onConfirm={handleApplyTemplate}
      />

      <LoadingModal isOpen={savingNoticeConfig} title="Saving changes..." />

      <BlockNavigationModal
        buttonArrangement="row"
        isBlock={hasNoticeConfigChanged || hasNoticeChanged}
        isLoading={savingNoticeConfig}
        title="You have unsaved changes!"
        subTitle="Pay attention"
        description="If you do not save now, you will lose the last changes you made."
        saveButtonText="Save and leave"
        onSave={saveChanges}
        keepEditingText="Keep on editing"
        // reset local config to the original state(noticeConfig)
        discardAndProceedText={`Don't Save`}
        onDiscardChanges={resetLocalConfig}
      />
    </>
  );
};
