import { RegulationCountry, RegulationRegion } from '@types';

// Regulation service does not provide metadata
// on which countries belong to the EU or not.
export const EUROPEAN_UNION_COUNTRY_CODES: string[] = [
  'AD',
  'AT',
  'BE',
  'BG',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FR',
  'GR',
  'HR',
  'HU',
  'IE',
  'IT',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SE',
  'SI',
  'SK',
  'SM',
  'VA',
];

type ContinentId = 'africa' | 'north-america' | 'south-america' | 'oceania' | 'asia' | 'europe';

export interface UiMapContinent {
  id: string;
  subdivisions: UiMapContinentSubdivision[];
  headerSubdivisionIds: string[];
}

export interface UiMapContinentSubdivision {
  id: string;
  name: string;
  territories: UiMapTerritory[];
  holdsCountries: boolean;
}

export interface UiMapTerritory {
  id: string;
  name: string;
}

const sortByNameAZ = (a: { name: string }, b: { name: string }) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);

/**
 * Returns a UiMapContinentSubdivision that represents a portion of a Continent (a country group).
 *
 * @param {string} code - The code/id of the subdivision.
 * @param {string} name - The visible name of the subdivision.
 * @param {Record<string, RegulationCountry>} countryCodeToCountry - A record mapping country codes to the Country object.
 */
function buildContinentSubdivision(id: string, name: string, geos: string[], countryCodeToCountry: Record<string, RegulationCountry>): UiMapContinentSubdivision {
  const territories = geos.map(code => ({ id: code, code, name: countryCodeToCountry[code].name }));
  return {
    id,
    name,
    territories,
    holdsCountries: true,
  };
}

/**
 * Returns a UiMapContinentSubdivision that represents a country.
 * If the country has regions, these will be included as territories.
 *
 * @param {RegulationCountry} country - The country to build a subdivision from.
 */
function buildCountrySubdivision(country: RegulationCountry): UiMapContinentSubdivision {
  return {
    id: country.id,
    name: country.name,
    territories: country.regions
      .map(
        (region): UiMapTerritory => ({
          id: regionToGeoLocation(country, region),
          name: region.name,
        }),
      )
      .sort(sortByNameAZ),
    holdsCountries: false,
  };
}

/**
 * Mapping of continent to subdivisions that will be displayed as the header portion of the collapsable..
 */
const CONTINENT_TO_HEADER_SUBDIVISION_CODES: Record<ContinentId, string[]> = {
  europe: ['EU', 'GB', 'NO'],
  'north-america': ['US', 'CA'],
  'south-america': ['BR'],
  asia: ['CN', 'JP', 'IN', 'KR'],
  africa: ['ZA', 'TZ'],
  oceania: ['AU', 'NZ'],
};

/**
 * Creates the given continent. The continent will contain 1 subdivision per country.
 * Countries included are those visible by default and/or present in the activeGeos array.
 *
 * @param {ContinentId} id - The id of the continent.
 * @param {string} continentName - The name of the continent.
 * @param {Record<string, RegulationCountry>} countryCodeToCountry - An object that maps country codes to the related country data.
 * @param {string[]} activeGeos - An array of active geo-locations.
 * @param {string[]} gdprGeos - An array of geo-locations (country codes) where GDPR should be applied.
 * @param {boolean} showAll - If true, all countries will be returned in the resulting object, otherwise only visible ones.
 */
function getUiContinent(id: ContinentId, continentName: string, countryCodeToCountry: Record<string, RegulationCountry>): UiMapContinent {
  const countries = Object.values(countryCodeToCountry).filter(country => country.didomi_territory === continentName);
  const subdivisions = countries
    .map(country => country.id)
    .map(countryCode => buildCountrySubdivision(countryCodeToCountry[countryCode]))
    .sort(sortByNameAZ);
  return {
    id,
    subdivisions,
    headerSubdivisionIds: CONTINENT_TO_HEADER_SUBDIVISION_CODES[id],
  };
}

/**
 * Creates the European continent and arranges EU countries within a European Union subdivision.
 *
 * @param {Record<string, RegulationCountry>} countryCodeToCountry - An object that maps country codes to the related country data.
 * @param {string[]} activeGeos - An array of active geo-locations.
 * @param {string[]} gdprGeos - An array of geo-locations (country codes) where GDPR should be applied.
 * @param {boolean} showAll - If true, all countries will be returned in the resulting object, otherwise only visible ones.
 */
function getUiEuropeContinent(countryCodeToCountry: Record<string, RegulationCountry>) {
  const europeContinent = getUiContinent('europe', 'Europe', countryCodeToCountry);
  const euCountrySet = new Set(EUROPEAN_UNION_COUNTRY_CODES);
  const euCountriesAsSubdivisions = europeContinent.subdivisions.filter(subdivision => euCountrySet.has(subdivision.id));
  const nonEUCountriesAsSubdivisions = europeContinent.subdivisions.filter(subdivision => !euCountrySet.has(subdivision.id));
  const europeanUnionSubdivision = buildContinentSubdivision(
    'EU',
    'European Union',
    euCountriesAsSubdivisions.map(subdivision => subdivision.id),
    countryCodeToCountry,
  );
  return {
    ...europeContinent,
    subdivisions: [europeanUnionSubdivision, ...nonEUCountriesAsSubdivisions],
  };
}

/**
 * Creates the North America continent and arranges USA and Canada to be at the top.
 *
 * @param {Record<string, RegulationCountry>} countryCodeToCountry - An object that maps country codes to the related country data.
 * @param {string[]} activeGeos - An array of active geo-locations.
 * @param {string[]} gdprGeos - An array of geo-locations (country codes) where GDPR should be applied.
 * @param {boolean} showAll - If true, all countries will be returned in the resulting object, otherwise only visible ones.
 */
function getUiNorthAmericaContinent(countryCodeToCountry: Record<string, RegulationCountry>) {
  const northAmerica = getUiContinent('north-america', 'North America', countryCodeToCountry);
  const unitedStates = northAmerica.subdivisions.find(subdivision => subdivision.id === 'US');
  const canada = northAmerica.subdivisions.find(subdivision => subdivision.id === 'CA');
  northAmerica.subdivisions = [unitedStates, canada, ...northAmerica.subdivisions.filter(subdivision => !['US', 'CA'].includes(subdivision.id))];
  return northAmerica;
}

/**
 * Returns the unique ID for a region (eg. US_CA)
 *
 * @param {RegulationCountry} country - The country object.
 * @param {RegulationRegion} region - The region object.
 */
export function regionToGeoLocation(country: RegulationCountry, region: RegulationRegion) {
  return `${country.id}_${region.id}`;
}

/**
 * Creates the six continents to display in the Territories Map.
 *
 * @param {Record<string, RegulationCountry>} countryCodeToCountry - An object that maps country codes to the related country data.
 * @param {string[]} activeGeos - An array of active geo-locations.
 * @param {string[]} gdprGeos - An array of geo-locations (country codes) where GDPR should be applied.
 * @param {boolean} showAll - If true, all countries will be returned in the resulting object, otherwise only visible ones.
 */
export function buildUIContinents(countries: RegulationCountry[]): UiMapContinent[] {
  const countryCodeToCountry = countries.reduce((acc, country) => ({ ...acc, [country.id]: country }), {});
  return [
    getUiEuropeContinent(countryCodeToCountry),
    getUiNorthAmericaContinent(countryCodeToCountry),
    getUiContinent('south-america', 'South America', countryCodeToCountry),
    getUiContinent('asia', 'Asia', countryCodeToCountry),
    getUiContinent('africa', 'Africa', countryCodeToCountry),
    getUiContinent('oceania', 'Oceania', countryCodeToCountry),
  ];
}

export function subdivisonToCountryCodes(subdivision: UiMapContinentSubdivision): string[] {
  if (subdivision.holdsCountries) {
    return subdivision.territories.map(t => t.id);
  }
  return [subdivision.id];
}

/**
 * Returns the geo-locations for the countries of a continent.
 *
 * @param {UiMapContinent} continent - The UiMapContinent to convert to country codes.
 */
export function continentToCountryCodes(continent: UiMapContinent): string[] {
  return continent.subdivisions.reduce((acc, subdivision) => [...acc, ...subdivisonToCountryCodes(subdivision)], []);
}

/**
 * Reduces a list of countries into a map of country code to the list of region ids the country has.
 * eg: { US: [US_CA, US_TX, US_VA] }
 *
 * @param {RegulationCountry[]} countries - The countries to reduce into the mapping
 */
export function buildCountryToRegionsMap(countries: RegulationCountry[]): Record<string, string[]> {
  return countries
    .filter(country => country.regions.length)
    .reduce(
      (acc, country) => ({
        ...acc,
        [country.id]: country.regions.map(region => regionToGeoLocation(country, region)),
      }),
      {},
    );
}

/**
 * Consolidates an array of geographic codes by replacing region codes belonging
 * to the same country with the country's code.
 *
 * For instance, if all regions of a specific country are present in the geographic
 * codes array, they get replaced with the respective country's code.
 *
 * @param {string[]} geos - The geos to condense
 * @param {Record<string, string[]>} countries - Mapping of country code to list of regions
 */
export function consolidateGeos(geos: string[], countryToRegionsMap: Record<string, string[]>): string[] {
  const geosSet = new Set(geos);
  Object.entries(countryToRegionsMap).forEach(([countryId, regions]) => {
    if (regions.every(region => geosSet.has(region))) {
      regions.forEach(region => geosSet.delete(region));
      geosSet.add(countryId);
    }
  });
  return [...geosSet];
}

/**
 * Expands an array of geographic codes by replacing each country code with its respective region codes.
 *
 * For instance, if a country's code is present in the geographic codes array, it gets replaced
 * with the respective regions' codes of that country. If the country has no associated regions or
 * is not found in the map, the country code is preserved.
 *
 * @param {string[]} geos - The geos to expand
 * @param {Record<string, string[]>} countryToRegionsMap - Mapping of country code to list of regions
 * @returns {string[]} - The expanded geos with country codes replaced by their respective region
 */
export function expandGeos(geos: string[], countryToRegionsMap: Record<string, string[]>): string[] {
  return geos.flatMap(geo => countryToRegionsMap[geo] ?? [geo]);
}

/**
 * Checks if the given geos are contained in another set of geos.
 * Both geo location sets are expanded before comparison.
 *
 * @param {string[]} geosToCheck - A list of geos that need to be checked.
 * @param {string[]} withinGeos - A list of geos within which we are looking for `geosToCheck`.
 * @param {Record<string, string[]>} countryToRegionsMap - An object mapping country geo codes to their respective region geo codes.
 * @returns {boolean} Returns true if all `geosToCheck` are found within `withinGeos`, else returns false.
 */
export function areGeosContainedIn(geosToCheck: string[], withinGeos: string[], countryToRegionsMap: Record<string, string[]>): boolean {
  const withinGeosAsRegions = new Set(expandGeos(withinGeos, countryToRegionsMap));
  const geosToCheckAsRegions = expandGeos(geosToCheck, countryToRegionsMap);
  return geosToCheckAsRegions.every(geo => withinGeosAsRegions.has(geo));
}

/**
 * Checks if the given subdivision is included in a set of geos.
 *
 * @param {UiMapContinentSubdivision} subdivision - The subdivision to check.
 * @param {string[]} withinGeos - A list of geos within which we are looking for the subdivision.
 * @param {Record<string, string[]>} regionsMap - An object mapping region codes to their respective geo codes.
 * @returns {boolean} Returns true if the subdivision is found within the `withinGeos`, else returns false.
 */
export function isSubdivisionIncludedIn(subdivision: UiMapContinentSubdivision, withinGeos: string[], regionsMap: Record<string, string[]>): boolean {
  return areGeosContainedIn(subdivisonToCountryCodes(subdivision), withinGeos, regionsMap);
}

/**
 * Checks if the given continent is included in a set of geos.
 *
 * @param {UiMapContinent} continent - The continent to check.
 * @param {string[]} withinGeos - A list of geos within which we are looking for the continent.
 * @param {Record<string, string[]>} regionsMap - An object mapping region codes to their respective geo codes.
 * @returns {boolean} Does the continent fall within the provided geos?
 */
export function isContinentIncludedIn(continent: UiMapContinent, withinGeos: string[], regionsMap: Record<string, string[]>) {
  return areGeosContainedIn(continentToCountryCodes(continent), withinGeos, regionsMap);
}
