import React, { useEffect, useState } from 'react';
import { DidomiBackButtonLink, DidomiButton, DidomiSecondaryHeader, DidomiSkeleton, DidomiTextInput } from '@didomi/ui-atoms-react';
import { useNavigate } from 'react-router-dom';
import { ConsentNoticeTemplate } from '@types';

interface TemplateLayoutProps {
  templateId: string;
  isLoading: boolean;
  disabled: boolean;
  templateName: string;
  setTemplateName: (arg0: string) => void;
  saveChanges: () => void;
  templates: ConsentNoticeTemplate[];
  children?: JSX.Element | JSX.Element[];
}

export const TemplateLayout = ({ isLoading, disabled, templateId, templateName, setTemplateName, templates, saveChanges, children }: TemplateLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  const [templateNameError, setTemplateNameError] = useState(null);

  const isNewTemplate = templateId === 'new';

  useEffect(() => {
    if (isNewTemplate) {
      setTemplateName(`Consent notice - Template #${templates.length + 1}`);
    }
  }, [isNewTemplate, templates, setTemplateName]);

  const handleTemplateNameChange = (newTemplateName: string) => {
    let textError;

    if (templates.some(t => t.name === newTemplateName && t.id !== templateId)) {
      textError = 'Name is not unique';
    }

    if (!newTemplateName.length) {
      textError = 'Name is Required';
    }

    setTemplateNameError(textError);
    setTemplateName(newTemplateName);
  };

  return (
    <section className="p-l pb-unsafe-bottom-space box-border flex flex-col">
      <DidomiSkeleton data-testid="page-skeleton" isLoading={isLoading} variant="layout" className="flex-1 flex flex-col">
        <DidomiSecondaryHeader titleText={isNewTemplate ? 'Create a vendor list' : 'Update a vendor list'} className="block mb-m">
          <div slot="back-button">
            <DidomiBackButtonLink text="Back to Vendors list management" class="cursor-pointer" onClick={() => navigate(`/vendors-list`)} />
          </div>
          <div slot="actions" className="flex gap-m">
            <DidomiButton data-testid="save-page-changes" variant="top" iconRight="save" disabled={disabled} onClick={saveChanges}>
              Save as vendor list
            </DidomiButton>
          </div>
        </DidomiSecondaryHeader>
        <hr className="h-0 mb-m border-t-1 border-neutral-gray-3" />
        <div className="flex mb-l">
          <DidomiTextInput
            label="Vendor list name"
            data-testid="template-name"
            placeholder="Vendor list name"
            className="w-1/3"
            disabled={isLoading}
            hideErrorMessage={!templateNameError}
            error={templateNameError}
            value={templateName}
            onValueChange={e => handleTemplateNameChange(e.detail)}
          />
        </div>

        <div>{children}</div>
      </DidomiSkeleton>
    </section>
  );
};
