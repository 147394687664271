import React, { useMemo } from 'react';
import { LOCAL_EXCEPTIONS, NoticeConfigV2 } from '@didomi/cmp-generator';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { ConsentNoticeConfig } from '@types';
import { ACCESS_POLICIES_CONFIG, getDefaultRegulationIdByGeo } from '@utils';
import { LocalExceptions } from '../../LocalExceptions/LocalExceptions';
import { NoticeClosingSwitch } from '../../NoticeClosingSwitch/NoticeClosingSwitch';

interface CustomizationLocalExceptionsProps {
  noticeId: string;
  regulationId: string;
  regulationLocalExceptions: string[];
}

export const CustomizationLocalExceptions = ({ noticeId, regulationId, regulationLocalExceptions }: CustomizationLocalExceptionsProps) => {
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { activeDefaultRegulations } = useLocalRegulationConfig(noticeId, regulationId);

  const localNoticeConfigForGenerator = localConsentNoticeConfig as unknown as NoticeConfigV2;

  // Check whether French exception should be visible
  const isFranceExceptionAvailable = useMemo(() => {
    return regulationLocalExceptions.includes('FR') && getDefaultRegulationIdByGeo('FR', activeDefaultRegulations) === regulationId;
  }, [regulationId, activeDefaultRegulations, regulationLocalExceptions]);

  // Check whether Italian exception should be visible
  const isItalyExceptionAvailable = useMemo(() => {
    // Close icon field is not available for CTV platform
    const isCloseIconFieldVisible = localConsentNoticeConfig?.platform !== 'ctv';
    return regulationLocalExceptions.includes('IT') && getDefaultRegulationIdByGeo('IT', activeDefaultRegulations) === regulationId && isCloseIconFieldVisible;
  }, [regulationId, activeDefaultRegulations, regulationLocalExceptions, localConsentNoticeConfig?.platform]);

  const hasFranceExceptionAdded = useMemo(() => LOCAL_EXCEPTIONS.FR.isActive(localNoticeConfigForGenerator), [localNoticeConfigForGenerator]);

  const hasItalyExceptionAdded = useMemo(() => LOCAL_EXCEPTIONS.IT.isActive(localNoticeConfigForGenerator), [localNoticeConfigForGenerator]);

  const handleFranceExceptionChange = (newValueEvent: CustomEvent<string>) => {
    const isSelected = newValueEvent.detail === 'selected';

    // We need to create copy of config because LOCAL_EXCEPTIONS.FR.enable mutates the object
    const configCopy = structuredClone(localNoticeConfigForGenerator);

    if (isSelected) {
      LOCAL_EXCEPTIONS.FR.enable(configCopy);
    } else {
      LOCAL_EXCEPTIONS.FR.disable(configCopy);
    }

    updateLocalConfig(configCopy as unknown as ConsentNoticeConfig);
  };

  const handleItalyExceptionChange = (newValueEvent: CustomEvent<string>) => {
    const isSelected = newValueEvent.detail === 'selected';

    // We need to create copy of config because LOCAL_EXCEPTIONS.FR.enable mutates the object
    const configCopy = structuredClone(localNoticeConfigForGenerator);

    if (isSelected) {
      LOCAL_EXCEPTIONS.IT.enable(configCopy);
    } else {
      LOCAL_EXCEPTIONS.IT.disable(configCopy);
    }

    updateLocalConfig(configCopy as unknown as ConsentNoticeConfig);
  };

  if (!isItalyExceptionAvailable && !isFranceExceptionAvailable) {
    return null;
  }

  return (
    <section className="mt-s" data-loading={loadingNoticeConfig}>
      <hr className="h-0 border-t-1 border-neutral-gray-3 mb-s" />
      <h3 className="text-body-small text-primary-pink-5 font-semibold mb-xs">Available display options</h3>

      <ul className="flex flex-col gap-m">
        {/* France */}
        {isFranceExceptionAvailable && (
          <li className="text-primary-blue-6">
            <LocalExceptions name="CNIL (France)" countryCode="fr" description="Options below will be applied only for traffic coming from France">
              <NoticeClosingSwitch
                data-testid="fr-local-exception-switch"
                image="disagree-and-close"
                value={hasFranceExceptionAdded}
                disabled={!isCMPEditor}
                onValueChange={handleFranceExceptionChange}
              >
                <div>Display the button:</div>
                <strong className="font-semibold">Disagree & close</strong>
              </NoticeClosingSwitch>
            </LocalExceptions>
          </li>
        )}

        {isItalyExceptionAvailable && isFranceExceptionAvailable && <hr className="h-0 border-t-1 border-neutral-gray-3" />}

        {/* Italy */}
        {isItalyExceptionAvailable && (
          <li className="text-primary-blue-6">
            <LocalExceptions name="Garante Della Privacy (Italy)" countryCode="it" description="Options below will be applied only for traffic coming from Italy">
              <NoticeClosingSwitch
                data-testid="it-local-exception-switch"
                image="display-x"
                value={hasItalyExceptionAdded}
                disabled={!isCMPEditor}
                onValueChange={handleItalyExceptionChange}
              >
                <div>
                  Display the “<strong className="font-semibold">X</strong>”
                </div>
                <div>closing icon</div>
              </NoticeClosingSwitch>
            </LocalExceptions>
          </li>
        )}
      </ul>
    </section>
  );
};
