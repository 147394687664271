import { Regulations } from '@didomi/cmp-generator';
import { Purpose, Vendor } from '@types';

// Extract unique SPI(`spi_purposes_id`) from vendors
// If `regulationId` is provided, only include SPIs that are assigned to this regulation
// Otherwise, include all SPIs from provided vendors
export const extractSpiFromVendors = (vendors: Vendor[], purposes: Purpose[], regulationId?: Regulations) => {
  const isSpiAssignedToRegulation = (spiId: string) => {
    const purpose = purposes.find(p => p.id === spiId);
    return purpose?.regulations_id?.includes(regulationId);
  };

  return vendors.reduce((acc, vendor) => {
    vendor?.spi_purposes_id?.forEach(id => {
      // If regulationId is not provided or regulationId is provided+SPI is assigned to that regulation - add SPI to the set
      if (!regulationId || isSpiAssignedToRegulation(id)) {
        acc.add(id);
      }
    });
    return acc;
  }, new Set<string>());
};
