import React from 'react';
import { DidomiLanguageFlag } from '@didomi/ui-atoms-react';

interface LocalExceptionsProps {
  name: string;
  description: string;
  countryCode: string;
  children: React.ReactChild | React.ReactChildren;
}

export const LocalExceptions = ({ name, description, countryCode, children }: LocalExceptionsProps) => {
  return (
    <div>
      <div className="border-l-1 border-solid border-neutral-gray-5 pl-xs mb-xxs">
        <div className="flex gap-xxs">
          <DidomiLanguageFlag style={{ '--icon-size': '15px' }} language={countryCode} />
          <h4 className="font-semibold text-body-small">{name}</h4>
        </div>

        <div className="text-body-small">{description}</div>
      </div>

      <div className="flex flex-col gap-xxs">{children}</div>
    </div>
  );
};
