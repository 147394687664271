import { NumericPurpose } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { fetchAllItems } from '@utils';

/**
 * Fetches the full list of numeric purposes ids
 */
export const usePurposesNumericIds = (options?: UseQueryOptions<NumericPurpose[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchPurposesNumericIds = async () => {
    const allNumericPurposes = await fetchAllItems<NumericPurpose>(
      axiosWithInterceptors,
      `${CONFIG.environmentConfig.apiBaseUrl}metadata/numeric-purposes?organization_id=${organizationId}`,
    );
    return allNumericPurposes;
  };

  return useQuery<NumericPurpose[], AxiosError>(['purposes-numeric-ids', organizationId], fetchPurposesNumericIds, options);
};
