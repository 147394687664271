import React, { useCallback, useEffect, useState } from 'react';
import { SDKConfigAppIabStacks, SDKConfigAppIabRestriction } from '@didomi/cmp-generator';
import { produce } from '@didomi/helpers';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton, DidomiChip } from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import equal from 'fast-deep-equal/es6/react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  ExpandableSection,
  ExpandableSectionToggle,
  IabAllVendors,
  IabStacks,
  LinkTemplateToNotices,
  NoticePurposes,
  PublisherRestrictions,
  VendorsList,
  VendorsSpiList,
} from '@components';
import {
  useIabStacksState,
  usePurposesCategoriesState,
  useRestrictionsState,
  useVendorsSelectionState,
  useConsentNoticeTemplate,
  useConsentNoticeTemplateConfig,
  useConsentNoticeTemplates,
  useEditConsentNoticeTemplate,
  useEditConsentNoticeTemplateConfig,
  useCreateConsentNoticeTemplate,
  useSpiState,
} from '@hooks';
import { TemplateLayout } from '@layouts';
import { LoadingModal } from '@modals';
import { ConsentNoticeTemplateConfig, PreferencesCategoriesConfig } from '@types';

/**
 * Edit Template page
 */
export const VendorsListTemplatePage = (): JSX.Element => {
  const { templateId } = useParams();
  const { displaySnackbar } = useSnackbar();
  const navigate = useNavigate();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  const isNewTemplate = templateId === 'new';

  const { data: template, isLoading: loadingTemplate } = useConsentNoticeTemplate(templateId);
  const { data: { data: templates } = { data: [] }, isLoading: loadingTemplates } = useConsentNoticeTemplates();
  const { data: templateConfig, isLoading: loadingTemplateConfig } = useConsentNoticeTemplateConfig(templateId);

  const { mutateAsync: updateTemplate, isLoading: savingUpdateTemplate } = useEditConsentNoticeTemplate(templateId);
  const { mutateAsync: createTemplate, isLoading: savingCreateTemplate } = useCreateConsentNoticeTemplate();
  const { mutateAsync: updateTemplateConfig, isLoading: savingTemplateConfig } = useEditConsentNoticeTemplateConfig();

  const restrictionsState = useRestrictionsState({ config: templateConfig?.config });
  const vendorsSelection = useVendorsSelectionState({ config: templateConfig?.config });
  const iabStacksState = useIabStacksState({ config: templateConfig?.config });
  const purposesCategoriesState = usePurposesCategoriesState({ config: templateConfig?.config });
  const spiState = useSpiState({ selectedVendorsIds: vendorsSelection.selectedIds, enabled: true });

  const [hasConfigChanged, setHasConfigChanged] = useState(false);

  const [templateName, setTemplateName] = useState('');

  useEffect(() => {
    if (template) {
      setTemplateName(template.name);
    }
  }, [template]);

  const checkChanges = (vendorsSelectedIds: string[]) => {
    const hasVendorsChanged = !equal(templateConfig?.config?.app?.vendors?.include || [], vendorsSelectedIds);
    setHasConfigChanged(hasVendorsChanged);
  };

  const handleVendorsSelectionChange = (selectedIds: string[]) => {
    const updatedCategories = purposesCategoriesState.getPurposesCategoriesBasedOnVendors(selectedIds.concat(vendorsSelection.preselectedIds));

    vendorsSelection.updateSelection(selectedIds);
    purposesCategoriesState.updateCategories(updatedCategories);
    checkChanges(selectedIds);
  };

  const handleAddRestriction = (newRestriction: SDKConfigAppIabRestriction) => {
    const updatedRestrictions = [...restrictionsState.restrictions, newRestriction];
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been added`, { icon: 'success-small' });
    setHasConfigChanged(true);
  };

  const handleUpdateRestriction = (updatedRestriction: SDKConfigAppIabRestriction) => {
    const updatedRestrictions = restrictionsState.restrictions.map(r => (r.id === updatedRestriction.id ? updatedRestriction : r));
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been updated`, { icon: 'success-small' });
    setHasConfigChanged(true);
  };

  const handleDeleteRestriction = (restrictionId: string) => {
    const updatedRestrictions = restrictionsState.restrictions.filter(r => r.id !== restrictionId);
    restrictionsState.updateRestrictions(updatedRestrictions);

    displaySnackbar(`Restriction has been deleted`, { icon: 'success-small' });
    setHasConfigChanged(true);
  };

  const handleStacksChange = (config: SDKConfigAppIabStacks) => {
    iabStacksState.updateStacksConfig(config);
    // TODO: improve hasConfigChanged logic to compare changes
    setHasConfigChanged(true);
  };

  const handleCategoriesChange = (categories: PreferencesCategoriesConfig) => {
    purposesCategoriesState.updateCategories(categories);
    // TODO: improve hasConfigChanged logic to compare changes
    setHasConfigChanged(true);
  };

  const handleEssentialPurposesChange = (purposeSdkId: string, isRequired: boolean) => {
    const updatedEssentialPurposes = isRequired
      ? [...purposesCategoriesState.essentialPurposes, purposeSdkId]
      : purposesCategoriesState.essentialPurposes.filter(sdkId => sdkId !== purposeSdkId);
    purposesCategoriesState.updateEssentialPurpose(updatedEssentialPurposes);
    // TODO: improve hasConfigChanged logic to compare changes
    setHasConfigChanged(true);
  };

  const handleAllIabVendorsChange = (allIabVendors: boolean) => {
    const preselectedIds = allIabVendors ? vendorsSelection.iabVendorsIds : [];
    const allSelectedIds = vendorsSelection.selectedIds.concat(preselectedIds);
    const updatedCategories = purposesCategoriesState.getPurposesCategoriesBasedOnVendors(allSelectedIds);

    vendorsSelection.setAllIabSelected(allIabVendors);
    purposesCategoriesState.updateCategories(updatedCategories);

    // TODO: improve hasConfigChanged logic to compare changes
    setHasConfigChanged(true);
  };

  function getLocalTemplateConfig(): ConsentNoticeTemplateConfig {
    const updatesToApply = [
      { path: 'config.app.vendors.include', value: vendorsSelection.selectedIds },
      { path: 'config.app.vendors.iab.all', value: vendorsSelection.isAllIabSelected },
      { path: 'config.app.vendors.iab.restrictions', value: restrictionsState.restrictions },
      { path: 'config.app.vendors.iab.stacks', value: iabStacksState.stacksConfig },
      { path: 'config.preferences.categories', value: purposesCategoriesState.categories },
      { path: 'config.app.essentialPurposes', value: purposesCategoriesState.essentialPurposes },
    ];

    return updatesToApply.reduce((acc, item) => produce(acc, item.path, item.value) as ConsentNoticeTemplateConfig, templateConfig || ({} as ConsentNoticeTemplateConfig));
  }

  const saveChanges = async () => {
    try {
      const updatedConfig = getLocalTemplateConfig();

      if (isNewTemplate) {
        const createdTemplate = await createTemplate({ name: templateName, config: updatedConfig.config });

        navigate(`/vendors-list/${createdTemplate.data.id}`);

        return true;
      }

      await updateTemplate({ name: templateName });
      await updateTemplateConfig(updatedConfig);
      setHasConfigChanged(false);

      displaySnackbar(`Your changes have been saved`, { icon: 'success-small' });
      return true;
    } catch (error) {
      displaySnackbar('There was an error saving the changes', { variant: 'error' });
      return false;
    }
  };

  const handleTemplateNameChange = useCallback(
    newTemplateName => {
      setTemplateName(newTemplateName);
      setHasConfigChanged(true);
    },
    [setTemplateName, setHasConfigChanged],
  );

  // Save template and template config before linking the notices
  const handleSaveBeforeApply = async () => {
    try {
      await updateTemplate({ name: templateName });
      await updateTemplateConfig(getLocalTemplateConfig());
      return true;
    } catch (error) {
      displaySnackbar(error?.response?.data?.message || 'There was an error saving the changes', { variant: 'error' });
      return false;
    }
  };

  const isLoading = loadingTemplateConfig || loadingTemplate || loadingTemplates || iabStacksState.isLoading || restrictionsState.isLoading || purposesCategoriesState.isLoading;
  const updatingTemplate = savingUpdateTemplate || savingCreateTemplate || savingTemplateConfig;

  return (
    <>
      <TemplateLayout
        templateId={templateId}
        isLoading={isLoading}
        disabled={!hasConfigChanged || isLoading}
        templateName={templateName}
        setTemplateName={handleTemplateNameChange}
        saveChanges={saveChanges}
        templates={templates}
      >
        <VendorsList
          withSmartVendorsListButton={true}
          hasConfigChanged={hasConfigChanged}
          selectedIds={vendorsSelection.selectedIds}
          preselectedIds={vendorsSelection.preselectedIds}
          selectedTotalCount={vendorsSelection.selectedTotalCount}
          updateSelection={handleVendorsSelectionChange}
          isGcmVendorRequired={false}
          isNewTemplate={isNewTemplate}
        />

        <ExpandableSection
          id="tcf-section"
          variant="top-level"
          className="mb-l"
          header={
            <div className="flex items-center gap-xs">
              <img alt="" src={`${ASSETS_URL}/assets/illustrations/vendors/tcf.svg`} />
              <div className="text-h2 font-bold text-secondary-cobalt-blue-4">TCF settings</div>
              <DidomiChip label="Will apply only to GDPR regulation" basic-type="error" leftIcon="warning-sm-white" />
              <ExpandableSectionToggle a11yLabel="TCF settings" className="ml-auto" />
            </div>
          }
        >
          <div className="flex flex-col gap-s pt-s pl-xxs">
            <IabAllVendors allIabVendors={vendorsSelection.isAllIabSelected} onChangeAllIabVendors={handleAllIabVendorsChange} />

            <PublisherRestrictions
              restrictions={restrictionsState.extendedRestrictions}
              noticeName={templateName}
              isLoading={restrictionsState.isLoading}
              onAddRestriction={handleAddRestriction}
              onUpdateRestriction={handleUpdateRestriction}
              onDeleteRestriction={handleDeleteRestriction}
            />

            <IabStacks
              isLoading={iabStacksState.isLoading}
              noticeName={templateName}
              stacksConfig={iabStacksState.stacksConfig}
              stacks={iabStacksState.extendedStacks}
              onStacksConfigChange={handleStacksChange}
            />
          </div>
        </ExpandableSection>

        <VendorsSpiList className="mb-l" withRegulations isLoading={isLoading} vendorsSpiGroups={spiState.spiGroups} />

        <NoticePurposes
          isLoading={purposesCategoriesState.isLoading}
          noticeName={templateName}
          categories={purposesCategoriesState.categories}
          extendedCategories={purposesCategoriesState.extendedCategories}
          isGDPRRegulation={true}
          onCategoriesChanges={handleCategoriesChange}
          onChangeEssentialPurpose={handleEssentialPurposesChange}
        />

        <div className="flex justify-end mt-l">
          <DidomiButton data-testid="save-page-changes-bottom" variant="top" iconRight="save" disabled={!hasConfigChanged || isLoading} onClick={saveChanges}>
            Save as vendor list
          </DidomiButton>
        </div>

        <LinkTemplateToNotices templateId={templateId} templateConfig={templateConfig} saveTemplateBeforeApplying={handleSaveBeforeApply} />
      </TemplateLayout>
      <LoadingModal isOpen={updatingTemplate} title="We are updating your Vendors List..." />
    </>
  );
};
