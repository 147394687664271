// Custom error class for API errors
export class ApiError extends Error {
  request_metadata;

  constructor(error) {
    const message = error.response?.data?.message || error.message;
    super(message);
    this.name = `ApiError: ${message}`;

    this.request_metadata = {
      method: error.config?.method?.toUpperCase(),
      url: error.config?.url,
    };
  }
}
