import React from 'react';
import { DidomiButton, DidomiChip, DidomiIllustration, DidomiModal, DidomiModalActions, DidomiModalContent, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type CrossDevicePreBookingModalProps = {
  isOpen: boolean;
  onMakeAppointmentClick: () => void;
  onCancel: () => void;
};

export const CrossDevicePreBookingModal = ({ isOpen, onMakeAppointmentClick, onCancel }: CrossDevicePreBookingModalProps) => {
  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} variant="slim" closable="true" onOpenChange={handleModalChange}>
      <DidomiIllustration className={tx('mb-m')} name="cmp-cross-device" />
      <DidomiChip className={tx('mb-xxs')} basicType="error" leftIcon="tags" label="Premium feature" />

      <DidomiModalHeader modalTitle="Cross-device Consent" />

      <DidomiModalContent>
        <p className={tx('text-primary-blue-6 text-body-small')}>
          Allow users status to be shared across multiple websites and apps, across multiple devices and across multiple platforms.{' '}
          <strong className={tx('font-medium')}>Cross-device is a premium feature. Please get in touch with us to enable the feature for your consent notice.</strong>
        </p>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton onClick={onMakeAppointmentClick}>Make an appointment</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
