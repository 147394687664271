import React, { useMemo } from 'react';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiFiltersBar } from '@didomi/ui-atoms-react';
import { NoticeWithTemplatePublicationStatus } from '@types';

interface TemplateConsentNoticeListToolbarProps {
  resultsText: string;
  search: string;
  statusFilter: NoticeWithTemplatePublicationStatus;
  onSearchChange?: (value: string) => void;
  onFilterChange?: (filters: { status: NoticeWithTemplatePublicationStatus }) => void;
}

export const TemplateConsentNoticeListToolbar = ({ resultsText, search, statusFilter, onSearchChange, onFilterChange }: TemplateConsentNoticeListToolbarProps) => {
  const handleSearchChange = (e: CustomEvent<string>) => onSearchChange(e.detail);
  const handleFilterChange = (e: DidomiFiltersBarCustomEvent<{ key: string; newValue: NoticeWithTemplatePublicationStatus }>) => {
    onFilterChange({ status: e.detail.newValue });
  };

  const handleClearAllFilters = () => {
    onFilterChange({ status: undefined });
    onSearchChange('');
  };

  const filtersMemoized = useMemo(
    () => ({
      filterByStatus: {
        placeholder: 'Publication status',
        value: statusFilter,
        options: [
          { value: '', label: 'Reset' },
          { value: 'applied', label: 'Applied Notices' },
          { value: 'published', label: 'Published Notices' },
        ],
      },
    }),
    [statusFilter],
  );
  return (
    <section className="flex justify-between gap-8 mb-4">
      <div className="flex gap-2 self-end text-body-small">
        <div className="text-primary-blue-6 font-semibold">{resultsText}</div>
      </div>

      <DidomiFiltersBar
        data-testid="filters-bar"
        className="flex-1"
        filters={filtersMemoized}
        showSearch
        searchValue={search}
        collapsibleFilters={false}
        onSearchTextChange={handleSearchChange}
        onFilterValueChange={handleFilterChange}
        onClearAllFilters={handleClearAllFilters}
      />
    </section>
  );
};
