import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';
import { LanguageSelection } from '@components';
import { useCustomizationStepOptions, useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { CustomizationRegulationContent } from '../CustomizationRegulationContent/CustomizationRegulationContent';

interface CustomizationSpecificContentProps {
  noticeId: string;
}

export const CustomizationSpecificContent = ({ noticeId }: CustomizationSpecificContentProps) => {
  const { data: localConsentNoticeConfig, isLoading: loadingNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { trafficLanguage, setTrafficLanguage } = useCustomizationStepOptions({ defaultTraffic: localConsentNoticeConfig?.config?.languages?.default });
  const { activeDefaultRegulations } = useLocalRegulationConfig(noticeId);

  return (
    <div id="customization-specific-content" data-testid="customization-specific-content" data-loading={loadingNoticeConfig} className="flex flex-col gap-s">
      <DidomiHintbox iconName="warning-blue">
        <p className="text-primary-blue-6">All the settings that you can specifically manage by regulation. All changes are only applied to the selected regulation.</p>
      </DidomiHintbox>

      <LanguageSelection
        id="language-select"
        enabledLanguages={localConsentNoticeConfig?.config?.languages?.enabled}
        isLoading={loadingNoticeConfig}
        className="self-end !w-auto"
        attachTo="customization-specific-content"
        boundaryElementId="customization-specific-content"
        value={trafficLanguage}
        onValueChange={lang => setTrafficLanguage(lang)}
      />

      <div className="flex flex-col gap-xs">
        {activeDefaultRegulations.map(regulation => (
          <div key={regulation.regulation_id}>
            <CustomizationRegulationContent noticeId={noticeId} regulationId={regulation.regulation_id} />
          </div>
        ))}
      </div>
    </div>
  );
};
