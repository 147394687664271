import React from 'react';
import { DidomiIcon, DidomiColorPicker, DidomiToggleButton, DidomiToggleButtonOption, DidomiNumberInput } from '@didomi/ui-atoms-react';
import { INPUT_DEBOUNCE_TIME } from '@utils';

interface AppTextCustomizationProps {
  label: string;
  disabled?: boolean;
  size: string | number;
  alignment: string;
  color: string;
  onSizeChange?: (size: number) => void;
  onAlignmentChange?: (alignment: string) => void;
  onColorChange?: (color: string) => void;
}

export const AppTextCustomization = ({ label, disabled, size, alignment, color, onSizeChange, onAlignmentChange, onColorChange }: AppTextCustomizationProps) => {
  return (
    <div data-testid={`app-text-${label}`}>
      <h3 className="text-body-small font-bold text-primary-blue-6 mb-xxs">{label}</h3>

      <div className="flex gap-xxs items-start">
        {/* Text Size */}
        <DidomiNumberInput
          label="Size"
          size="xs"
          description="(px)"
          className="w-full"
          debounce={INPUT_DEBOUNCE_TIME}
          value={Number(size) || 24}
          min="0"
          hideErrorMessage
          disabled={disabled}
          onValueChange={e => onSizeChange(parseInt(e.detail, 10))}
        />

        {/* Text Alignment */}
        <div className="flex flex-col gap-0.5">
          <div className="text-primary-blue-6 text-field-label font-semibold">Alignment</div>
          <DidomiToggleButton value={alignment || 'start'} disabled={disabled} onValueChange={e => onAlignmentChange(e.detail)}>
            <DidomiToggleButtonOption value="start" data-testid="app-text-alignment-start">
              <DidomiIcon name={alignment === 'start' ? 'alignement-start' : 'alignement-start-inactive'} />
            </DidomiToggleButtonOption>
            <DidomiToggleButtonOption value="center" data-testid="app-text-alignment-center">
              <DidomiIcon name={alignment === 'center' ? 'alignment-center' : 'alignment-center-inactive'} />
            </DidomiToggleButtonOption>
            <DidomiToggleButtonOption value="end" data-testid="app-text-alignment-end">
              <DidomiIcon name={alignment === 'end' ? 'alignment-end' : 'alignment-end-inactive'} />
            </DidomiToggleButtonOption>
            <DidomiToggleButtonOption value="justify" data-testid="app-text-alignment-justify">
              <DidomiIcon name={alignment === 'justify' ? 'alignment-justify' : 'alignment-justify-inactive'} />
            </DidomiToggleButtonOption>
          </DidomiToggleButton>
        </div>

        {/* Text Color */}
        <div className="flex flex-col gap-0.5">
          <div className="text-primary-blue-6 text-field-label font-semibold">Color</div>
          <DidomiColorPicker data-testid="app-text-color" value={color || '#000000'} disabled={disabled} onValueChange={e => onColorChange(e.detail)} />
        </div>
      </div>
    </div>
  );
};
