import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { Purpose, ExtendedPreferencesCategoriesConfig, ExtendedPreferenceCategoryGroup } from '@types';

export const extendPreferencesConfig = (
  categoriesConfig: SDKConfigPreferencesCategory[],
  purposes: Purpose[],
  essentialPurposes: string[],
): ExtendedPreferencesCategoriesConfig => {
  // the "purposeId" stored on "preferences.categories" is the sdk_id of the purpose
  const getPurpose = (id: string) => {
    const purpose = purposes.find(p => p.sdk_id === id);

    // Guard against missing purposes
    if (!purpose) {
      return null;
    }

    return { ...purpose, required: essentialPurposes.includes(purpose.sdk_id) };
  };
  const getMappedCategoryGroup = (categoryGroup: SDKConfigPreferencesCategory): ExtendedPreferenceCategoryGroup => {
    return {
      ...categoryGroup,
      children: categoryGroup.children.map(c => getPurpose(c.purposeId)).filter(Boolean),
    } as ExtendedPreferenceCategoryGroup;
  };

  return categoriesConfig.map(c => (c.type === 'purpose' ? getPurpose(c.purposeId) : getMappedCategoryGroup(c))).filter(Boolean);
};
