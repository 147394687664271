import React from 'react';
import { DidomiHintbox, DidomiSelectionBlock } from '@didomi/ui-atoms-react';
import { SelectableTitle } from '@components';

type LegitimateInterestSettingsProps = {
  className?: string;
  noticeLiEnabled?: boolean;
  preferencesLiEnabled?: boolean;
  onKeepLegitimateInterestEnabledInNotice?: (enabled: boolean) => void;
  onKeepLegitimateInterestEnabledInPreference?: (enabled: boolean) => void;
};

export const LegitimateInterestSettings = ({
  className = '',
  noticeLiEnabled,
  preferencesLiEnabled,
  onKeepLegitimateInterestEnabledInNotice,
  onKeepLegitimateInterestEnabledInPreference,
}: LegitimateInterestSettingsProps) => {
  return (
    <section className={`w-full ${className}`}>
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-xs">Legitimate interest</h2>
      <p className="text-body-small text-primary-blue-6">Decide how the legitimate interest legal basis behaves when the user disagrees in the notice or in the preferences</p>

      <DidomiSelectionBlock className="w-full mt-xs" selected={noticeLiEnabled && preferencesLiEnabled} data-skeleton>
        <SelectableTitle
          className="mb-xxs"
          data-testid="legitimate-interest-notice"
          selected={noticeLiEnabled}
          onSelectionChanged={onKeepLegitimateInterestEnabledInNotice}
          title="Keep legitimate interest enabled when the user disagrees in the notice"
        />

        <DidomiHintbox className="mb-xxs">
          When the user clicks on the &#34;Disagree and close&#34; button or the &#34;Continue without agreeing&#34; link in the notice, only purposes and vendors based on the
          consent legal basis will be disabled. Purposes and vendors on the legitimate interest legal basis will be kept enabled and the user can disable them by going to the
          preferences.
        </DidomiHintbox>

        <hr className="h-0 border-t-1 border-neutral-gray-3 my-s" />

        <SelectableTitle
          className="mb-xxs"
          data-testid="legitimate-interest-preference"
          selected={preferencesLiEnabled}
          onSelectionChanged={onKeepLegitimateInterestEnabledInPreference}
          title="Keep legitimate interest enabled when the user clicks on “disagree to all” in the preferences"
        />

        <DidomiHintbox className="mb-xs">
          When user clicks on the “Disagree to all” button in the list of purposes and vendors based on the consent legal basis will be disabled. Purposes and vendors on the
          legitimate interest legal basis will be kept enabled and the user can disable them with specific choices.
        </DidomiHintbox>
      </DidomiSelectionBlock>
    </section>
  );
};
