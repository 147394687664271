// TODO: use default error text for all error messages
export const DEFAULT_ERROR_TEXT = {
  notice: {
    create: 'There was an error creating the notice',
    save: 'There was an error updating the notice',
    publish: 'There was an error deploying the notice',
    restore: 'There was an error restoring the notice',
    duplicate: 'There was an error duplicating the notice',
    archive: 'There was an error archiving the notice',
  },
  template: {
    create: 'There was an error creating template and applying it to the notice',
    save: 'There was an error saving the changes',
    apply: 'There was an error applying the template',
    unlink: 'There was an error unlinking the template',
  },
};

export const getApiErrorText = (error: any, fallback: string) => {
  return error?.response?.data?.message || fallback;
};
