import { useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

type Step = 'regulations' | 'territories' | 'consent-recollection';

enum RegulationsStep {
  'regulations' = 0,
  'territories' = 1,
  'consent-recollection' = 2,
}

const defaultRegulationsStepOptions = {
  defaultStep: 'regulations',
};

interface PaginationOptions {
  defaultStep?: Step;
  defaultTraffic?: string;
}

export const useRegulationsOptions = (options?: PaginationOptions) => {
  const { defaultStep } = {
    ...defaultRegulationsStepOptions,
    ...options,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const regulationsStep = useMemo(() => {
    return RegulationsStep[searchParams.get('step')] || RegulationsStep[defaultStep];
  }, [searchParams, defaultStep]);

  const setRegulationsStep = useCallback(
    (regulationStep: number) => {
      searchParams.set('step', RegulationsStep[regulationStep]);
      if (RegulationsStep[regulationStep] === defaultStep) {
        searchParams.delete('step');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, defaultStep],
  );

  return {
    regulationsStep,
    setRegulationsStep,
  };
};
