import React, { useState } from 'react';
import { generateUniqueId } from '@didomi/helpers';
import { DidomiCheckbox, DidomiCollapse, DidomiRadio, DidomiSelectionBlock } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type CardRadioOptionProps = {
  labelText: string;
  value?: string;
  selected?: boolean;
  inputType?: 'radio' | 'checkbox';
  collapsibleContent?: boolean;
  name?: string;
  children?: React.ReactChild;
  className?: string;
  disabled?: boolean;
  onChange?: (event: CustomEvent<boolean>) => void;
};

export const CardSelectionField = ({
  labelText,
  value,
  selected,
  children,
  collapsibleContent,
  name,
  inputType = 'radio',
  className,
  disabled,
  onChange,
}: CardRadioOptionProps) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);
  const inputId = generateUniqueId();

  return (
    <DidomiSelectionBlock className={className} selected={selected} disabled={disabled}>
      <div className="flex gap-s mb-2">
        <label htmlFor={inputId} className={tx('self-center mr-auto text-body-small text-primary-blue-6 font-semibold', { 'cursor-pointer': !disabled })}>
          {labelText}
        </label>

        {inputType === 'radio' ? (
          <DidomiRadio value={value} name={name} radioId={inputId} />
        ) : (
          <DidomiCheckbox
            data-testid={`selection-field-checkbox`}
            checkboxId={inputId}
            title={labelText}
            checked={selected}
            name={name}
            disabled={disabled}
            hideErrorMessage
            onValueChange={onChange}
          />
        )}
      </div>

      <DidomiCollapse isExpanded={isDetailsVisible || !collapsibleContent}>
        <div className="text-body-extra-small text-primary-blue-6">{children}</div>
      </DidomiCollapse>

      {collapsibleContent && (
        <button type="button" className="block text-body-extra-small text-primary-blue-4 underline" onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
          {isDetailsVisible ? 'Hide details' : 'Show details'}
        </button>
      )}
    </DidomiSelectionBlock>
  );
};
