import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNotice } from '@types';

/**
 * Archives a notice, makes sure to update the list of notices with the edited notice value
 * */
export const useArchiveConsentNotice = (options?: UseMutationOptions<AxiosResponse<ConsentNotice>, AxiosError, string>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async (noticeId: string) => {
      const newDate = new Date();
      const requestBody = {
        archived_at: newDate.toString(),
      };
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/${noticeId}?$translations=true`, requestBody);
    },
    {
      ...options,
      onSuccess: (editedConsentNotice: AxiosResponse<ConsentNotice>, variables, context) => {
        const noticeId = editedConsentNotice.data.id;
        const consentNoticesGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notices', organizationId]);
        /* istanbul ignore if */
        if (consentNoticesGet) {
          queryClient.setQueryData(['consent-notices', organizationId], {
            ...consentNoticesGet,
            data: consentNoticesGet.data.map(consentNotice => (consentNotice.id === noticeId ? { ...consentNotice, ...editedConsentNotice.data } : consentNotice)),
            total: consentNoticesGet.total + 1,
          });
        }

        const consentNoticeGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notice', noticeId]);

        /* istanbul ignore if */
        if (consentNoticeGet) {
          queryClient.setQueryData(['consent-notice', noticeId], { ...consentNoticeGet, ...editedConsentNotice.data });
        }

        if (options?.onSuccess) {
          options.onSuccess(editedConsentNotice, variables, context);
        }
      },
    },
  );
};
