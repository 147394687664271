import { ConsentNoticeConfigRegulation } from '@types';

/*
 * Find the regulation_id to which geo is assigned
 */
export const getDefaultRegulationIdByGeo = (geo: string, regulationsConfigs: ConsentNoticeConfigRegulation[]): string => {
  const defaultConfigs = regulationsConfigs.filter(r => r.is_default_regulation_config);
  const assignedDirectlyTo = defaultConfigs.find(r => r.geo_locations.includes(geo));
  const getRegulationWithWildCard = () => defaultConfigs.find(r => r.geo_locations.includes('*'));

  return assignedDirectlyTo?.regulation_id || getRegulationWithWildCard()?.regulation_id;
};
