import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmDeleteModalProps = {
  isOpen: boolean;
  children?: React.ReactChild;
  onConfirm: () => void;
};

export const MigrateToIabTcfV2Modal = ({ isOpen, onConfirm }: ConfirmDeleteModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="iab-tcf-v1-to-iab-tcf-v2" />
      <DidomiModalHeader variant="warning" modalTitle="IAB TCF v.1 No Longer Supported" modalSubTitle="Action required" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p className="mb-xxs">The integration associated with this notice is currently using the IAB TCF v.1 integration which is no longer supported by IAB.</p>
          <p className="mb-xxs">By clicking “Agree”, you will allow us to remove the IAB TCF v.1 integration and enable the IAB TCF v.2 integration.</p>
          <p className="mb-s">Additional steps are required to edit and republish the notice, please see the following links for more information.</p>

          <a href="https://support.didomi.io/iab-tcf#migration-guides" target="_blank" rel="noopener noreferrer" className="font-bold">
            Migration guide
          </a>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton onClick={onConfirm}>Agree</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
