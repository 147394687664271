import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';

export const WhatArePresets = () => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <>
      <p className="h3 text-primary-blue-6">
        Presets are <span className="text-primary-blue-4 font-semibold">essentially pre-configuration</span> that you can add to your notices.
      </p>
      <img className="my-s" width={440} height={300} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-presets.svg`} alt="" />

      <p className="mb-s h3 text-primary-blue-6">
        Didomi provides an option to <span className="text-primary-blue-4 font-semibold">choose either an IAB Transparency and Consent Framework </span> preset, or{' '}
        <span className="text-primary-blue-4 font-semibold">no preset</span>.
      </p>
      <p className="text-body-normal text-primary-blue-6 mb-xxxs">
        The IAB TCF Framework preset is useful for complying with applicable regulations and ensuring best practices for online advertising.
      </p>
      <p className="text-body-normal text-primary-blue-6 mb-m">
        We would recommend this preset for companies whose notices will be subject to GDPR, or for regions where IAB TCF is applicable.
      </p>

      <hr className="block h-0 my-xs border-t-1 border-primary-pink-2" />

      <h2 id="iab-tcf-title" className="h2 text-primary-blue-6 mb-s">
        What is IAB? and what is an IAB TCF framework?
      </h2>

      <p className="h3 text-primary-blue-6 mb-s">
        The Interactive Advertising Bureau (IAB) is organization at the european level, that gathers players in Digital Marketing and advertising.
      </p>

      <img className="my-s" width={440} height={148} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-tcf.svg`} alt="" />

      <p className="h3 text-primary-blue-6 mb-m">
        The IAB created the <span className="text-primary-blue-4 font-semibold">Transparency and Consent Framework (TCF)</span> a framework with a consent solution in compliance
        with the GDPR and the ePrivacy guidelines that set standards to collect consent, especially for ad tech providers.
      </p>

      <p className="h3 text-primary-blue-6 mb-m">
        The TCF creates an environment where website publishers can tell users what data are collected and how their websites and companies wish to use them.
      </p>

      <p className="h3 text-primary-blue-6  mb-m">
        It offers <span className="text-primary-blue-4 font-semibold">a legal safety net for all players, and a sustainable business models to reduce compliance cost.</span>
      </p>

      <hr className="block h-0 my-xs border-t-1 border-primary-pink-2" />

      <h2 id="iab-tcf-gcm-title" className="h2 text-primary-blue-6 mb-s border-t-1">
        What is Google Consent Mode V2?
      </h2>

      <p className="h3 text-primary-blue-6">
        For advertisers, <span className="text-primary-blue-4 font-semibold">Google Consent Mode (GCM) bridges data privacy and digital advertising</span> by adjusting the behavior
        of Google tags to the consent status of your users.
      </p>

      <img className="my-s" width={440} height={300} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-gcm.svg`} alt="" />

      <p className="h3 text-primary-blue-6 mb-m">
        This allows you to obtain insights into marketing campaign conversions and other data, always respecting the user&apos;s consent choices for advertising cookies and
        analytics cookies, which will help you optimize and improve ad monetization.
      </p>

      <p className="h3 text-primary-blue-6 mb-m">
        With Consent Mode, your Google tags will be able to determine whether or not a user has given consent for advertising and/or analytical purposes.
      </p>
    </>
  );
};
