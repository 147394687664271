import React, { useEffect, useState } from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiTextarea, DidomiIllustration, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type PublishTemplateModalProps = {
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (deploymentMessage: string) => void;
};

export const PublishTemplateModal = ({ isOpen, onCancel, onSubmit }: PublishTemplateModalProps) => {
  const [deploymentMessage, setDeploymentMessage] = useState('');

  // Reset deployment message when modal is opened
  useEffect(() => {
    setDeploymentMessage('');
  }, [isOpen]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal data-testid="publish-template-modal" isOpen={isOpen} onOpenChange={handleModalChange}>
      <div>
        <DidomiIllustration className={tx('mb-s')} name="publish" />
        <DidomiModalHeader
          modalSubTitle="Please pay attention"
          modalTitle="You’re about to apply your template to selected notices and publish a new notice version"
          modalDescription="All changes will be deployed to your live consent notices on any website or mobile app that it is targeting."
        ></DidomiModalHeader>

        <DidomiModalContent className={tx('text-left w-full')}>
          <DidomiTextarea
            class="w-full"
            value={deploymentMessage}
            onValueChange={e => setDeploymentMessage(e.detail)}
            name="message"
            label="Description of the changes"
            placeholder="e.g.: 5 vendors added, additional text updates..."
            rows={3}
            hideErrorMessage
          />
        </DidomiModalContent>

        <DidomiModalActions>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton iconRight="save-publish" variant="top" onClick={() => onSubmit(deploymentMessage)}>
            Apply & Publish
          </DidomiButton>
        </DidomiModalActions>
      </div>
    </DidomiModal>
  );
};
