import React from 'react';
import { DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiChip, DidomiChipList } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { getCounterText } from '@utils';

type SelectAtLeastAPurposeOrVendorModalProps = {
  isOpen: boolean;
  invalidRegulations: [string, { vendorsCount: number; purposesCount: number }][];
  onClose: () => void;
};

export const SelectAtLeastAPurposeOrVendorModal = ({ isOpen, invalidRegulations, onClose }: SelectAtLeastAPurposeOrVendorModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader
        variant="warning"
        modalTitle="You have to add vendors and purposes"
        modalSubTitle="Something is missing"
        modalDescription="The following selected regulations do not contain vendors and purposes and can’t be published"
      />
      <DidomiModalContent style={{ width: '100%', paddingX: '24px' }}>
        <ul data-testid="invalid-regulations-list" className={tx('w-full border-t-1 border-solid border-neutral-gray-3')}>
          {invalidRegulations.map(([reg, { vendorsCount, purposesCount }]) => (
            <li className={tx('w-full py-s border-b-1 border-solid border-neutral-gray-3')} key={reg}>
              <div className={tx('w-full flex justify-between items-center')}>
                <div className={tx('uppercase text-primary-blue-6 font-bold text-header-row-title-bold')}>{reg}</div>
                <DidomiChipList>
                  <DidomiChip variation="basic-outline" basicType="empty" label={getCounterText(vendorsCount, 'vendor')} />
                  <DidomiChip variation="basic-outline" basicType="empty" label={getCounterText(purposesCount, 'purpose')} />
                </DidomiChipList>
              </div>
            </li>
          ))}
        </ul>
        <div className={tx('mt-xs text-body-small text-primary-blue-6')}>
          Click on <div className={tx('inline-flex items-center font-bold gap-xxxs')}>Edit Vendors & Purposes</div>
        </div>
        <div className={tx('text-body-small text-primary-blue-6')}>to fill your notice content</div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton data-testid="i-got-it" onClick={onClose}>
          I got it
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
