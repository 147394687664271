import React from 'react';
import { DidomiLoader, DidomiModal, DidomiModalHeader } from '@didomi/ui-atoms-react';

type LoadingModalProps = {
  'data-cy'?: string;
  title: string;
  subTitle?: string;
  isOpen: boolean;
};

export const LoadingModal = ({ 'data-cy': dataCy, isOpen, title, subTitle = 'Please wait' }: LoadingModalProps) => (
  <DidomiModal data-cy={dataCy} isOpen={isOpen} permanent variant="slim">
    <div style={{ marginBottom: '24px' }}>
      <DidomiLoader />
    </div>
    <DidomiModalHeader modalTitle={title} modalSubTitle={subTitle} />
  </DidomiModal>
);
