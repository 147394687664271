import { ConsentNoticeConfig, Vendor } from '@types';
import { getDefaultGdprConfig } from './getDefaultGdprConfig';
import { TCF_2p2_PURPOSES_BASED_ON_CONSENT } from './tcf2p2PurposesBasedOnConsent';

const isPurposeInvalidLiForTcf2p2 = (purposeId: string) => TCF_2p2_PURPOSES_BASED_ON_CONSENT.includes(purposeId);
const doesVendorHasInvalidLiPurposesForTcf2p2 = (vendor: Vendor) => vendor.legitimate_interest_purposes_id.some(isPurposeInvalidLiForTcf2p2);

/*
 * Returns the list of vendors with invalid legitimate interest purposes for IAB TCF 2.2
 */
export const getVendorsWithInvalidLiPurposesForTcf2p2 = (rootConfig: ConsentNoticeConfig, vendors: Vendor[] = []) => {
  const defaultGdpr = getDefaultGdprConfig(rootConfig);
  const selectedCustomVendorsIds = defaultGdpr?.config.app.vendors.include || [];
  const selectedCustomVendors = vendors.filter(vendor => selectedCustomVendorsIds.includes(vendor.id));
  const vendorsWithInvalidLiPurposesForTcf2p2 = selectedCustomVendors.filter(doesVendorHasInvalidLiPurposesForTcf2p2);

  return vendorsWithInvalidLiPurposesForTcf2p2;
};
