import { useActiveOrganization, useCustomFeature } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNoticeConfig } from '@types';
import { sanitizeObject, updateDefaultNoticeConfigsCache, updateNoticeCache, updateNoticeConfigCache, updateNoticesCache, updateTemplatesCache } from '@utils';

/**
 * Edits a notice config, makes sure to update the list of notices with the edited notice value
 * */
export const useEditConsentNoticeConfig = (options?: UseMutationOptions<AxiosResponse<ConsentNoticeConfig>, AxiosError, Partial<ConsentNoticeConfig>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const [useSanitisation] = useCustomFeature('consent-notice-sanitize-xss');
  const queryClient = useQueryClient();

  return useMutation(
    async (consentNoticeConfig: Partial<ConsentNoticeConfig>) => {
      const noticeConfigPayload = useSanitisation ? sanitizeObject(consentNoticeConfig) : consentNoticeConfig;
      const noticeConfigBody = {
        ...noticeConfigPayload,
        organization_id: organizationId,
      };

      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs/${consentNoticeConfig?.id}?$translations=true`, noticeConfigBody, {
        headers: {
          v: '2',
        },
      });
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<ConsentNoticeConfig>, variables, context) => {
        const patchedNoticeConfig: ConsentNoticeConfig = response.data;

        updateTemplatesCache(queryClient, patchedNoticeConfig);

        updateDefaultNoticeConfigsCache(queryClient, patchedNoticeConfig);

        updateNoticesCache(queryClient, patchedNoticeConfig);

        updateNoticeCache(queryClient, patchedNoticeConfig);

        // Updating NoticeConfig goes last as previous cache updates may rely on the stale cached NoticeConfig
        updateNoticeConfigCache(queryClient, patchedNoticeConfig);

        // When patching the Notice Config, the admin API creates required numeric ids for Vendors and Purposes
        // Clear the cache so they are refetched again when needed
        queryClient.removeQueries({ queryKey: ['vendors-numeric-ids', patchedNoticeConfig.organization_id] });
        queryClient.removeQueries({ queryKey: ['purposes-numeric-ids', patchedNoticeConfig.organization_id] });

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    },
  );
};
