import { SDKConfigPreferencesCategory } from '@didomi/cmp-generator';
import { matchId } from '@didomi/helpers';
import { arrayMove } from '@dnd-kit/sortable';

// Get indexes of active item and over item
export const getItemsIndexes = (items: { id: string }[], activeId: string, overId: string) => {
  const fromIndex = items.findIndex(matchId(activeId));
  const toIndex = items.findIndex(matchId(overId));

  return { fromIndex, toIndex };
};

// Reorder category children and return new updated category
export const updateCategoryGroupChildren = (category: SDKConfigPreferencesCategory, fromIndex: number, toIndex: number) => {
  return { ...category, children: arrayMove(category.children, fromIndex, toIndex) };
};

export const movePurposeFromRootToGroup = (categories: SDKConfigPreferencesCategory[], toGroupId: string, sdkId: string): SDKConfigPreferencesCategory[] => {
  return categories.reduce((acc, item) => {
    if (item.type === 'category' && item.id === toGroupId) {
      // add item to group
      acc.push({ ...item, children: [{ type: 'purpose', purposeId: sdkId }, ...item.children] });
    } else if (item['purposeId'] !== sdkId) {
      acc.push(item);
    }

    return acc;
  }, []);
};

export const movePurposeFromGroupToRoot = (categories: SDKConfigPreferencesCategory[], fromGroupId: string, sdkId: string): SDKConfigPreferencesCategory[] => {
  const filteredCategories = categories.map(item => {
    if (item.type === 'category' && item.id === fromGroupId) {
      // remove item from group
      return { ...item, children: item.children.filter(child => child.purposeId !== sdkId) };
    } else {
      return item;
    }
  });
  //   // Add purpose as first item to the root
  return ([{ type: 'purpose' as const, purposeId: sdkId }] as SDKConfigPreferencesCategory[]).concat(filteredCategories);
};

export const movePurposeFromGroupToGroup = (categories: SDKConfigPreferencesCategory[], fromGroupId: string, toGroupId: string, sdkId: string): SDKConfigPreferencesCategory[] => {
  return categories.map(item => {
    if (item.type === 'category' && item.id === fromGroupId) {
      // remove item from group
      return { ...item, children: item.children.filter(child => child.purposeId !== sdkId) };
    } else if (item.type === 'category' && item.id === toGroupId) {
      // add item to group
      return { ...item, children: [{ type: 'purpose', purposeId: sdkId }, ...item.children] };
    } else {
      return item;
    }
  });
};
