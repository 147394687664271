import React, { useMemo } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import { DidomiControlledCollapsibleCard, DidomiIconButton, DidomiLanguageFlag, DidomiMenu, DidomiMenuItem } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { CategoryLanguageData } from '@types';

interface PurposeCategoryLanguageCardProps {
  languageData: CategoryLanguageData;
  onEdit?: () => void;
  onDelete?: () => void;
}

export const PurposeCategoryLanguageCard = ({ languageData, onEdit, onDelete }: PurposeCategoryLanguageCardProps) => {
  const longLanguageName = useMemo(() => {
    return supportedLanguages.find(l => l.code === languageData.language)?.longName;
  }, [languageData]);

  return (
    <DidomiControlledCollapsibleCard className={tx('w-full')}>
      {/* TITLE */}
      <div slot="header-title-content" className={tx('flex gap-xxs items-center')}>
        <DidomiLanguageFlag language={languageData.language} style={{ '--icon-size': '20px' }} />
        <div className={tx('font-semibold text-body-extra-small')}>{longLanguageName}</div>
      </div>

      {/* EDIT/DELETE MENU */}
      <div slot="header-controls">
        <DidomiIconButton variant="rounded" icon="submenu" id={'menu-' + languageData.language} title="Language menu"></DidomiIconButton>
        <DidomiMenu for={'menu-' + languageData.language} variant="main" placement="left-start" distance="xxs">
          <DidomiMenuItem data-tracking="cmp-notice-remove" iconName="edit" onItemSelected={onEdit}>
            Edit
          </DidomiMenuItem>
          <DidomiMenuItem data-tracking="cmp-notice-remove" iconName="delete" onItemSelected={onDelete}>
            Delete
          </DidomiMenuItem>
        </DidomiMenu>
      </div>

      {/* BODY */}
      <div>
        <div className={tx('font-bold text-body-extra-small')}>NAME</div>
        <div className={tx('text-body-small mb-xs')}>{languageData.name}</div>

        <div className={tx('font-bold text-body-extra-small')}>DESCRIPTION</div>
        <div className={tx('text-body-small')}>{languageData.description || '-'}</div>
      </div>
    </DidomiControlledCollapsibleCard>
  );
};
