import { useEffect, useState } from 'react';
import { SDKConfig, SDKConfigAppIabStacks } from '@didomi/cmp-generator';
import { IabStackExtended } from '@types';
import { enrichIabStacks, selectIAB2Purposes } from '@utils';
import { useIabStacks } from './useIabStacks.hook';
import { usePurposes } from './usePurposes.hook';

export const useIabStacksState = ({ config }: { config: SDKConfig }) => {
  const [stacksConfig, setStacksConfig] = useState<SDKConfigAppIabStacks>({ auto: false, ids: [] });
  const [extendedStacks, setExtendedStacks] = useState<IabStackExtended[]>([]);

  const { data: iabStacks, isLoading: isStacksLoading } = useIabStacks();
  const { data: purposes, isLoading: isPurposesLoading } = usePurposes({ select: selectIAB2Purposes });

  useEffect(() => {
    const stacks = config?.app?.vendors?.iab?.stacks;

    if (stacks) {
      setStacksConfig({ auto: stacks.auto || false, ids: stacks.ids || [] });
    }
  }, [config]);

  useEffect(() => {
    if (iabStacks && purposes) {
      setExtendedStacks(enrichIabStacks(iabStacks, purposes));
    }
  }, [iabStacks, purposes]);

  const updateStacksConfig = (config: SDKConfigAppIabStacks) => {
    setStacksConfig(config);
  };

  return {
    stacksConfig,
    extendedStacks,
    updateStacksConfig,
    isLoading: isStacksLoading || isPurposesLoading,
  };
};
