import React from 'react';
import { useSortable } from '@dnd-kit/sortable';

type SortableDropzoneProps = {
  dropzoneId: string;
  children: React.ReactChild;
};

export const SortableDropzone = ({ dropzoneId, children }: SortableDropzoneProps) => {
  const { setNodeRef } = useSortable({
    id: dropzoneId,
  });

  return (
    <div className="p-5 rounded border-1 border-dashed border-neutral-gray-8 text-center text-body-extra-small font-semibold" ref={setNodeRef}>
      {children}
    </div>
  );
};
