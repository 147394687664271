import React, { useMemo } from 'react';
import { generateUniqueId, supportedLanguages } from '@didomi/helpers';
import { DidomiSelect, DidomiSelectOptions, DidomiSelectOption } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface LanguageSelectionProps {
  id?: string;
  className?: string;
  enabledLanguages?: string[];
  isLoading?: boolean;
  value: string;
  attachTo?: string;
  boundaryElementId?: string;
  variant?: 'standard' | 'modern';
  onValueChange: (value: string) => void;
}

export const LanguageSelection = ({
  id = generateUniqueId('language-selection'),
  className,
  value,
  enabledLanguages = ['en'],
  attachTo,
  boundaryElementId,
  isLoading,
  variant = 'standard',
  onValueChange,
}: LanguageSelectionProps) => {
  const languagesForDisplay = useMemo(() => {
    if (!enabledLanguages) return [];
    return supportedLanguages?.filter(lang => enabledLanguages.includes(lang.code)) || [];
  }, [enabledLanguages]);

  return (
    <DidomiSelect
      id={id}
      className={tx({ 'max-w-[180px]': variant === 'standard' }, className)}
      size="sm"
      hideErrorMessage
      data-skeleton={isLoading}
      value={value}
      variant={variant}
      attachTo={attachTo}
      boundaryElementId={boundaryElementId}
      aria-label="Select language"
      onValueChange={e => onValueChange(e.detail as string)}
      placeholder="Select language"
      allowAutomaticSize
    >
      <DidomiSelectOptions>
        {languagesForDisplay.map(l => (
          <DidomiSelectOption key={'selected-lang-' + l.code} value={l.code} label={l.longName} />
        ))}
      </DidomiSelectOptions>
    </DidomiSelect>
  );
};
