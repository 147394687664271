import { useMemo } from 'react';
import { Regulation, Regulations } from '@didomi/cmp-generator';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useLocalRegulationConfig, usePurposes, useVendors } from '@hooks';
import { GcmVendorsIds, getIabTcfVersion, getPurposeCategoriesBasedOnVendors, isIAB2Vendor, produceListOfChanges } from '@utils';

/**
 * Custom hook for managing local consent notice configuration domain logic.
 * This hooks should be used as facade for accessing and modifying the local consent notice configuration.
 * For most cases it should be used in combination with the useLocalConsentNoticeConfigFacade hook - so we have only single instance of the facade logic per regulation context.
 *
 * @param noticeId - The ID of the consent notice.
 * @param regulationId - Optional. The ID of the regulation. By default, it is GDPR.
 * @returns An object containing the notice config getters and modifying functions(use cases).
 */
export const useLocalConsentNoticeConfigFacadeLogic = (noticeId: string, regulationId: Regulation = Regulations.GDPR) => {
  const { data: vendors = [], isLoading: isLoadingVendors } = useVendors();
  const { data: purposes, isLoading: isLoadingPurposes } = usePurposes();
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { regulationConfigIndex, aggregatedRegConfig, isLoading: isLoadingConfig, activeRegulationIds } = useLocalRegulationConfig(noticeId, regulationId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);

  // Helpers
  const addChangesToConfig = (changes: { path: string; value: any }[]) => {
    const updatedConfig = produceListOfChanges(changes, localConsentNoticeConfig);
    updateLocalConfig(updatedConfig);
  };

  const getUpdatedCategoriesFromVendors = (allSelectedVendorsIds: string[]) => {
    const allSelectedVendors = vendors.filter(vendor => allSelectedVendorsIds.includes(vendor.id));
    return getPurposeCategoriesBasedOnVendors(aggregatedRegConfig.config?.preferences?.categories, allSelectedVendors, purposes);
  };

  const getChangesForUpdatedVendors = (updatedIncludedVendor: string[]) => {
    const allSelectedVendorsIds = preselectedVendors.concat(updatedIncludedVendor);
    const updatedCategories = getUpdatedCategoriesFromVendors(allSelectedVendorsIds);

    return [
      { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.include`, value: updatedIncludedVendor },
      { path: `regulation_configurations[${regulationConfigIndex}].config.preferences.categories`, value: updatedCategories },
    ];
  };

  // Getters
  const textMode = aggregatedRegConfig?.text_mode;
  const textId = aggregatedRegConfig?.text_id;
  const iabTcfVersion = useMemo(() => getIabTcfVersion(localConsentNoticeConfig), [localConsentNoticeConfig]);
  const platform = aggregatedRegConfig?.platform;
  const isIabEnabled = aggregatedRegConfig?.config?.app?.vendors?.iab?.enabled;
  const isIabAll = aggregatedRegConfig?.config?.app?.vendors?.iab?.all;
  const preferenceCategories = aggregatedRegConfig?.config?.preferences?.categories;

  const iabVendorsIds = useMemo(() => vendors.filter(isIAB2Vendor).map(({ id }) => id), [vendors]);
  const includedVendorIds = useMemo(() => aggregatedRegConfig?.config?.app?.vendors?.include || [], [aggregatedRegConfig]);
  const preselectedVendors = isIabAll ? iabVendorsIds : [];

  const gcm = useMemo(() => {
    return {
      isEnabled: aggregatedRegConfig?.gcm_enabled,
      isSetDefaultStatus: aggregatedRegConfig?.gcm_set_default_status,
      isAdsDefaultStatus: aggregatedRegConfig?.gcm_ads_default_status,
      isAdsUserDataDefaultStatus: aggregatedRegConfig?.gcm_ad_user_data_default_status,
      isAnalyticsDefaultStatus: aggregatedRegConfig?.gcm_analytics_default_status,
      isPersonalizationDefaultStatus: aggregatedRegConfig?.gcm_personalization_default_status,
      isAdsPersonalizationDefaultStatus: aggregatedRegConfig?.gcm_ad_personalization_default_status,
      isFunctionalityDefaultStatus: aggregatedRegConfig?.gcm_functionality_default_status,
      dataLayerName: aggregatedRegConfig?.gcm_data_layer_name,
      isAnalyticsEnabled: includedVendorIds.includes(GcmVendorsIds.googleAnalytics),
      isAdsEnabled: includedVendorIds.includes(GcmVendorsIds.googleAds),
      isTCFEnabled: aggregatedRegConfig?.gcm_tcf_enabled,
    };
  }, [aggregatedRegConfig, includedVendorIds]);

  const gpp = useMemo(
    () => ({
      isEnabled: aggregatedRegConfig?.gpp_enabled,
      isMspaSignatory: aggregatedRegConfig?.gpp_mspa_signatory,
      isMspaOptOutMode: aggregatedRegConfig?.gpp_mspa_optout_mode,
      isMspaServiceProviderMode: aggregatedRegConfig?.gpp_mspa_service_provider_mode,
      isMspaGenerateUsnatString: aggregatedRegConfig?.gpp_mspa_generate_usnat_string,
    }),
    [aggregatedRegConfig],
  );

  // Logic is inverted - "full_atp: false" means that checkbox should be checked ("true" for front-end)
  // "full_atp: false" - the hardcoded ATP vendor list is used
  // "full_atp: true" (default value) - ATP vendors from custom vendor list is used(client selection of ATP vendors)
  const isStandardAtpListSelected = !aggregatedRegConfig?.full_atp;

  const tagManagerProvider = aggregatedRegConfig?.config?.tagManager?.provider;
  const tagManagerDataLayerName = aggregatedRegConfig?.config?.tagManager?.dataLayerName;

  // Use Cases
  const enableIabWithStandardText = (textId: string) => {
    const changes = [
      { path: 'text_id', value: textId },
      { path: 'text_mode', value: 'approved' },
      { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.enabled`, value: true },
    ];
    addChangesToConfig(changes);
  };

  const changeIabEnablement = (isEnabled: boolean) => {
    const changes = [
      { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.enabled`, value: isEnabled },
      // Changes to make when IAB TCF is disabled
      !isEnabled && { path: `regulation_configurations[${regulationConfigIndex}].config.app.vendors.iab.all`, value: false },
      !isEnabled && gcm.isTCFEnabled && { path: `regulation_configurations[${regulationConfigIndex}].gcm_tcf_enabled`, value: false },
    ].filter(Boolean);
    addChangesToConfig(changes);
  };

  const changeGcmEnablement = (isEnabled: boolean) => {
    const changes: { path: string; value: any }[] = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_enabled`, value: isEnabled }];

    // If GCM is enabled, and no Gcm vendors are included, include Google Analytics and Google Ads
    if (isEnabled && !gcm.isAdsEnabled && !gcm.isAnalyticsEnabled) {
      const updatedIncludedVendor = [...includedVendorIds, GcmVendorsIds.googleAds, GcmVendorsIds.googleAnalytics];
      changes.push(...getChangesForUpdatedVendors(updatedIncludedVendor));
    }

    // If GCM and IAB are both enabled - enable GCM IAB TCF integration
    if (isEnabled && isIabEnabled) {
      changes.push({ path: `regulation_configurations[${regulationConfigIndex}].gcm_tcf_enabled`, value: true });
    }
    addChangesToConfig(changes);
  };

  const changeGcmTCFEnablement = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_tcf_enabled`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmSetDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_set_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmAdsDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_ads_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmAdsUserDataDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_ad_user_data_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmPersonalizationDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_personalization_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmAdsPersonalizationDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_ad_personalization_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmAnalyticsDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_analytics_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmFunctionalityDefaultStatus = (isEnabled: boolean) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_functionality_default_status`, value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGcmDataLayerName = (dataLayerName: string) => {
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].gcm_data_layer_name`, value: dataLayerName || null }];
    addChangesToConfig(changes);
  };

  const changeGcmAnalyticsEnablement = (isEnabled: boolean) => {
    const updatedIncludedVendor = isEnabled ? [...includedVendorIds, GcmVendorsIds.googleAnalytics] : includedVendorIds.filter(id => id !== GcmVendorsIds.googleAnalytics);

    const changes = getChangesForUpdatedVendors(updatedIncludedVendor);
    addChangesToConfig(changes);
  };

  const changeGcmAdsEnablement = (isEnabled: boolean) => {
    const updatedIncludedVendor = isEnabled ? [...includedVendorIds, GcmVendorsIds.googleAds] : includedVendorIds.filter(id => id !== GcmVendorsIds.googleAds);

    const changes = getChangesForUpdatedVendors(updatedIncludedVendor);
    addChangesToConfig(changes);
  };

  const changeIsStandardAtpListSelected = (isSelected: boolean) => {
    // Logic is inverted
    const changes = [{ path: `regulation_configurations[${regulationConfigIndex}].full_atp`, value: !isSelected }];
    addChangesToConfig(changes);
  };

  const changeTagManagerProvider = (provider: string) => {
    const changes = [{ path: 'config.tagManager.provider', value: provider }];
    addChangesToConfig(changes);
  };

  const changeTagManagerDataLayerName = (dataLayerName: string) => {
    const changes = [{ path: 'config.tagManager.dataLayerName', value: dataLayerName }];
    addChangesToConfig(changes);
  };

  const changeGppEnablement = (isEnabled: boolean) => {
    const changes = [{ path: 'gpp_enabled', value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGppMspaSignatory = (isEnabled: boolean) => {
    const changes = [{ path: 'gpp_mspa_signatory', value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGppMspaOptOutMode = (isEnabled: boolean) => {
    const changes = [{ path: 'gpp_mspa_optout_mode', value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGppMspaServiceProviderMode = (isEnabled: boolean) => {
    const changes = [{ path: 'gpp_mspa_service_provider_mode', value: isEnabled }];
    addChangesToConfig(changes);
  };

  const changeGppMspaGenerateUsnatString = (isEnabled: boolean) => {
    const changes = [{ path: 'gpp_mspa_generate_usnat_string', value: isEnabled }];
    addChangesToConfig(changes);
  };

  const isLoading = isLoadingVendors || isLoadingPurposes || isLoadingConfig;

  return {
    isLoading,
    isLoadingConfig,
    regulationId,

    // Getters
    textMode,
    textId,
    platform,
    iabTcfVersion,
    isIabEnabled,
    isIabAll,
    preferenceCategories,
    gcm,
    gpp,
    isStandardAtpListSelected,
    tagManagerProvider,
    tagManagerDataLayerName,
    activeRegulationIds,

    // Use Cases
    enableIabWithStandardText,
    changeIabEnablement,
    changeGcmEnablement,
    changeGcmTCFEnablement,
    changeGcmSetDefaultStatus,
    changeGcmAdsDefaultStatus,
    changeGcmAdsUserDataDefaultStatus,
    changeGcmPersonalizationDefaultStatus,
    changeGcmAdsPersonalizationDefaultStatus,
    changeGcmAnalyticsDefaultStatus,
    changeGcmFunctionalityDefaultStatus,
    changeGcmDataLayerName,
    changeGcmAnalyticsEnablement,
    changeGcmAdsEnablement,
    changeIsStandardAtpListSelected,
    changeTagManagerProvider,
    changeTagManagerDataLayerName,
    changeGppEnablement,
    changeGppMspaSignatory,
    changeGppMspaOptOutMode,
    changeGppMspaServiceProviderMode,
    changeGppMspaGenerateUsnatString,
  };
};
