import React from 'react';
import { DidomiCheckbox, DidomiIllustration, DidomiRadio, DidomiSelectionBlock } from '@didomi/ui-atoms-react';

interface PresetSelectionCardProps {
  className?: string;
  value: string;
  illustration: string;
  titleText: string;
  description: string;
  children?: React.ReactNode;
  inputType?: 'radio' | 'checkbox';
  onValueChange?: (event: CustomEvent<any>) => void;
}

export const PresetSelectionCard = ({ className, value, illustration, titleText, description, children, inputType = 'radio', onValueChange }: PresetSelectionCardProps) => {
  return (
    <div className={className}>
      <DidomiSelectionBlock className={'w-full h-full'} variant="accent" data-skeleton>
        <label htmlFor={value} className="flex flex-col items-center text-center text-primary-blue-6 h-full pb-xs cursor-pointer 2xl:pt-s">
          <DidomiIllustration className="mb-xs" name={illustration as any} />

          <h2 className="font-semibold mb-xxs">{titleText}</h2>
          <p className="text-body-small mb-xxs">{description}</p>

          {children}

          {/* Selection is handled by parent's RadioGroup */}
          {inputType === 'radio' ? (
            <DidomiRadio data-testid={'select-preset-' + value} className="mt-auto" value={value} radioId={value} />
          ) : (
            <DidomiCheckbox data-testid={'select-preset-' + value} className="mt-auto" checkboxId={value} hideErrorMessage onValueChange={onValueChange} />
          )}
        </label>
      </DidomiSelectionBlock>
    </div>
  );
};
