const AVAILABLE_RESTRICTIONS_CONTENT = [
  {
    value: 'allow',
    label: 'Allow purpose for selected vendors only',
    description: `Only the selected vendors will be allowed to process personal data for the purpose "Create a personalised ads profile (IAB TCF v2)". Vendors that are not
        selected will NOT be allowed to process personal data for that purpose which might impact their behavior and your monetization.`,
  },
  {
    value: 'disallow',
    label: 'Do not allow purpose for selected vendors',
    description: `The selected vendors will NOT be allowed to process personal data for the purpose "Create a personalised ads profile (IAB TCF v2)". This might impact
        their behavior and your monetization. Vendors that are not selected will be allowed to process personal data for the purpose.`,
  },
  {
    value: 'req-consent',
    label: 'Require consent for selected vendors',
    description: `The selected vendors will be allowed to process personal data for the purpose "Create a personalised ads profile (IAB TCF v2)" if they can do it with the
        consent legal basis. Vendors that are not able to do so will NOT be allowed to process personal data for the purpose. This might impact their behavior and
        monetization.`,
  },
  {
    value: 'req-li',
    label: 'Require legitimate interest for selected vendors',
    description: ` The selected vendors will be allowed to process personal data for the purpose "Create a personalised ads profile (IAB TCF v2)" if they can do it with the
        legitimate interest legal basis. Vendors that are not able to do so will NOT be allowed to process personal data for the purpose. This might impact their behavior
        and your monetization.`,
  },
];

const SIMPLIFIED_RESTRICTIONS_CONTENT = [AVAILABLE_RESTRICTIONS_CONTENT[0], AVAILABLE_RESTRICTIONS_CONTENT[1]];

type AvailableRestriction = {
  value: string;
  label: string;
  description: string;
};

const RESTRICTIONS_PER_PURPOSE = {
  device_characteristics: SIMPLIFIED_RESTRICTIONS_CONTENT,
  geolocation_data: SIMPLIFIED_RESTRICTIONS_CONTENT,
  create_ads_profile: SIMPLIFIED_RESTRICTIONS_CONTENT,
  select_personalized_ads: SIMPLIFIED_RESTRICTIONS_CONTENT,
  create_content_profile: SIMPLIFIED_RESTRICTIONS_CONTENT,
  select_personalized_content: SIMPLIFIED_RESTRICTIONS_CONTENT,
  cookies: SIMPLIFIED_RESTRICTIONS_CONTENT,
};

export const getAvailableRestrictions = (purposeId?: string): AvailableRestriction[] => {
  return RESTRICTIONS_PER_PURPOSE[purposeId] || AVAILABLE_RESTRICTIONS_CONTENT;
};
