import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useConsentNotices } from '@hooks';
import { ConsentNotice } from '@types';

const getTargetsList = (notices: ConsentNotice[]): string[] => {
  return notices.map(notice => notice?.config?.targets || []).reduce((targets, noticeTargets) => [...targets, ...noticeTargets], []);
};

/**
 * Fetches the full list of targets of the organization. (It depends on fetching the full list of notices)
 * */
export const useOrganizationTargets = (options?: UseQueryOptions<string[]>) => {
  const queryClient = useQueryClient();
  const targets = queryClient.getQueryData<string[]>(['organization-targets']);
  const { data: notices, isLoading: loadingNotices, isFetched: noticesFetched } = useConsentNotices({ enabled: !targets });

  const fetchOrganizationTargets = async () => {
    if (targets) {
      return Promise.resolve(targets);
    } else {
      return Promise.resolve(getTargetsList(notices.data));
    }
  };

  const result = useQuery<string[], AxiosError>(['organization-targets'], fetchOrganizationTargets, { ...options, enabled: !!targets || noticesFetched });

  return {
    ...result,
    isLoading: result.isLoading || loadingNotices,
  };
};
