import { SDKConfig } from '@didomi/cmp-generator';
import { Vendor } from '@types';
import { isIAB2Vendor } from './checkVendorType';

/**
 * Retrieves all selected vendors based on the provided notice config and vendors list.
 */
export const getAllSelectedVendors = (config: SDKConfig, vendors: Vendor[]) => {
  const customVendorsIds = config?.app?.vendors?.include || [];
  const customVendors = vendors.filter(({ id }) => customVendorsIds.includes(id));
  const isIabAllSelected = config?.app?.vendors?.iab?.enabled && config?.app?.vendors?.iab?.all;

  if (isIabAllSelected) {
    const iabVendors = vendors.filter(isIAB2Vendor);
    return customVendors.concat(iabVendors);
  } else {
    return customVendors;
  }
};
