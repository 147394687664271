import { useEffect, useState } from 'react';
import { SDKConfig, SDKConfigAppIabRestriction } from '@didomi/cmp-generator';
import { NoticePublisherRestrictionExtended } from '@types';
import { enrichPublisherRestrictions, selectIAB2Purposes } from '@utils';
import { usePurposes } from './usePurposes.hook';

export const useRestrictionsState = ({ config }: { config: SDKConfig }) => {
  const [restrictions, setRestrictions] = useState<SDKConfigAppIabRestriction[]>([]);
  const [extendedRestrictions, setExtendedRestrictions] = useState<NoticePublisherRestrictionExtended[]>([]);

  const { data: purposes, isLoading: isPurposesLoading } = usePurposes({ select: selectIAB2Purposes });

  useEffect(() => {
    const restrictions: SDKConfigAppIabRestriction[] = config?.app?.vendors?.iab?.restrictions || [];
    setRestrictions(restrictions);
  }, [config, purposes]);

  useEffect(() => {
    setExtendedRestrictions(enrichPublisherRestrictions(restrictions, purposes));
  }, [restrictions, purposes]);

  const updateRestrictions = (updatedRestrictions: SDKConfigAppIabRestriction[]) => {
    setRestrictions(updatedRestrictions);
  };

  return {
    restrictions,
    extendedRestrictions,
    updateRestrictions,
    isLoading: isPurposesLoading,
  };
};
