import { CategoryLanguageData, PreferenceCategoryGroup } from '@types';

/**
 * Convert a preference category to a list of language data
 * @example
 * const category = { name: { en: 'Name', fr: 'Nom' }, description: { en: 'Description en', fr: 'Description fr' } }
 * purposeCategoryToLanguageDataList(category, false)
 * =>
 * [{ language: 'en', name: 'Name', description: 'Description en' }, { language: 'fr', name: 'Nom', description: 'Description fr' }]
 */
export const purposeCategoryToLanguageDataList = (category: PreferenceCategoryGroup, withButtonsCustomization: boolean): CategoryLanguageData[] => {
  if (!category) return [];

  // Get list of languages that were added to the category
  const languages = Object.keys(category.name);

  return languages.map(language => ({
    language,
    name: category.name[language],
    description: category.description[language],
    ...(withButtonsCustomization ? { agree: category.agree?.[language], disagree: category.disagree?.[language] } : {}),
  }));
};
