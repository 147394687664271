import { AxiosError } from 'axios';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNotice } from '@types';

/**
 * Fetches a particular consent notice
 * */
export const useConsentNotice = (noticeId: string, options?: UseQueryOptions<ConsentNotice>) => {
  const queryClient = useQueryClient();
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchConsentNotice = async (): Promise<ConsentNotice> => {
    const response = await axiosWithInterceptors.get<ConsentNotice>(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/${noticeId}?$with[]=config&$translations=true`, {
      headers: {
        v: '2',
      },
    });

    return response.data;
  };

  return useQuery<ConsentNotice, AxiosError>(['consent-notice', noticeId], fetchConsentNotice, {
    enabled: !!noticeId,
    ...options,
    onSuccess: (fetchedConsentNotice: ConsentNotice) => {
      queryClient.setQueryData(['consent-notice-local', noticeId], fetchedConsentNotice);

      if (options?.onSuccess) {
        options.onSuccess(fetchedConsentNotice);
      }
    },
  });
};
