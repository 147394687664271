import { Platform } from '@didomi/cmp-generator';
import { matchId } from '@didomi/helpers';
import { ConsentNoticeConfigRegulation, RegulationState } from '@types';

type MatchingOptions = {
  platform?: Platform;
};

// Update regulation_configurations to match platform - set disabled for configs that does not match
export const updateRegulationConfigsEnablement = (regulationConfigs: ConsentNoticeConfigRegulation[], regulationEntities: RegulationState[], { platform }: MatchingOptions) => {
  return regulationConfigs.map(config => {
    const regulationMetaInfo = regulationEntities.find(matchId(config.regulation_id));
    const matchPlatform = !platform || regulationMetaInfo.platforms.includes(platform);

    return matchPlatform ? config : { ...config, disabled_at: new Date().toISOString() };
  });
};
