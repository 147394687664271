import React from 'react';

interface RegulationItemProps {
  regulationName: string;
  regulationDescription?: string;
  flag?: string;
}

export const RegulationItem = ({ regulationName, regulationDescription, flag }: RegulationItemProps) => {
  return (
    <div className="bg-neutral-gray-1 rounded-lg w-full flex p-xxs text-primary-blue-6">
      <div className="flex-1 flex flex-col items-start">
        <div className="text-body-small font-semibold">{regulationName}</div>
        <div className="text-tooltip">{regulationDescription}</div>
      </div>
      <div className="self-center">{flag}</div>
    </div>
  );
};
