import React, { useMemo } from 'react';
import { matchId } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { useConsentNoticeStandardTextsByPlatform, useLocalConsentNoticeConfig } from '@hooks';
import { MigrateToIabTcfV2Modal } from '@modals';
import { ConsentNoticeConfig } from '@types';
import { isIabTcfV1Config, matchDefaultRegulationId, produceListOfChanges } from '@utils';

export const TcfMigrationWidget = ({ noticeId, onMigrationSave }: { noticeId: string; onMigrationSave: (config: ConsentNoticeConfig) => void }) => {
  const { organization } = useActiveOrganization();
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { data } = useConsentNoticeStandardTextsByPlatform(localConsentNoticeConfig?.platform);
  const approvedStandardTexts = data?.data || [];

  const isIabTcfV1 = useMemo(() => isIabTcfV1Config(localConsentNoticeConfig), [localConsentNoticeConfig]);

  const getStandardTextChangesForTcfV2 = () => {
    if (localConsentNoticeConfig.text_mode === 'custom' && !organization.allow_tcf_with_custom_text) {
      // get standard text by id or default one if ID is wrong
      const currentOrDefaultStandardText = approvedStandardTexts.find(matchId(localConsentNoticeConfig.text_id)) || approvedStandardTexts.find(text => text.default);

      if (currentOrDefaultStandardText) {
        return [
          { path: 'text_mode', value: 'approved' },
          { path: 'text_id', value: currentOrDefaultStandardText.id },
        ];
      }
    }
  };

  const migrateToIabTcfV2 = () => {
    const defaultGdprIndex = localConsentNoticeConfig?.regulation_configurations?.findIndex(matchDefaultRegulationId('gdpr'));

    const changes: { path: string; value: any }[] = [
      { path: 'config.app.vendors.iab.version', value: 2 },
      { path: `regulation_configurations[${defaultGdprIndex}].config.app.vendors.iab.version`, value: 2 },
      ...(getStandardTextChangesForTcfV2() || []),
    ];

    onMigrationSave(produceListOfChanges(changes, localConsentNoticeConfig));
  };

  return <MigrateToIabTcfV2Modal isOpen={!!localConsentNoticeConfig && isIabTcfV1} onConfirm={migrateToIabTcfV2} />;
};
