import React, { useEffect, useState } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { DidomiModal } from '@didomi/ui-atoms-react';
import { useLocalConsentNoticeConfig, useLocalRegulationConfig } from '@hooks';
import { LoadingModal } from '@modals';
import { PreviewInitialParams, PreviewSize } from '@types';
import { NOTICE_PREVIEW_DEFAULT_PARAMS } from '@utils';
import { ConsentNoticePreview } from '../ConsentNoticePreview/ConsentNoticePreview';

type ConsentNoticePreviewStandaloneProps = {
  id: string;
  noticeId?: string;
  onClose: () => void;
  initialParams?: PreviewInitialParams;
  isOpen: boolean;
};

export const ConsentNoticePreviewStandalone = ({ id, noticeId, initialParams, onClose, isOpen }: ConsentNoticePreviewStandaloneProps) => {
  const { data: noticeConfig, isLoading } = useLocalConsentNoticeConfig(noticeId);
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);

  const [previewSize, setPreviewSize] = useState<PreviewSize>(NOTICE_PREVIEW_DEFAULT_PARAMS.size);
  const [trafficLanguage, setTrafficLanguage] = useState<string>(NOTICE_PREVIEW_DEFAULT_PARAMS.language);
  const [previewRegulation, setPreviewRegulation] = useState<Regulation>();

  useEffect(() => {
    if (!previewRegulation || (activeRegulationIds?.length && !activeRegulationIds?.includes(previewRegulation))) {
      setPreviewRegulation(activeRegulationIds[0]);
    }
  }, [activeRegulationIds, previewRegulation]);

  useEffect(() => {
    if (noticeConfig) {
      noticeConfig?.config?.languages?.default && setTrafficLanguage(noticeConfig.config.languages.default);
    }
  }, [noticeConfig]);

  useEffect(() => {
    if (initialParams?.regulation) setPreviewRegulation(initialParams.regulation);
    if (initialParams?.previewSize) setPreviewSize(initialParams.previewSize);
    if (initialParams?.trafficLanguage) setTrafficLanguage(initialParams.trafficLanguage);
  }, [initialParams]);

  return (
    <>
      {isOpen && (
        <DidomiModal variant="fullscreen" data-testid="notice-preview-standalone-modal" isOpen={true}>
          <ConsentNoticePreview
            id={id}
            className="p-xs bg-squared"
            config={noticeConfig}
            previewSize={previewSize}
            trafficLanguage={trafficLanguage}
            setPreviewSize={setPreviewSize}
            setTrafficLanguage={setTrafficLanguage}
            onClose={onClose}
            activeRegulationIds={activeRegulationIds}
            previewRegulation={previewRegulation}
            setPreviewRegulation={setPreviewRegulation}
          />
        </DidomiModal>
      )}

      <LoadingModal isOpen={isLoading} title="Generating preview..." />
    </>
  );
};
