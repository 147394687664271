import React, { useMemo, useState } from 'react';
import { DidomiCheckbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { TerritoryCard } from '@components';

import { UiMapContinent, UiMapContinentSubdivision, UiMapTerritory } from '@utils';
import { RegulationsMapSubdivision } from './RegulationsMapSubdivision';

type ContinentCardProps = {
  continent: UiMapContinent;
  disabled?: boolean;
  isAppliedToRemaining?: boolean;
  selectedContinents: Record<string, boolean>;
  disabledContinents: Record<string, boolean>;
  selectedSubdivisions: Record<string, boolean>;
  disabledSubdivisions: Record<string, boolean>;
  selectedTerritories: Record<string, boolean>;
  disabledTerritories: Record<string, boolean>;
  onContinentSelection?: (continent: UiMapContinent, isSelected: boolean) => void;
  onSubdivisionSelection?: (subdivision: UiMapContinentSubdivision, isSelected: boolean) => void;
  onTerritorySelection?: (territories: UiMapTerritory[], isSelected: boolean) => void;
};

const ContinentCard = ({
  continent,
  isAppliedToRemaining,
  disabled,
  selectedContinents,
  disabledContinents,
  selectedSubdivisions,
  disabledSubdivisions,
  selectedTerritories,
  disabledTerritories,
  onContinentSelection,
  onSubdivisionSelection,
  onTerritorySelection,
}: ContinentCardProps): JSX.Element => {
  const [areExtraSubdivisionsExpanded, setAreExtraSubdivisionsExpanded] = useState(false);

  const subdivisionsChunk = useMemo(() => {
    return {
      initial: continent?.subdivisions?.filter(subdivision => continent.headerSubdivisionIds.includes(subdivision.id)),
      extra: continent?.subdivisions?.filter(subdivision => !continent.headerSubdivisionIds.includes(subdivision.id)),
    };
  }, [continent]);

  return (
    <TerritoryCard
      key={continent.id}
      continent={continent.id as any}
      className="flex-1 h-full"
      territoryCheckbox={
        <DidomiCheckbox
          data-testid={`${continent.id}-checkbox`}
          checkboxId={`${continent.id}-checkbox`}
          indeterminate={continent.subdivisions.some(s => selectedSubdivisions[s.id])}
          checked={selectedContinents[continent.id] || isAppliedToRemaining}
          disabled={disabled || disabledContinents[continent.id] || isAppliedToRemaining}
          hideErrorMessage
          onValueChange={() => onContinentSelection(continent, !selectedContinents[continent.id])}
        />
      }
    >
      <div className="flex flex-col">
        {/* Territories */}
        {!!continent?.subdivisions?.length && (
          <div className="pt-xxxs ml-[5px]">
            <div className="flex flex-wrap xl:flex-col " data-testid={continent.id + '-initial-list'}>
              {subdivisionsChunk?.initial?.map(subdivision => (
                <RegulationsMapSubdivision
                  key={subdivision.id}
                  subdivision={subdivision}
                  disabled={disabled}
                  isAppliedToRemaining={isAppliedToRemaining}
                  selectedSubdivisions={selectedSubdivisions}
                  disabledSubdivisions={disabledSubdivisions}
                  selectedTerritories={selectedTerritories}
                  disabledTerritories={disabledTerritories}
                  onSubdivisionSelection={onSubdivisionSelection}
                  onTerritorySelection={onTerritorySelection}
                />
              ))}
            </div>

            {!!subdivisionsChunk?.extra?.length && (
              <>
                {/* TODO: Use collapsable to apply an animation when expanding/contracting.
                 It was removed since invisible elements were taking up vertical space. */}
                {areExtraSubdivisionsExpanded && (
                  <div className="flex flex-wrap xl:flex-col" data-testid={continent.id + '-extra-list'}>
                    {subdivisionsChunk?.extra?.map(subdivision => (
                      <RegulationsMapSubdivision
                        key={subdivision.id}
                        subdivision={subdivision}
                        disabled={disabled}
                        isAppliedToRemaining={isAppliedToRemaining}
                        selectedSubdivisions={selectedSubdivisions}
                        disabledSubdivisions={disabledSubdivisions}
                        selectedTerritories={selectedTerritories}
                        disabledTerritories={disabledTerritories}
                        onSubdivisionSelection={onSubdivisionSelection}
                        onTerritorySelection={onTerritorySelection}
                      />
                    ))}
                  </div>
                )}

                <button
                  type="button"
                  className="block text-body-extra-small text-primary-blue-4 underline !outline-none focus-visible:(ring-[3px] !ring-primary-blue-2)"
                  onClick={() => setAreExtraSubdivisionsExpanded(!areExtraSubdivisionsExpanded)}
                >
                  {areExtraSubdivisionsExpanded ? 'See less' : 'See all'}
                </button>
              </>
            )}
          </div>
        )}
      </div>
    </TerritoryCard>
  );
};

type RegulationsMapProps = {
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
  isAppliedToRemaining?: boolean;
  selectedContinents: Record<string, boolean>;
  disabledContinents: Record<string, boolean>;
  selectedSubdivisions: Record<string, boolean>;
  disabledSubdivisions: Record<string, boolean>;
  selectedTerritories: Record<string, boolean>;
  disabledTerritories: Record<string, boolean>;
  onContinentSelection?: (continent: UiMapContinent, isSelected: boolean) => void;
  onSubdivisionSelection?: (subdivision: UiMapContinentSubdivision, isSelected: boolean) => void;
  onTerritorySelection?: (territories: UiMapTerritory[], isSelected: boolean) => void;
  uiContinents?: UiMapContinent[];
};

export const RegulationsMap = ({
  className,
  isLoading,
  disabled,
  isAppliedToRemaining,
  selectedContinents,
  disabledContinents,
  selectedSubdivisions,
  disabledSubdivisions,
  selectedTerritories,
  disabledTerritories,
  onContinentSelection,
  onSubdivisionSelection,
  onTerritorySelection,
  uiContinents,
}: RegulationsMapProps): JSX.Element => {
  return (
    <section className={tx(className, '!grid xl:grid-cols-6 gap-xxs')} data-skeleton={isLoading}>
      {/* Continents */}
      {uiContinents?.map(continent => (
        <ContinentCard
          key={continent.id}
          continent={continent}
          disabled={disabled}
          isAppliedToRemaining={isAppliedToRemaining}
          selectedContinents={selectedContinents}
          disabledContinents={disabledContinents}
          selectedSubdivisions={selectedSubdivisions}
          disabledSubdivisions={disabledSubdivisions}
          selectedTerritories={selectedTerritories}
          disabledTerritories={disabledTerritories}
          onContinentSelection={onContinentSelection}
          onSubdivisionSelection={onSubdivisionSelection}
          onTerritorySelection={onTerritorySelection}
        />
      ))}
    </section>
  );
};
