import React, { useState, lazy, Suspense, useEffect } from 'react';
import { produce } from '@didomi/helpers';
import { DidomiSkeleton, DidomiHintbox, DidomiLargePush, DidomiLoader } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useCustomFeature } from '@didomi/utility-react';
import { sanitizeCSS } from '@didomi/xss/sanitizers';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { PageHeader } from '@components';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig } from '@hooks';
import { EditPageOutletContext } from '@types';
import { ACCESS_POLICIES_CONFIG } from '@utils';

/* istanbul ignore next */
const LazyEditor = lazy(() => import('@components').then(module => ({ default: module.Editor })));

/**
 * Edit custom CSS page
 */
export const EditCustomCSSPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const [CSSError, setCSSError] = useState(null);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { data: localConsentNoticeConfig, isLoading: loadingConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasChanges, saveNoticeChanges, openPreview } = useOutletContext<EditPageOutletContext>();
  const [initialCssValue, setInitialCssValue] = useState('');
  const [useSanitisation] = useCustomFeature('consent-notice-sanitize-xss');

  useEffect(() => {
    if (!initialCssValue && localConsentNoticeConfig) {
      setInitialCssValue(localConsentNoticeConfig.config?.theme?.css);
    }
  }, [localConsentNoticeConfig, initialCssValue]);

  const handleCSSChange = (newCSS: string) => {
    const cssToUse = useSanitisation ? sanitizeCSS(newCSS).result : newCSS;
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'config.theme.css', cssToUse || undefined));
  };

  const handleCSSValidationChange = validationChanges => {
    if (validationChanges?.length) {
      let error = validationChanges.find(e => e.type === 'error');
      if (!error) {
        error = validationChanges[0];
      }
      setCSSError({ message: error?.text, type: error.type });
    } else {
      setCSSError('');
    }
  };

  const handleContinue = async () => {
    const isSaved = await saveNoticeChanges();

    if (isSaved) {
      navigate('/' + noticeId + '/customize');
    }
  };

  return (
    <DidomiSkeleton isLoading={loadingConsentNoticeConfig} variant="layout" className="h-full p-l pb-6 w-full box-border !flex flex-col">
      <PageHeader
        isLoading={loadingConsentNoticeConfig}
        title="Custom CSS"
        description="Further customize the look and feel of your consent notice"
        backText="Back to Customization"
        saveDisabled={loadingConsentNoticeConfig || !hasChanges}
        withSeparator
        saveVisible={isCMPEditor}
        onPreview={openPreview}
        onSave={handleContinue}
        onCancel={() => navigate('/' + noticeId + '/customize')}
      />
      <div className="pb-s">
        <DidomiHintbox className="mb-s" variant="neutral" titleText="Custom CSS">
          Custom CSS can be used to further customize the look and feel of your consent notice. It will be automatically embedded on your website when the consent notice is
          displayed.
          <div slot="info">
            <DidomiLargePush
              className="w-[310px]"
              text="Developer documentation"
              highlightedText="How to customize the theme & UI"
              icon="export"
              href="https://developers.didomi.io/cmp/web-sdk/consent-notice/customize-theme#customize-with-your-own-css"
              target="_blank"
            />
          </div>
        </DidomiHintbox>
        <Suspense
          fallback={
            <div className="h-full w-full flex items-center justify-center">
              <DidomiLoader />
            </div>
          }
        >
          <LazyEditor
            name="css_editor"
            mode="css"
            defaultValue={initialCssValue}
            onChange={handleCSSChange}
            label="Custom CSS"
            error={CSSError?.message}
            errorType={CSSError?.type}
            readOnly={!isCMPEditor || loadingConsentNoticeConfig}
            onValidate={handleCSSValidationChange}
          />
        </Suspense>
      </div>
    </DidomiSkeleton>
  );
};
