import React from 'react';
import { DidomiLoader } from '@didomi/ui-atoms-react';
import { createBrowserRouter, RouteObject, redirect } from 'react-router-dom';
import { ErrorState, WhatArePresets, WhichPlatformIsYourNoticeFor } from '@components';
import { MainLayout, CreateStepLayout, NoticeLayout } from '@layouts';
import {
  ConsentNotices,
  CreateNoticeSelectFrameworks,
  CreateNoticeSelectConsentStrategy,
  CreateNoticeSelectPlatformStep,
  ConsentNoticeRegulation,
  ConsentNoticePublish,
  ConsentNoticeCustomizationPage,
  VendorsAndPurposesPage,
  VendorsListPage,
  VendorsListTemplatePage,
  ConsentNoticeEditPage,
} from '@pages';
import { ERROR_CONFIG } from '@utils';

async function redirectLoader() {
  return redirect('/');
}

export const routes: RouteObject[] = [
  {
    path: '',
    errorElement: <ErrorState {...ERROR_CONFIG} />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <ConsentNotices />,
          },
        ],
      },
      {
        path: 'vendors-list',
        children: [
          {
            path: '',
            element: <VendorsListPage />,
          },
          {
            path: ':templateId',
            element: <VendorsListTemplatePage />,
          },
        ],
      },
      {
        path: '/:noticeId',
        children: [
          {
            element: <ConsentNoticeEditPage />,
            path: '',
            children: [
              {
                element: <NoticeLayout />,
                children: [
                  {
                    path: '',
                    index: true,
                    element: <ConsentNoticeRegulation />,
                  },
                  {
                    path: 'customize',
                    element: <ConsentNoticeCustomizationPage />,
                  },
                  {
                    path: 'publish',
                    element: <ConsentNoticePublish />,
                  },
                ],
              },
              {
                path: 'custom-json',
                async lazy() {
                  let { EditCustomJSONPage } = await import('@pages');
                  return {
                    loader: () => (
                      <div className="h-full w-full flex items-center justify-center">
                        <DidomiLoader />
                      </div>
                    ),
                    Component: EditCustomJSONPage,
                  };
                },
              },
              {
                path: 'custom-css',
                async lazy() {
                  let { EditCustomCSSPage } = await import('@pages');
                  return {
                    loader: () => (
                      <div className="h-full w-full flex items-center justify-center">
                        <DidomiLoader />
                      </div>
                    ),
                    Component: EditCustomCSSPage,
                  };
                },
              },
            ],
          },

          {
            path: ':regulationId/vendors',
            element: <VendorsAndPurposesPage />,
          },
        ],
      },

      {
        path: '/add',
        children: [
          {
            element: (
              <CreateStepLayout
                data-testid="create-platform-step"
                title="Select the platform for your notice"
                backText="Back to Consent notice list"
                backPath="/"
                helperContent={<WhichPlatformIsYourNoticeFor />}
                helperTitle="What are platforms"
                helperSubtitle="Good to know!"
              />
            ),
            children: [
              {
                index: true,
                element: <CreateNoticeSelectPlatformStep />,
              },
            ],
          },
          {
            element: (
              <CreateStepLayout
                title="Select the configuration for your consent strategy"
                backText="Back to Consent notice list"
                helperTitle="What are frameworks?"
                backPath="/"
                helperSubtitle="Good to know!"
                helperContent={<WhatArePresets />}
              />
            ),
            children: [
              {
                path: ':platformType',
                element: <CreateNoticeSelectConsentStrategy />,
              },
            ],
          },

          {
            element: (
              <CreateStepLayout
                title="Select the frameworks for your consent strategy"
                backText="Back to Consent notice list"
                helperTitle="What are frameworks?"
                backPath="/"
                helperSubtitle="Good to know!"
                helperContent={<WhatArePresets />}
              />
            ),
            children: [
              {
                path: ':platformType/frameworks',
                element: <CreateNoticeSelectFrameworks />,
              },
            ],
          },
        ],
      },
    ],
  },
  { path: '*', loader: redirectLoader },
];

export const router = ({ basename } = { basename: undefined }) => createBrowserRouter(routes, { basename });
