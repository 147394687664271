import React from 'react';
import { DidomiButton, DidomiModal, DidomiModalActions, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { CustomVendorsPlainList } from '@components';
import { Vendor } from 'types';

type CustomVendorsWithLiModalProps = {
  isOpen: boolean;
  vendors: Vendor[];
  onClose: () => void;
};

export const CustomVendorsWithLiModal = ({ isOpen, vendors, onClose }: CustomVendorsWithLiModalProps) => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onClose();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} closable={true} onOpenChange={handleOnChange}>
      <div className={tx('text-h3 text-primary-blue-6 font-bold font-serif')}>Custom vendors</div>
      <div className={tx('text-body-small text-primary-blue-6 mb-s')}>Here is the list of vendors who have one or more purpose with a legitimate interest legal basis</div>

      <DidomiModalContent className={tx('w-full')}>
        <CustomVendorsPlainList vendors={vendors} />
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onClose}>
          Close
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
