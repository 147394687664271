import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { Vendor } from '@types';
import { isIAB1Vendor } from '@utils';
import { useFetchAllPaginatedResults } from '../../useFetchAllPaginatedResults.hook';

// Check if vendor belongs to the organization + Global + Not IAB v1
const isVendorAvailable = (currentOrgId: string) => (vendor: Vendor) => {
  return (!vendor.organization_id || vendor.organization_id === currentOrgId) && !isIAB1Vendor(vendor);
};

/**
 * Fetch all vendors
 */
export const useVendors = (regulationId?: string, options?: UseQueryOptions<Vendor[]>) => {
  const { organizationId } = useActiveOrganization();
  const { fetchAllPaginatedResults } = useFetchAllPaginatedResults();

  const fetchVendors = async () => {
    // $regulation_id param allows to fetch vendors with assigned SPI for specific regulation
    const withRegulationId = regulationId ? `&$regulation_id=${regulationId}` : '';

    const allVendors = await fetchAllPaginatedResults<Vendor>(`metadata/partners?$translations=true${withRegulationId}`);
    return allVendors.filter(isVendorAvailable(organizationId));
  };

  return useQuery<Vendor[], AxiosError>(['vendors', { regulationId }], fetchVendors, options);
};
