import React, { memo } from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiChip } from '@didomi/ui-atoms-react';
import { Vendor } from '@types';
import { isATPVendor, isIAB2Vendor } from '@utils';

const VendorIconComponent = ({ vendor }: { vendor: Vendor }) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  const isATP = isATPVendor(vendor);
  const isIAB = isIAB2Vendor(vendor);

  return (
    <div className="flex items-center">
      {isIAB && <img alt="iab logo" src={`${ASSETS_URL}/assets/illustrations/vendors/iab-logo.svg`} />}
      {isATP && <img alt="atp logo" src={`${ASSETS_URL}/assets/illustrations/vendors/atp-logo.svg`} />}
      {vendor.deprecated && <DidomiChip className="opacity-70 ml-xxxs" basicType="info-alternative" variation="basic-outline" label="Deprecated"></DidomiChip>}
    </div>
  );
};

export const VendorIcon = memo(VendorIconComponent);
