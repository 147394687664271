import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmChangeToCustomTextModalProps = {
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmChangeToCustomTextModal = ({ isOpen, onCancel, onConfirm }: ConfirmChangeToCustomTextModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle="Switching to a custom text will disable your IAB TCF integration" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p>You will no longer be IAB TCF compliant if you switch to a custom notice text.</p>
          <p className={tx('font-semibold')}>Are you sure you want to continue?</p>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Yes, continue</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
