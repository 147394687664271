import { useQuery, UseQueryResult, useQueryClient, UseQueryOptions } from 'react-query';
import { ConsentNotice } from '@types';
import { useConsentNotice } from '../consent-notice/useConsentNotice.hook';

/**
 * Retrieves from the react-query cache the "local" version of a notice that's being edited
 * */
export const useLocalConsentNotice = (noticeId: string, options?: UseQueryOptions<ConsentNotice>): UseQueryResult<ConsentNotice> => {
  const queryClient = useQueryClient();

  const fetchLocalConsentNotice = async (): Promise<ConsentNotice> => {
    return Promise.resolve(queryClient.getQueryData(['consent-notice-local', noticeId]));
  };

  const result = useQuery<ConsentNotice>(['consent-notice-local', noticeId], fetchLocalConsentNotice, {
    ...options,
    enabled: !!noticeId && options?.enabled,
  });
  const hasNoResult = !result.data && result.isFetched;

  const realNoticeQuery = useConsentNotice(noticeId, { enabled: hasNoResult });

  return hasNoResult ? realNoticeQuery : result;
};
