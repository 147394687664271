import { getTranslatedValue } from '@didomi/helpers';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, Purpose } from '@types';

const sortPurposesAlphabetically = (p1: Purpose, p2: Purpose) => {
  return getTranslatedValue(p1.description) < getTranslatedValue(p2.description) ? -1 : 1;
};

/**
 * Fetches the full list of purposes(max 5000)
 *
 */
export const usePurposes = (options?: UseQueryOptions<Purpose[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchPurposes = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<Purpose>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes?$translations=true&$limit=5000`);

    return response.data.data.filter(purpose => !purpose.organization_id || purpose.organization_id === organizationId).sort(sortPurposesAlphabetically);
  };

  return useQuery<Purpose[], AxiosError>('purposes', fetchPurposes, options);
};
