import { NoticeConfig } from '@didomi/cmp-generator';

export function isFormatSelectedForPlatform(regulationConfig: NoticeConfig, selectedNoticePosition: string, platform: string) {
  let isFormatSelectedForPlatform = regulationConfig?.config?.notice?.position === selectedNoticePosition;

  if (platform !== 'app') {
    return isFormatSelectedForPlatform;
  }

  const isFullscreen = regulationConfig?.config?.theme?.fullscreen;

  return selectedNoticePosition === 'fullscreen' ? isFullscreen : isFormatSelectedForPlatform && !isFullscreen;
}
