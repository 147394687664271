import React from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiIllustration, DidomiModalHeader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmApplyTemplateModalProps = {
  isOpen: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export const ConfirmApplyTemplateModal = ({ isOpen, onCancel, onConfirm }: ConfirmApplyTemplateModalProps) => {
  return (
    <DidomiModal data-testid="confirm-apply-template-modal" isOpen={isOpen} variant="slim" permanent>
      <DidomiIllustration className={tx('mb-s')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalTitle="Do you want to continue?" modalSubTitle="You’re about to apply your template to selected regulations" />

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton iconRight="save" variant="main" onClick={onConfirm}>
          Apply
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
