import React from 'react';
import { produce } from '@didomi/helpers';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiLargePush } from '@didomi/ui-atoms-react';
import { SelectionCardCheckbox } from '@components';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig } from '@hooks';

export const CookiesSecure = ({ noticeId, isLoading }: { noticeId: string; isLoading: boolean }) => {
  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  const handleSecureCookiesChange = (isSelected: boolean) => {
    updateLocalConfig(produce(localConsentNoticeConfig, 'secure_cookies', isSelected));
  };

  return (
    <SelectionCardCheckbox
      title="Secure cookies"
      data-testid="cookies-secure"
      disabled={isLoading}
      checked={localConsentNoticeConfig?.secure_cookies}
      onValueChange={handleSecureCookiesChange}
      description={
        <div className="text-primary-blue-6">
          If enabled, Didomi will{' '}
          <a href="https://developer.mozilla.org/en-US/docs/Web/HTTP/Cookies#restrict_access_to_cookies" target="_blank" rel="noreferrer">
            create cookies marked as Secure
          </a>{' '}
          that are only available to HTTPS traffic.
          <br />
          We recommend enabling secure cookies if your website is only accessible via HTTPS.
        </div>
      }
      illustrationContent={<img src={`${ASSETS_URL}/assets/illustrations/cookies/secure.svg`} width={90} alt="" />}
    >
      <DidomiLargePush
        highlighted-text="Cookies and storage"
        text="Documentation"
        icon="export"
        href="https://developers.didomi.io/cmp/web-sdk/consent-notice/cookies"
        target="_blank"
      ></DidomiLargePush>
    </SelectionCardCheckbox>
  );
};
