import { QueryClient } from 'react-query';
import { PaginatedResponse, ConsentNoticeConfig, ConsentNoticeWithTemplateMetaData, ConsentNoticeTemplate, ConsentNoticeDeployment } from '@types';
import { getNoticeTemplateMetadataFromConfig, getTemplateWithUpdatedNoticesIdField } from './templateCacheHelpers';

// Update deployed_at field in notices list cache on the template page(`consent-notices-with-template-metadata`)
export const updateNoticeWithTemplatesMetadataDeploymentCache = (queryClient: QueryClient, deployment: ConsentNoticeDeployment) => {
  queryClient.setQueryData<ConsentNoticeWithTemplateMetaData[]>(['consent-notices-with-template-metadata', deployment.organization_id], (prevNotices = []) => {
    return prevNotices.map(notice => (notice.id === deployment.notice_id ? { ...notice, deployed_at: deployment.updated_at } : notice));
  });
};

/**
 * Update templates cache based on the changes to a NoticeConfig:
 * - templates list cache (Template[])
 * - template entity cache (Template)
 * - notices with template metadata (ConsentNoticeWithTemplateMetaData[])
 * The template entity has a list of notice_ids. When a template is assigned or unassigned to a NoticeConfig regulation,
 * the related cached Template entity must have the list of notices_ids updated.
 */
export function updateTemplatesCache(queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) {
  updateNoticeWithTemplatesMetadataCache(queryClient, patchedNoticeConfig);
  updateTemplateEntityCache(queryClient, patchedNoticeConfig);
  updateTemplateListCache(queryClient, patchedNoticeConfig);
}

// Update template_metadata cache of the notices list on template page(`consent-notices-with-template-metadata`)
export const updateNoticeWithTemplatesMetadataCache = (queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) => {
  const orgId = patchedNoticeConfig.organization_id;

  // If the cache is not present, no need to update it
  if (!queryClient.getQueryData(['consent-notices-with-template-metadata', orgId])) {
    return;
  }

  queryClient.setQueryData<ConsentNoticeWithTemplateMetaData[]>(['consent-notices-with-template-metadata', orgId], prevNotices => {
    const updatedMetadata = getNoticeTemplateMetadataFromConfig(patchedNoticeConfig);
    return prevNotices.map(notice => (notice.id === patchedNoticeConfig.notice_id ? { ...notice, template_metadata: updatedMetadata } : notice));
  });
};

// Update cache of the individual template(`consent-notice-template`)
export const updateTemplateEntityCache = (queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) => {
  queryClient.setQueriesData<ConsentNoticeTemplate>({ queryKey: ['consent-notice-template'] }, template => {
    if (template) {
      return getTemplateWithUpdatedNoticesIdField(template, patchedNoticeConfig);
    }
  });
};

// Update cache of the templates list(`consent-notice-templates`)
export const updateTemplateListCache = (queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) => {
  // If the cache is not present, no need to update it
  if (!queryClient.getQueryData(['consent-notice-templates', patchedNoticeConfig.organization_id])) {
    return;
  }

  queryClient.setQueryData<PaginatedResponse<ConsentNoticeTemplate>>(['consent-notice-templates', patchedNoticeConfig.organization_id], prevTemplates => {
    return {
      ...prevTemplates,
      data: prevTemplates.data.map(template => getTemplateWithUpdatedNoticesIdField(template, patchedNoticeConfig)),
    };
  });
};
