import React, { useEffect, useMemo, useState } from 'react';
import { produce } from '@didomi/helpers';
import { DidomiHintbox, DidomiButton, DidomiCopyText, DidomiLargePush, DidomiSkeleton, DidomiBottomBar, DidomiSwitch } from '@didomi/ui-atoms-react';
import { useSnackbar, useCustomFeature, useActiveOrganization, useHasAccessPolicies } from '@didomi/utility-react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { PublicationCard, ConsentNoticeTargetsInput, EmbeddedSdkCode } from '@components';
import {
  useLocalConsentNoticeConfig,
  useDeployConsentNotice,
  useEditConsentNotice,
  useEditConsentNoticeConfig,
  useLocalConsentNotice,
  useEditLocalConsentNoticeConfig,
  useOrganizationTargets,
  useHasNoticeChanged,
  useConsentNoticesPlatformDefault,
  useVendors,
} from '@hooks';
import { LoadingModal, AddCommentOnConsentPublishModal, CustomVendorsWithLiModal } from '@modals';
import { ACCESS_POLICIES_CONFIG, getDefaultGdprConfigIab, getIabTcfVersion, getVendorsWithInvalidLiPurposesForTcf2p2, isRegulationEnabled } from '@utils';

const TAG_MANGER_LINK = {
  web: 'https://developers.didomi.io/cmp/web-sdk/third-parties',
  amp: 'https://developers.didomi.io/cmp/amp#block-non-iab-vendors',
  app: 'https://developers.didomi.io/cmp/mobile-sdk/third-party-sdks',
  ctv: 'https://developers.didomi.io/cmp/mobile-sdk/third-party-sdks',
  undefined: 'https://developers.didomi.io/cmp/web-sdk/third-parties',
};

const STEP_1_TITLE = {
  web: 'Add target domains and click on Publish',
  amp: 'Add target domains and click on Publish',
  app: 'Add Mobile App ID and click on Publish',
  ctv: 'Add ctv App ID and click on Publish',
  undefined: 'Add target domains and click on Publish',
};

const STEP_1_DESC = {
  web: 'Make a list of domains where the notice will be embedded.',
  amp: 'Make a list of domains where the notice will be embedded.',
  app: 'Make a list of mobile app ids where the notice will be embedded.',
  ctv: 'Make a list of ctv app ids where the notice will be embedded.',
  undefined: 'Make a list of domains where the notice will be embedded.',
};

const STEP_2_TITLE = {
  web: 'Configure the tags on your websites',
  amp: 'Configure the tags on your website',
  app: 'Configure the tags on your mobile app',
  ctv: 'Configure the tags on your television app',
  undefined: 'Configure the tags on your website',
};

const STEP_2_DESC = {
  web: 'Configure the tags on your website in a way that the notice can control them according to the user’s choices.',
  amp: 'Configure the tags on your website in a way that the notice can control them according to the user’s choices.',
  app: 'Read our documentation to set up our mobile SDKs for Android and iOS. They will automatically pull their configuration from the notice that you just configured.',
  ctv: 'Read our documentation to set up our TV SDKs for Android TV, Amazon Fire TV and tvOS. They will automatically pull their configuration from the notice that you just configured.',
  undefined: 'Configure the tags on your website in a way that the notice can control them according to the user’s choices.',
};
/**
 * Consent Notice publish page
 */
export const ConsentNoticePublish = (): JSX.Element => {
  const navigate = useNavigate();
  const { noticeId } = useParams();
  const { displaySnackbar } = useSnackbar();
  const { organization } = useActiveOrganization();
  const [isAddCommentOnConsentPublishModalOpen, setAddCommentOnConsentPublishModalOpen] = useState<boolean>(false);
  const [isViewCustomVendorsModalOpen, setIsViewCustomVendorsModalOpen] = useState(false);
  const [canAddTargetDomain] = useCustomFeature('new-cmp-target-domain');
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { isLoading: isVendorsLoading, data: vendors = [] } = useVendors();

  const { data: localConsentNotice, isLoading: loadingLocalConsentNotice } = useLocalConsentNotice(noticeId);
  const { data: localConsentNoticeConfig, isLoading: loadingLocalConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { data: organizationTargets, isLoading: loadingTargets } = useOrganizationTargets();
  const { data: defaultNoticeConfig, isLoading: loadingDefaultNotice } = useConsentNoticesPlatformDefault(localConsentNoticeConfig?.platform);

  const { mutateAsync: updateNotice, isLoading: savingNotice } = useEditConsentNotice(noticeId);
  const { mutateAsync: updateNoticeConfig, isLoading: savingNoticeConfig } = useEditConsentNoticeConfig();
  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);

  const { hasNoticeChanged, hasNoticeConfigChanged } = useHasNoticeChanged(noticeId);

  const { mutateAsync: deployNotice, isLoading: deployingNotice } = useDeployConsentNotice();

  const vendorsWithInvalidLiPurposesForTcf2p2 = useMemo(() => {
    if (vendors && localConsentNoticeConfig) {
      return getVendorsWithInvalidLiPurposesForTcf2p2(localConsentNoticeConfig, vendors);
    } else {
      return [];
    }
  }, [vendors, localConsentNoticeConfig]);

  const isIabEnabled = useMemo(() => getDefaultGdprConfigIab(localConsentNoticeConfig)?.enabled, [localConsentNoticeConfig]);
  const iabTcfVersion = useMemo(() => getIabTcfVersion(localConsentNoticeConfig), [localConsentNoticeConfig]);

  const isInvalidLiPurposesForTcf2p2WarningVisible = isIabEnabled && iabTcfVersion === '2.2' && vendorsWithInvalidLiPurposesForTcf2p2.length > 0;

  // Block page if no regulation has been selected
  useEffect(() => {
    /* istanbul ignore next */
    if (!navigate) return;

    if (noticeId && !!localConsentNoticeConfig && !localConsentNoticeConfig.regulation_configurations?.some(isRegulationEnabled)) {
      navigate('/' + noticeId);
    }
  }, [noticeId, localConsentNoticeConfig, navigate]);

  const publishNoticeChanges = async (message: string) => {
    try {
      if (hasNoticeConfigChanged) {
        await updateNoticeConfig(localConsentNoticeConfig);
      }
      if (hasNoticeChanged) {
        await updateNotice(localConsentNotice);
      }
      await deployNotice({
        noticeConfig: localConsentNoticeConfig,
        message,
      });

      displaySnackbar(`${localConsentNotice?.name} has been deployed`, { icon: 'success-small' });
    } catch (error) {
      displaySnackbar(error?.response?.data?.message || 'There was an error deploying the notice', { variant: 'error' });
    }
  };

  const handleDefaultUpdate = (defaultChange: CustomEvent<'selected' | 'not-selected'>) => {
    const newValue = defaultChange?.detail === 'selected';
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'default', newValue));
  };

  const loading = loadingLocalConsentNotice || loadingLocalConsentNoticeConfig || isVendorsLoading;
  const savingAndDeploying = savingNotice || savingNoticeConfig || deployingNotice;

  return (
    <>
      <DidomiSkeleton data-testid="page-skeleton" variant="layout" className="flex-1 flex flex-col gap-xs w-full mb-s" isLoading={loading}>
        <DidomiHintbox titleText="It’s almost done!" headerIconName="compliance">
          Follow the four steps below to finalize your consent notice setup flow.
        </DidomiHintbox>

        <div>
          {isInvalidLiPurposesForTcf2p2WarningVisible && (
            <DidomiHintbox data-testid="invalid-li-purposes-warning" variant="warning" iconName="warning">
              Per IAB TCF v2.2 guidelines, Purposes 1,3,4,5 and 6 can only be based on <b>consent</b>. Your notice currently includes{' '}
              <button className="font-bold underline !outline-none rounded focus:(ring-[3px] !ring-primary-blue-2)" onClick={() => setIsViewCustomVendorsModalOpen(true)}>
                custom vendors
              </button>{' '}
              (not part of the IAB TCF) with purposes whose legal basis is legitimate interest. By clicking on <b>&quot;Publish&quot;</b> you authorise Didomi to remove legitimate
              interest of these purposes. <b>Changes cannot be reversed.</b>
            </DidomiHintbox>
          )}
        </div>

        {canAddTargetDomain && isCMPEditor ? (
          <PublicationCard
            data-testid="publication-card-step-1"
            step={1}
            title={STEP_1_TITLE[localConsentNoticeConfig?.platform]}
            subTitle={STEP_1_DESC[localConsentNoticeConfig?.platform]}
          >
            <div>
              <div className="w-full flex flex-col lg:!flex-row gap-xxs lg:!gap-xs items-start">
                <ConsentNoticeTargetsInput
                  className="w-full"
                  consentNoticeConfig={localConsentNoticeConfig}
                  organizationTargets={organizationTargets}
                  disabled={localConsentNoticeConfig?.default}
                  isLoadingTargets={loadingTargets || loadingLocalConsentNoticeConfig}
                  onUpdateTargets={t => updateLocalNoticeConfig({ ...localConsentNoticeConfig, targets: t })}
                />
                <DidomiButton
                  className="shrink-0"
                  data-skeleton
                  disabled={loading || !isCMPEditor}
                  variant="top"
                  iconRight="save-publish"
                  onClick={() => setAddCommentOnConsentPublishModalOpen(true)}
                >
                  Publish
                </DidomiButton>
              </div>

              {defaultNoticeConfig && defaultNoticeConfig?.id !== localConsentNoticeConfig?.id ? (
                <DidomiHintbox iconName="warning-blue" className="mt-s">
                  You already have a default notice set for this organization, If you want to define the current notice as default, you have to switch off your previous notice
                  default setting for:{' '}
                  <Link
                    className="font-semibold underline !outline-none inline-block rounded-sm focus-visible:(ring-[3px] ring-primary-blue-2)"
                    to={`/${defaultNoticeConfig?.notice_id}`}
                  >
                    {defaultNoticeConfig?.notice?.name}
                  </Link>
                </DidomiHintbox>
              ) : (
                <DidomiSwitch
                  className="mt-s text-body-small"
                  data-testid="default-switch"
                  value={localConsentNoticeConfig?.default ? 'selected' : 'not-selected'}
                  data-skeleton={loading || loadingDefaultNotice}
                  onValueChange={handleDefaultUpdate}
                >
                  <div className="font-bold">Declare the notice as the default notice.</div>
                  <div>Enable this option to load this notice configuration when no other configuration is specifically targeting a website or app.</div>
                </DidomiSwitch>
              )}
            </div>
          </PublicationCard>
        ) : (
          <PublicationCard inline step={1} title="Apply your configuration" subTitle="Set your Consent Notice live by clicking the “Publish” button.">
            <div className="w-full flex flex-col lg:!flex-row gap-xxs lg:!gap-xs items-start">
              <DidomiButton
                data-skeleton
                className="mt-xxxs"
                disabled={loading || !isCMPEditor}
                variant="top"
                iconRight="save-publish"
                onClick={() => setAddCommentOnConsentPublishModalOpen(true)}
              >
                Publish
              </DidomiButton>
            </div>
          </PublicationCard>
        )}

        <PublicationCard step={2} title={STEP_2_TITLE[localConsentNoticeConfig?.platform]} subTitle={STEP_2_DESC[localConsentNoticeConfig?.platform]}>
          <div className="w-full flex gap-xs">
            <DidomiLargePush
              highlighted-text="How to configure my tags?"
              text="Help center"
              icon="export"
              data-skeleton
              href={TAG_MANGER_LINK[localConsentNoticeConfig?.platform]}
              target="_blank"
            ></DidomiLargePush>
            {localConsentNoticeConfig?.platform === 'amp' && (
              <DidomiLargePush
                highlighted-text="Amp documentation"
                data-skeleton
                text="Help center"
                icon="export"
                href="https://amp.dev/documentation/components/amp-consent/#blocking-behaviors"
                target="_blank"
              ></DidomiLargePush>
            )}
          </div>
        </PublicationCard>

        {['ctv', 'app'].includes(localConsentNoticeConfig?.platform) ? (
          <PublicationCard step={3} title="Your API Key" subTitle="Use the following API Key in you app in order to load the notice’s configuration.">
            <DidomiCopyText
              truncate
              isIconPersistent
              variation="basic-outline"
              text={organization?.public_api_key}
              className="w-full max-w-[500px]"
              is-icon-persistent="true"
              data-testid="public-api-key"
              onTextCopied={() => displaySnackbar('Key copied', { icon: 'success-small' })}
            >
              <div>{organization?.public_api_key}</div>
            </DidomiCopyText>
          </PublicationCard>
        ) : (
          <PublicationCard
            step={3}
            title="Paste the Didomi SDK code"
            subTitle="Add the following code in the header of your website, it’s needed in order to load the notice’s configuration."
          >
            <EmbeddedSdkCode noticeId={noticeId} />
          </PublicationCard>
        )}

        {['ctv', 'app'].includes(localConsentNoticeConfig?.platform) ? (
          <PublicationCard
            step={4}
            title="Add a link to open the consent preferences"
            subTitle="To ensure that your visitors can change their consent preferences at any point in time, add a link to re-open the Didomi consent preferences pop-in in your mobile app. The link can be in a menu, in the footer of your app or in your privacy policy. Read our documentation to learn more about this."
          >
            <DidomiLargePush
              highlighted-text="Read our documentation"
              text="Help center"
              icon="export"
              data-skeleton
              href="https://developers.didomi.io/cmp/mobile-sdk/consent-notice/getting-started#add-a-link-for-the-user-to-manage-their-preferences"
              target="_blank"
            ></DidomiLargePush>
          </PublicationCard>
        ) : (
          <PublicationCard
            step={4}
            title="Paste the code for the opening link"
            subTitle="Paste this code on your website where you want to visualise the link to re-open the notice."
          >
            <DidomiCopyText
              className="w-full max-w-[500px]"
              truncate
              data-skeleton={loading}
              isIconPersistent
              data-testid="link-code-text"
              variation="basic-outline"
              text={`<a href="javascript:Didomi.preferences.show()">Consent choices</a>`}
              is-icon-persistent="true"
              onTextCopied={() => displaySnackbar('Code copied', { icon: 'success-small' })}
            >
              <div>{`<a href="javascript:Didomi.preferences.show()">Consent choices</a>`}</div>
            </DidomiCopyText>
          </PublicationCard>
        )}
      </DidomiSkeleton>

      {isCMPEditor && (
        <DidomiBottomBar data-testid="bottom-bar" className="-mx-l -mb-m flex-shrink-0" isOpen={true} variant="light" closable={false}>
          <div slot="actions" className="flex items-center gap-4">
            <DidomiButton iconLeft="left-on" variant="secondary" disabled={loading} onClick={() => navigate('/' + noticeId + '/customize')}>
              Previous
            </DidomiButton>
            <DidomiButton
              iconRight="save-publish"
              variant="top"
              disabled={loading || !isCMPEditor}
              data-testid="bottom-bar-publish"
              onClick={() => setAddCommentOnConsentPublishModalOpen(true)}
            >
              Publish
            </DidomiButton>
          </div>
        </DidomiBottomBar>
      )}

      <LoadingModal isOpen={savingAndDeploying} title="Deploying the notice..." />
      <CustomVendorsWithLiModal isOpen={isViewCustomVendorsModalOpen} vendors={vendorsWithInvalidLiPurposesForTcf2p2} onClose={() => setIsViewCustomVendorsModalOpen(false)} />
      {isAddCommentOnConsentPublishModalOpen && (
        <AddCommentOnConsentPublishModal
          isOpen={true}
          onCancel={() => {
            setAddCommentOnConsentPublishModalOpen(false);
          }}
          onSave={message => {
            setAddCommentOnConsentPublishModalOpen(false);
            publishNoticeChanges(message);
          }}
        />
      )}
    </>
  );
};
