import { useActiveOrganization, useCustomFeature } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNoticeTemplate } from '@types';
import { sanitizeObject } from '@utils';

/**
 * Merges the updated template with the cached template.
 *
 * Applies the ID of notices from the cached template into the current template.
 *
 * The response to PATCH /template does not return "notices_id", so this function helps to keep the old value.
 * It's important to note "notices_id" is only a getter, so it will remain unchanged.
 * To actually change the linked Notices, their Notice-Configs must be linked to the Template individually.
 *
 * @param {ConsentNoticeTemplate} template - The current Consent Notice Template being worked on.
 * @param {ConsentNoticeTemplate} cachedTemplate - The cached Consent Notice Template from which we extract the notices_id.
 *
 * @returns {ConsentNoticeTemplate} - The updated Consent Notice Template with the applied notices_id from the cached template.
 */

function mergeWithCachedTemplate(template: ConsentNoticeTemplate, cachedTemplate: ConsentNoticeTemplate): ConsentNoticeTemplate {
  return {
    ...cachedTemplate,
    ...template,
    notices_id: cachedTemplate.notices_id,
  };
}

/**
 * Edits a template, makes sure to update the list of templates with the edited template value
 * */
export const useEditConsentNoticeTemplate = (
  templateId: string,
  options?: UseMutationOptions<AxiosResponse<ConsentNoticeTemplate>, AxiosError, Partial<ConsentNoticeTemplate>>,
) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const [useSanitisation] = useCustomFeature('consent-notice-sanitize-xss');
  const queryClient = useQueryClient();

  return useMutation(
    async (consentNoticeTemplate: Partial<ConsentNoticeTemplate>) => {
      const noticeConfigBody = useSanitisation ? sanitizeObject(consentNoticeTemplate) : consentNoticeTemplate;
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/${templateId}?organization_id=${organizationId}`, noticeConfigBody);
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<ConsentNoticeTemplate>, variables, context) => {
        const template: ConsentNoticeTemplate = response.data;

        const cachedTemplateList: PaginatedResponse<ConsentNoticeTemplate> = queryClient.getQueryData(['consent-notice-templates', organizationId]);
        /* istanbul ignore if */
        if (cachedTemplateList) {
          const cachedTemplateFromList: ConsentNoticeTemplate | undefined = cachedTemplateList.data.find(cachedTemplate => cachedTemplate.id === templateId);

          if (cachedTemplateFromList) {
            queryClient.setQueryData(['consent-notice-templates', organizationId], {
              ...cachedTemplateList,
              data: cachedTemplateList.data.map(cachedTemplate => (cachedTemplate.id === templateId ? mergeWithCachedTemplate(template, cachedTemplateFromList) : cachedTemplate)),
            });
          }
        }

        const cachedTemplate: ConsentNoticeTemplate = queryClient.getQueryData(['consent-notice-template', template.id]);
        if (cachedTemplate) {
          queryClient.setQueryData(['consent-notice-template', template.id], mergeWithCachedTemplate(template, cachedTemplate));
        }

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    },
  );
};
