import React from 'react';
import { DidomiRightPanelTab } from '@didomi/ui-atoms-react';
import { useParams } from 'react-router-dom';
import { CustomizationGlobalOptions, CustomizationSpecificOptions, ConsentNoticePreview } from '@components';
import { useLocalConsentNoticeConfig, useCustomizationStepOptions, useLocalRegulationConfig, CustomizationStep } from '@hooks';

const LOOK_AND_FEEL_OPTIONS = [
  { name: 'Global settings', 'data-testid': 'global-options-header', slotName: 'global-options' },
  { name: 'Specific settings', 'data-testid': 'specific-options-header', slotName: 'specific-options' },
];

/**
 * Look and feel tab
 */
export const LookAndFeel = (): JSX.Element => {
  const { noticeId } = useParams();

  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);

  const {
    customizationStep,
    lookAndFeelStep,
    contentEditorLayer,
    previewSize,
    trafficLanguage,
    setPreviewSize,
    setTrafficLanguage,
    setLookAndFeelStep,
    previewRegulation,
    setPreviewRegulation,
  } = useCustomizationStepOptions({
    defaultPreviewSize: localConsentNoticeConfig?.platform === 'amp' || localConsentNoticeConfig?.platform === 'app' ? 'small' : 'large',
    defaultTraffic: localConsentNoticeConfig?.config?.languages?.default,
    activeRegulationIds,
  });

  return (
    <div className="flex gap-l min-h-[720px]">
      <div className="w-full" data-skeleton>
        {/* Load preview iframe only if component is visible */}
        {customizationStep === CustomizationStep['look-and-feel'] && (
          <ConsentNoticePreview
            id="look-and-feel"
            config={localConsentNoticeConfig}
            layer={contentEditorLayer}
            previewSize={previewSize}
            trafficLanguage={trafficLanguage}
            setPreviewSize={setPreviewSize}
            setTrafficLanguage={setTrafficLanguage}
            activeRegulationIds={activeRegulationIds}
            previewRegulation={previewRegulation}
            setPreviewRegulation={setPreviewRegulation}
          />
        )}
      </div>

      <DidomiRightPanelTab
        headers={LOOK_AND_FEEL_OPTIONS}
        activeTab={lookAndFeelStep}
        onActiveTabChange={e => {
          e.stopImmediatePropagation();
          setLookAndFeelStep(e.detail?.active);
        }}
        className="slidein min-w-[432px]"
      >
        <div slot="global-options" className="p-s flex flex-col gap-xs">
          <CustomizationGlobalOptions noticeId={noticeId} />
        </div>
        <div slot="specific-options" className="p-s flex flex-col gap-xs">
          <CustomizationSpecificOptions noticeId={noticeId} />
        </div>
      </DidomiRightPanelTab>
    </div>
  );
};
