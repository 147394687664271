import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, VendorStorage } from '@types';

/**
 * Fetches the full list of vendors storages (max 5000)
 *
 */
export const useVendorsStorages = (options?: UseQueryOptions<VendorStorage[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchVendorsStorages = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<VendorStorage>>(
      `${CONFIG.environmentConfig.apiBaseUrl}metadata/partners-storages?$translations=true&$limit=5000&organization_id=${organizationId}`,
    );

    return response.data.data;
  };

  return useQuery<VendorStorage[], AxiosError>('vendors-storages', fetchVendorsStorages, options);
};
