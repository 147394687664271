import React, { useMemo, useState } from 'react';
import { reduceToRecord } from '@didomi/helpers';
import { DidomiFiltersBarCustomEvent } from '@didomi/ui-atoms';
import { DidomiEmptyState, DidomiFiltersBar, DidomiHintbox, DidomiLargePush, DidomiLoader } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useParams, useSearchParams } from 'react-router-dom';
import { RegulationSelectionCard } from '@components';
import { useRegulations, useLocalConsentNoticeConfig, useEditLocalConsentNoticeConfig, useConsentNoticeConfig, useRegulationSelectionMetaInfo } from '@hooks';
import { ConfirmOverwriteRegulationModal } from '@modals';
import { RegulationFilterStatus, RegulationState } from '@types';
import {
  ACCESS_POLICIES_CONFIG,
  geoLocationTransform,
  getRegulationConfigsAfterRegulationSelection,
  isRegulationEnabled,
  isRegulationUsedOverlappingATerritory,
  getCounterText,
} from '@utils';

/**
 * Consent Notice regulation tab
 */
export const Regulations = (): JSX.Element => {
  const { noticeId } = useParams();

  let [searchParams, setSearchParams] = useSearchParams();
  const { isLoading: loadingNoticeConfig } = useConsentNoticeConfig(noticeId);
  const { data: localConsentNoticeConfig, isLoading: loadingLocalConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { data: regulations, isLoading: loadingRegulationList } = useRegulations(localConsentNoticeConfig?.platform, null, {
    filterById: searchParams.get('regulationId') || '',
  });

  const [statusFilter, setStatusFilter] = useState<RegulationFilterStatus>(RegulationFilterStatus.ALL);

  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { countsPerRegulation } = useRegulationSelectionMetaInfo(noticeId);
  const [regulationToOverwrite, setRegulationToOverwrite] = useState<RegulationState | undefined>(undefined);

  const visibleRegulations = useMemo(() => regulations?.data?.filter(reg => reg.ui_status !== 'hidden'), [regulations]);

  // Keep hash of selected regulations in order to simplify selection checking
  const selectedRegulationsHash = useMemo(() => {
    const activeRegulations = (localConsentNoticeConfig?.regulation_configurations || []).filter(isRegulationEnabled);
    return reduceToRecord('regulation_id', 'regulation_id', activeRegulations);
  }, [localConsentNoticeConfig]);

  const hasSelectedRegulations = useMemo(() => !!Object.keys(selectedRegulationsHash).length, [selectedRegulationsHash]);

  const enabledRegulations = useMemo(() => {
    return hasSelectedRegulations ? visibleRegulations?.filter(reg => !!selectedRegulationsHash[reg.id]) : [];
  }, [visibleRegulations, selectedRegulationsHash, hasSelectedRegulations]);

  const disabledRegulations = useMemo(() => {
    return hasSelectedRegulations ? visibleRegulations?.filter(reg => !selectedRegulationsHash[reg.id]) : [];
  }, [visibleRegulations, selectedRegulationsHash, hasSelectedRegulations]);

  const filteredRegulations = useMemo(() => {
    if (enabledRegulations && disabledRegulations) {
      switch (statusFilter) {
        case RegulationFilterStatus.ENABLED:
          return enabledRegulations;
        case RegulationFilterStatus.DISABLED:
          return disabledRegulations;
        case RegulationFilterStatus.ALL:
        default:
          return [...enabledRegulations, ...disabledRegulations];
      }
    }
  }, [statusFilter, enabledRegulations, disabledRegulations]);

  const handleSearchValueChange = (e: CustomEvent<string>) => {
    if (e.detail) {
      searchParams.set('regulationId', e.detail);
    } else {
      searchParams.delete('regulationId');
    }
    setSearchParams(searchParams);
  };

  const statusFilters = useMemo(
    () => ({
      filterByStatus: {
        defaultValue: RegulationFilterStatus.ALL,
        value: statusFilter,
        options: [
          { value: RegulationFilterStatus.ALL, label: 'All Status' },
          { value: RegulationFilterStatus.ENABLED, label: 'Enabled' },
          { value: RegulationFilterStatus.DISABLED, label: 'Disabled' },
        ],
      },
    }),
    [statusFilter],
  );

  const handleClearFilters = () => {
    searchParams.delete('regulationId');
    setSearchParams(searchParams);
    setStatusFilter(RegulationFilterStatus.ALL);
  };

  const handleFilterChange = (e: DidomiFiltersBarCustomEvent<{ key: string; newValue: any }>) => {
    const filterValue = e.detail.newValue;
    setStatusFilter(filterValue);
  };

  const handleSelectionChange = (regulation: RegulationState, selectedValue: boolean) => {
    const { id: regulationId, geos } = regulation;

    const geosToAdd = geos.map(geoLocationTransform);
    const updatedRegulations = getRegulationConfigsAfterRegulationSelection(localConsentNoticeConfig.regulation_configurations, regulationId, selectedValue, geosToAdd);
    const updatedConfig = { ...localConsentNoticeConfig, regulation_configurations: updatedRegulations };

    updateLocalNoticeConfig(updatedConfig);
  };

  const loading = loadingNoticeConfig || loadingLocalConsentNoticeConfig || loadingRegulationList;

  return (
    <>
      <div className="flex justify-between mb-s">
        <div>
          <h2 className="h2 text-secondary-cobalt-blue-4 mb-xxxs">Select the regulation you want to cover</h2>
          <div className="text-body-small text-primary-blue-6">Click on Edit Vendors & Purposes button to manage the content per regulation.</div>
        </div>
        <div className="flex gap-xs">
          <DidomiLargePush text="Developers documentation" highlightedText="Regulations" href="https://developers.didomi.io/api/data-manager/regulations" target="_blank" />
        </div>
      </div>
      <div className="h-full w-full">
        <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-xs">
          <DidomiFiltersBar
            data-testid="regulations-filter-bar"
            className="flex-1"
            leftText={loading ? '' : `${getCounterText(filteredRegulations?.length, 'regulation')}`}
            filters={statusFilters}
            onClearAllFilters={handleClearFilters}
            onFilterValueChange={handleFilterChange}
            showSearch
            searchValue={searchParams.get('regulationId') || ''}
            collapsibleFilters={false}
            onSearchTextChange={handleSearchValueChange}
          />
        </div>
        {localConsentNoticeConfig && !hasSelectedRegulations && (
          <DidomiHintbox className="mb-xs" titleText="At least one regulation should be selected" variant="failure" icon-name="failure" />
        )}

        {!loading && !!regulations?.data?.length && (
          <div className="w-full grid gap-s mb-s 2xl:grid-cols-2 2xl:grid-rows-[auto]">
            {filteredRegulations?.map(reg => (
              <RegulationSelectionCard
                key={reg.id}
                checked={!!selectedRegulationsHash[reg.id]}
                description={reg.name}
                addPurposePath={`/${noticeId}/${reg.id}/vendors`}
                regulationId={reg.id}
                vendorsCount={countsPerRegulation[reg.id]?.vendorsCount || 0}
                purposesCount={countsPerRegulation[reg.id]?.purposesCount || 0}
                spiCount={countsPerRegulation[reg.id]?.spiCount}
                disabled={!isCMPEditor || reg.ui_status === 'coming-soon'}
                comingSoon={reg.ui_status === 'coming-soon'}
                onValueChange={checked => {
                  if (checked && isRegulationUsedOverlappingATerritory(localConsentNoticeConfig.regulation_configurations, reg)) {
                    setRegulationToOverwrite(reg);
                    return false;
                  }

                  handleSelectionChange(reg, checked);
                  return true;
                }}
              />
            ))}
          </div>
        )}

        {!loading && !regulations?.data?.length && (
          <DidomiEmptyState className="h-full" illustrationName="traces-no-match-found">
            <div slot="title">Sorry,</div>
            No regulation found
          </DidomiEmptyState>
        )}

        {loading && (
          <div className="w-full flex flex-col items-center justify-center pt-xl">
            <DidomiLoader />
            <div>Loading regulations</div>
          </div>
        )}
      </div>

      <ConfirmOverwriteRegulationModal
        regulationId={regulationToOverwrite?.id}
        isOpen={regulationToOverwrite !== undefined}
        onCancel={() => setRegulationToOverwrite(undefined)}
        onConfirm={() => {
          handleSelectionChange(regulationToOverwrite, true);
          setRegulationToOverwrite(undefined);
        }}
      />
    </>
  );
};
