import React, { useRef, useState, useEffect, memo } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { useSPAAssetsUrl, useDebounce, useEventListener } from '@didomi/helpers-react';
import { DidomiSkeleton, DidomiHintbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import useResizeObserver from 'use-resize-observer';
import { useSDKConfig } from '@hooks';
import { ConsentNoticeConfig, NoticeLayer, PreviewSize } from '@types';

enum SCREEN_WIDTH {
  large = 1200,
  medium = 900,
  small = 520,
  amp = 520,
}

type ConsentNoticePreviewIframeProps = {
  size?: PreviewSize;
  language?: string;
  layer?: NoticeLayer;
  className?: string;
  didomiConfig: Partial<ConsentNoticeConfig>;
  regulation: Regulation;
  id: string;
};

const NOTICE_SCREENS = ['notice', 'purposes', 'spi', 'vendors'];

/**
 * Displays the notice preview by using an iframe that renders the preview inside
 *
 * We send messages to the iframe to update the config.
 * Each iframe has to have an id, so that we can target it when sending the message
 *
 * @component
 * @example
 * return (
 *   <NoticePreviewIframe didomiConfig={config} />
 * )
 */
export const ConsentNoticePreviewIframe = memo(
  ({ className, size = 'large', layer = 'notice', language = 'en', didomiConfig, regulation, id }: ConsentNoticePreviewIframeProps): JSX.Element => {
    const IFrameRef = useRef(null);
    const [isConfiguring, setIsConfiguring] = useState(null);
    const [isIframeReady, setIsFrameReady] = useState(false);
    const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
    const debouncedConfig = useDebounce(didomiConfig, 300);
    const previewHtml = `${ASSETS_URL}/assets/notice-preview/${CONFIG.production ? 'preview.html' : 'preview-staging.html'}?id=${id}`;

    const { generateSDKConfig, isReady: isGenerationReady } = useSDKConfig();

    const { ref: IFrameWrapperRef, width: wrapperWidth, height: wrapperHeight } = useResizeObserver();

    /*
     * Listens for changes in the configuration and update the iframe
     * We make sure the iframe is ready (has loaded and set the listeners) before sending the message
     * We also react to a change in the language, layer, size and regulation, so that we can refresh the preview
     */
    useEffect(() => {
      (async () => {
        if (!isIframeReady || !isGenerationReady || !IFrameRef.current || !debouncedConfig) return;

        setIsConfiguring(true);
        const sdkConfig = await generateSDKConfig(debouncedConfig, regulation);
        IFrameRef.current.contentWindow.postMessage({ action: 'update-config', payload: { config: sdkConfig, language, startLayer: layer }, iframeId: IFrameRef.current.id }, '*');
      })();
    }, [layer, debouncedConfig, language, regulation, generateSDKConfig, isGenerationReady, isIframeReady]);

    /*
     * Listens for changes in the layer to update the view of the SDK
     * We make sure the iframe has fully being configured before sending the message
     */
    useEffect(() => {
      if (IFrameRef.current && isIframeReady && isConfiguring === false) {
        const screen = NOTICE_SCREENS.includes(layer) ? layer : NOTICE_SCREENS[0];
        IFrameRef.current.contentWindow?.postMessage({ action: 'update-section', payload: screen, iframeId: IFrameRef.current.id }, '*');
      }
    }, [layer, isIframeReady, isConfiguring]);

    /*
     * Listens for messages coming from the iframe
     * We discard any message that is not coming from the iframe we are rendering in this component (We use the iframe id to check that)
     * We want to listen for the loading-finished event that gets triggered once the SDK has fully finished rendering
     * If we have run the configure flow we expect the iframe to return a hasConfigChanges flag, otherwise it means the iframe is loading for the first time
     * and will trigger a reload with the new config changes after, we need to wait for the message coming after the reload.
     */
    useEventListener('message', e => {
      try {
        // @ts-ignore
        const data = JSON.parse(e.data || '{}');

        // Skip messages from other iframes
        if (data?.iframeId !== id) {
          return;
        }

        if (data?.action === 'loading-finished') {
          if (isConfiguring && data?.hasConfigChanges) {
            setIsConfiguring(false);
          }
        }
      } catch (e) {
        // We don't really care about the error, there might be messages coming from Chrome extensions for example
        // If the message is not parsable we just ignore it
      }
    });

    const containerWidth = size === 'small' ? 300 : wrapperWidth;
    const containerHeight = size === 'small' ? Math.min(wrapperHeight - 120, 620) : wrapperHeight;

    const scale = containerWidth / SCREEN_WIDTH[size];
    const sizeGrow = 1 / scale;
    const width = containerWidth * sizeGrow;
    const height = containerHeight * sizeGrow;

    const isPreviewLoading = isConfiguring !== false || !isGenerationReady;

    return (
      <div className={tx(className, 'h-full flex justify-center items-center bg-squared')} ref={IFrameWrapperRef}>
        {didomiConfig?.platform === 'app' && (
          <div className={tx('h-full w-full flex flex-col p-xxs')}>
            <DidomiHintbox class={tx('mb-xxl')} variant="warning" titleText="Disclaimer" icon-name="warning" />

            <div className={tx('h-full w-full flex flex-col items-center text-center p-m relative')}>
              <img src={`${ASSETS_URL}/assets/illustrations/mobile-preview-not-available.png`} alt="Preview not available" width={70} height={86} />
              <h3 className={tx('h3 mt-s mb-xxxs')}>The overview of the mobile APP cannot be displayed because the Didomi console does not use the mobile SDK.</h3>
              <p className={tx('text-body-small')}>
                Customization is still possible, manage all your options, publish your consent notice and check it on a mobile device to see it live.
              </p>
            </div>
          </div>
        )}
        {didomiConfig?.platform !== 'app' && (
          <DidomiSkeleton
            data-testid="preview-loading"
            isLoading={isPreviewLoading}
            className={tx(`w-full max-w-[${containerWidth + 48}px] h-full max-h-[${containerHeight + 72}px] relative block overflow-hidden m-0`, {
              'shadow-app-iframe rounded-[60px]': size === 'small',
            })}
          >
            <div className={tx('h-full w-full absolute inset-0', { 'bg-app-iframe p-s pb-l': size === 'small' })}>
              <iframe
                ref={IFrameRef}
                id={id}
                data-testid="iframe-preview"
                className={tx(`bg-transparent w-[${width}px] h-[${height}px] scale-[${scale}] origin-top-left shadow-report-score rounded-2xl`, {
                  '!bg-squared': size !== 'small',
                })}
                onLoad={() => setIsFrameReady(true)}
                title="Notice preview"
                src={previewHtml}
                frameBorder="0"
                height="448px" // Default for the skeleton, will be updated when the iframe is loaded
                width="80%" // Default for the skeleton, will be updated when the iframe is loaded
              />
            </div>
          </DidomiSkeleton>
        )}
      </div>
    );
  },
);

ConsentNoticePreviewIframe.displayName = 'ConsentNoticePreviewIframe';
