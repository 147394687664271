import React, { useState } from 'react';
import { DidomiCheckbox, DidomiLargePush, DidomiTextInput, DidomiTooltip } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { AddGCMWarningModal } from '@modals';
import { ACCESS_POLICIES_CONFIG } from '@utils';

const PLATFORMS_WITH_ADDITIONAL_STEPS = ['app', 'ctv'];
const DOCS_LINK_PER_PLATFORM = {
  web: 'https://support.didomi.io/enable-google-consent-mode-with-didomi-new-flow#gcm_didomi_console',
  app: 'https://developers.didomi.io/cmp/mobile-sdk/google-consent-mode',
  ctv: 'https://developers.didomi.io/cmp/mobile-sdk/google-consent-mode',
};

export const IntegrationGoogleConsentMode = () => {
  const {
    isLoading,
    gcm,
    isIabAll,
    isIabEnabled,
    platform,
    changeGcmSetDefaultStatus,
    changeGcmAdsDefaultStatus,
    changeGcmEnablement,
    changeGcmTCFEnablement,
    changeGcmAdsUserDataDefaultStatus,
    changeGcmPersonalizationDefaultStatus,
    changeGcmAdsPersonalizationDefaultStatus,
    changeGcmAnalyticsDefaultStatus,
    changeGcmFunctionalityDefaultStatus,
    changeGcmDataLayerName,
    changeGcmAdsEnablement,
    changeGcmAnalyticsEnablement,
  } = useLocalConsentNoticeConfigFacade();
  const [displayAddGoogleVendorModal, setDisplayAddGoogleVendorModal] = useState<'advertising' | 'analytics' | 'all'>(null);
  const [dataLayerNameError, setDataLayerNameError] = useState(false);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const handleGCMIntegrationChange = (isSelected: boolean) => {
    if (isSelected && !gcm.isAdsEnabled && !gcm.isAnalyticsEnabled) {
      setDisplayAddGoogleVendorModal('all');
    } else {
      changeGcmEnablement(isSelected);
    }
  };

  const handleGcmAnalyticsIntegrationChange = (selectionEvent: CustomEvent<boolean>) => {
    const isSelected = selectionEvent.detail;
    if (isSelected) {
      setDisplayAddGoogleVendorModal('analytics');
    } else {
      changeGcmAnalyticsEnablement(false);
    }
  };

  const handleGcmAdsEnabledIntegrationChange = (selectionEvent: CustomEvent<boolean>) => {
    const isSelected = selectionEvent.detail;
    if (isSelected) {
      setDisplayAddGoogleVendorModal('advertising');
    } else {
      changeGcmAdsEnablement(false);
    }
  };

  const handleAddVendorConfirmation = () => {
    if (displayAddGoogleVendorModal === 'analytics') {
      changeGcmAnalyticsEnablement(true);
    } else if (displayAddGoogleVendorModal === 'advertising') {
      changeGcmAdsEnablement(true);
    } else if (displayAddGoogleVendorModal === 'all') {
      // Enable GCM with all required vendors
      changeGcmEnablement(true);
    }
    setDisplayAddGoogleVendorModal(null);
  };

  const handleGCMDataLayerNameIntegrationChange = (dataLayerChange: CustomEvent<string>) => {
    const dataLayerName = dataLayerChange.detail;
    const dataLayerValidator = new RegExp(/^[A-Za-z0-9_]+$/);

    if (dataLayerValidator.test(dataLayerName) || dataLayerName === '') {
      changeGcmDataLayerName(dataLayerName);
      setDataLayerNameError(false);
    } else {
      setDataLayerNameError(true);
    }
  };

  const canSetDefaultStatus = Boolean(gcm.isSetDefaultStatus && isCMPEditor);
  const canSetDefaultStatusForAds = Boolean(canSetDefaultStatus && gcm.isAdsEnabled);
  const canSetDefaultStatusForAnalytics = Boolean(canSetDefaultStatus && gcm.isAnalyticsEnabled);
  const isWebPlatform = platform === 'web';

  return (
    <section data-testid="integrations-google-wrapper" data-loading={isLoading}>
      <SelectionCardCheckbox
        title="Google Consent Mode V2"
        data-testid="google-consent-mode"
        checked={gcm.isEnabled}
        onValueChange={handleGCMIntegrationChange}
        description={
          <>
            <div>
              Enable Google Consent Mode to adjust how your Google tags (Analytics, Ads, Floodlight) use cookies and behave based on the user consent choices. Google’s tags will
              dynamically adapt to only use measurement tools for the consented purposes when consent has been given by the user.
            </div>
            <div>
              Enabling &quot;Google Advertising Products&quot; or &quot;Google Analytics Products&quot; in this GCM integration will automatically add the vendor in the
              &quot;Vendors & Purposes&quot; section.
            </div>
            {PLATFORMS_WITH_ADDITIONAL_STEPS.includes(platform) && (
              <div>To complete your Google Consent Mode setup, please follow the additional steps detailed in our technical documentation.</div>
            )}
          </>
        }
        illustration="integrations-google-consent-mode"
        disabled={!isCMPEditor}
      >
        <div className="flex flex-col gap-xxs">
          {/* Some fields are only available for the web platform */}
          {isWebPlatform && (
            <>
              <DidomiCheckbox
                data-testid="google-consent-mode-default-status"
                disabled={!gcm.isEnabled || !isCMPEditor}
                checked={gcm.isSetDefaultStatus}
                onValueChange={e => changeGcmSetDefaultStatus(e.detail)}
                hideErrorMessage
                label="Set the default status of Google Consent Mode when the page loads"
              />
              <DidomiCheckbox
                data-testid="google-consent-mode-advertising"
                disabled={!gcm.isEnabled || isIabAll || !isCMPEditor}
                checked={gcm.isAdsEnabled}
                onValueChange={handleGcmAdsEnabledIntegrationChange}
                hideErrorMessage
                label="Enable Google Advertising Products"
              />
              <DidomiCheckbox
                data-testid="google-consent-mode-advertising-default-status"
                disabled={!canSetDefaultStatusForAds || !gcm.isEnabled}
                checked={canSetDefaultStatusForAds && gcm.isAdsDefaultStatus}
                onValueChange={e => changeGcmAdsDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable ad_storage before user gives consent"
              />
              <DidomiCheckbox
                data-testid="google-consent-mode-ad-user-data"
                disabled={!canSetDefaultStatusForAds || !gcm.isEnabled}
                checked={canSetDefaultStatusForAds && gcm.isAdsUserDataDefaultStatus}
                onValueChange={e => changeGcmAdsUserDataDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable ad_user_data before user gives consent"
              />

              <DidomiCheckbox
                data-testid="google-consent-mode-ad-personalization"
                disabled={!canSetDefaultStatusForAds || !gcm.isEnabled}
                checked={canSetDefaultStatusForAds && gcm.isAdsPersonalizationDefaultStatus}
                onValueChange={e => changeGcmAdsPersonalizationDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable ad_personalization before user gives consent"
              />

              <DidomiCheckbox
                data-testid="google-consent-mode-functionality"
                disabled={!canSetDefaultStatusForAds || !gcm.isEnabled}
                checked={canSetDefaultStatusForAds && gcm.isFunctionalityDefaultStatus}
                onValueChange={e => changeGcmFunctionalityDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable functionality_storage before user gives consent"
              />

              <DidomiCheckbox
                data-testid="google-consent-mode-personalization"
                disabled={!canSetDefaultStatusForAds || !gcm.isEnabled}
                checked={canSetDefaultStatusForAds && gcm.isPersonalizationDefaultStatus}
                onValueChange={e => changeGcmPersonalizationDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable personalization_storage before user gives consent"
              />
              <DidomiCheckbox
                data-testid="google-consent-mode-analytics"
                disabled={!gcm.isEnabled || !isCMPEditor}
                checked={gcm.isAnalyticsEnabled}
                onValueChange={handleGcmAnalyticsIntegrationChange}
                hideErrorMessage
                label="Enable Google Analytics Products"
              />
              <DidomiCheckbox
                data-testid="google-consent-mode-analytics-default-status"
                disabled={!canSetDefaultStatusForAnalytics || !gcm.isEnabled}
                checked={canSetDefaultStatusForAnalytics && gcm.isAnalyticsDefaultStatus}
                onValueChange={e => changeGcmAnalyticsDefaultStatus(e.detail)}
                className="ml-s"
                hideErrorMessage
                label="Enable analytics_storage before user gives consent"
              />
            </>
          )}
          {/* If GCM is enabled but no IAB - disable checkbox and show helper tooltip */}
          <DidomiTooltip variant="helper" content={gcm.isEnabled && !isIabEnabled ? 'TCF IAB Framework is required to enabled integration with Google Consent Mode V2' : ''}>
            <div className="inline-block">
              <DidomiCheckbox
                data-testid="google-consent-mode-tcf"
                disabled={!gcm.isEnabled || !isCMPEditor || !isIabEnabled}
                checked={gcm.isTCFEnabled}
                onValueChange={e => changeGcmTCFEnablement(e.detail)}
                hideErrorMessage
                label="Enable IAB TCF integration with Google Consent Mode"
              />
            </div>
          </DidomiTooltip>

          {isWebPlatform && (
            <DidomiTextInput
              disabled={!gcm.isEnabled || !isCMPEditor}
              value={gcm.dataLayerName || ''}
              onValueChange={handleGCMDataLayerNameIntegrationChange}
              error={dataLayerNameError ? 'Invalid format. Please use only alphanumerics and underscore character.' : null}
              label="Data layer name"
            />
          )}

          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="Google Consent Mode"
            text="Documentation"
            icon="export"
            href={DOCS_LINK_PER_PLATFORM[platform]}
            target="_blank"
          ></DidomiLargePush>
        </div>
      </SelectionCardCheckbox>
      <AddGCMWarningModal
        isOpen={!!displayAddGoogleVendorModal}
        type={displayAddGoogleVendorModal}
        onCancel={() => setDisplayAddGoogleVendorModal(null)}
        onProceed={handleAddVendorConfirmation}
      />
    </section>
  );
};
