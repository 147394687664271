import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { REGULATION_DICTIONARY } from '@utils';

type ConfirmOverwriteRegulationModalProps = {
  isOpen: boolean;
  regulationId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
};

/**
 * Confirmation modal displayed when the user enables a regulation which has as default geos at least one geo used by another regulation.
 * Which is going to be overwritten by the new selected regulation
 */
export const ConfirmOverwriteRegulationModal = ({ isOpen, regulationId, onCancel, onConfirm }: ConfirmOverwriteRegulationModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle="Pay attention" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          Another regulation is already being used in the selected territories. Do you want to apply {REGULATION_DICTIONARY[regulationId]?.titleText} to these territories instead?
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Yes, apply it</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
