import { ConsentNoticeWithTemplateMetaData, NoticeWithTemplatePublicationStatus } from '@types';

// Check whether notice matches selected filter: Published | Applied
export const matchNoticeWithTemplatePublicationStatus = (statusFilter: NoticeWithTemplatePublicationStatus, templateId: string) => (notice: ConsentNoticeWithTemplateMetaData) => {
  if (!statusFilter) {
    return true;
  }

  // Check if template is assigned to any regulation inside the notice
  const isUsingCurrentTemplate = !!Object.values(notice.template_metadata).find(({ template_id }) => template_id === templateId);

  if (statusFilter === 'published') {
    return !!notice.deployed_at && isUsingCurrentTemplate;
  }

  if (statusFilter === 'applied') {
    return isUsingCurrentTemplate;
  }
};
