/**
 * This is a custom implementation of the domain validation
 * We had to create this implementations based on client's existing domain values
 * We just simply want to restrict any *.* subdomain values, anything else is valid
 *
 * @param domain {string} - The domain value to be validated
 * @returns {boolean} - Returns true if the domain is valid, false otherwise
 */
export const isValidDomain = (domain: string): boolean => {
  if (domain === '*.*' || domain === '*') return false;
  const pattern = /^([A-Za-z0-9-_.*]+)$/;
  return pattern.test(domain);
};
