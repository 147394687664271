import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNoticeStandardTextContent, PaginatedResponse } from '@types';

/**
 * Fetches all the available standard text contents
 * */
export const useConsentNoticeApprovedStandardTextContents = (options?: UseQueryOptions<ConsentNoticeStandardTextContent[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchConsentNoticeApprovedStandardTextContents = async (): Promise<ConsentNoticeStandardTextContent[]> => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeStandardTextContent>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/texts-contents?$translations=true&status=approved&$limit=5000`,
    );

    return response.data.data;
  };

  return useQuery<ConsentNoticeStandardTextContent[], AxiosError>(['consent-notice-approved-standard-text-contents'], fetchConsentNoticeApprovedStandardTextContents, options);
};
