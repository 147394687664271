import React from 'react';
import { generateUniqueId } from '@didomi/helpers';
import { DidomiCheckbox } from '@didomi/ui-atoms-react';
import { SelectionCard, SelectionCardProps } from '@components';

interface SelectionCardCheckboxProps extends SelectionCardProps {
  name?: string;
  onValueChange?: (checked: boolean) => void;
}

export const SelectionCardCheckbox = ({
  id = generateUniqueId('selection-card-checkbox'),
  title,
  name,
  description,
  checked,
  disabled,
  className,
  isLoading,
  'data-testid': dataTestId,
  illustration,
  illustrationContent,
  children,
  onValueChange,
}: SelectionCardCheckboxProps) => {
  const inputId = id + '-checkbox';

  const handleValueChange = () => {
    onValueChange(!checked);
  };

  return (
    <SelectionCard
      id={id}
      title={title}
      description={description}
      checked={checked}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      data-testid={dataTestId}
      illustration={illustration}
      illustrationContent={illustrationContent}
      input={
        <DidomiCheckbox
          data-testid={dataTestId ? dataTestId + '-checkbox' : null}
          tabIndex={-1}
          checkboxId={inputId}
          className="min-w-[24px]"
          title={title}
          disabled={isLoading || disabled}
          name={name}
          checked={checked}
          hideErrorMessage
          onValueChange={handleValueChange}
        />
      }
      inputId={inputId}
    >
      {children}
    </SelectionCard>
  );
};
