import { QueryClient } from 'react-query';
import { PaginatedResponse, ConsentNotice, ConsentNoticeConfig } from '@types';
import { replaceByIdOrInsert, mergeOrSet, removeByIdIfExists, pickNoticeConfigForNotice } from '@utils';

/**
 * Updates the QueryClient's cache for default notice configurations based on whether the patched notice configuration is set to default.
 *
 * @param {QueryClient} queryClient - An instance of the QueryClient.
 * @param {ConsentNoticeConfig} patchedNoticeConfig - The updated ConsentNoticeConfig object.
 *
 * @returns {void}
 */
export function updateDefaultNoticeConfigsCache(queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) {
  if (patchedNoticeConfig.default) {
    replaceByIdOrInsert(queryClient, 'consent-notices-defaults', patchedNoticeConfig);
  } else {
    removeByIdIfExists(queryClient, 'consent-notices-defaults', patchedNoticeConfig);
  }
}

/**
 * Updates the Notices cache, to merge the Notice-Config on the Notice.config association.
 * Picks only the fields that the Notice API exposes.
 *
 * @function updateNoticesCache
 * @param {QueryClient} queryClient - Client to manage queries for fetching and caching data.
 * @param {ConsentNoticeConfig} patchedNoticeConfig - The updated consent notice configuration.
 */
export function updateNoticesCache(queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) {
  const cachedNotices: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notices', patchedNoticeConfig.organization_id]);
  if (cachedNotices) {
    queryClient.setQueryData(['consent-notices', patchedNoticeConfig.organization_id], {
      ...cachedNotices,
      data: cachedNotices.data.map(notice =>
        notice.id === patchedNoticeConfig.notice_id
          ? {
              ...notice,
              config: pickNoticeConfigForNotice(patchedNoticeConfig),
            }
          : notice,
      ),
    });
  }
}

/**
 * Updates the cached Notice at `consent-notice` and `consent-notice-local` to merge
 * the values of the updated NoticeConfig under Notice.config
 *
 * @function updateNoticeCache
 * @param {QueryClient} queryClient - The QueryClient instance which performs caching operations.
 * @param {ConsentNoticeConfig} patchedNoticeConfig - Configuration details for a consent notice that needs to be updated.
 *
 * @returns {void}
 */
export function updateNoticeCache(queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) {
  const cachedNotice: ConsentNotice = queryClient.getQueryData(['consent-notice', patchedNoticeConfig.notice_id]);
  if (cachedNotice) {
    const updatedNotice = {
      ...cachedNotice,
      config: pickNoticeConfigForNotice(patchedNoticeConfig),
    };

    queryClient.setQueryData(['consent-notice', patchedNoticeConfig.notice_id], updatedNotice);
    queryClient.setQueryData(['consent-notice-local', patchedNoticeConfig.notice_id], updatedNotice);
  }
}

/**
 * This function updates the consent notice configuration in the cache.
 * It replaces both 'consent-notice-config' and 'consent-notice-config-local' with a patched version of the config.
 *
 * @param {QueryClient} queryClient - A QueryClient object used to manage cache.
 * @param {ConsentNoticeConfig} patchedNoticeConfig - The updated Consent Notice Configuration that will replace the existing config in cache.
 */
export function updateNoticeConfigCache(queryClient: QueryClient, patchedNoticeConfig: ConsentNoticeConfig) {
  mergeOrSet(queryClient, 'consent-notice-config', patchedNoticeConfig);
  mergeOrSet(queryClient, 'consent-notice-config-local', patchedNoticeConfig);
}
