import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmEnablingIabTcfModalProps = {
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

export const ConfirmEnablingIabTcfModal = ({ isOpen, onCancel, onConfirm }: ConfirmEnablingIabTcfModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle="A standard text is required to enable the IAB TCF" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>
          <p>
            Didomi provides IAB TCF standard wording that can be included at the end of your notice.&nbsp;
            <strong>You can select different texts in the &quot;Specific settings&quot; section of the Content Editor.</strong>
          </p>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Enable the IAB TCF</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
