import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiIcon, DidomiButton } from '@didomi/ui-atoms-react';

export const WhichPlatformIsYourNoticeFor = () => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <>
      <p className="mb-s h3 text-primary-blue-6">
        Platforms are the <span className="text-primary-blue-4 font-semibold">different environments/mediums used by your users</span> on which you can deploy a consent banner.
      </p>
      <p className="mb-xs text-body-normal text-primary-blue-6">
        Didomi supports multiple platforms so that you can collect user consent across all your online and offline touch points.
      </p>
      <img width={440} height={300} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-platforms-hero.png`} alt="" />

      <p className="my-s h3 text-primary-blue-6 font-semibold">The environments supported are:</p>
      <ul className="text-body-normal text-primary-blue-6">
        <li className="flex items-center gap-xxs mb-xxxs">
          <DidomiIcon name="consent-type-website" />
          <span className="font-medium">Website</span> (default)
        </li>
        <li className="flex items-center gap-xxs mb-xxxs">
          <DidomiIcon name="consent-type-amp" />
          <span className="font-medium">AMP Website</span> (related to Google)
        </li>
        <li className="flex items-center gap-xxs mb-xxxs">
          <DidomiIcon name="consent-type-mobile" />
          <span className="font-medium">Mobile for Android, iOS, Flutter, Unity, and React Native</span>
        </li>
        <li className="flex items-center gap-xxs">
          <DidomiIcon name="consent-type-ctv" />
          <span className="font-medium">Connected TV APP and Web base</span> (soon)
        </li>
      </ul>
      <p className="mt-m mb-s h3 text-primary-blue-6">
        The set-up of the consent banner and its operations{' '}
        <span className="text-primary-blue-4 font-semibold">work the same for all platforms and provide superior performance.</span>
      </p>
      <p className="mb-s text-body-normal text-primary-blue-6">
        If you have several touch-points and want to synchronize consent collection from one environment to the other, check out the Cross-Device functionality to enable sharing
        consent across various devices and prevent consent fatigue among your users.
      </p>
      <a target="_blank" rel="noopener noreferrer" tabIndex={-1} href="https://support.didomi.io/how-to-enable-cross-device-from-didomi-console">
        <DidomiButton type="button" size="small" iconRight="export">
          Learn more about “cross device”
        </DidomiButton>
      </a>
    </>
  );
};
