import { PurposeGroup } from '@didomi/cmp-generator';
import { getTranslatedValue, matchId } from '@didomi/helpers';
import { IabStackExtended, Purpose } from '@types';

export const enrichIabStacks = (stacks: PurposeGroup[], purposes: Purpose[]): IabStackExtended[] => {
  const getDescriptions = (purposesIds: string[]) => {
    return purposesIds.reduce(
      (acc, purposeId) => {
        const purpose = purposes.find(matchId(purposeId));

        // Guard against missing purposes
        if (!purpose) return acc;

        const description = getTranslatedValue(purpose.description);
        const isSpecialFeature = purpose.namespaces.iab2_special_feature > 0;

        isSpecialFeature ? acc.specialFeaturesDescriptions.push(description) : acc.purposesDescriptions.push(description);

        return acc;
      },
      { purposesDescriptions: [], specialFeaturesDescriptions: [] },
    );
  };

  return stacks.map(stack => {
    const { purposesDescriptions, specialFeaturesDescriptions } = getDescriptions(stack.purposes_id);

    return {
      ...stack,
      purposesDescriptions,
      specialFeaturesDescriptions,
    };
  });
};
