import React, { useMemo } from 'react';
import { DidomiModal, DidomiModalContent } from '@didomi/ui-atoms-react';
import { useUserProfile } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { InlineWidget } from 'react-calendly';

type CrossDeviceBookingModalProps = {
  isOpen: boolean;
  onCancel: () => void;
};

export const CrossDeviceBookingModal = ({ isOpen, onCancel }: CrossDeviceBookingModalProps) => {
  const [userProfile] = useUserProfile();

  const url = useMemo(() => {
    const baseUrl = new URL('https://calendly.com/didomi-customers/didomi-pc-introduction-en');
    baseUrl.searchParams.set('name', userProfile?.name || '');
    baseUrl.searchParams.set('email', userProfile?.email || '');
    return baseUrl.toString();
  }, [userProfile]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <DidomiModalContent className={tx('w-full')}>
        <InlineWidget
          iframeTitle="Didomi Calendly Widget"
          pageSettings={{
            backgroundColor: 'ffffff',
            hideEventTypeDetails: true,
            hideLandingPageDetails: true,
            primaryColor: '265973',
            textColor: '4d5055',
            hideGdprBanner: true,
          }}
          url={url}
        />
      </DidomiModalContent>
    </DidomiModal>
  );
};
