import equal from 'fast-deep-equal/es6/react';
import { useConsentNotice, useLocalConsentNotice, useConsentNoticeConfig, useLocalConsentNoticeConfig } from '@hooks';

/**
 * Validates if the notice has changed in regard to the local changes
 * */
export const useHasNoticeChanged = (noticeId: string) => {
  const { data: notice, isLoading: loadingNotice } = useConsentNotice(noticeId);
  const { data: localNotice, isLoading: loadingLocalNotice } = useLocalConsentNotice(noticeId);
  const { data: noticeConfig, isLoading: loadingNoticeConfig } = useConsentNoticeConfig(noticeId);
  const { data: localNoticeConfig, isLoading: loadingLocalNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const hasNoticeChanged = !loadingNotice && !loadingLocalNotice && !equal(localNotice, notice);
  const hasNoticeConfigChanged = !loadingNoticeConfig && !loadingLocalNoticeConfig && !equal(noticeConfig, localNoticeConfig);

  return {
    hasChanged: hasNoticeChanged || hasNoticeConfigChanged,
    hasNoticeChanged,
    hasNoticeConfigChanged,
    isLoading: loadingNotice || loadingLocalNotice || loadingNoticeConfig || loadingLocalNoticeConfig,
  };
};
