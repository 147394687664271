import React from 'react';
import { IntegrationIAB, IntegrationGoogleAdManagerACM, IntegrationGoogleConsentMode } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { useCustomizationRegulationConfig } from '@hooks';

const GCM_SUPPORTED_PLATFORMS = ['web', 'app', 'ctv'];

export const IntegrationSelectionSection = () => {
  const { isStandardAtpListSelected, platform, isIabEnabled, isLoading, regulationId } = useLocalConsentNoticeConfigFacade();
  const { hasIabIntegration } = useCustomizationRegulationConfig(regulationId);

  if (isLoading)
    return (
      <div className="flex flex-col gap-xs">
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
        <div className="w-full h-[320px]" data-skeleton />
      </div>
    );

  return (
    <div className="flex flex-col gap-xs pb-s">
      {hasIabIntegration && <IntegrationIAB />}
      {/* Google Ad Manager is hidden for all new notices (full_apt is true), and shown for old notices with full_atp set to false */}
      {isIabEnabled && isStandardAtpListSelected && platform === 'web' && <IntegrationGoogleAdManagerACM />}
      {GCM_SUPPORTED_PLATFORMS.includes(platform) && <IntegrationGoogleConsentMode />}
    </div>
  );
};
