import { Geo } from '@types';

/**
 * Generates an string format from a geo location object
 *
 * Example:
 *
 * const geoValue = geoLocationTransform({ country: {id: "US", name: "United States"}, region: {id: "CA", name: "California"} }});
 * console.log(geoValue) // US_CA
 *
 * @param {Geo} geoLocation The geo location object
 * @returns {string} the geo location string
 */
export const geoLocationTransform = (geoLocation: Geo): string => {
  if (!geoLocation?.region?.id || geoLocation?.region?.id === '*') {
    return geoLocation.country.id;
  }
  return geoLocation.country.id + '_' + geoLocation?.region.id;
};
