import { useCallback, useEffect, useMemo, useState } from 'react';
import { LOCAL_EXCEPTIONS, NoticeConfigV2 } from '@didomi/cmp-generator';
import { matchId } from '@didomi/helpers';
import { useEditLocalConsentNoticeConfig, useLocalConsentNoticeConfig, useRegulations, useRegulationsCountries } from '@hooks';
import { ConsentNoticeConfig, ConsentNoticeConfigRegulation } from '@types';
import {
  geoLocationTransform,
  getDefaultRegulationIdByGeo,
  isRegulationEnabled,
  matchRegulationId,
  produceListOfChanges,
  UiMapContinent,
  UiMapContinentSubdivision,
  buildUIContinents,
  buildCountryToRegionsMap,
  isContinentIncludedIn,
  UiMapTerritory,
  areGeosContainedIn,
  isSubdivisionIncludedIn,
  subdivisonToCountryCodes,
  expandGeos,
  consolidateGeos,
} from '@utils';

// helpers
const geosReducer = (acc: string[], reg: ConsentNoticeConfigRegulation) => [...acc, ...reg.geo_locations];
const matchWildCardGeo = (reg: ConsentNoticeConfigRegulation) => reg.geo_locations[0] === '*';

const getUpdatedRegulationsWithToggledWildCard = (regulationsConfig: ConsentNoticeConfigRegulation[], regulationIdToAddWildCard: string) => {
  return regulationsConfig.map(config => {
    if (config.regulation_id === regulationIdToAddWildCard && config.is_default_regulation_config) {
      // Swap selected geo_locations for wild card
      return { ...config, geo_locations: ['*'] };
    } else if (config.geo_locations.includes('*')) {
      // Remove wild card from the other regulations (it could be present in disabled regulation)
      return { ...config, geo_locations: [] };
    } else {
      return config;
    }
  });
};

export const useRegulationsTerritoriesState = ({ noticeId }) => {
  const [regulationId, setRegulationId] = useState<string>();
  const [uiContinents, setUiContinents] = useState<UiMapContinent[]>([]);
  const { data: countriesPage, isLoading: loadingCountries } = useRegulationsCountries();
  const { data: localConsentNoticeConfig, isLoading: loadingLocalConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const { data: regulations, isLoading: loadingRegulations } = useRegulations(localConsentNoticeConfig?.platform);

  const regConfigs = useMemo(() => localConsentNoticeConfig?.regulation_configurations || [], [localConsentNoticeConfig]);
  const activeRegConfigs = useMemo(() => regConfigs.filter(isRegulationEnabled), [regConfigs]);
  const activeDefaultRegConfigs = useMemo(() => activeRegConfigs.filter(r => r.is_default_regulation_config), [activeRegConfigs]);
  const currentRegConfig = useMemo(() => activeDefaultRegConfigs.find(matchRegulationId(regulationId)), [regulationId, activeDefaultRegConfigs]);
  const activeGeos = useMemo(() => activeDefaultRegConfigs.reduce(geosReducer, []), [activeDefaultRegConfigs]);
  const currentGeos = useMemo(() => (currentRegConfig ? [currentRegConfig] : []).reduce(geosReducer, []), [currentRegConfig]);
  const countryToRegionsMap = useMemo(() => (countriesPage?.data?.length ? buildCountryToRegionsMap(countriesPage?.data) : {}), [countriesPage]);

  // Get geos of others active regulations
  const disabledForSelectionGeos = useMemo(() => {
    return activeDefaultRegConfigs.filter(matchRegulationId(regulationId, false)).reduce(geosReducer, []);
  }, [activeDefaultRegConfigs, regulationId]);

  const regulationIdForRemainingTerritories = useMemo(() => {
    return activeDefaultRegConfigs.find(matchWildCardGeo)?.regulation_id;
  }, [activeDefaultRegConfigs]);

  // === CONTINENTS ===
  const isContinentSelected = useCallback((continent: UiMapContinent) => isContinentIncludedIn(continent, activeGeos, countryToRegionsMap), [activeGeos, countryToRegionsMap]);

  const isContinentDisabled = useCallback(
    (continent: UiMapContinent) => isContinentIncludedIn(continent, disabledForSelectionGeos, countryToRegionsMap),
    [disabledForSelectionGeos, countryToRegionsMap],
  );

  useEffect(() => {
    if (countriesPage?.data?.length && regulations?.data?.length && localConsentNoticeConfig?.regulation_configurations) {
      setUiContinents(buildUIContinents(countriesPage.data));
    }
  }, [countriesPage?.data, regulations?.data, activeGeos, localConsentNoticeConfig?.regulation_configurations]);

  const selectedContinents = useMemo(() => {
    return uiContinents.reduce((acc, continent) => {
      return { ...acc, [continent.id]: isContinentSelected(continent) };
    }, {});
  }, [isContinentSelected, uiContinents]);

  const disabledContinents = useMemo(() => {
    return uiContinents.reduce((acc, continent) => {
      return { ...acc, [continent.id]: isContinentDisabled(continent) };
    }, {});
  }, [isContinentDisabled, uiContinents]);

  // === SUBDIVISIONS ===
  const isSubdivisionSelected = useCallback(
    (subdivision: UiMapContinentSubdivision) => isSubdivisionIncludedIn(subdivision, activeGeos, countryToRegionsMap),
    [activeGeos, countryToRegionsMap],
  );

  const isSubdivisionDisabled = useCallback(
    (subdivision: UiMapContinentSubdivision) => isSubdivisionIncludedIn(subdivision, disabledForSelectionGeos, countryToRegionsMap),
    [disabledForSelectionGeos, countryToRegionsMap],
  );

  const selectedSubdivisions = useMemo(() => {
    return uiContinents.flatMap(c => c.subdivisions).reduce((acc, subdivision) => ({ ...acc, [subdivision.id]: isSubdivisionSelected(subdivision) }), {});
  }, [isSubdivisionSelected, uiContinents]);

  const disabledSubdivisions = useMemo(() => {
    return uiContinents.flatMap(c => c.subdivisions).reduce((acc, subdivision) => ({ ...acc, [subdivision.id]: isSubdivisionDisabled(subdivision) }), {});
  }, [isSubdivisionDisabled, uiContinents]);

  // === TERRITORIES ===
  const isTerritorySelected = useCallback((territory: UiMapTerritory) => areGeosContainedIn([territory.id], activeGeos, countryToRegionsMap), [activeGeos, countryToRegionsMap]);

  const isTerritoryDisabled = useCallback(
    (territory: UiMapTerritory) => areGeosContainedIn([territory.id], disabledForSelectionGeos, countryToRegionsMap),
    [disabledForSelectionGeos, countryToRegionsMap],
  );

  const selectedTerritories = useMemo(
    () =>
      uiContinents
        .flatMap(c => c.subdivisions)
        .flatMap(s => s.territories)
        .reduce((acc, territory) => ({ ...acc, [territory.id]: isTerritorySelected(territory) }), {}),
    [isTerritorySelected, uiContinents],
  );

  const disabledTerritories = useMemo(
    () =>
      uiContinents
        .flatMap(c => c.subdivisions)
        .flatMap(s => s.territories)
        .reduce((acc, territory) => ({ ...acc, [territory.id]: isTerritoryDisabled(territory) }), {}),
    [isTerritoryDisabled, uiContinents],
  );

  useEffect(() => {
    if (activeDefaultRegConfigs?.length && (!regulationId || !activeDefaultRegConfigs.find(matchRegulationId(regulationId)))) {
      setRegulationId(activeDefaultRegConfigs[0].regulation_id);
    }
  }, [activeDefaultRegConfigs, regulationId]);

  const hasGeoBeenAddedToRegulation = (
    geo: string,
    targetRegulationId: string,
    prevRegulations: ConsentNoticeConfigRegulation[],
    updatedRegulations: ConsentNoticeConfigRegulation[],
  ) => {
    // Get regulation_id where geo was before the changes
    const previousRegulationId = getDefaultRegulationIdByGeo(geo, prevRegulations);
    // Get regulation_id where geo is after the changes
    const newRegulationId = getDefaultRegulationIdByGeo(geo, updatedRegulations);

    return previousRegulationId !== newRegulationId && newRegulationId === targetRegulationId;
  };

  const updateRegulations = (regulations: ConsentNoticeConfigRegulation[]) => {
    const changes = [
      { path: 'regulation_configurations', value: regulations },
      // if gdpr has geo * - gdprAppliesGlobally true, else - false
      { path: 'config.app.gdprAppliesGlobally', value: regulations.find(matchWildCardGeo)?.regulation_id === 'gdpr' },
    ];
    const configAfterUpdates = produceListOfChanges(changes, localConsentNoticeConfig) as unknown as NoticeConfigV2;

    if (hasGeoBeenAddedToRegulation('IT', 'gdpr', localConsentNoticeConfig.regulation_configurations, regulations)) {
      // this method mutates the configAfterUpdates
      LOCAL_EXCEPTIONS.IT.enable(configAfterUpdates);
    }

    if (hasGeoBeenAddedToRegulation('FR', 'gdpr', localConsentNoticeConfig.regulation_configurations, regulations)) {
      // this method mutates the configAfterUpdates
      LOCAL_EXCEPTIONS.FR.enable(configAfterUpdates);
    }

    updateLocalNoticeConfig(configAfterUpdates as unknown as ConsentNoticeConfig);
  };

  const handleApplyToRemainingTerritories = (isSelected: boolean) => {
    if (isSelected) {
      const updatedRegulationsConfig = getUpdatedRegulationsWithToggledWildCard(regConfigs, regulationId);
      updateRegulations(updatedRegulationsConfig);
    } else {
      const updatedRegulationsConfig = regConfigs.map(config => {
        if (config.regulation_id === regulationId && config.is_default_regulation_config) {
          // restore default geos
          const regulationInfo = regulations.data.find(matchId(regulationId));
          const defaultGeos = regulationInfo.geos.map(geoLocationTransform);
          // restore only geos that aren't selected
          const geosToApply = defaultGeos.filter(geo => !activeGeos.includes(geo));
          return { ...config, geo_locations: geosToApply };
        } else {
          return config;
        }
      });
      updateRegulations(updatedRegulationsConfig);
    }
  };

  const selectGeos = (selectionGeos: string[]): ConsentNoticeConfigRegulation[] => {
    const currentRegulationRegConfigs = regConfigs.filter(regConfig => regConfig.regulation_id === regulationId);
    const otherRegulationRegConfigs = regConfigs.filter(regConfig => regConfig.regulation_id !== regulationId);
    const activeOtherRegConfigs = otherRegulationRegConfigs.filter(regConfig => !regConfig.disabled_at);
    const unavailableGeosAsRegions = new Set(expandGeos(activeOtherRegConfigs.reduce(geosReducer, []), countryToRegionsMap));
    const currentGeosAsRegions = expandGeos(currentGeos, countryToRegionsMap);
    const selectionGeosAsRegions = expandGeos(selectionGeos, countryToRegionsMap);
    const missingGeosSet = new Set(selectionGeosAsRegions.filter(geo => !unavailableGeosAsRegions.has(geo)));
    const updatedGeosSet = new Set(currentGeosAsRegions.concat([...missingGeosSet]));

    // apply updated geo locations to the default config of the current regulation
    const updatedForCurrentRegulation = currentRegulationRegConfigs.map(regConfig => {
      if (regConfig.is_default_regulation_config) {
        return { ...regConfig, geo_locations: [...updatedGeosSet] };
      }
      return { ...regConfig };
    });

    // configs for other regulations should not have any of the current regulation geo locations
    const updatedForOtherRegulations = otherRegulationRegConfigs.map(regConfig => ({
      ...regConfig,
      geo_locations: regConfig.geo_locations.filter(geoLocation => !updatedGeosSet.has(geoLocation)),
    }));

    return [...updatedForCurrentRegulation, ...updatedForOtherRegulations];
  };

  const unselectGeos = (unselectionGeos: string[]): ConsentNoticeConfigRegulation[] => {
    const currentGeosAsRegions = new Set(expandGeos(currentGeos, countryToRegionsMap));
    const unselectionGeosAsRegions = expandGeos(unselectionGeos, countryToRegionsMap);
    const toRemoveGeosSet = new Set(unselectionGeosAsRegions.filter(geo => currentGeosAsRegions.has(geo)));
    return regConfigs.map(regConfig => {
      // If the config belongs to the current regulation
      if (regConfig.regulation_id === currentRegConfig.regulation_id) {
        // Filter out the geo locations to remove
        return { ...regConfig, geo_locations: regConfig.geo_locations.filter(geo => !toRemoveGeosSet.has(geo)) };
      }
      // For all other configurations, return as it is without alterations.
      return { ...regConfig };
    });
  };

  const handleGeoLocationSelection = (geos: string[], isSelected: boolean) => {
    for (const regConfig of regConfigs) {
      regConfig.geo_locations = expandGeos(regConfig.geo_locations, countryToRegionsMap);
    }

    const updatedRegConfigs = isSelected ? selectGeos(geos) : unselectGeos(geos);

    for (const regConfig of updatedRegConfigs) {
      regConfig.geo_locations = consolidateGeos(regConfig.geo_locations, countryToRegionsMap);
    }

    updateRegulations(updatedRegConfigs);
  };

  const handleTerritorySelection = (territories: UiMapTerritory[], isSelected: boolean) => {
    handleGeoLocationSelection(
      territories.map(t => t.id),
      isSelected,
    );
  };

  const handleSubdivisionSelection = (subdivision: UiMapContinentSubdivision, isSelected: boolean) => {
    handleGeoLocationSelection(subdivisonToCountryCodes(subdivision), isSelected);
  };

  const handleContinentSelection = (continent: UiMapContinent, isSelected: boolean) => {
    handleGeoLocationSelection(continent.subdivisions.flatMap(subdivisonToCountryCodes), isSelected);
  };

  return {
    isLoading: loadingLocalConsentNoticeConfig || loadingRegulations || loadingCountries || !uiContinents,
    activeRegulations: activeRegConfigs,
    activeDefaultRegulations: activeDefaultRegConfigs,
    regulationId,
    selectedTerritories,
    disabledTerritories,
    selectedContinents,
    disabledContinents,
    selectedSubdivisions,
    disabledSubdivisions,
    regulationIdForRemainingTerritories,
    setRegulationId,
    handleApplyToRemainingTerritories,
    handleTerritorySelection,
    handleSubdivisionSelection,
    handleContinentSelection,
    uiContinents,
  };
};
