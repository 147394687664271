import React from 'react';
import { DidomiLoader } from '@didomi/ui-atoms-react';

type VendorListApplicationProgressProps = {
  itemsProcessed: number;
  itemsTotal: number;
};

export const VendorListApplicationProgress = ({ itemsProcessed, itemsTotal }: VendorListApplicationProgressProps) => {
  return (
    <div className="transition">
      <div className="flex justify-center">
        <DidomiLoader className="mb-xs" />
      </div>
      <div className="text-body-normal text-primary-blue-5">We&apos;re applying the vendors list to the selected notices</div>
      <div className="text-primary-blue-5">
        {itemsProcessed} / {itemsTotal}
      </div>
    </div>
  );
};
