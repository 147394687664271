import React, { useMemo } from 'react';
import { DidomiChip } from '@didomi/ui-atoms-react';
import { ConsentNoticeTemplateConfig } from '@types';
import { ConsentNoticeWithTemplateMetaData } from 'types/consentNoticeWithTemplateMetaData';

interface TemplateNoticePublicationStatusProps {
  notice: ConsentNoticeWithTemplateMetaData;
  templateId: string;
  templateConfig: ConsentNoticeTemplateConfig;
}

export const TemplateNoticePublicationStatus = ({ notice, templateId, templateConfig }: TemplateNoticePublicationStatusProps) => {
  const isNoticeUsingTemplate = useMemo(() => {
    return !!Object.values(notice.template_metadata).find(({ template_id }) => template_id === templateId);
  }, [templateId, notice]);

  // Compare template's `updated_at` field with notice's `deployed_at`. Notice is up to date if it was deployed after template was saved
  const isUpToDate = useMemo(() => {
    return Boolean(templateConfig) && new Date(templateConfig.updated_at).getTime() <= new Date(notice.deployed_at).getTime();
  }, [templateConfig, notice]);

  return (
    <div>
      {isNoticeUsingTemplate && <DidomiChip label={isUpToDate ? 'Up to date' : 'Not up to date'} basicType={isUpToDate ? 'success' : 'warning'} />}
      {!isNoticeUsingTemplate && '--'}
    </div>
  );
};
