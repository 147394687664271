import { ConsentNoticeConfig } from '@types';
import { matchDefaultRegulationId } from './matchRegulationId';

export const getDefaultGdprConfig = (rootConfig: ConsentNoticeConfig) => {
  return rootConfig?.regulation_configurations?.find(matchDefaultRegulationId('gdpr'));
};

export const getDefaultGdprConfigIab = (rootConfig: ConsentNoticeConfig) => {
  return getDefaultGdprConfig(rootConfig)?.config?.app?.vendors?.iab;
};
