import React, { useState } from 'react';
import { SDKConfigAppIabRestriction } from '@didomi/cmp-generator';
import {
  DidomiButton,
  DidomiEmptyState,
  DidomiHintbox,
  DidomiIconButton,
  DidomiLoader,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { ExpandableSection, ExpandableSectionToggle } from '@components';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { ConfirmDeleteModal, RestrictionFormModal } from '@modals';
import { NoticePublisherRestrictionExtended, RestrictionsSortableField } from '@types';

interface PublisherRestrictionsProps {
  restrictions: NoticePublisherRestrictionExtended[];
  isLoading: boolean;
  noticeName: string;
  viewOnly?: boolean;
  onAddRestriction?: (data: SDKConfigAppIabRestriction) => void;
  onUpdateRestriction?: (data: SDKConfigAppIabRestriction) => void;
  onDeleteRestriction?: (restrictionId: string) => void;
}

export const PublisherRestrictions = ({
  restrictions,
  isLoading,
  noticeName,
  viewOnly = false,
  onAddRestriction,
  onUpdateRestriction,
  onDeleteRestriction,
}: PublisherRestrictionsProps) => {
  const [restrictionModalData, setRestrictionModalData] = useState({ isOpen: false, restriction: null });
  const [restrictionToConfirmDelete, setRestrictionToConfirmDelete] = useState<NoticePublisherRestrictionExtended>(null);
  const { limit, page, sortConfig, changeLimit, changePage, changeSorting } = useTableState<RestrictionsSortableField>({
    defaultLimit: 5,
    defaultSorting: { field: 'purposeDescription', dir: 'asc' },
  });

  const { data: displayedRestrictions, total: paginationTotal } = usePaginatedFilteredItems<NoticePublisherRestrictionExtended, RestrictionsSortableField>(restrictions, {
    limit,
    page,
    sortConfig,
  });

  const isEmpty = !restrictions.length || !displayedRestrictions.length;

  const handleSaveRestriction = (restriction: SDKConfigAppIabRestriction) => {
    if (restrictionModalData.restriction) {
      onUpdateRestriction(restriction);
    } else {
      onAddRestriction(restriction);
    }

    setRestrictionModalData({ isOpen: false, restriction: null });
  };

  const handleDeleteConfirm = () => {
    onDeleteRestriction(restrictionToConfirmDelete.id);
    setRestrictionToConfirmDelete(null);
  };

  return (
    <section>
      <ExpandableSection
        id="restrictions-collapse"
        variant="sub-level"
        header={
          <div className="flex items-center gap-xs">
            <ExpandableSectionToggle a11yLabel="IAB TCF Publisher Restrictions" />
            <h3 className="font-semibold text-h4 mr-auto">IAB TCF Publisher Restrictions</h3>
            {!viewOnly && (
              <DidomiButton
                size="small"
                onClick={e => {
                  // Prevent toggling of the expandable section
                  e.stopPropagation();
                  setRestrictionModalData({ isOpen: true, restriction: null });
                }}
              >
                Add restriction
              </DidomiButton>
            )}
          </div>
        }
      >
        <div className="flex flex-col mt-xs">
          <DidomiHintbox className="mb-m" titleText="Configuring restrictions">
            For IAB vendors, you can restrict the purposes for which personal data is processed by vendors and specify the legal basis upon which you require vendors to operate.
          </DidomiHintbox>

          {!isEmpty && (
            <>
              <DidomiTable
                className="mb-4 bg-white"
                data-testid="restrictions-table"
                fixedLayout
                disabled={viewOnly}
                sortable
                sortBy={sortConfig.field}
                sortDirection={sortConfig.dir}
                onSortChange={changeSorting}
              >
                <DidomiTableHeading>
                  <DidomiTableHeaderRow>
                    <DidomiTableTh sortId="purposeDescription" data-testid="purposeDescription" style={{ flexBasis: 80 }}>
                      PURPOSE
                    </DidomiTableTh>
                    <DidomiTableTh sortId="restrictionDescription">TYPE</DidomiTableTh>

                    {/* Controls Column */}
                    <DidomiTableTh class="max-w-[150px]"></DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>

                <DidomiTableBody>
                  {displayedRestrictions.map(restriction => (
                    <DidomiTableRow key={restriction.id} data-testid={restriction.id} data-cy={restriction.id}>
                      <DidomiTableTd style={{ flexBasis: 80 }}>
                        <div>{restriction.purposeDescription}</div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div>{restriction.restrictionDescription}</div>
                      </DidomiTableTd>

                      {/* Controls */}
                      <DidomiTableTd class="max-w-[150px]" cellAlign="right">
                        <div className="flex gap-xxs">
                          <DidomiTooltip content="Edit" placement="top">
                            <DidomiIconButton
                              aria-label="Edit restriction"
                              data-testid="edit-restriction"
                              type="button"
                              variant="rounded"
                              icon="edit"
                              onClick={() => setRestrictionModalData({ isOpen: true, restriction })}
                            />
                          </DidomiTooltip>

                          <DidomiTooltip content="Delete" placement="top">
                            <DidomiIconButton
                              aria-label="Delete restriction"
                              type="button"
                              variant="rounded"
                              icon="delete"
                              onClick={() => setRestrictionToConfirmDelete(restriction)}
                            />
                          </DidomiTooltip>
                        </div>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>

              <DidomiPaginator
                data-testid="restrictions-paginator"
                className="self-end"
                page={page}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={changeLimit}
                onPageChange={changePage}
              />
            </>
          )}

          {/* Empty State */}
          {!isLoading && isEmpty && (
            <DidomiEmptyState
              illustration-name="traces-no-result-found"
              className="h-[500px]"
              actionName={viewOnly ? '' : 'Add restrictions'}
              actionIconRight={viewOnly ? '' : 'new-create'}
              onActionClick={() => setRestrictionModalData({ isOpen: true, restriction: null })}
            >
              <div slot="title">It&apos;s empty here!</div>
              You have no publisher restriction configured.
            </DidomiEmptyState>
          )}
        </div>
      </ExpandableSection>

      {/* Loading State */}
      {isLoading && (
        <div className="w-full h-[500px] flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading restrictions</div>
        </div>
      )}

      {/* Modals */}
      <RestrictionFormModal
        isOpen={restrictionModalData.isOpen}
        noticeName={noticeName}
        restriction={restrictionModalData.restriction}
        onCancel={() => setRestrictionModalData({ isOpen: false, restriction: null })}
        onSave={handleSaveRestriction}
      />

      <ConfirmDeleteModal
        isOpen={!!restrictionToConfirmDelete}
        titleText="Are you sure you want to delete this restriction?"
        onCancel={() => setRestrictionToConfirmDelete(null)}
        onConfirm={handleDeleteConfirm}
      >
        <div>
          You are about to delete the restriction on <strong>&quot;{restrictionToConfirmDelete?.purposeDescription}&quot;</strong>.
        </div>
      </ConfirmDeleteModal>
    </section>
  );
};
