import { useCallback, useMemo } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { useSearchParams } from 'react-router-dom';
import { NoticeLayer, PreviewSize } from '@types';
import { NOTICE_PREVIEW_DEFAULT_PARAMS } from '@utils';

type Step = 'look-and-feel' | 'content-editor' | 'integrations';
type IntegrationType = 'advertising' | 'tag-manager';

export enum CustomizationStep {
  'look-and-feel' = 0,
  'content-editor' = 1,
  'integrations' = 2,
  'cookies' = 3,
}

enum LookAndFeelStep {
  'global-options' = 0,
  'specific-options' = 1,
}

enum ContentEditorStep {
  'global-options' = 0,
  'specific-options' = 1,
}

const defaultCustomizationStepOptions = {
  defaultStep: 'look-and-feel',
  defaultIntegrationType: 'tag-manager',
  defaultPreviewSize: NOTICE_PREVIEW_DEFAULT_PARAMS.size,
  defaultTraffic: NOTICE_PREVIEW_DEFAULT_PARAMS.language,
};

interface PaginationOptions {
  defaultIntegrationType?: IntegrationType;
  defaultPreviewSize?: PreviewSize;
  defaultTraffic?: string;
  activeRegulationIds?: string[];
}

export const useCustomizationStepOptions = (options?: PaginationOptions) => {
  const { defaultIntegrationType, defaultPreviewSize, defaultTraffic, activeRegulationIds } = {
    ...defaultCustomizationStepOptions,
    ...options,
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const customizationStep = useMemo(() => {
    return CustomizationStep[searchParams.get('step')] || CustomizationStep['look-and-feel'];
  }, [searchParams]);

  const trafficLanguage = useMemo(() => {
    return (searchParams.get('traffic') || defaultTraffic) as Step;
  }, [searchParams, defaultTraffic]);

  const previewSize = useMemo(() => {
    return (searchParams.get('size') || defaultPreviewSize) as PreviewSize;
  }, [searchParams, defaultPreviewSize]);

  const lookAndFeelStep = useMemo(() => {
    return LookAndFeelStep[searchParams.get('look')] || LookAndFeelStep['global-options'];
  }, [searchParams]);

  const contentEditorStep = useMemo(() => {
    return ContentEditorStep[searchParams.get('content')] || ContentEditorStep['global-options'];
  }, [searchParams]);

  const contentEditorLayer = useMemo(() => {
    return (searchParams.get('content-layer') || 'notice') as NoticeLayer;
  }, [searchParams]);

  const previewRegulation = useMemo(() => {
    return (searchParams.get('preview-regulation') || activeRegulationIds?.[0]) as Regulation;
  }, [searchParams, activeRegulationIds]);

  const integrationType = useMemo(() => {
    return (searchParams.get('integrationType') || defaultIntegrationType) as IntegrationType;
  }, [searchParams, defaultIntegrationType]);

  const setPreviewSize = useCallback(
    (size: PreviewSize) => {
      if (size !== previewSize && size !== defaultPreviewSize) {
        searchParams.set('size', size);
      } else {
        searchParams.delete('size');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, previewSize, defaultPreviewSize],
  );

  const setTrafficLanguage = useCallback(
    (lang: string) => {
      if (lang !== defaultTraffic) {
        searchParams.set('traffic', lang);
      } else {
        searchParams.delete('traffic');
      }
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, defaultTraffic],
  );

  const setLookAndFeelStep = useCallback(
    (lookAndFeelStep: number) => {
      searchParams.set('look', LookAndFeelStep[lookAndFeelStep]);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setContentEditorStep = useCallback(
    (contentEditorStep: number) => {
      searchParams.set('content', ContentEditorStep[contentEditorStep]);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setContentEditorLayer = useCallback(
    (layer: string) => {
      searchParams.set('content-layer', layer);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setPreviewRegulation = useCallback(
    (previewRegulation: Regulation) => {
      searchParams.set('preview-regulation', previewRegulation);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setIntegrationType = useCallback(
    (type: IntegrationType) => {
      searchParams.set('integrationType', type);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const setCustomizationStep = useCallback(
    (step: number) => {
      if (CustomizationStep[step] !== 'look-and-feel') {
        searchParams.delete('look');
      }
      if (CustomizationStep[step] !== 'content-editor') {
        setContentEditorStep(0);
        searchParams.delete('content');
        searchParams.delete('content-layer');
      }
      if (CustomizationStep[step] !== 'integrations') {
        searchParams.delete('integrationType');
      }
      searchParams.set('step', CustomizationStep[step]);
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams, setContentEditorStep],
  );

  return {
    customizationStep,
    integrationType,
    previewSize,
    trafficLanguage,
    lookAndFeelStep,
    contentEditorStep,
    contentEditorLayer,
    previewRegulation,
    setCustomizationStep,
    setPreviewSize,
    setTrafficLanguage,
    setLookAndFeelStep,
    setContentEditorStep,
    setContentEditorLayer,
    setIntegrationType,
    setPreviewRegulation,
  };
};
