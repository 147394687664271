import React, { useEffect, useMemo, useState } from 'react';
import {
  DidomiModal,
  DidomiModalHeader,
  DidomiModalContent,
  DidomiSelect,
  DidomiSelectOptions,
  DidomiSelectOption,
  DidomiModalActions,
  DidomiButton,
} from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ConsentNoticeTemplate } from '@types';

interface TemplateSelectionModalProps {
  isOpen: boolean;
  currentTemplateId: string | undefined;
  templates: ConsentNoticeTemplate[];
  regulationName: string;
  onConfirm: (templateId: string) => void;
  onCancel: () => void;
}

export const TemplateSelectionModal = ({ isOpen, currentTemplateId = null, templates = [], regulationName, onCancel, onConfirm }: TemplateSelectionModalProps) => {
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(null);
  const sortedTemplates = useMemo(() => templates.sort((a, b) => a.name.localeCompare(b.name, 'en')), [templates]);

  useEffect(() => {
    setSelectedTemplateId(currentTemplateId);
  }, [currentTemplateId, isOpen]);

  const hasTemplateChanged = selectedTemplateId !== currentTemplateId;

  return (
    <DidomiModal isOpen={isOpen}>
      <DidomiModalHeader
        className={tx('mb-xs')}
        illustrationName="templating"
        modalSubTitle="Apply an existing template"
        modalTitle={`Select a template to apply to the ${regulationName} regulation`}
      />

      <DidomiModalContent style={{ width: '400px' }}>
        {!!sortedTemplates.length && (
          <DidomiSelect placeholder="Template to apply" hideErrorMessage value={selectedTemplateId} onValueChange={e => setSelectedTemplateId(e.detail as string)}>
            <DidomiSelectOptions>
              <DidomiSelectOption variant="empty" value={null} label="Reset"></DidomiSelectOption>
              {sortedTemplates.map(template => (
                <DidomiSelectOption key={template.id} value={template.id} label={template.name} secondary-label={`ID - ${template.id}`}></DidomiSelectOption>
              ))}
            </DidomiSelectOptions>
          </DidomiSelect>
        )}
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton disabled={!hasTemplateChanged} onClick={() => onConfirm(selectedTemplateId)}>
          Apply
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
