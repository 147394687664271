import React, { useEffect, useState } from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiHintbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { IabStacksSelectionList } from '@components';
import { IabStackExtended } from '@types';

type AddIabStacksModalProps = {
  isOpen: boolean;
  noticeName: string;
  availableStacks: IabStackExtended[];
  onCancel?: () => void;
  onSave?: (stackIds: string[]) => void;
};

export const AddIabStacksModal = ({ isOpen, noticeName, availableStacks, onCancel, onSave }: AddIabStacksModalProps) => {
  const [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    setSelectedIds([]);
  }, [isOpen]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleSave = () => {
    onSave(selectedIds);
  };

  return (
    <DidomiModal data-testid="add-stacks-modal" isOpen={isOpen} closable="true" onOpenChange={handleModalChange}>
      <div data-testid="stack-notice-name" className={tx('text-body-extra-small text-primary-blue-6')}>
        {noticeName}
      </div>
      <div className={tx('text-h3 text-primary-blue-6 font-bold font-serif mb-s')}>Add Stacks</div>

      <DidomiModalContent className={tx('text-left')}>
        <DidomiHintbox className={tx('mb-s')} titleText="How to choose stacks?">
          Select the IAB stacks that you want to display in the notice. Stacks must cover all the purposes and special features configured for your vendors and must not include
          duplicated elements across stacks.
        </DidomiHintbox>

        {isOpen && <IabStacksSelectionList selectedIds={selectedIds} stacks={availableStacks} onSelectionChange={ids => setSelectedIds(ids)} />}
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton data-testid="add-stacks" type="submit" disabled={selectedIds.length === 0} onClick={handleSave}>
          Save
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
