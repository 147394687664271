import React, { useState } from 'react';
import { DidomiCollapse, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

interface ExpandableTitleProps {
  title: string;
  children: React.ReactChild;
  separate?: boolean;
}

export const ExpandableTitle = ({ title, separate, children }: ExpandableTitleProps) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="text-body-normal font-semibold text-primary-pink-5 m-0">{title}</h2>
        <DidomiIconButton
          title={isExpanded ? 'Hide ' + title : 'Expand ' + title}
          variant="rounded-border"
          size="small"
          icon="down-on-sm"
          className={tx('transition-transform duration-500 ease-in-out rotate-0', { 'rotate-180': isExpanded })}
          onClick={() => setIsExpanded(e => !e)}
        />
      </div>
      <DidomiCollapse isExpanded={isExpanded}>
        <div className="flex flex-col gap-xs">{children}</div>
      </DidomiCollapse>
      {separate && <hr className={tx('h-0 border-t-1 border-neutral-gray-3 mt-xs', { '-mt-xs': !isExpanded })} />}
    </>
  );
};
