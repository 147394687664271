import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmDeleteModalProps = {
  titleText: string;
  isOpen: boolean;
  children?: React.ReactChild;
  onCancel?: () => void;
  onConfirm?: () => void;
};

/**
 * Generic modal for delete confirmation
 */
export const ConfirmDeleteModal = ({ titleText, isOpen, children, onCancel, onConfirm }: ConfirmDeleteModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalTitle={titleText} modalSubTitle="Pay attention" />

      <DidomiModalContent>
        <div className={tx('text-body-small text-primary-blue-6')}>{children}</div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Yes, delete</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
