import React from 'react';
import { DidomiLargePush, DidomiRadioGroup, DidomiTextInput } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardRadio } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { ACCESS_POLICIES_CONFIG } from '@utils';

export const TagManagerSection = () => {
  const { tagManagerProvider, tagManagerDataLayerName, changeTagManagerProvider, changeTagManagerDataLayerName } = useLocalConsentNoticeConfigFacade();
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const handleTagManagerChange = (newTagManagerEvent: CustomEvent<string>) => {
    const newTagManager = newTagManagerEvent.detail || undefined;
    changeTagManagerProvider(newTagManager);
  };

  const handleGoogleDataLayerIntegrationChange = (dataLayerChange: CustomEvent<string>) => {
    // Stop propagation to prevent onValueChange firing on DidomiRadioGroup
    dataLayerChange.stopPropagation();

    const dataLayerName = dataLayerChange.detail;
    changeTagManagerDataLayerName(dataLayerName);
  };

  return (
    <div className="flex flex-col gap-xs pb-s">
      <DidomiRadioGroup value={tagManagerProvider} onValueChange={handleTagManagerChange} disabled={!isCMPEditor}>
        <SelectionCardRadio
          value={undefined}
          illustration="integrations-no-tag"
          title="No tag manager"
          checked={!tagManagerProvider}
          description="Do not share the user consent status with any tag manager on the page."
          disabled={!isCMPEditor}
        >
          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="No tag manager"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/no-tag-manager"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
        <SelectionCardRadio
          value="adobe"
          illustration="integrations-adobe-dtm"
          title="Adobe DTM and Launch"
          checked={tagManagerProvider === 'adobe'}
          description='Enable our direct integration with Adobe to control your tags in Adobe DTM and Launch with rules and data elements built on consent information collected by Didomi. Our SDK will push the consent status of the user "JavaScript object" data elements and create direct call rules.'
          disabled={!isCMPEditor}
        >
          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="Configure the Didomi / Adobe integration"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/adobe-launch-dtm"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
        <SelectionCardRadio
          value="commandersact"
          illustration="integrations-commanders-act"
          title="CommandersAct"
          checked={tagManagerProvider === 'commandersact'}
          description="Read our documentation to integrate with CommandersAct to control your tags with constraints and internal variables built on consent information collected by Didomi."
          disabled={!isCMPEditor}
        >
          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="CommandersAct"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
        <SelectionCardRadio
          value="eulerian"
          illustration="integrations-eulerian"
          title="Eulerian"
          checked={tagManagerProvider === 'eulerian'}
          description="Read our documentation to integrate with Eulerian to control your tags with constraints built on consent information collected by Didomi."
          disabled={!isCMPEditor}
        >
          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="Configure the Didomi / Eulerian integration"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/eulerian"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
        <SelectionCardRadio
          value="gtm"
          illustration="integrations-google-tag-manager"
          title="Google Tag Manager"
          checked={tagManagerProvider === 'gtm'}
          disabled={!isCMPEditor}
          description="Enable our direct integration with Google Tag Manager to be able to create triggers and control your tags with the consent information collected by Didomi. Our SDK will push the consent status of the user to the dataLayer as well as custom events."
        >
          <DidomiTextInput
            value={tagManagerDataLayerName || ''}
            onClick={
              /* istanbul ignore next */ e => {
                if (tagManagerProvider === 'gtm') e.stopPropagation();
              }
            }
            onValueChange={handleGoogleDataLayerIntegrationChange}
            hideErrorMessage
            disabled={!isCMPEditor}
            label="Data layer name"
            placeholder="dataLayer"
          />
          <DidomiLargePush
            className="w-full children:(!max-w-none) my-xs"
            highlighted-text="Configure the Didomi / GTM integration"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/google-tag-manager"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
        <SelectionCardRadio
          value="tealium"
          illustration="integrations-tealium"
          title="Tealium"
          checked={tagManagerProvider === 'tealium'}
          description="Enable our direct integration with Tealium to control your tags with load rules and variables built on consent information collected by Didomi."
          disabled={!isCMPEditor}
        >
          <DidomiLargePush
            className="w-full children:(!max-w-none) mb-xs"
            highlighted-text="Configure the Didomi / Tealium integration"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/third-parties/custom-integrations/tag-managers/tealium"
            target="_blank"
          ></DidomiLargePush>
        </SelectionCardRadio>
      </DidomiRadioGroup>
    </div>
  );
};
