import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiButton } from '@didomi/ui-atoms-react';

export const WhatIsAVendorsList = () => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <>
      <p className="mb-s h3 text-primary-blue-6">
        A vendor list is a list of vendors that you create and that contains specific parameters (vendors, purposes, editor restrictions, stacks, categories),{' '}
        <span className="h3-bold text-primary-blue-4">in order to apply it to several notices at once.</span>
      </p>
      <img width={440} height={300} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-vendors-list.png`} alt="" />
      <p className="mt-s mb-xs text-body-normal">Instead of having to update them one by one, Vendors list will help you to save a lot of time.</p>
      <a target="_blank" rel="noopener noreferrer" tabIndex={-1} href="https://support.didomi.io/mass-updates">
        <DidomiButton type="button" size="small" iconRight="link">
          All to know about vendor list
        </DidomiButton>
      </a>
    </>
  );
};
