import React from 'react';
import { DidomiModal, DidomiModalHeader, DidomiButton } from '@didomi/ui-atoms-react';

interface SvlRedirectionGuardModalProps {
  isOpen: Boolean;
  onClose: () => void;
}
export const SvlRedirectionGuardModal = ({ isOpen, onClose }: SvlRedirectionGuardModalProps) => {
  return (
    <DidomiModal permanent isOpen={isOpen}>
      <DidomiModalHeader
        illustrationName="widget-lost"
        modalSubTitle="You need to save first"
        modalDescription="Please click the “Save as a Vendor list” button before accessing this tool."
        variant="warning"
      ></DidomiModalHeader>
      <div className="flex justify-around" style={{ marginTop: '32px' }}>
        <DidomiButton onClick={onClose}>Ok</DidomiButton>
      </div>
    </DidomiModal>
  );
};
