import React from 'react';
import { DidomiBackButtonLink, DidomiControlFlowHeader, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useNavigate, Outlet } from 'react-router-dom';
import { InAppHelper } from '@components';

interface IMainLayoutProps {
  'data-testid'?: string;
  title: string;
  subTitle?: string;
  backPath?: string;
  backText?: string;
  helperTitle?: string;
  helperSubtitle?: string;
  helperIcon?: string;
  helperContent?: React.ReactElement;
  loading?: boolean;
  onGoBack?: () => void;
}

/**
 * Create step Layout
 * Default layout for create notice steps
 */
export const CreateStepLayout = ({
  'data-testid': dataTestId = 'create-step-layout',
  loading,
  backPath,
  backText = 'Back',
  title,
  subTitle = 'Create a notice',
  helperTitle,
  helperSubtitle = 'Help Center',
  helperIcon = 'read',
  helperContent,
  onGoBack,
}: IMainLayoutProps): JSX.Element => {
  const navigate = useNavigate();

  return (
    <section className="relative min-h-full box-border !flex flex-col bg-cover bg-create-step">
      <DidomiSkeleton isLoading={loading} variant="layout" className="flex-1 flex flex-col">
        <div className="flex flex-col px-12 pt-l mb-l">
          <DidomiControlFlowHeader className="w-full" style={{ display: 'block' }} titleText={title} subTitleText={subTitle}>
            <div slot="back-button">
              <DidomiBackButtonLink
                data-testid={`${dataTestId}-back-button`}
                className="cursor-pointer"
                onClick={() => {
                  if (onGoBack) {
                    onGoBack();
                  }
                  if (backPath) {
                    navigate(backPath);
                  }
                }}
                text={backText}
              />
            </div>
            <div slot="actions" data-skeleton>
              {helperContent && helperTitle && (
                <InAppHelper title={helperTitle} icon={helperIcon} subtitle={helperSubtitle} data-testid={`${dataTestId}-help`}>
                  {helperContent}
                </InAppHelper>
              )}
            </div>
          </DidomiControlFlowHeader>
        </div>
        <div>
          <Outlet />
        </div>
      </DidomiSkeleton>
    </section>
  );
};
