import React from 'react';
import { tx } from '@twind/core';
import { Vendor } from '@types';

interface CustomVendorsPlainListProps {
  vendors: Vendor[];
}

export const CustomVendorsPlainList = ({ vendors }: CustomVendorsPlainListProps) => {
  return (
    <section className={tx('text-primary-blue-6 text-left')}>
      <p className={tx('text-body-small font-semibold mb-xs')}>Custom vendors ({vendors.length})</p>
      <ul className={tx('flex flex-col gap-xxs max-h-[400px] overflow-y-auto')}>
        {vendors.map(vendor => (
          <li key={vendor.id} className={tx('text-body-small bg-neutral-gray-1 rounded p-xs')}>
            {vendor.name}
          </li>
        ))}
      </ul>
    </section>
  );
};
