import React, { useState } from 'react';
import { DidomiChip, DidomiHintbox, DidomiNumberInput, DidomiSelectionBlock, DidomiIcon } from '@didomi/ui-atoms-react';
import { SelectableTitle } from '@components';
import { CrossDeviceBookingModal, CrossDevicePreBookingModal } from '@modals';

type CrossDeviceConsentSettingsProps = {
  className?: string;
  crossDeviceEnabled: boolean;
  canEnableCrossDeviceSettings?: boolean;
  crossDeviceTimeout: number;
  disabled: boolean;
  onTimeoutChange?: (timeout: number) => void;
  onEnabledChange?: (isEnabled: boolean) => void;
};

export const CrossDeviceConsentSettings = ({
  className = '',
  crossDeviceEnabled,
  crossDeviceTimeout,
  canEnableCrossDeviceSettings,
  disabled,
  onTimeoutChange,
  onEnabledChange,
}: CrossDeviceConsentSettingsProps) => {
  const [isPreBookingModalVisible, setIsPreBookingModalVisible] = useState(false);
  const [isBookingModalVisible, setIsBookingModalVisible] = useState(false);

  const handleCrossDeviceEnabledChange = (crossDeviceEnabledChanged: boolean) => {
    if (canEnableCrossDeviceSettings) {
      onEnabledChange(crossDeviceEnabledChanged);
    } else {
      setIsPreBookingModalVisible(true);
    }
  };

  const handleMakeAppointmentClick = () => {
    setIsPreBookingModalVisible(false);
    setIsBookingModalVisible(true);
  };

  return (
    <section className={`w-full ${className}`}>
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-xs">Cross-device consent</h2>

      <DidomiSelectionBlock className="w-full" selected={crossDeviceEnabled} disabled={disabled} data-skeleton>
        <div className="flex items-center gap-xs mb-xxs">
          <DidomiChip className="leading-none" basicType="error" leftIcon="tags" label="Premium feature" />

          <SelectableTitle
            selected={crossDeviceEnabled}
            data-testid="cross-device-enabled"
            disabled={disabled}
            onSelectionChanged={handleCrossDeviceEnabledChange}
            title="Enable cross-device consent"
          />
        </div>

        <DidomiHintbox className="mb-xxs">
          <div className="w-full flex gap-s flex-wrap items-center lg:flex-nowrap">
            <div>
              Allow user status to be shared across multiple websites and apps, across multiple devices and across multiple platforms. It applies to users that are logged in and
              whose identifier is shared with Didomi.
            </div>
            <a
              href="https://developers.didomi.io/cmp/web-sdk/share-consents-across-devices"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center gap-xxxs whitespace-nowrap text-button-small font-semibold"
            >
              <DidomiIcon name="read" />
              Learn more about cross-device consent
            </a>
          </div>
        </DidomiHintbox>

        <DidomiNumberInput
          className="block max-w-[400px]"
          value={crossDeviceTimeout}
          onValueChange={e => onTimeoutChange(Number(e.detail) || 1)}
          label="Timeout in milliseconds"
          hideErrorMessage
          min="1"
          disabled={disabled || !crossDeviceEnabled}
          data-testid="cross-device-timeout-input"
        />
      </DidomiSelectionBlock>

      <CrossDevicePreBookingModal isOpen={isPreBookingModalVisible} onMakeAppointmentClick={handleMakeAppointmentClick} onCancel={() => setIsPreBookingModalVisible(false)} />
      <CrossDeviceBookingModal isOpen={isBookingModalVisible} onCancel={() => setIsBookingModalVisible(false)} />
    </section>
  );
};
