export type Territory = 'europe' | 'north-america' | 'south-america' | 'asia' | 'africa' | 'oceania';

export const TERRITORY_CONTENT = {
  europe: {
    name: 'Europe',
  },
  'north-america': {
    name: 'North America',
  },
  'south-america': {
    name: 'South America',
  },
  asia: {
    name: 'Asia',
  },
  africa: {
    name: 'Africa',
  },
  oceania: {
    name: 'Oceania',
  },
};
