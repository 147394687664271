import React, { useState } from 'react';
import { DidomiDrawer, DidomiDrawerContent, DidomiSmallPush } from '@didomi/ui-atoms-react';
import { useEventListener } from 'usehooks-ts';
import { CUSTOM_EVENTS } from '@types';

interface InAppHelperProps {
  title: string;
  subtitle?: string;
  icon?: string;
  children?: React.ReactNode;
}

export const InAppHelper = ({ subtitle = 'Help Center', title, children }: InAppHelperProps) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleOpenDrawerEvent = (event: CustomEvent<{ scrollTo?: string }>) => {
    setIsDrawerOpen(true);

    if (event.detail?.scrollTo) {
      requestAnimationFrame(() => {
        const el = document.querySelector(event.detail.scrollTo);
        el?.scrollIntoView();
      });
    }
  };

  useEventListener(CUSTOM_EVENTS.openDrawer as any, handleOpenDrawerEvent);

  return (
    <>
      <DidomiSmallPush
        data-tracking="cmp-open-in-app-help"
        data-testid="in-app-helper-action"
        highlightedText={title}
        text={subtitle}
        smallIcon="arrow-left-sm"
        onClick={() => setIsDrawerOpen(true)}
      />
      <DidomiDrawer data-testid="in-app-helper-drawer" isOpen={isDrawerOpen} panelVisualContainer=".cmp-root" onClose={/* istanbul ignore next */ () => setIsDrawerOpen(false)}>
        <DidomiDrawerContent>
          <h2 className="h2 mb-s text-primary-blue-6">{title}</h2>
          {children && <div className="text-body-normal text-primary-blue-6">{children}</div>}
        </DidomiDrawerContent>
      </DidomiDrawer>
    </>
  );
};
