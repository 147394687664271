import get from 'lodash.get';

// Get value by path from object or fallback object
export const getValueByPath = (path: string, obj: any, fallbackObj?: any) => {
  const value = get(obj, path);

  if (value === undefined && fallbackObj) {
    return get(fallbackObj, path);
  } else {
    return value;
  }
};
