import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { ConsentNotice } from '@types';

/**
 * Edits a local version of a notice
 * */
export const useEditLocalConsentNotice = (noticeId: string, options?: UseMutationOptions<Partial<ConsentNotice>, AxiosError, Partial<ConsentNotice>>) => {
  const queryClient = useQueryClient();

  return useMutation(
    async (consentNotice: Partial<ConsentNotice>) => {
      const consentNoticeGet: ConsentNotice = queryClient.getQueryData(['consent-notice-local', noticeId]);
      return Promise.resolve({ ...consentNoticeGet, ...consentNotice });
    },
    {
      ...options,
      onSuccess: (editedConsentNotice: ConsentNotice, variables, context) => {
        queryClient.setQueryData(['consent-notice-local', noticeId], editedConsentNotice);

        if (options?.onSuccess) {
          options.onSuccess(editedConsentNotice, variables, context);
        }
      },
    },
  );
};
