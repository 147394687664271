import { PurposeGroup } from '@didomi/cmp-generator';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse } from '@types';

/**
 * Fetches the full list of iab stacks
 *
 */
export const useIabStacks = (options?: UseQueryOptions<PurposeGroup[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchStacks = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<PurposeGroup>>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes-groups?$limit=5000`);

    return response.data.data;
  };

  return useQuery<PurposeGroup[], AxiosError>('iab-stacks', fetchStacks, options);
};
