import React from 'react';
import { generateUniqueId } from '@didomi/helpers';
import { DidomiIllustration, DidomiSelectionBlock } from '@didomi/ui-atoms-react';
import { Illustrations } from '@didomi/ui-foundation';
import { tx } from '@twind/core';

export interface SelectionCardProps {
  id?: string;
  title: string;
  description?: string | React.ReactNode;
  checked?: boolean;
  illustration?: Illustrations;
  illustrationContent?: React.ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  className?: string;
  'data-testid'?: string;
  children?: React.ReactNode;
  input?: React.ReactNode;
  inputId?: string;
}

export const SelectionCard = ({
  id = generateUniqueId('selection-card'),
  title = 'Help Center',
  description,
  checked,
  disabled,
  className,
  isLoading,
  'data-testid': dataTestId,
  illustration,
  illustrationContent,
  children,
  input,
  inputId,
}: SelectionCardProps) => {
  return (
    <DidomiSelectionBlock className={tx('w-full', className)} id={id} data-testid={dataTestId} data-skeleton={isLoading} selected={checked} disabled={disabled}>
      <div className="flex items-start">
        {illustration && <DidomiIllustration className="pr-s flex justify-center" name={illustration} />}
        {!illustration && <div className="pr-s flex justify-center">{illustrationContent}</div>}

        <div className="flex flex-col w-full text-primary-blue-6">
          {!input ? (
            <div className="mb-xs text-h3 font-bold font-serif">{title}</div>
          ) : (
            <div className="w-full flex items-center justify-start mb-xs">
              <label htmlFor={inputId} className={tx('flex-1 text-h3 font-bold font-serif', { 'cursor-pointer': !disabled })}>
                {title}
              </label>

              {input}
            </div>
          )}
          {description && <div className="text-body-small">{description}</div>}
          {children && <div className="w-full pt-xs">{children}</div>}
        </div>
      </div>
    </DidomiSelectionBlock>
  );
};
