import React, { useMemo } from 'react';
import { supportedLanguages } from '@didomi/helpers';
import { DidomiButton, DidomiCardContainer, DidomiSelectField, DidomiSelectOption, DidomiSelectOptions, DidomiTextInputField, DidomiTextareaField } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { CategoryLanguageData } from '@types';

const getValidationSchema = (withButtonsCustomization: boolean) =>
  Yup.object().shape({
    language: Yup.string().trim().required('Required'),
    name: Yup.string().trim().required('Required'),
    description: Yup.string().trim(),
    ...(withButtonsCustomization ? { agree: Yup.string().trim().required('Required'), disagree: Yup.string().trim().required('Required') } : {}),
  });

const getInitialFormValue = (withButtonsCustomization: boolean): CategoryLanguageData => {
  return {
    language: '',
    name: '',
    description: '',
    ...(withButtonsCustomization ? { agree: '', disagree: '' } : {}),
  };
};

interface PurposeCategoryLanguageFormProps {
  languageData?: CategoryLanguageData;
  disabledLanguages: string[];
  withButtonsCustomization?: boolean;
  onCancel?: () => void;
  onSave?: (languageData: CategoryLanguageData) => void;
}

export const PurposeCategoryLanguageForm = ({ languageData, disabledLanguages, withButtonsCustomization, onCancel, onSave }: PurposeCategoryLanguageFormProps) => {
  const handleSave = (values: CategoryLanguageData) => {
    onSave(values);
  };

  const validationSchema = useMemo(() => getValidationSchema(withButtonsCustomization), [withButtonsCustomization]);
  const initialFormValue = useMemo(() => getInitialFormValue(withButtonsCustomization), [withButtonsCustomization]);

  return (
    <DidomiCardContainer className={tx('w-full')}>
      <Formik initialValues={languageData || initialFormValue} validationSchema={validationSchema} onSubmit={handleSave}>
        {() => (
          <Form className={tx('w-full')} data-testid="category-lang-form" noValidate>
            <DidomiSelectField name="language" placeholder="Choose a language in the list" label="Language" required>
              <DidomiSelectOptions>
                {supportedLanguages.map(l => (
                  <DidomiSelectOption key={l.code} value={l.code} label={l.longName} disabled={disabledLanguages.includes(l.code)} />
                ))}
              </DidomiSelectOptions>
            </DidomiSelectField>

            <DidomiTextInputField name="name" label="Name" placeholder="Type your category name..." required />

            {withButtonsCustomization && (
              <>
                <DidomiTextInputField name="agree" label="Agree" description="Button for the category and its purposes" required />
                <DidomiTextInputField name="disagree" label="Disagree" description="Button for the category and its purposes" required />
              </>
            )}

            <DidomiTextareaField name="description" label="Description" placeholder="Enter a category description..." rows={3} />

            <div className={tx('flex gap-xxs justify-end')}>
              <DidomiButton type="reset" variant="secondary" size="small" onClick={onCancel}>
                Cancel
              </DidomiButton>
              <DidomiButton data-testid="save-category-language" type="submit" variant="secondary" size="small">
                Save
              </DidomiButton>
            </div>
          </Form>
        )}
      </Formik>
    </DidomiCardContainer>
  );
};
