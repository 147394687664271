import { useState, useEffect, useCallback } from 'react';
import { useSPARouter } from '@didomi/utility-react';
import { unstable_useBlocker as useReactRouterBlocker, useLocation } from 'react-router-dom';
import { useEventListener } from 'usehooks-ts';

const validateRegex = (regexString: RegExp, stringToTest: string): boolean => {
  const regex = new RegExp(regexString);
  return regex.test(stringToTest);
};

declare interface UseBlockerProps {
  isBlock: boolean;
  whitelist?: RegExp[];
  basePath?: string;
  isSaving?: boolean;
}

declare interface UseBlockerReturnType {
  isBlocked: boolean;
  proceedWithNavigation: () => void;
  continueInThePage: () => void;
}

export const useBlocker = ({ isBlock, whitelist, basePath = '/consent-notices', isSaving }: UseBlockerProps): UseBlockerReturnType => {
  const [sPARouterBlockedPath, setSPARouterBlockedPath] = useState<string>(null);
  const location = useLocation();

  const isWhitelisted = useCallback(
    (pathname: string) => {
      if (!pathname) return false;
      const pathOfPage = pathname.split(basePath)[1];
      return whitelist?.length && whitelist?.reduce((value, expression) => value || validateRegex(expression, pathOfPage), false);
    },
    [whitelist, basePath],
  );

  const blocker = useReactRouterBlocker(blockerState => {
    // Hack around the blocker being trigger when the same page loads
    if (blockerState.nextLocation.key === location.key) {
      return false;
    }
    if (isWhitelisted(blockerState?.nextLocation?.pathname)) {
      return false;
    }
    return isBlock;
  });
  const { unblock, block, navigateToAbsolute, isBlocked: isSPARouterBlocked } = useSPARouter();

  useEffect(() => {
    if (blocker.state === 'blocked' && !isBlock && !isSaving) {
      blocker.reset();
    }
  }, [blocker, isBlock, isSaving]);

  // @ts-expect-error - useEventListener expects a default event name, this is a custom one
  useEventListener('routeBlocked', (e: CustomEvent<{ path: string }>) => {
    const blockPath = e?.detail?.path;
    setSPARouterBlockedPath(blockPath);
  });

  // Handle single-spa navigation
  useEffect(() => {
    if (!block || !unblock || !isWhitelisted) return;
    if (isBlock && !isWhitelisted(sPARouterBlockedPath)) {
      if (!isSPARouterBlocked()) {
        block();
      }
    } else {
      unblock();
    }
  }, [isBlock, sPARouterBlockedPath, isWhitelisted, block, unblock, isSPARouterBlocked]);

  const proceedWithNavigation = () => {
    if (sPARouterBlockedPath) {
      unblock();
      navigateToAbsolute(sPARouterBlockedPath);
    }
    if (blocker.state === 'blocked') {
      blocker?.proceed();
    }
  };

  const continueInThePage = () => {
    if (sPARouterBlockedPath) {
      setSPARouterBlockedPath(null);
    }
    if (blocker.state === 'blocked') {
      blocker?.reset();
    }
  };

  return {
    isBlocked: blocker.state === 'blocked' || !!sPARouterBlockedPath,
    proceedWithNavigation,
    continueInThePage,
  };
};
