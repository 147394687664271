import React, { useEffect, useState } from 'react';
import { DidomiModal, DidomiModalActions, DidomiButton, DidomiModalContent, DidomiTextarea, DidomiIllustration, DidomiHintbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type AddCommentOnConsentPublishModalProps = {
  isOpen: boolean;
  onCancel?: () => void;
  onSave?: (message: string) => void;
};

export const AddCommentOnConsentPublishModal = ({ isOpen, onCancel, onSave }: AddCommentOnConsentPublishModalProps) => {
  const [message, setMessage] = useState('');

  useEffect(() => {
    setMessage('');
  }, [isOpen]);

  /* istanbul ignore next */
  const handleModalChange = (e: CustomEvent<boolean>) => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  const handleSave = () => {
    onSave(message);
  };

  return (
    <DidomiModal data-testid="add-comment-on-consent-publish-modal" isOpen={isOpen} onOpenChange={handleModalChange}>
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />

      <div data-testid="stack-notice-name" className={tx('text-body-big font-semibold text-primary-pink-5 font-bold mb-xxxs')}>
        You are going to publish a new version
      </div>
      <div className={tx('text-h3 text-primary-blue-6 font-serif mb-xs')}>
        All your changes will be deployed on your live consent notice on any website or mobile app it targets.
      </div>

      <DidomiHintbox iconName="warning" variant="warning" titleText="Important" className={tx('mb-xs text-left')}>
        Vendors who have not updated their information in the Global Vendor List v3 (provided by the IAB) will not appear in your notice.
      </DidomiHintbox>

      <DidomiModalContent className={tx('text-left w-full')}>
        <DidomiTextarea
          class="w-full"
          value={message}
          onValueChange={e => setMessage(e.detail)}
          name="message"
          label="Description of the changes"
          placeholder="e.g.: 5 vendors added, additional text updates..."
          rows={3}
          hideErrorMessage
        />
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButton type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </DidomiButton>
        <DidomiButton data-testid="publish-consent-notice-button" type="submit" variant="top" iconRight="save-publish" onClick={handleSave}>
          Publish
        </DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
