import React from 'react';
import { DidomiHeaderInput } from '@didomi/ui-atoms-react';
import { sanitizeHTML } from '@didomi/xss/sanitizers';

// This component is a wrapper around the DidomiHeaderInput component with onValueChange handler that sanitizes the input value.
export const HeaderInput = (props: React.ComponentProps<typeof DidomiHeaderInput>) => {
  const handleChange = e => {
    const sanitizedValue = sanitizeHTML(e.detail).result;
    props.onValueChange({ ...e, detail: sanitizedValue });
  };

  return <DidomiHeaderInput {...props} onValueChange={handleChange} />;
};
