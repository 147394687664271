import React, { useCallback, useMemo } from 'react';
import { DidomiCheckbox, DidomiEmptyState, DidomiPaginator, DidomiSelect, DidomiSelectOption, DidomiSelectOptions, DidomiTextSearchInput } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { usePaginatedFilteredItems, useTableState, useVendors } from '@hooks';
import { Vendor, VendorFilterType, VendorsSortableField } from '@types';
import { checkVendorType, isIAB2Vendor } from '@utils';

const SEARCH_FIELDS = [{ field: 'name' }];

interface RestrictionsVendorsListProps {
  className?: string;
  selectedIds: string[];
  updateSelection?: (newSelection: string[]) => void;
}

export const RestrictionsVendorsList = ({ className, selectedIds, updateSelection }: RestrictionsVendorsListProps) => {
  const { data: vendors = [] } = useVendors();
  const { limit, page, sortConfig, search, filters, changeLimit, changePage, changeSearch, changeFilters } = useTableState<VendorsSortableField>({
    defaultLimit: 5,
    defaultFilters: { type: VendorFilterType.ALL },
  });

  const filterByType = useCallback((vendor: Vendor) => checkVendorType(vendor, filters.type as VendorFilterType, selectedIds), [filters, selectedIds]);
  const filtersToApply = useMemo(() => [filterByType], [filterByType]);

  const initialVendorsData = useMemo(() => vendors.filter(v => isIAB2Vendor(v)), [vendors]);

  const { data: displayedVendors, total: paginationTotal } = usePaginatedFilteredItems<Vendor, VendorsSortableField>(initialVendorsData, {
    search,
    searchFields: SEARCH_FIELDS,
    limit,
    page,
    sortConfig,
    filters: filtersToApply,
  });

  const handleSelectionChange = (vendorId: string, selectionEvent: CustomEvent<boolean>) => {
    const isSelected = selectionEvent.detail;

    if (isSelected) {
      updateSelection([...selectedIds, vendorId]);
    } else {
      updateSelection(selectedIds.filter(id => id !== vendorId));
    }
  };

  return (
    <section data-testid="restrictions-vendors-list" className={tx(className, 'flex flex-col')}>
      {/* Filters */}
      <div className="flex gap-2 mb-4">
        <DidomiTextSearchInput
          data-testid="restriction-vendors-search"
          value=""
          placeholder="Search vendors"
          variant="secondary"
          hideErrorMessage
          onValueChange={e => changeSearch(e.detail)}
        />
        <DidomiSelect className="w-[180px]" placeholder="All vendors" hideErrorMessage variant="modern" onValueChange={e => changeFilters({ type: e.detail })}>
          <DidomiSelectOptions>
            <DidomiSelectOption label="All vendors" value={VendorFilterType.ALL}></DidomiSelectOption>
            <DidomiSelectOption label="Selected vendors" value={VendorFilterType.SELECTED}></DidomiSelectOption>
          </DidomiSelectOptions>
        </DidomiSelect>
      </div>

      {displayedVendors.length > 0 && (
        <>
          <ul className="flex flex-col gap-2 mb-4">
            {displayedVendors.map(vendor => (
              <li key={vendor.id} className="bg-neutral-gray-1 rounded p-xs">
                <DidomiCheckbox
                  data-testid={`vendor-${vendor.id}`}
                  label={vendor.name}
                  checked={selectedIds.includes(vendor.id)}
                  hideErrorMessage
                  onValueChange={e => handleSelectionChange(vendor.id, e)}
                />
              </li>
            ))}
          </ul>

          <DidomiPaginator
            data-testid="restriction-vendors-paginator"
            className="self-end"
            page={page}
            itemCount={paginationTotal}
            size={limit}
            onPageSizeChange={changeLimit}
            onPageChange={changePage}
          />
        </>
      )}

      {/* Empty State */}
      {displayedVendors.length === 0 && <DidomiEmptyState illustration-name="traces-no-match-found">No results</DidomiEmptyState>}
    </section>
  );
};
