import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';

export const WhatIsAConsentNotice = () => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  return (
    <>
      <p className="mb-s h3 text-primary-blue-6">
        A Consent Notice is a notification banner to inform your users about the <span className="text-primary-blue-4 font-semibold">collection of their data and its usage</span>{' '}
        by you and your vendor partners.
      </p>
      <img width={440} height={300} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-hero.png`} alt="" />
      <p className="mt-s mb-xs text-body-normal">
        <strong>A consent banner is an interface to inform and collect user consent for the declared cookies and purposes.</strong> In fact, this is the only way to collect consent
        in countries with GDPR, CCPA, CPRA, LGPD, and POPIA mandates.
      </p>
      <p className="mb-s text-body-small">
        <strong>With a 99% consent validity rate</strong> (the highest in the industry), we ensure that the information collected reaches your vendors, respecting your user choices
        and ultimately protecting your ad revenue.
      </p>
      <p className="mt-xs mb-m h3 text-primary-blue-6">
        Didomi enables creating <span className="text-primary-blue-4 font-semibold">highly customizable banners</span> to match your branding needs.
      </p>
      <img width={440} height={148} src={`${ASSETS_URL}/assets/illustrations/in-app-helper/drawer_consent-notice-customization.png`} alt="" />
      <p className="mt-xs text-body-normal text-primary-blue-6">Make sure to use the full customization potential of the tool.</p>
    </>
  );
};
