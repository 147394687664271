import React from 'react';
import { DidomiIconButton, DidomiMenu, DidomiMenuItem, DidomiTooltip } from '@didomi/ui-atoms-react';

type ConsentNoticeOptionsMenuProps = {
  id?: string;
  isArchived: boolean;
  hasTargets?: boolean;
  onArchive?: () => void;
  onDuplicate?: () => void;
  onRestore?: () => void;
};

export const ConsentNoticeOptionsMenu = ({ id = 'notice-options', isArchived, hasTargets, onArchive, onDuplicate, onRestore }: ConsentNoticeOptionsMenuProps): JSX.Element => {
  if (!isArchived && !onArchive && !onDuplicate) {
    return null;
  }

  if (isArchived && !onRestore) {
    return null;
  }

  return (
    <>
      <DidomiTooltip content="Options" placement="top">
        <DidomiIconButton data-testid="notice-options" data-cy="notice-options" title="Notice options" id={id} variant="rounded" icon="submenu" />
      </DidomiTooltip>
      <DidomiMenu for={id} variant="main" placement="left-start" distance="xxs">
        <div>
          {!isArchived && onArchive && (
            <DidomiMenuItem
              data-tracking="cmp-notice-archive"
              disabled={hasTargets}
              iconName="archive"
              data-testid="archive-item"
              data-cy="archive-item"
              onItemSelected={onArchive}
            >
              Archive
            </DidomiMenuItem>
          )}
          {!isArchived && onDuplicate && (
            <DidomiMenuItem data-tracking="cmp-notice-duplicate" iconName="copy" data-testid="duplicate-item" data-cy="duplicate-item" onItemSelected={onDuplicate}>
              Duplicate
            </DidomiMenuItem>
          )}
          {isArchived && onRestore && (
            <DidomiMenuItem data-tracking="cmp-notice-restore" iconName="copy" data-testid="restore-item" data-cy="restore-item" onItemSelected={onRestore}>
              Restore
            </DidomiMenuItem>
          )}
        </div>
      </DidomiMenu>
    </>
  );
};
