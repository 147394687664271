import { useEffect } from 'react';

const SPA_ROOT_SELECTOR = 'main';

// Scroll to top of the page or container element(main)
export const useScrollToTop = () => {
  useEffect(() => {
    // Use main element as scroll container(overflow is declared on it) if it exists, otherwise use window
    const scrollContainer = document.querySelector(SPA_ROOT_SELECTOR) || window;
    scrollContainer.scrollTo(0, 0);
  }, []);
};
