import { Vendor, VendorFilterType } from '@types';

export const isATPVendor = (vendor: Vendor) => Number.isInteger(vendor.namespaces?.google);

export const isIAB2Vendor = (vendor: Vendor) => vendor.namespaces?.iab2 > 0;
export const isActiveIAB2Vendor = (vendor: Vendor) => isIAB2Vendor(vendor) && vendor.deprecated !== true;

// Check that vendor has single namespace and it is iab v1
export const isIAB1Vendor = (vendor: Vendor) => vendor.namespaces && Object.keys(vendor.namespaces).length === 1 && vendor.namespaces.iab > 0;

export const hasAnyPurpose = (vendor: Vendor) => vendor.default_purposes_id?.length > 0 || vendor.legitimate_interest_purposes_id?.length > 0;

// Not deprecated and have at least one purpose(default or legitimate - so that they can actually be used in the consent notice)
const isActiveVendor = (vendor: Vendor) => vendor.deprecated !== true && (hasAnyPurpose(vendor) || isIAB2Vendor(vendor));

const isDidomiVendor = (vendor: Vendor) => typeof vendor.namespaces?.didomi === 'string';

export const checkVendorType = (vendor: Vendor, type: VendorFilterType, selectedIds: string[] = []): boolean => {
  const isSelected = selectedIds.includes(vendor.id);
  // Vendors are visible if they are selected(so user can unselect them) or if they are active(not deprecated and with at least 1 purpose)
  const isVisible = isSelected || isActiveVendor(vendor);

  switch (type) {
    /**
     * All vendors:
     *  - Selected(so user can unselect them) or if they are active(not deprecated and with at least 1 purpose)
     */
    case VendorFilterType.ALL:
      return isVisible;

    /**
     * Custom vendors:
     *  - Selected(so user can unselect them) or if they are active(not deprecated and with at least 1 purpose)
     *  - Non Google vendors
     *  - Non IAB vendors
     *  - Non Didomi vendors
     *  - Vendors from the organization
     */
    case VendorFilterType.CUSTOM:
      return isVisible && !isATPVendor(vendor) && !isIAB2Vendor(vendor) && !isDidomiVendor(vendor) && !!vendor.organization_id;

    /**
     * Google vendors:
     * - Selected(so user can unselect them) or if they are active(not deprecated and with at least 1 purpose)
     * - Google vendors
     */
    case VendorFilterType.ATP:
      return isVisible && isATPVendor(vendor);

    /**
     * IAB vendors:
     * - Selected(so user can unselect them) or if they are active(not deprecated and with at least 1 purpose)
     * - IAB2 vendors
     */
    case VendorFilterType.IAB:
      return isVisible && isIAB2Vendor(vendor);
    case VendorFilterType.SELECTED:
      return isSelected;
  }
};
