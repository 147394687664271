import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse } from '@types';

// Hooks that allows to fetch all items from the API even if number of items exceeds the limit(5000)
// It works by checking the response total and number of returned items. If not all items are returned - make sequential requests to fetch all items(till total is reached)
// As exceeding the limit is not a common case, the requests are made sequentially and not in parallel(it can be changed if there is a need for further optimization)
export const useFetchAllPaginatedResults = () => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchItems = <T>(path: string, { skip, limit = 5000 }: { skip: number; limit?: number }) => {
    const url = new URL(`${CONFIG.environmentConfig.apiBaseUrl}${path}`);
    url.searchParams.append(`$skip`, String(skip));
    url.searchParams.append(`$limit`, String(limit));

    return axiosWithInterceptors.get<PaginatedResponse<T>>(url.toString());
  };

  // Make sequential requests in order to fetch all items from the API
  const fetchAllPaginatedResults = async <T>(path: string) => {
    const response = await fetchItems<T>(path, { skip: 0 });

    const total = response.data.total;
    let items = response.data.data;

    // Make requests till all the items are fetched
    while (items.length < total) {
      const response = await fetchItems<T>(path, { skip: items.length });
      items = items.concat(response.data.data);
    }

    return items;
  };

  return {
    fetchAllPaginatedResults,
  };
};
