import React, { useRef, useEffect } from 'react';
import { DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/theme-github';

type EditorProps = {
  mode: 'json' | 'css';
  name: string;
  label?: string;
  defaultValue: any;
  minHeight?: string;
  error?: string;
  errorType?: string;
  isLoading?: boolean;
  readOnly?: boolean;
  onChange?: (value: any) => void;
  onBlur?: (value: any) => void;
  onValidate?: (value: any) => void;
};

/**
 * Ace Editor wrapper
 */
export const Editor = ({
  isLoading,
  label,
  mode,
  minHeight = '320px',
  name,
  defaultValue,
  error,
  errorType = 'error',
  readOnly = false,
  onChange,
  onBlur,
  onValidate,
}: EditorProps): JSX.Element => {
  const aceEditorRef = useRef(null);

  /* istanbul ignore next */
  useEffect(() => {
    if (aceEditorRef.current?.editor) {
      aceEditorRef.current.editor.setValue(defaultValue);
      aceEditorRef.current?.editor?.clearSelection();
    }
  }, [defaultValue]);

  return (
    <DidomiSkeleton isLoading={isLoading} variant="layout">
      <div>
        {label && (
          <div data-skeleton className="text-field-label text-primary-blue-6 font-bold mb-xxxs">
            {label}
          </div>
        )}
        <div className="w-full" data-skeleton>
          <AceEditor
            ref={aceEditorRef}
            data-testid="custom_editor"
            className={`min-h-[${minHeight}] border-1 border-solid border-primary-blue-4 rounded-md`}
            mode={mode}
            width="100%"
            height="100%"
            theme="github"
            showPrintMargin={false}
            fontSize="14"
            maxLines={Infinity}
            defaultValue={defaultValue}
            onChange={onChange}
            debounceChangePeriod={300}
            onBlur={/* istanbul ignore next */ () => onBlur && onBlur(aceEditorRef?.current?.editor?.getValue())}
            onValidate={onValidate}
            name={name}
            editorProps={{ $blockScrolling: true }}
            readOnly={readOnly}
          />
        </div>
        {error && (
          <div data-testid="error-message" className={tx('text-secondary-red-4 text-field-label pt-xxxs', { 'text-secondary-yellow-3': errorType === 'warning' })}>
            {error}
          </div>
        )}
      </div>
    </DidomiSkeleton>
  );
};
