import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiLoader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmRestoreConsentNoticeModalProps = {
  noticeName: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Confirm before restoring a consent notice
 */
export const ConfirmRestoreConsentNoticeModal = ({ noticeName, isOpen, isLoading, onCancel, onProceed }: ConfirmRestoreConsentNoticeModalProps): JSX.Element => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiIllustration name="warning" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle="Please pay attention"
        modalTitle={`Restore ${noticeName}`}
        modalDescription="Restoring a notice will allow you to edit it and publish it again. You will need to manually assign targets before publishing the new notice. You will be able to edit the new notice configuration before publishing it."
      ></DidomiModalHeader>

      <DidomiModalActions className={tx('!pt-5')}>
        <div>
          {isLoading && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} data-testid="loading-notice-archive">
              <DidomiLoader />
            </div>
          )}
          {!isLoading && (
            <DidomiButtonGroup>
              <DidomiButton variant="secondary" onClick={onCancel}>
                Oops, no thanks
              </DidomiButton>
              <DidomiButton onClick={onProceed} iconLeft="archive">
                Restore
              </DidomiButton>
            </DidomiButtonGroup>
          )}
        </div>
      </DidomiModalActions>
    </DidomiModal>
  );
};
