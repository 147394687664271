import React from 'react';
import { DidomiCheckbox, DidomiLargePush } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useParams } from 'react-router-dom';
import { RegulationPicker, RegulationsMap } from '@components';
import { useRegulationsTerritoriesState } from '@hooks';
import { ACCESS_POLICIES_CONFIG, REGULATION_DICTIONARY } from '@utils';

/**
 * Edit territories tab
 */
export const Territories = (): JSX.Element => {
  const { noticeId } = useParams();
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const territoriesState = useRegulationsTerritoriesState({ noticeId });

  const isApplyToRemainingChecked = !!territoriesState.regulationId && territoriesState.regulationIdForRemainingTerritories === territoriesState.regulationId;

  return (
    <div data-testid="territories" data-loading={territoriesState.isLoading}>
      <div className="!flex gap-xs mb-s" data-skeleton={territoriesState.isLoading}>
        <div className="mr-auto">
          <h2 className="h2 text-secondary-cobalt-blue-4 mb-xxxs">Edit the Country/Region per regulation</h2>
          <div className="text-body-small text-primary-blue-6">Select a regulation from the list to change its country or region.</div>
        </div>

        {!!territoriesState.activeDefaultRegulations.length && (
          <RegulationPicker
            selectedRegulationId={territoriesState.regulationId}
            regulations={territoriesState.activeDefaultRegulations}
            onRegulationSelected={territoriesState.setRegulationId}
          />
        )}

        <DidomiLargePush
          text="Developers documentation"
          highlightedText="What are Country/Region?"
          href="https://developers.didomi.io/cmp/mobile-sdk/consent-notice/getting-started#what-visitors-to-collect-consent-for"
          target="_blank"
        />
      </div>

      <RegulationsMap
        className="mb-s"
        disabled={!isCMPEditor}
        isLoading={territoriesState.isLoading}
        isAppliedToRemaining={isApplyToRemainingChecked}
        selectedContinents={territoriesState.selectedContinents}
        disabledContinents={territoriesState.disabledContinents}
        selectedSubdivisions={territoriesState.selectedSubdivisions}
        disabledSubdivisions={territoriesState.disabledSubdivisions}
        selectedTerritories={territoriesState.selectedTerritories}
        disabledTerritories={territoriesState.disabledTerritories}
        onContinentSelection={territoriesState.handleContinentSelection}
        onSubdivisionSelection={territoriesState.handleSubdivisionSelection}
        onTerritorySelection={territoriesState.handleTerritorySelection}
        uiContinents={territoriesState.uiContinents}
      />

      <div className="flex mb-s">
        <DidomiCheckbox
          data-testid="apply-to-remaining-territories-checkbox"
          label={`Apply ${REGULATION_DICTIONARY[territoriesState.regulationId]?.titleText} to all countries and states remaining`}
          hideErrorMessage
          checked={isApplyToRemainingChecked}
          disabled={
            !isCMPEditor || (territoriesState.regulationIdForRemainingTerritories && territoriesState.regulationIdForRemainingTerritories !== territoriesState.regulationId)
          }
          onValueChange={e => territoriesState.handleApplyToRemainingTerritories(e.detail)}
        />
      </div>
    </div>
  );
};
