import React from 'react';
import { useReleaseFlag } from '@didomi/utility-react';
import { useParams } from 'react-router-dom';
import { CookiesSecure, ConsentStringSettings } from '@components';
import { useLocalConsentNoticeConfig } from '@hooks';

/**
 * Cookies and Storage tab
 */
export const CookiesAndStorage = (): JSX.Element => {
  const { noticeId } = useParams();
  const { isLoading } = useLocalConsentNoticeConfig(noticeId);
  const [hasConsentStringReleaseFlag] = useReleaseFlag('dcs-spa-beta');

  return (
    <div className="slidein w-full flex flex-col gap-xs">
      {hasConsentStringReleaseFlag && <ConsentStringSettings noticeId={noticeId} />}
      <CookiesSecure noticeId={noticeId} isLoading={isLoading} />
    </div>
  );
};
