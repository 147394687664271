import { tw, animation, css, keyframes } from '@twind/core';
const ASSET_URL = CONFIG.standalone ? 'http://localhost:9000' : new URL(DIDOMI_SPA_IMPORTS_MAP['@didomi-spa/consent-notices']).origin;

const slidein = () => {
  return tw(
    animation(
      '500ms ease-in-out',
      keyframes({
        from: {
          transform: 'translateX(100%)',
        },
        to: {
          transform: 'translateX(0%)',
        },
      }),
    ),
  );
};

const squaredBackground = () => css`
  background-color: #f9fafa;
  background-image: repeating-conic-gradient(#f9fafa 0deg 25%, white 0deg 50%);
  background-position: 12px 0, 12px 0, 0 0, 0 0;
  background-size: 12px 12px;
  background-repeat: repeat;
`;

const rules = [
  ['slidein', slidein],
  ['bg-squared', squaredBackground],
];

export default function presetConsentNotice() {
  return {
    rules,
    variants: ['aria-expanded', '&[aria-expanded=\\"true\\"]'],
    theme: {
      colors: {
        europe: '#F5FAFF',
        'north-america': '#F9FFFD',
        'south-america': '#FAFFF7',
        asia: '#FFFEEE',
        africa: '#FFF8EC',
        oceania: '#FFF5EF',
      },
      backgroundImage: {
        'create-step': `url(${ASSET_URL}/assets/illustrations/background-notice.svg)`,
        'marketplace-promotion': 'linear-gradient(225deg, #D66A49 0%, #EAA85B 100%)',
        'app-iframe': 'linear-gradient(314.69deg, #EFF7FB 0%, #F9FAFA 100%)',
        'phone-separator': 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(198, 198, 198, 0.4) 100%)',
      },
      boxShadow: {
        'platform-card':
          '0px 100px 80px rgba(0, 0, 0, 0.08), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916)',
        'app-iframe':
          '0px 100px 80px rgba(0, 0, 0, 0.08), 0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886), 0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04), 0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916)',
      },
      dropShadow: {
        territories:
          'drop-shadow(0px 100px 80px rgba(0, 0, 0, 0.08)) drop-shadow(0px 22.3363px 17.869px rgba(0, 0, 0, 0.0476886)) drop-shadow(0px 12.5216px 10.0172px rgba(0, 0, 0, 0.04)) drop-shadow(0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0323114)) drop-shadow(0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0224916))',
      },
      gridTemplateColumns: {
        'fill-350': 'repeat(auto-fill, minmax(min(100%, 350px), 1fr))',
      },
    },
  };
}
