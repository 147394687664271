import { useCallback, useMemo } from 'react';
import { Regulations } from '@didomi/cmp-generator';
import { matchId } from '@didomi/helpers';
import { useLocalRegulationConfig, usePurposes, useVendors } from '@hooks';
import { ConsentNoticeConfigRegulation } from '@types';
import { CUSTOMIZATION_CONFIG_PER_REGULATION, extractSpiFromVendors, getConfigPurposesCount, isActiveIAB2Vendor } from '@utils';

/*
 * Calculate counters info for the default selected regulations
 * 1. Calculate unique vendors
 * Custom + IAB Vendors(is AIB is selected + regulations supports IAB)
 * 2. Calculate unique purposes
 * Get number of purposes in the preferences.categories
 * 3. Calculate unique SPI
 * Get SPI assigned to the vendors calculated on the first step. If regulation doesn't support SPI - returns undefined
 *
 * countsPerRegulation = { gdpr: { vendorsCount: 5, purposesCount: 4, spiCount: undefined }, cpra: { vendorsCount: 5, purposesCount: 4, spiCount: 3 } }
 */
export const useRegulationSelectionMetaInfo = (noticeId: string) => {
  const { defaultRegulations, isLoading: isConfigLoading } = useLocalRegulationConfig(noticeId);
  const { data: vendors = [], isLoading: isVendorsLoading } = useVendors();
  const { data: purposes, isLoading: isPurposesLoading } = usePurposes();

  const iabVendors = useMemo(() => vendors?.filter(isActiveIAB2Vendor), [vendors]);
  const iabVendorsIds = useMemo(() => iabVendors?.map(({ id }) => id), [iabVendors]);

  const checkIsIabAllSelected = (regConfig: ConsentNoticeConfigRegulation) => {
    return regConfig?.config?.app?.vendors?.iab?.enabled && regConfig?.config?.app?.vendors?.iab?.all;
  };

  const getRegulationUniqueSelectedVendors = useCallback(
    (regConfig: ConsentNoticeConfigRegulation) => {
      const isRegulationSupportsIAB = CUSTOMIZATION_CONFIG_PER_REGULATION[regConfig.regulation_id].hasIabIntegration;
      const inheritedVendorsIds = isRegulationSupportsIAB && checkIsIabAllSelected(regConfig) ? iabVendorsIds : [];
      const customVendorsIds = regConfig.config?.app?.vendors?.include || [];
      const combinedUniqueVendorsIds = [...new Set(customVendorsIds.concat(inheritedVendorsIds))];

      // Don't count deleted vendors (might be deleted from the data manager but still in the config)
      const combinedUniqueValidVendorsIds = combinedUniqueVendorsIds.filter(v => vendors.find(matchId(v)));

      return combinedUniqueValidVendorsIds.map(id => vendors.find(matchId(id)));
    },
    [vendors, iabVendorsIds],
  );

  const countsPerRegulation: { [key: string]: { vendorsCount: number; purposesCount: number; spiCount: number | undefined } } = useMemo(() => {
    if (isConfigLoading || isVendorsLoading) {
      return {};
    }

    return defaultRegulations.reduce((acc, regConfig) => {
      const selectedVendors = getRegulationUniqueSelectedVendors(regConfig);
      const isRegulationSupportsSPI = CUSTOMIZATION_CONFIG_PER_REGULATION[regConfig.regulation_id].hasSPI;

      return {
        ...acc,
        [regConfig.regulation_id]: {
          vendorsCount: selectedVendors.length,
          purposesCount: getConfigPurposesCount(regConfig) || 0,
          spiCount: isRegulationSupportsSPI ? extractSpiFromVendors(selectedVendors, purposes, regConfig.regulation_id as Regulations).size : undefined,
        },
      };
    }, {});
  }, [defaultRegulations, getRegulationUniqueSelectedVendors, isConfigLoading, isVendorsLoading, purposes]);

  return {
    countsPerRegulation,
    isLoading: isConfigLoading || isVendorsLoading || isPurposesLoading,
  };
};
