import { SDKConfigAppIabRestriction } from '@didomi/cmp-generator';
import { getTranslatedValue, matchId } from '@didomi/helpers';
import { NoticePublisherRestrictionExtended, Purpose } from '@types';
import { getCounterText } from './getCounterText';

const RESTRICTION_TYPES_DICTIONARY = {
  allow: 'Allow',
  disallow: 'Block',
  'req-consent': 'Require consent',
  'req-li': 'Require legitimate interest',
};

const getRestrictionDescription = (restriction: SDKConfigAppIabRestriction) => {
  const restrictionStatusText = RESTRICTION_TYPES_DICTIONARY[restriction.restrictionType];

  if (restriction.vendors.type === 'all') {
    return `${restrictionStatusText} for all vendors`;
  }

  if (restriction.vendors.type === 'list') {
    return `${restrictionStatusText} for ${getCounterText(restriction.vendors.ids.length, 'vendor')}`;
  }
};

export const enrichPublisherRestrictions = (restrictions: SDKConfigAppIabRestriction[], purposes: Purpose[]): NoticePublisherRestrictionExtended[] => {
  const getPurposeDescription = (purposeId: string) => {
    const purpose = purposes?.find(matchId(purposeId));
    return getTranslatedValue(purpose?.description, purposeId);
  };

  return restrictions.map(restriction => ({
    ...restriction,
    purposeDescription: getPurposeDescription(restriction.purposeId),
    restrictionDescription: getRestrictionDescription(restriction),
  }));
};
