import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNoticeConfig } from '@types';

/**
 * Fetches the list of default consent notices for the organization
 * (There is only one default notice per platform)
 * */
export const useConsentNoticesDefaults = (options?: UseQueryOptions<PaginatedResponse<ConsentNoticeConfig>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchConsentNoticesDefaults = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeConfig>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/configs?organization_id=${organizationId}&$translations=true&$with[]=notice&$limit=5000&default=true&deployed_at=null`,
    );

    return response.data;
  };

  return useQuery<PaginatedResponse<ConsentNoticeConfig>, AxiosError>(['consent-notices-defaults', organizationId], fetchConsentNoticesDefaults, options);
};
