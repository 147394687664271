import React, { createContext, useEffect, useMemo, useState } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { DidomiNavTabHeading, DidomiNavTabHeader, DidomiNavTabHeaders, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies, useSnackbar } from '@didomi/utility-react';
import { tx } from '@twind/core';
import { Outlet, NavLink, useNavigate, useParams, useLocation, useOutletContext } from 'react-router-dom';
import { HeaderInput, PageHeader, PlatformSelectionMenu, TcfMigrationWidget } from '@components';
import {
  useConsentNotice,
  useLocalConsentNotice,
  useLocalConsentNoticeConfig,
  useEditConsentNotice,
  useEditLocalConsentNotice,
  useEditConsentNoticeConfig,
  useHasNoticeChanged,
  useLocalRegulationConfig,
  useRegulationSelectionMetaInfo,
} from '@hooks';
import { LoadingModal, SelectAtLeastAPurposeOrVendorModal } from '@modals';
import { ConsentNoticeConfig, EditPageOutletContext } from '@types';
import { ACCESS_POLICIES_CONFIG, isRegulationEnabled } from '@utils';

export const NoticeLayoutContext = createContext<{ loading: boolean; saving: boolean; saveNoticeChanges: () => Promise<boolean> }>(null);

interface IMainLayoutProps {
  backPath?: string;
  backText?: string;
  onGoBack?: () => void;
  onNoticeTitleUpdated?: () => string;
}

// TODO: Add isLoading to Secondary header title
/**
 * Layout for a notice view
 * The layout takes care of the general layout but specific for the notice
 */
export const NoticeLayout = ({ backPath = '/', backText = 'Back to Consent notice list', onGoBack }: IMainLayoutProps): JSX.Element => {
  const navigate = useNavigate();
  let { noticeId } = useParams();
  const location = useLocation();
  const { displaySnackbar } = useSnackbar();
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const isCustomizationTab = location?.pathname?.includes('/customize');
  const isPublishTab = location?.pathname?.includes('/publish');

  const { data: notice, isLoading: loadingNotice } = useConsentNotice(noticeId, {
    onSuccess: noticeData => setConsentNoticeName(noticeData.name),
  });
  const { data: localConsentNotice } = useLocalConsentNotice(noticeId);
  const { data: localConsentNoticeConfig, isLoading: loadingLocalNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const loading = loadingNotice || loadingLocalNoticeConfig;

  const [consentNoticeName, setConsentNoticeName] = useState(notice?.name || 'Loading...');
  const [showInvalidRegulationModal, setShowInvalidRegulationModal] = useState(false);

  const { mutateAsync: updateConsentNoticeLocal } = useEditLocalConsentNotice(noticeId);

  const { mutateAsync: updateConsentNotice, isLoading: savingNotice } = useEditConsentNotice(noticeId);

  const { mutateAsync: updateConsentNoticeConfig, isLoading: savingNoticeConfig } = useEditConsentNoticeConfig();

  const { hasNoticeChanged, hasNoticeConfigChanged } = useHasNoticeChanged(noticeId);

  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);
  const { countsPerRegulation } = useRegulationSelectionMetaInfo(noticeId);
  const { openPreview } = useOutletContext<EditPageOutletContext>();

  const saving = savingNotice || savingNoticeConfig;

  const saveNoticeChanges = async () => {
    try {
      if (!validateRegulationConfig()) {
        return;
      }
      if (hasNoticeConfigChanged) {
        await updateConsentNoticeConfig(localConsentNoticeConfig);
      }
      if (hasNoticeChanged) {
        await updateConsentNotice(localConsentNotice);
      }

      displaySnackbar(`${localConsentNotice.name} has been updated`, { icon: 'success-small' });
      return true;
    } catch (error) {
      displaySnackbar('There was an error updating the notice', { variant: 'error' });
      return false;
    }
  };

  const saveConfigTcfMigration = async (updatedConfig: ConsentNoticeConfig) => {
    try {
      await updateConsentNoticeConfig(updatedConfig);
      displaySnackbar(`${localConsentNotice.name} has been updated`, { icon: 'success-small' });
      return true;
    } catch (error) {
      displaySnackbar(error?.response?.data?.message || 'There was an error updating the notice', { variant: 'error' });
      return false;
    }
  };

  // Get counts for active invalid regulations(no purposes + no vendors)
  const invalidRegulations = useMemo(() => {
    return Object.entries(countsPerRegulation).filter(
      ([regulationId, { vendorsCount, purposesCount }]) => activeRegulationIds.includes(regulationId as Regulation) && purposesCount + vendorsCount === 0,
    );
  }, [countsPerRegulation, activeRegulationIds]);

  // Check if there are invalid regulations - if so, prevent the user from navigating to Customization or Publish tabs
  const validateRegulationConfig = (e = undefined) => {
    if (invalidRegulations.length) {
      e?.preventDefault();
      setShowInvalidRegulationModal(true);
      return false;
    }
    return true;
  };

  const hasActiveRegulations = useMemo(() => {
    return localConsentNoticeConfig?.regulation_configurations?.some(isRegulationEnabled);
  }, [localConsentNoticeConfig]);

  useEffect(() => {
    // If user is on /customize or /publish tab and there are no active regulations, redirect to Regulations tab
    if (localConsentNoticeConfig && !hasActiveRegulations && (isCustomizationTab || isPublishTab)) {
      navigate(`/${noticeId}`);
    }
  }, [hasActiveRegulations, localConsentNoticeConfig, isCustomizationTab, isPublishTab, navigate, noticeId]);

  return (
    <section className="p-l pb-m min-h-full box-border !flex flex-col">
      <DidomiSkeleton data-testid="notice-skeleton" isLoading={loading} variant="layout" className="flex-1 flex flex-col">
        <PageHeader
          isLoading={loading}
          titleContent={
            <div className="flex gap-xxxs items-center w-[296px]">
              <HeaderInput
                data-cy="notice-name"
                data-testid="notice-name"
                maxCharsInRow={24}
                maxlength={255}
                value={consentNoticeName || ''}
                onValueChange={ev => {
                  setConsentNoticeName(ev.detail);
                  updateConsentNoticeLocal({ name: ev.detail });
                }}
                icon="edit"
                tooltipContent="Edit name"
                className={tx('font-bold', { 'min-w-[250px] h-10': loading })}
                disabled={!isCMPEditor}
                data-skeleton
              />
            </div>
          }
          description={
            <div className="text-tooltip text-primary-blue-6 flex gap-xxs items-center leading-4">
              <PlatformSelectionMenu />
            </div>
          }
          backText={backText}
          saveDisabled={loading || (!hasNoticeChanged && !hasNoticeConfigChanged) || !hasActiveRegulations}
          saveVisible={isCMPEditor}
          onPreview={openPreview}
          onSave={saveNoticeChanges}
          onCancel={() => {
            if (onGoBack) {
              onGoBack();
            }
            if (backPath) {
              navigate(backPath);
            }
          }}
        />
        <div className="w-full flex flex-col gap-m flex-grow pt-[32px]">
          <DidomiNavTabHeading className={tx({ '!border-0': loading })} variant="flow" align="center">
            <DidomiNavTabHeaders>
              <DidomiNavTabHeader disabled={loading}>
                <NavLink
                  data-testid="nav-link-regulations"
                  className={() => (isPublishTab || isCustomizationTab ? '' : 'didomi-active-tab')}
                  data-cy="regulations"
                  to={`/${noticeId}`}
                >
                  1. Regulations
                </NavLink>
              </DidomiNavTabHeader>
              <DidomiNavTabHeader disabled={loading || !hasActiveRegulations}>
                <NavLink
                  data-testid="nav-link-customization"
                  className={() => (isCustomizationTab ? 'didomi-active-tab' : '')}
                  data-cy="customization"
                  to={`/${noticeId}/customize`}
                  onClick={validateRegulationConfig}
                >
                  2. Customization
                </NavLink>
              </DidomiNavTabHeader>
              <DidomiNavTabHeader disabled={loading || !hasActiveRegulations}>
                <NavLink
                  data-testid="nav-link-publish"
                  className={() => (isPublishTab ? 'didomi-active-tab' : '')}
                  data-cy="publish"
                  to={`/${noticeId}/publish`}
                  onClick={validateRegulationConfig}
                >
                  3. Publish
                </NavLink>
              </DidomiNavTabHeader>
            </DidomiNavTabHeaders>
          </DidomiNavTabHeading>
          <div className="w-full flex flex-col flex-grow">
            <NoticeLayoutContext.Provider value={{ saveNoticeChanges: saveNoticeChanges, loading, saving }}>
              <Outlet />
            </NoticeLayoutContext.Provider>
          </div>
        </div>
      </DidomiSkeleton>

      {/* Modals */}
      <LoadingModal isOpen={saving} title="Updating the notice..." />
      {isCMPEditor && <TcfMigrationWidget noticeId={noticeId} onMigrationSave={saveConfigTcfMigration} />}
      <SelectAtLeastAPurposeOrVendorModal isOpen={showInvalidRegulationModal} invalidRegulations={invalidRegulations} onClose={() => setShowInvalidRegulationModal(false)} />
    </section>
  );
};
