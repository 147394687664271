import { useCustomFeature } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNotice, ConsentNoticeTemplateConfig } from '@types';
import { sanitizeObject } from '@utils';

/**
 * Edits a template config, makes sure to update the list of templates with the edited template value
 * */
export const useEditConsentNoticeTemplateConfig = (options?: UseMutationOptions<AxiosResponse<ConsentNoticeTemplateConfig>, AxiosError, Partial<ConsentNoticeTemplateConfig>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const queryClient = useQueryClient();
  const [useSanitisation] = useCustomFeature('consent-notice-sanitize-xss');

  return useMutation(
    async (templateConfig: Partial<ConsentNoticeTemplateConfig>) => {
      const noticeConfigBody = useSanitisation ? sanitizeObject(templateConfig) : templateConfig;
      return axiosWithInterceptors.patch(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/configs/${templateConfig?.id}?$translations=true`, noticeConfigBody);
    },
    {
      ...options,
      onSuccess: (response: AxiosResponse<ConsentNoticeTemplateConfig>, variables, context) => {
        const templateConfig = response.data;

        const templateConfigGet: ConsentNotice = queryClient.getQueryData(['consent-notice-template-config', templateConfig.template_id]);

        /* istanbul ignore if */
        if (templateConfigGet) {
          queryClient.setQueryData(['consent-notice-template-config', templateConfig.template_id], templateConfig);
        }

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    },
  );
};
