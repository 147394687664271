import { useState } from 'react';
import { AxiosError } from 'axios';
import { useQueryClient } from 'react-query';
import { ConsentNoticeConfig, ConsentNoticeWithTemplateMetaData } from '@types';
import { updateNoticeWithTemplatesMetadataDeploymentCache } from '@utils';
import { useEditConsentNoticeConfig } from './useEditConsentNoticeConfig.hook';
import { useDeployConsentNotice } from '../consent-notice-deploys/useDeployConsentNotice.hook';

interface ApplyTemplateParams {
  templateId: string;
  selectedRegConfigIdsPerNotice: Record<string, string[]>;
  notices: ConsentNoticeWithTemplateMetaData[];
  publishingOptions?: { message: string };
}

export const useApplyTemplateToNotices = ({ onError, onSuccess }: { onError?: (error: AxiosError<{ message: string }>) => void; onSuccess?: () => void }) => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateNoticeConfig, isLoading: isUpdatingConfig } = useEditConsentNoticeConfig();
  const { mutateAsync: deployNotice, isLoading: isDeployingNotice } = useDeployConsentNotice({
    onSuccess: ({ data }) => updateNoticeWithTemplatesMetadataDeploymentCache(queryClient, data),
  });
  const [linkingProgressState, setLinkingProgressState] = useState({ processed: 0, total: 0 });

  const getNoticeConfigPayload = (templateId: string, notice: ConsentNoticeWithTemplateMetaData, regConfigIds: string[]) => {
    const regulationsConfigs = regConfigIds.map(id => ({ id, template_id: templateId }));
    return { id: notice.config.id, notice_id: notice.id, regulation_configurations: regulationsConfigs } as ConsentNoticeConfig;
  };

  const unlinkRegulationsFromNotice = async ({ notice, regConfigIdsToUnlink }: { notice: ConsentNoticeWithTemplateMetaData; regConfigIdsToUnlink: string[] }) => {
    // To unlink template from notice - set the regulation config `template_id` field to null
    const configPayload = getNoticeConfigPayload(null, notice, regConfigIdsToUnlink);

    try {
      await updateNoticeConfig(configPayload);
      onSuccess();
    } catch (error) {
      onError(error);
    }
  };

  const applyTemplateToNotices = async ({ templateId, selectedRegConfigIdsPerNotice, notices, publishingOptions }: ApplyTemplateParams) => {
    // Get notices ids that have regulations selected - in order to update their config by assigning the template id to the selected regulations
    const noticesIdsToUpdate = Object.keys(selectedRegConfigIdsPerNotice).filter(noticeId => selectedRegConfigIdsPerNotice[noticeId].length > 0);
    const noticesToUpdate = notices.filter(notice => noticesIdsToUpdate.includes(notice.id));

    // We are using a scoped to function state to track the progress as React state might not be updated in time
    const scopedState = { processed: 0, total: noticesToUpdate.length };
    setLinkingProgressState(scopedState);

    try {
      for (const notice of noticesToUpdate) {
        const configPayload = getNoticeConfigPayload(templateId, notice, selectedRegConfigIdsPerNotice[notice.id]);
        setLinkingProgressState({ processed: ++scopedState.processed, total: scopedState.total });
        await updateNoticeConfig(configPayload);

        if (publishingOptions) {
          await deployNotice({ noticeConfig: configPayload, message: publishingOptions.message });
        }
      }
      onSuccess();
    } catch (error) {
      onError(error);
    } finally {
      // reset progress state
      setLinkingProgressState({ processed: 0, total: 0 });
    }
  };

  return {
    linkingProgressState,
    isApplyingTemplate: (isUpdatingConfig || isDeployingNotice) && linkingProgressState.total > 0,
    isUnlinkingRegulations: isUpdatingConfig && linkingProgressState.total === 0,
    applyTemplateToNotices,
    unlinkRegulationsFromNotice,
  };
};
