export const NOTICE_FORMATS = {
  web: [
    {
      dataTestid: 'bottom-banner',
      iconDefault: 'bottom-banner-disabled',
      iconSelected: 'bottom-banner',
      value: 'bottom',
      label: 'Bottom banner',
    },
    {
      dataTestid: 'top-banner',
      iconDefault: 'top-banner-disabled',
      iconSelected: 'top-banner',
      value: 'top',
      label: 'Top banner',
    },
    {
      dataTestid: 'popin',
      iconDefault: 'popin-disabled',
      iconSelected: 'popin',
      value: 'popup',
      label: 'Pop in',
    },
  ],
  amp: [
    {
      dataTestid: 'bottom-banner',
      iconDefault: 'amp-bottom-banner-disabled',
      iconSelected: 'amp-bottom-banner',
      value: 'bottom',
      disabled: true,
      label: 'Bottom banner',
    },
  ],
  app: [
    {
      dataTestid: 'bottom-banner',
      iconDefault: 'mobile-bottom-banner-disabled',
      iconSelected: 'mobile-bottom-banner',
      value: 'bottom',
      label: 'Bottom banner',
    },
    {
      dataTestid: 'popin',
      iconDefault: 'mobile-popin-disabled',
      iconSelected: 'mobile-popin',
      value: 'popup',
      label: 'Pop in',
    },
    {
      dataTestid: 'fullscreen',
      iconDefault: 'mobile-fullscreen-disabled',
      iconSelected: 'mobile-fullscreen',
      value: 'fullscreen',
      label: 'Fullscreen',
    },
  ],
};
