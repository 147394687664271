import { RegulationConfigDefaults, Regulation } from '@didomi/cmp-generator';
import { SPIRegulationSettings } from '@didomi/cmp-generator/dist/types/types/spi-regulations/spi-regulation-settings.type';
import { ConsentNoticeConfigRegulation, RegulationState } from '@types';
import { geoLocationTransform } from './geoLocationTransform';

// Update disabled_at field for the default regulation(and its descendants) that is being selected/unselected
// If it is selected - add default geos to the default regulation
const updateRegulationEnablement = (regulationId: string, isSelected: boolean, defaultGeosToAdd: string[]) => (regulationConfig: ConsentNoticeConfigRegulation) => {
  if (regulationId === regulationConfig.regulation_id) {
    return {
      ...regulationConfig,
      disabled_at: isSelected ? null : new Date().toISOString(),
      ...(isSelected && regulationConfig.is_default_regulation_config ? { geo_locations: defaultGeosToAdd } : undefined),
    };
  } else {
    return regulationConfig;
  }
};

// Geos overwrite - remove geos from other default regulations as they are added to the newly-selected/re-enabled one
const getRegulationWithFilteredGeos =
  (geosToFilterOut: string[]) =>
  (regulationConfig: ConsentNoticeConfigRegulation): ConsentNoticeConfigRegulation => {
    return regulationConfig.is_default_regulation_config
      ? { ...regulationConfig, geo_locations: regulationConfig.geo_locations.filter(geo => !geosToFilterOut.includes(geo)) }
      : regulationConfig;
  };

export const getRegulationConfigsAfterRegulationSelection = (
  regulationConfigs: ConsentNoticeConfigRegulation[],
  regulationId: Regulation,
  isRegulationSelected: boolean,
  defaultGeosToAdd: string[],
): ConsentNoticeConfigRegulation[] => {
  const wasRegulationAddedBefore = !!regulationConfigs.find(({ regulation_id }) => regulation_id === regulationId);

  if (wasRegulationAddedBefore) {
    let updatedRegulationConfigs = regulationConfigs;
    if (isRegulationSelected) {
      updatedRegulationConfigs = updatedRegulationConfigs.map(getRegulationWithFilteredGeos(defaultGeosToAdd));
    }
    return updatedRegulationConfigs.map(updateRegulationEnablement(regulationId, isRegulationSelected, defaultGeosToAdd));
  } else {
    const regulationToAdd: ConsentNoticeConfigRegulation = {
      regulation_id: regulationId,
      is_default_regulation_config: true,
      geo_locations: defaultGeosToAdd,
      disabled_at: null,
      config: RegulationConfigDefaults.getDefaultRegulationConfig(regulationId as Regulation),
      regulation_settings: RegulationConfigDefaults.getDefaultRegulationSettings(regulationId as Regulation) as SPIRegulationSettings,
    };

    return regulationConfigs.map(getRegulationWithFilteredGeos(defaultGeosToAdd)).concat([regulationToAdd]);
  }
};

export const isRegulationUsedOverlappingATerritory = (regulationConfigs: ConsentNoticeConfigRegulation[], regulation: RegulationState): boolean => {
  const { id: regulationId, geos } = regulation;

  const geosToAdd = geos.map(geoLocationTransform);

  return regulationConfigs
    .filter(regConfig => regConfig.regulation_id !== regulationId)
    .some((regulationConfig: ConsentNoticeConfigRegulation) => geosToAdd.some(geo => regulationConfig.geo_locations.includes(geo)));
};
