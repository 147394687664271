import React, { useMemo, useState } from 'react';
import { DidomiCheckbox } from '@didomi/ui-atoms-react';
import { UiMapContinentSubdivision, UiMapTerritory } from '@utils';

const MAX_VISIBLE_TERRITORIES = 3;

type RegulationsMapSubdivisionProps = {
  subdivision: UiMapContinentSubdivision;
  disabled?: boolean;
  isAppliedToRemaining?: boolean;
  selectedSubdivisions: Record<string, boolean>;
  disabledSubdivisions: Record<string, boolean>;
  selectedTerritories: Record<string, boolean>;
  disabledTerritories: Record<string, boolean>;
  onSubdivisionSelection?: (subdivision: UiMapContinentSubdivision, isSelected: boolean) => void;
  onTerritorySelection?: (territories: UiMapTerritory[], isSelected: boolean) => void;
};

export const RegulationsMapSubdivision = ({
  subdivision,
  disabled,
  isAppliedToRemaining,
  selectedSubdivisions,
  disabledSubdivisions,
  selectedTerritories,
  disabledTerritories,
  onSubdivisionSelection,
  onTerritorySelection,
}: RegulationsMapSubdivisionProps): JSX.Element => {
  const [isExtraTerritoriesExpanded, setIsExtraTerritoriesExpanded] = useState(false);

  const territoriesChunks = useMemo(() => {
    return {
      initial: subdivision.territories.slice(0, MAX_VISIBLE_TERRITORIES),
      extra: subdivision.territories.slice(MAX_VISIBLE_TERRITORIES),
    };
  }, [subdivision]);

  return (
    <div>
      <DidomiCheckbox
        className="mb-xs"
        label={subdivision.name}
        checkboxId={subdivision.id}
        indeterminate={subdivision.territories.some(t => selectedTerritories[t.id])}
        checked={selectedSubdivisions[subdivision.id] || isAppliedToRemaining}
        disabled={disabled || disabledSubdivisions[subdivision.id] || isAppliedToRemaining}
        hideErrorMessage
        data-testid={subdivision.id + '-checkbox'}
        onValueChange={() => onSubdivisionSelection(subdivision, !selectedSubdivisions[subdivision.id])}
      />

      {/* Territories */}
      {!!subdivision.territories.length && (
        <div className="pl-xs pt-xxxs border-l-1 border-solid border-neutral-gray-4 mb-xs ml-[10px]">
          <div className="flex flex-wrap xl:flex-col gap-xs" data-testid={subdivision.id + '-initial-list'}>
            {territoriesChunks.initial.map(territory => (
              <DidomiCheckbox
                data-testid={territory.id + '-checkbox'}
                key={territory.id}
                label={territory.name}
                checkboxId={territory.id}
                checked={selectedTerritories[territory.id] || isAppliedToRemaining}
                disabled={disabled || disabledTerritories[territory.id] || isAppliedToRemaining}
                onValueChange={e => onTerritorySelection([territory], e.detail)}
                hideErrorMessage
              />
            ))}
          </div>

          {!!territoriesChunks.extra.length && (
            <>
              {/* TODO: Use collapsable to apply an animation when expanding/contracting.
                 It was removed since invisible elements were taking up vertical space. */}
              {isExtraTerritoriesExpanded && (
                <div className="flex flex-wrap xl:flex-col gap-xs mt-xs" data-testid={subdivision.id + '-extra-list'}>
                  {territoriesChunks.extra.map(territory => (
                    <DidomiCheckbox
                      key={territory.id}
                      className="relative" // Use 'relative' to prevent absolutely positioned input to cause page overflow
                      label={territory.name}
                      checkboxId={territory.id}
                      checked={selectedTerritories[territory.id] || isAppliedToRemaining}
                      disabled={disabled || disabledTerritories[territory.id] || isAppliedToRemaining}
                      onValueChange={e => onTerritorySelection([territory], e.detail)}
                      hideErrorMessage
                    />
                  ))}
                </div>
              )}

              <button
                type="button"
                className="block mt-xs text-body-extra-small text-primary-blue-4 underline !outline-none focus-visible:(ring-[3px] !ring-primary-blue-2)"
                onClick={() => setIsExtraTerritoriesExpanded(!isExtraTerritoriesExpanded)}
              >
                {isExtraTerritoriesExpanded ? 'See less' : 'See all'}
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};
