import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiLoader } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmArchiveConsentNoticeModalProps = {
  noticeName: string;
  isOpen?: boolean;
  isLoading?: boolean;
  onCancel?: () => void;
  onProceed?: () => void;
};

/**
 * Confirm before archiving a consent notice
 */
export const ConfirmArchiveConsentNoticeModal = ({ noticeName, isOpen, isLoading, onCancel, onProceed }: ConfirmArchiveConsentNoticeModalProps): JSX.Element => {
  /* istanbul ignore next */
  const handleOnChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal isOpen={isOpen} onOpenChange={handleOnChange}>
      <DidomiIllustration name="warning" style={{ marginBottom: 'var(--space-m)' }} />
      <DidomiModalHeader
        variant="warning"
        modalSubTitle="Please pay attention"
        modalTitle={`Archive ${noticeName}`}
        modalDescription="Archiving a notice will prevent you from modifying or publishing. You will be able to un-archive a notice if you need to."
      ></DidomiModalHeader>

      <DidomiModalActions className={tx('!pt-5')}>
        <div>
          {isLoading && (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} data-testid="loading-notice-archive">
              <DidomiLoader />
            </div>
          )}
          {!isLoading && (
            <DidomiButtonGroup>
              <DidomiButton variant="secondary" onClick={onCancel}>
                Oops, no thanks
              </DidomiButton>
              <DidomiButton onClick={onProceed} iconLeft="archive">
                Archive
              </DidomiButton>
            </DidomiButtonGroup>
          )}
        </div>
      </DidomiModalActions>
    </DidomiModal>
  );
};
