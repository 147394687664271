import React from 'react';
import { getCounterText, getTranslatedValue } from '@didomi/helpers';
import { DidomiChip, DidomiTooltip, DidomiIconButton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { VendorsSpiGroup } from '@types';

interface VendorsSpiCardProps {
  className?: string;
  vendorsSpiGroup: VendorsSpiGroup;
  withRegulations: boolean;
  onSeeVendorsClicked: () => void;
}

export const VendorsSpiCard = ({ className, vendorsSpiGroup, withRegulations, onSeeVendorsClicked }: VendorsSpiCardProps) => {
  const vendorsText = getCounterText(vendorsSpiGroup.vendorsNames.length, 'vendor asks for', 'vendors ask for');

  return (
    <div className={tx(className, 'flex gap-xs p-xs bg-white rounded')}>
      <DidomiChip variation="basic-outline" label={vendorsText} />
      <div className="text-primary-blue-6 mr-auto">
        <span className="mr-auto text-body-small">{getTranslatedValue(vendorsSpiGroup.description)}</span>
        {withRegulations && <div className="mt-xxs uppercase font-semibold text-body-extra-small">{vendorsSpiGroup.regulationsId.join(' - ')}</div>}
      </div>

      <DidomiTooltip className="self-center " content="See vendors">
        <DidomiIconButton icon="preview" variant="rounded-no-border" aria-label="See vendors" onClick={onSeeVendorsClicked} />
      </DidomiTooltip>
    </div>
  );
};
