import React from 'react';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent, DidomiHintbox } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';

type ConfirmConsentStringActivationModalProps = {
  isOpen: boolean;
  noticeName: string;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ConfirmConsentStringActivationModal = ({ isOpen, noticeName, onCancel, onConfirm }: ConfirmConsentStringActivationModalProps) => {
  /* istanbul ignore next */
  const handleOpenChange = e => {
    if (isOpen && !e.detail) {
      onCancel();
    }
  };

  return (
    <DidomiModal variant="slim" isOpen={isOpen} closable="true" onOpenChange={handleOpenChange}>
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle="Important" />

      <DidomiModalContent className={tx('w-full text-primary-blue-6')}>
        <div className={tx('w-full')}>
          <h3 className={tx('text-h3 mb-xs font-serif')}>Didomi Consent String activation</h3>

          <p className={tx('mb-s text-body-small')}>
            You are about to enable the Didomi Consent String for <strong>{noticeName}</strong> notice. Consent re-collection will be triggered upon publishing and the user status
            will be stored in a binary format.
          </p>

          <DidomiHintbox titleText="Update your code:" className={tx('text-left w-full')}>
            If your code uses the JSON-formatted didomi_token cookie or local storage item, please ensure you update your code to parse the new binary format of the Didomi Consent
            String, as explained in{' '}
            <a href="https://developers.didomi.io/cmp/web-sdk/consent-notice/didomi-consent-string" className={tx('underline')} target="_blank" rel="noreferrer">
              our developer documentation.
            </a>
          </DidomiHintbox>
        </div>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Continue</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
