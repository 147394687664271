import React from 'react';
import { Platform } from '@didomi/cmp-generator';
import { DidomiButton, DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButtonGroup, DidomiModalContent } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { CONSENT_NOTICES_PLATFORMS_DESC } from '@types';

type ConfirmPlatformChangeModalProps = {
  platform: Platform;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
};

/**
 * Modal to confirm the platform change
 */
export const ConfirmPlatformChangeModal = ({ platform, isOpen, onCancel, onConfirm }: ConfirmPlatformChangeModalProps) => {
  return (
    <DidomiModal isOpen={isOpen} permanent variant="slim">
      <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
      <DidomiModalHeader variant="warning" modalSubTitle={`You want to change the platform to: ${CONSENT_NOTICES_PLATFORMS_DESC.get(platform)?.title || '-'}`} />

      <DidomiModalContent>
        <p className={tx('text-primary-blue-6 text-body-small')}>
          By changing the platform some <strong className={tx('font-medium')}>of your settings can be different to suit the new platform.</strong>
          <br />
          Do you want to continue?
        </p>
      </DidomiModalContent>

      <DidomiModalActions>
        <DidomiButtonGroup>
          <DidomiButton variant="secondary" onClick={onCancel}>
            Cancel
          </DidomiButton>
          <DidomiButton onClick={onConfirm}>Yes, I do</DidomiButton>
        </DidomiButtonGroup>
      </DidomiModalActions>
    </DidomiModal>
  );
};
