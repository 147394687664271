import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiHintbox, DidomiToggleButton, DidomiToggleButtonOption } from '@didomi/ui-atoms-react';
import { useParams } from 'react-router-dom';
import { IntegrationSelectionSection, TagManagerSection } from '@components';
import { useLocalConsentNoticeConfig, useCustomizationStepOptions, useLocalRegulationConfig } from '@hooks';

const INFO_TEXT_PER_TAB = {
  'tag-manager': 'Tag manager settings are applied globally to all selected regulations.',
  advertising: 'Advertising setting is only available for GDPR.',
};

/**
 * Integrations tab
 */
export const Integrations = (): JSX.Element => {
  const { noticeId } = useParams();
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);
  const { data: localConsentNoticeConfig, isLoading: loadingConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const hasGDPR = activeRegulationIds?.includes('gdpr');

  // Show Tag Manager section only for web platform
  const isTagManagerAvailable = localConsentNoticeConfig?.platform === 'web';

  // Show Advertising section only if GDPR is selected
  const isAdvertisingAvailable = hasGDPR;

  const { integrationType: selectedIntegrationTab, setIntegrationType } = useCustomizationStepOptions({
    defaultIntegrationType: isTagManagerAvailable ? 'tag-manager' : 'advertising',
  });

  const infoText = INFO_TEXT_PER_TAB[selectedIntegrationTab];

  return (
    <div className="slidein w-full flex flex-col">
      <div className="flex gap-l justify-between">
        <div className="w-full">
          <div className="flex gap-xxs items-center mb-xs">
            <DidomiHintbox iconName="warning-blue" className="!py-3">
              <p className="text-primary-blue-6">{infoText}</p>
            </DidomiHintbox>
          </div>

          {loadingConsentNoticeConfig ? (
            <div className="w-full h-[56px] mb-xs" data-skeleton />
          ) : (
            <div className="h-xl bg-neutral-gray-1 border-1 border-solid border-neutral-gray-3 rounded-2xl w-full px-xxxs flex items-center justify-center mb-xs">
              <div className="py-xxs h-full ml-xs">
                <DidomiToggleButton className="mb-s max-w-max" value={selectedIntegrationTab} onValueChange={e => setIntegrationType(e.detail as 'advertising' | 'tag-manager')}>
                  {isTagManagerAvailable && <DidomiToggleButtonOption value="tag-manager">Tag Managers</DidomiToggleButtonOption>}
                  {isAdvertisingAvailable && <DidomiToggleButtonOption value="advertising">Advertising</DidomiToggleButtonOption>}
                </DidomiToggleButton>
              </div>
            </div>
          )}
          {selectedIntegrationTab === 'tag-manager' && isTagManagerAvailable && <TagManagerSection />}
          {selectedIntegrationTab === 'advertising' && isAdvertisingAvailable && <IntegrationSelectionSection />}
        </div>
        <div className="hidden xl:!flex items-center bg-marketplace-promotion min-w-[432px] w-[432px] h-[max-content] rounded-lg p-s">
          <div className="flex flex-col">
            <h3 className="h3-bold text-white mb-xs">Explore more integrations on the Marketplace</h3>
          </div>
          <img alt="" width={96} src={`${ASSETS_URL}/assets/illustrations/promote-marketplace.svg`} />
        </div>
      </div>
    </div>
  );
};
