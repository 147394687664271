import { useMemo } from 'react';
import { Platform } from '@didomi/cmp-generator';
import { useConsentNoticesDefaults } from '@hooks';

/**
 * Fetches the default notice for a particular platform
 * */
export const useConsentNoticesPlatformDefault = (platform: Platform) => {
  const { data: consentNoticesDefaults, ...fetchDefaults } = useConsentNoticesDefaults();

  const defaultNoticeForPlatform = useMemo(() => consentNoticesDefaults?.data?.find(defNot => defNot.platform === platform), [consentNoticesDefaults?.data, platform]);

  if (!platform) {
    return {
      data: undefined,
      isLoading: true,
    };
  }

  return {
    data: defaultNoticeForPlatform,
    ...fetchDefaults,
  };
};
