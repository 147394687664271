import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNoticeTemplate, ConsentNoticeTemplateConfig } from '@types';

interface DuplicateConsentNoticeTemplateParams {
  id: string;
  name: string;
}

/*
 * Hook to duplicate a consent notice template
 * 1. Fetch the template config by template id in order to duplicate it
 * 2. Create a new template with the same config and a generated name
 * 3. Add the new template to the cache - it is assumed that templates cache is already loaded
 */
export const useDuplicateConsentNoticeTemplate = (options?: UseMutationOptions<ConsentNoticeTemplate, AxiosError, DuplicateConsentNoticeTemplateParams>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  // Fetch the config of the template by id in order to duplicate it
  const getConfigByTemplateId = async (templateId: string) => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNoticeTemplateConfig>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates/configs?template_id=${templateId}&$translations=true`,
    );
    return response.data?.data?.[0];
  };

  // Create a copy of the template with the new name
  const createTemplateCopy = async (baseName: string, templateConfig: ConsentNoticeTemplateConfig) => {
    const requestBody = {
      name: `${baseName} - Copy`,
      organization_id: organizationId,
      config: templateConfig.config,
    };
    const response = await axiosWithInterceptors.post<ConsentNoticeTemplate>(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/templates`, requestBody);

    // Add notices_id manually as it is not returned by the API
    return { ...response.data, notices_id: [] };
  };

  const addTemplateToCache = (newConsentNoticeTemplate: ConsentNoticeTemplate) => {
    queryClient.setQueryData<PaginatedResponse<ConsentNoticeTemplate>>(['consent-notice-templates', organizationId], templatedData => ({
      ...templatedData,
      total: templatedData.total + 1,
      data: [...templatedData.data, newConsentNoticeTemplate],
    }));
  };

  return useMutation(
    async ({ id, name }: DuplicateConsentNoticeTemplateParams) => {
      const templateConfigToDuplicate = await getConfigByTemplateId(id);
      return createTemplateCopy(name, templateConfigToDuplicate);
    },
    {
      ...options,
      onSuccess: (newConsentNoticeTemplate: ConsentNoticeTemplate, variables, context) => {
        options?.onSuccess?.(newConsentNoticeTemplate, variables, context);
        addTemplateToCache(newConsentNoticeTemplate);
      },
    },
  );
};
