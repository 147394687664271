import React, { useState } from 'react';
import { DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButton, DidomiLoader, DidomiButtonGroup } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useBlocker } from '@hooks';

type BlockNavigationModalProps = {
  'data-cy'?: string;
  title: string;
  isBlock: boolean;
  subTitle: string;
  buttonArrangement: 'row' | 'col';
  discardAndProceedText: string;
  saveButtonText?: string;
  keepEditingText?: string;
  description?: string;
  whitelist?: RegExp[];
  isLoading: boolean;
  onSave?: () => Promise<boolean>;
  onDiscardChanges: () => void;
};

export const BlockNavigationModal = ({
  'data-cy': dataCy,
  isBlock,
  title,
  subTitle,
  whitelist,
  description = '',
  discardAndProceedText,
  keepEditingText,
  saveButtonText,
  buttonArrangement,
  isLoading,
  onSave,
  onDiscardChanges,
}: BlockNavigationModalProps) => {
  const [isSaving, setIsSaving] = useState(false);
  const { isBlocked, proceedWithNavigation, continueInThePage } = useBlocker({ isBlock, whitelist, isSaving });

  const trySave = async () => {
    setIsSaving(true);
    const saved = await onSave();

    if (saved) {
      proceedWithNavigation();
    } else {
      // cancel navigation to prevent data being lost
      continueInThePage();
    }
    setIsSaving(false);
  };

  const discardAndProceed = () => {
    onDiscardChanges();
    proceedWithNavigation();
  };

  return (
    <>
      <DidomiModal data-cy={dataCy} className={tx('relative')} isOpen={isBlocked} permanent variant="slim">
        <DidomiIllustration className={tx('mb-6')} name="warning-modal" />
        <DidomiModalHeader variant="warning" modalTitle={title} modalSubTitle={subTitle} modalDescription={description} />

        <DidomiModalActions>
          <div>
            {isLoading && (
              <div className={tx('flex justify-center')}>
                <DidomiLoader />
              </div>
            )}
            {!isLoading && (
              <DidomiButtonGroup>
                <div className={tx(`flex ${buttonArrangement === 'col' ? 'flex-col' : 'flex-row flex-wrap justify-center'}`)}>
                  <DidomiButton data-testid="discard-changes" fullWidth={buttonArrangement === 'col'} variant="secondary" disabled={isLoading} onClick={discardAndProceed}>
                    <div>{discardAndProceedText}</div>
                  </DidomiButton>

                  <div>
                    {keepEditingText && (
                      <DidomiButton
                        data-testid="keep-editing-changes"
                        fullWidth={buttonArrangement === 'col'}
                        variant={buttonArrangement === 'col' ? 'top' : 'main'}
                        onClick={continueInThePage}
                      >
                        <div>{keepEditingText}</div>
                      </DidomiButton>
                    )}
                  </div>

                  <div>
                    {saveButtonText && (
                      <DidomiButton
                        data-testid="save-unsaved-changes"
                        fullWidth={buttonArrangement === 'col'}
                        variant="top"
                        iconRight="save"
                        disabled={isLoading}
                        onClick={trySave}
                      >
                        <div>{saveButtonText}</div>
                      </DidomiButton>
                    )}
                  </div>
                </div>
              </DidomiButtonGroup>
            )}
          </div>
        </DidomiModalActions>
      </DidomiModal>
    </>
  );
};
