import React from 'react';
import { DidomiRadio } from '@didomi/ui-atoms-react';
import { NoticeClosingCard } from '@components';

type NoticeRadioOption = 'granular-consent' | 'toggle-button';

interface NoticeClosingRadioProps {
  image?: NoticeRadioOption;
  currentValue: string;
  value: string;
  'data-testid'?: string;
  children?: React.ReactNode;
}

export const NoticeClosingRadio = ({ image, 'data-testid': dataTestId, value, children, currentValue }: NoticeClosingRadioProps) => {
  return (
    <NoticeClosingCard isSelected={value === currentValue} image={image}>
      <span className="text-body-small text-primary-blue-6">{children}</span>
      <DidomiRadio data-testid={dataTestId} value={value} />
    </NoticeClosingCard>
  );
};
