import { ConsentNoticeStandardTextContent } from '@types';

/**
 * Get the latest content for a text ID
 * If no textId is provided, we pick the approved content with the highest content version
 *
 * @param {ConsentNoticeStandardTextContent[]} approvedStandardTextContents - The list of approved text contents
 * @param {id} string - The text content id - optional
 * @returns {ConsentNoticeStandardTextContent | null} - The correct text content
 */
export const getApprovedStandardText = (approvedStandardTextContents: ConsentNoticeStandardTextContent[], textId: string): ConsentNoticeStandardTextContent | null => {
  if (!approvedStandardTextContents?.length || !textId) {
    return null;
  }

  const approvedTexts = approvedStandardTextContents.filter(aSTC => aSTC.text_id === textId).sort((a, b) => b.content_version - a.content_version);

  return approvedTexts[0] || null;
};
