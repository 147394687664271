import { getTranslatedValue } from '@didomi/helpers';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { Purpose } from '@types';

const sortPurposesAlphabetically = (p1: Purpose, p2: Purpose) => {
  return getTranslatedValue(p1.description) < getTranslatedValue(p2.description) ? -1 : 1;
};

/**
 * Fetches the full list of purposes suitable for restriction creation
 *
 */
export const useRestrictionPurposes = (options?: UseQueryOptions<Purpose[]>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchPurposes = async () => {
    const response = await axiosWithInterceptors.get<Purpose[]>(`${CONFIG.environmentConfig.apiBaseUrl}metadata/purposes/restrictions`);

    return response.data.sort(sortPurposesAlphabetically);
  };

  return useQuery<Purpose[], AxiosError>('restriction-purposes', fetchPurposes, options);
};
