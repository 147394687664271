import React from 'react';
import { DidomiFeatureHeader, DidomiIcon, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { useCustomFeature, useSPARouter } from '@didomi/utility-react';
import { NavLink, Outlet } from 'react-router-dom';
import { InAppHelper, WhatIsAConsentNotice } from '@components';

interface IMainLayoutProps {
  loading?: boolean;
}

/**
 * Main Layout
 * Default layout with header
 */
export const MainLayout = ({ loading }: IMainLayoutProps): JSX.Element => {
  const { navigateTo } = useSPARouter();
  const [hasStandardTextFeature] = useCustomFeature('cmp-standard-text');

  return (
    <section className="p-l pb-unsafe-bottom-space min-h-full box-border !flex flex-col">
      <DidomiSkeleton isLoading={loading} variant="layout" className="flex-1 flex flex-col">
        <div className="flex mb-8">
          <DidomiFeatureHeader titleText="Consent Notices" className="w-full" style={{ display: 'block' }}>
            <div slot="description" data-cy="consent-notices-header">
              The Consent Notice module allows you to collect user consent on all your channels.
            </div>
            <div className="flex gap-xl" slot="actions" data-skeleton>
              {hasStandardTextFeature && (
                <button
                  className="flex items-center text-body-extra-small font-semibold text-left text-primary-blue-5 !outline-none focus:ring ring-primary-blue-2 rounded"
                  onClick={() => navigateTo('/standard-text')}
                >
                  Manage Standard text
                  <DidomiIcon name="edit-sm" className="text-lg ml-2" />
                </button>
              )}

              <NavLink data-testid="nav-link-vendors-list" className={`flex items-center text-body-extra-small font-semibold text-left text-primary-blue-5`} to={`/vendors-list/`}>
                Manage vendor lists
                <DidomiIcon name="required-sm" className="text-lg ml-2" />
              </NavLink>

              <InAppHelper subtitle="Good to know!" title="What is a Consent Notice?" icon="read">
                <WhatIsAConsentNotice />
              </InAppHelper>
            </div>
          </DidomiFeatureHeader>
        </div>
        <Outlet />
      </DidomiSkeleton>
    </section>
  );
};
