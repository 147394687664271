import React, { useState } from 'react';
import { getTranslatedValue } from '@didomi/helpers';
import { DidomiChip, DidomiCollapse } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { CardSelectionField } from '@components';
import { IabStackExtended } from '@types';
import { getCounterText } from '@utils';

type IabStackSelectionCardProps = {
  stack: IabStackExtended;
  isSelected: boolean;
  className?: string;
  onChange?: (e: CustomEvent<boolean>) => void;
};

export const IabStackSelectionCard = ({ stack, isSelected, className, onChange }: IabStackSelectionCardProps) => {
  const [isDetailsVisible, setIsDetailsVisible] = useState(false);

  return (
    <CardSelectionField className={className} labelText={getTranslatedValue(stack.name)} selected={isSelected} inputType="checkbox" onChange={onChange}>
      <div>
        <p className={tx('mb-2 pr-s')}>{getTranslatedValue(stack.description)}</p>

        <div className={tx('flex justify-between items-center')}>
          <button type="button" className={tx('block text-body-extra-small text-primary-blue-4 underline')} onClick={() => setIsDetailsVisible(!isDetailsVisible)}>
            {isDetailsVisible ? 'Hide details' : 'Show details'}
          </button>

          <DidomiChip label={getCounterText(stack.purposes_id.length, 'selected purpose')}></DidomiChip>
        </div>

        <DidomiCollapse isExpanded={isDetailsVisible}>
          <div className={tx('mt-3 bg-neutral-gray-1 p-4 flex gap-3 text-body-extra-small')}>
            <div className={tx('flex-1')}>
              <div className={tx('text-secondary-cobalt-blue-3 font-semibold')}>List of purposes ({stack.purposesDescriptions.length})</div>
              <ul>
                {stack.purposesDescriptions.map(purposeDescription => (
                  <li key={purposeDescription} className={tx('mt-1')}>
                    {purposeDescription}
                  </li>
                ))}
              </ul>
            </div>
            <div className={tx('flex-1')}>
              <div className={tx('text-secondary-cobalt-blue-3 font-semibold')}>List of special features ({stack.specialFeaturesDescriptions.length})</div>
              <ul>
                {stack.specialFeaturesDescriptions.map(specialFeatureDescription => (
                  <li key={specialFeatureDescription} className={tx('mt-1')}>
                    {specialFeatureDescription}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </DidomiCollapse>
      </div>
    </CardSelectionField>
  );
};
