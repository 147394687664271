import React, { useMemo, useState } from 'react';
import { SDKConfigAppIabStacks } from '@didomi/cmp-generator';
import { getTranslatedValue } from '@didomi/helpers';
import {
  DidomiButton,
  DidomiEmptyState,
  DidomiHintbox,
  DidomiIconButton,
  DidomiLoader,
  DidomiPaginator,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { CardSelectionField, ExpandableSection, ExpandableSectionToggle } from '@components';
import { usePaginatedFilteredItems, useTableState } from '@hooks';
import { AddIabStacksModal, ConfirmDeleteModal } from '@modals';
import { IabStackExtended, IabStacksSortableField } from '@types';

interface IabStacksProps {
  isLoading: boolean;
  noticeName: string;
  stacksConfig: SDKConfigAppIabStacks;
  stacks: IabStackExtended[];
  viewOnly?: boolean;
  onStacksConfigChange?: (config: SDKConfigAppIabStacks) => void;
}

export const IabStacks = ({ isLoading, noticeName, stacksConfig, stacks, viewOnly, onStacksConfigChange }: IabStacksProps) => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState({ isOpen: false, stack: null });
  const { displaySnackbar } = useSnackbar();

  const { limit, page, sortConfig, changeLimit, changePage, changeSorting } = useTableState<IabStacksSortableField>({
    defaultLimit: 5,
  });

  const filtersToApply = useMemo(() => [(stack: IabStackExtended) => stacksConfig.ids.includes(stack.id)], [stacksConfig]);

  const { data: displayedStacks, total: paginationTotal } = usePaginatedFilteredItems<IabStackExtended, IabStacksSortableField>(stacks, {
    limit,
    filters: filtersToApply,
    page,
    sortConfig,
  });

  const availableStacksForAdding = useMemo(() => stacks.filter(({ id }) => !stacksConfig.ids?.includes(id)), [stacks, stacksConfig]);

  const isEmpty = !stacks.length || !displayedStacks.length;
  const isEmptyStateVisible = !isLoading && isEmpty && !stacksConfig.auto;
  const isTableVisible = !isEmpty && !stacksConfig.auto;

  const handleAddStacks = (stackIds: string[]) => {
    const snackbarText = stackIds.length === 1 ? '1 stack has been added' : `${stackIds.length} stacks have been added`;

    onStacksConfigChange({ ...stacksConfig, ids: stacksConfig.ids.concat(stackIds) });
    setIsAddModalOpen(false);
    displaySnackbar(snackbarText, { icon: 'success-small' });
  };

  const handleDeleteConfirm = () => {
    const updatedIds = deleteModalData.stack ? stacksConfig.ids.filter(id => id !== deleteModalData.stack.id) : [];
    const snackbarText = deleteModalData.stack ? 'Stack has been deleted' : 'All stacks have been deleted';

    onStacksConfigChange({ ...stacksConfig, ids: updatedIds });
    setDeleteModalData({ isOpen: false, stack: null });
    displaySnackbar(snackbarText, { icon: 'success-small' });
  };

  return (
    <section>
      <ExpandableSection
        id="stacks-collapse"
        variant="sub-level"
        header={
          <div className="flex items-center gap-xs">
            <ExpandableSectionToggle a11yLabel="Group TCF purposes as stacks" />
            <h3 className="font-semibold text-h4 mr-auto">Group TCF purposes as stacks</h3>
            {!viewOnly && (
              <DidomiButton
                size="small"
                disabled={stacksConfig.auto}
                onClick={e => {
                  // Prevent toggling of the expandable section
                  e.stopPropagation();
                  setIsAddModalOpen(true);
                }}
              >
                Add stacks
              </DidomiButton>
            )}
          </div>
        }
      >
        <div className="flex flex-col mt-xs">
          <DidomiHintbox className="mb-m">
            <div className="flex gap-6">
              <div>
                <div className="text-h4 text-primary-blue-6 font-semibold mb-2">Configuring stacks</div>
                <div>
                  Version 2.2 of the TCF introduced the ability for publishers to group purposes in the consent notice under &quot;stacks&quot; to avoid displaying the full list of
                  purposes. Stacks are pre-defined by the IAB TCF and cannot be modified but you can freely decide what stack you want to display.
                </div>
              </div>

              <CardSelectionField
                className="w-[400px] flex-shrink-0"
                labelText="Enable automatic stacks"
                inputType="checkbox"
                selected={stacksConfig.auto}
                disabled={viewOnly}
                onChange={e => onStacksConfigChange({ auto: e.detail, ids: [] })}
              >
                This option generates a stack list for you. The list will be automatically updated and optimized to cover new TCF purposes.
              </CardSelectionField>
            </div>
          </DidomiHintbox>

          {isTableVisible && (
            <>
              <DidomiTable
                className="mb-4 bg-white"
                data-testid="iab-stacks-table"
                fixedLayout
                disabled={viewOnly}
                sortable
                sortBy={sortConfig.field}
                sortDirection={sortConfig.dir}
                onSortChange={changeSorting}
              >
                <DidomiTableHeading>
                  <DidomiTableHeaderRow>
                    <DidomiTableTh sortId="name" data-testid="stackName">
                      STACK
                    </DidomiTableTh>
                    <DidomiTableTh>PURPOSES</DidomiTableTh>

                    {/* Controls Column */}
                    <DidomiTableTh class="max-w-[170px]" cellAlign="right">
                      <div className="flex items-center">
                        <div>DELETE ALL</div>
                        <DidomiIconButton
                          type="button"
                          variant="rounded"
                          icon="delete"
                          aria-label="Delete all stacks"
                          onClick={() => setDeleteModalData({ isOpen: true, stack: null })}
                        />
                      </div>
                    </DidomiTableTh>
                  </DidomiTableHeaderRow>
                </DidomiTableHeading>

                <DidomiTableBody>
                  {displayedStacks.map(stack => (
                    <DidomiTableRow key={stack.id} data-testid={stack.id}>
                      <DidomiTableTd>
                        <div className="leading-normal">{getTranslatedValue(stack.name)}</div>
                      </DidomiTableTd>
                      <DidomiTableTd>
                        <div className="leading-normal">{stack.purposesDescriptions.concat(stack.specialFeaturesDescriptions).join(', ')}</div>
                      </DidomiTableTd>

                      {/* Controls */}
                      <DidomiTableTd class="max-w-[170px]" cellAlign="right">
                        <DidomiTooltip content="Delete" placement="top">
                          <DidomiIconButton aria-label="Delete stack" type="button" variant="rounded" icon="delete" onClick={() => setDeleteModalData({ isOpen: true, stack })} />
                        </DidomiTooltip>
                      </DidomiTableTd>
                    </DidomiTableRow>
                  ))}
                </DidomiTableBody>
              </DidomiTable>

              <DidomiPaginator
                data-testid="iab-stacks-paginator"
                className="self-end"
                page={page}
                itemCount={paginationTotal}
                size={limit}
                onPageSizeChange={changeLimit}
                onPageChange={changePage}
              />
            </>
          )}

          {/* Empty State */}
          {isEmptyStateVisible && (
            <DidomiEmptyState
              illustration-name="traces-no-result-found"
              actionName={viewOnly ? '' : 'Add stacks'}
              actionIconRight={viewOnly ? '' : 'new-create'}
              onActionClick={() => setIsAddModalOpen(true)}
            >
              <div slot="title">It&apos;s empty here!</div>
              You have no stacks selected. You can enable the automatic stacks option or manually add new ones.
            </DidomiEmptyState>
          )}
        </div>
      </ExpandableSection>

      {/* Loading State */}
      {isLoading && (
        <div className="w-full flex flex-col items-center justify-center">
          <DidomiLoader className="mb-xs" />
          <div className="text-body-normal text-primary-blue-5">Loading stacks</div>
        </div>
      )}

      {/* Modals */}
      <AddIabStacksModal
        isOpen={isAddModalOpen}
        availableStacks={availableStacksForAdding}
        noticeName={noticeName}
        onSave={handleAddStacks}
        onCancel={() => setIsAddModalOpen(false)}
      />

      <ConfirmDeleteModal
        isOpen={deleteModalData.isOpen}
        titleText={deleteModalData.stack ? 'Are you sure you want to delete this stack?' : 'Are you sure you want to delete all stacks?'}
        onCancel={() => setDeleteModalData({ isOpen: false, stack: null })}
        onConfirm={handleDeleteConfirm}
      >
        {deleteModalData.stack && (
          <div>
            You are about to delete stack <strong>&quot;{getTranslatedValue(deleteModalData.stack.name)}&quot;</strong>.
          </div>
        )}
      </ConfirmDeleteModal>
    </section>
  );
};
