import React, { lazy, Suspense, useContext } from 'react';
import {
  DidomiBottomBar,
  DidomiButton,
  DidomiLoader,
  DidomiTabHeader,
  DidomiTabHeaders,
  DidomiTabHeading,
  DidomiTabPanel,
  DidomiTabPanels,
  DidomiTabs,
} from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { useNavigate, useParams } from 'react-router-dom';
import { Regulations } from '@components';
import { useRegulationsOptions, useLocalRegulationConfig } from '@hooks';
import { NoticeLayoutContext } from '@layouts';
import { ACCESS_POLICIES_CONFIG } from '@utils';

/* istanbul ignore next */
const Territories = lazy(() => import('@components').then(module => ({ default: module.Territories })));

/* istanbul ignore next */
const ConsentSettings = lazy(() => import('@components').then(module => ({ default: module.ConsentSettings })));

/**
 * Consent Notice regulation page
 */
export const ConsentNoticeRegulation = (): JSX.Element => {
  const { noticeId } = useParams();
  const navigate = useNavigate();
  const { saveNoticeChanges, loading, saving } = useContext(NoticeLayoutContext);
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { regulationsStep, setRegulationsStep } = useRegulationsOptions();
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);

  const handleSave = async () => {
    const isSaved = await saveNoticeChanges();

    if (isSaved) {
      navigate('/' + noticeId + '/customize');
    }
  };

  const hasActiveRegulations = activeRegulationIds?.length;

  return (
    <>
      <DidomiTabs className="flex-grow" activeTab={regulationsStep} onActiveTabChange={e => setRegulationsStep(e.detail.active)}>
        <DidomiTabHeading variant="sub-level">
          <DidomiTabHeaders>
            <DidomiTabHeader name="Regulations" data-testid="regulations-tab"></DidomiTabHeader>
            <DidomiTabHeader name="Country/Region" disabled={!hasActiveRegulations} data-testid="region-tab"></DidomiTabHeader>
            <DidomiTabHeader name="Consent settings" disabled={!hasActiveRegulations} data-testid="consent-settings-tab"></DidomiTabHeader>
          </DidomiTabHeaders>
        </DidomiTabHeading>
        <DidomiTabPanels className="pt-[32px] !overflow-x-visible">
          <DidomiTabPanel>
            <Regulations />
          </DidomiTabPanel>
          <DidomiTabPanel>
            <Suspense
              fallback={
                <div className="h-full w-full flex items-center justify-center">
                  <DidomiLoader />
                </div>
              }
            >
              <Territories />
            </Suspense>
          </DidomiTabPanel>
          <DidomiTabPanel>
            <Suspense
              fallback={
                <div className="h-full w-full flex items-center justify-center">
                  <DidomiLoader />
                </div>
              }
            >
              <ConsentSettings />
            </Suspense>
          </DidomiTabPanel>
        </DidomiTabPanels>
      </DidomiTabs>

      {isCMPEditor && (
        <DidomiBottomBar data-testid="notice-bottom-bar" className="-mx-l -mb-m flex-shrink-0" isOpen={true} variant="light" closable={false}>
          <DidomiButton
            slot="actions"
            iconRight="right-on"
            disabled={loading || saving || !hasActiveRegulations}
            data-testid="notice-save-and-continue-button"
            onClick={handleSave}
          >
            Save & continue
          </DidomiButton>
        </DidomiBottomBar>
      )}
    </>
  );
};
