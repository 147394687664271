import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNotice } from '@types';

/**
 * Fetches the full list of consent notices. It fetches all notices (max 5000)
 * */
export const useConsentNotices = (options?: UseQueryOptions<PaginatedResponse<ConsentNotice>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  const fetchConsentNotices = async () => {
    const response = await axiosWithInterceptors.get<PaginatedResponse<ConsentNotice>>(
      `${CONFIG.environmentConfig.apiBaseUrl}widgets/notices?organization_id=${organizationId}&$with[]=config&$translations=true&$limit=5000`,
    );

    return response.data;
  };

  return useQuery<PaginatedResponse<ConsentNotice>, AxiosError>(['consent-notices', organizationId], fetchConsentNotices, options);
};
