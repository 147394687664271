import React from 'react';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { tx } from '@twind/core';

interface NoticeClosingCardProps {
  image: string;
  children?: React.ReactNode;
  isSelected: boolean;
}

export const NoticeClosingCard = ({ image, children, isSelected }: NoticeClosingCardProps) => {
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');
  return (
    <div className={tx('px-xs py-xxs bg-white rounded-lg flex items-center gap-xs transition duration-300', { '!bg-neutral-gray-2': isSelected })}>
      {image && <img width={80} height={80} src={`${ASSETS_URL}/assets/illustrations/notice-options/${image}.svg`} alt="" />}
      <>{children}</>
    </div>
  );
};
