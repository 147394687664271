import { ConsentNoticeWithTemplateMetaData } from '@types';

/**
 * Get the selection of regulations per notice for a specific template
 * Return an object with the notice id as key and the list of selected regulation ids as value: Record<noticeId, regulationIds[]>
 * [{ id: 'n1' , template_metadata: { gdpr: { template_id: 't1', regulation_config_id: 'r1' } }}] => { n1: ['r1'] }
 */
export const getTemplateRegulationSelectionPerNotice = (consentNotices: ConsentNoticeWithTemplateMetaData[], templateId: string): Record<string, string[]> => {
  return consentNotices.reduce<Record<string, string[]>>((acc, notice) => {
    // Iterate over regulations and add the regulation id to the list if it is linked to the template
    for (const regulationName in notice.template_metadata) {
      const regulationInfo = notice.template_metadata[regulationName];
      if (regulationInfo.template_id === templateId) {
        acc[notice.id] = (acc[notice.id] || []).concat([regulationInfo.regulation_config_id]);
      }
    }
    return acc;
  }, {});
};
