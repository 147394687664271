import React from 'react';
import { DidomiHintbox } from '@didomi/ui-atoms-react';
import { useLocalRegulationConfig } from '@hooks';
import { CustomizationRegulationOptions } from '../CustomizationRegulationOptions/CustomizationRegulationOptions';

interface CustomizationSpecificOptionsProps {
  noticeId: string;
}

export const CustomizationSpecificOptions = ({ noticeId }: CustomizationSpecificOptionsProps) => {
  const { activeDefaultRegulations } = useLocalRegulationConfig(noticeId);

  return (
    <>
      <DidomiHintbox iconName="warning-blue">
        <p className="text-primary-blue-6">All the settings that you can specifically manage by regulation. All changes are only applied to the selected regulation.</p>
      </DidomiHintbox>

      {activeDefaultRegulations.map(regulation => (
        <CustomizationRegulationOptions key={regulation.id} noticeId={noticeId} regulationId={regulation.regulation_id} />
      ))}
    </>
  );
};
