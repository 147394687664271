import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { ConsentNoticeDeployment, ConsentNoticeConfig } from '@types';
import { replaceByIdOrInsert } from '@utils';

interface DeployConsentNoticeParams {
  noticeConfig: Partial<ConsentNoticeConfig>;
  message: string;
}

/**
 * Deploys a notice
 * */
export const useDeployConsentNotice = (options?: UseMutationOptions<AxiosResponse<ConsentNoticeDeployment>, AxiosError, Partial<DeployConsentNoticeParams>>) => {
  const queryClient = useQueryClient();
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();

  return useMutation(
    async (deployConsentNoticeParams: DeployConsentNoticeParams) => {
      const { noticeConfig, message } = deployConsentNoticeParams;

      const requestBody = {
        message,
        notice_id: noticeConfig?.notice_id,
        organization_id: organizationId,
        production_config_id: noticeConfig?.id,
      };
      return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/deployments`, requestBody);
    },
    {
      ...options,
      onSuccess: async (response: AxiosResponse<ConsentNoticeDeployment>, variables, context) => {
        const deployment = response.data;
        // Deployment generates a new notice config, and updates Notice.deployed_at.

        if (queryClient.getQueryData(['consent-notices', organizationId])) {
          // Fetch the updated Notice and update Notice[] and Notice caches. (this is to avoid fetching ALL the notices again)
          const { data: noticeWithConfig } = await axiosWithInterceptors.get(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices/${deployment.notice_id}?$with[]=config`);
          queryClient.setQueryData(['consent-notice', deployment.notice_id], noticeWithConfig);
          queryClient.setQueryData(['consent-notice-local', deployment.notice_id], noticeWithConfig);
          replaceByIdOrInsert(queryClient, 'consent-notices', noticeWithConfig);
        } else {
          // We dont have cached Notice[], then clear the cached Notice for them to be reloaded.
          queryClient.removeQueries({ queryKey: ['consent-notice', deployment.notice_id] });
          queryClient.removeQueries({ queryKey: ['consent-notice-local', deployment.notice_id] });
        }

        queryClient.removeQueries({ queryKey: ['consent-notices-defaults', deployment.organization_id] });
        queryClient.removeQueries({ queryKey: ['consent-notice-config', deployment.production_config_id] });
        queryClient.removeQueries({ queryKey: ['consent-notice-config-local', deployment.production_config_id] });

        if (options?.onSuccess) {
          options.onSuccess(response, variables, context);
        }
      },
    },
  );
};
