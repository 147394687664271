import { useMemo } from 'react';
import { Regulations } from '@didomi/cmp-generator';
import { extractSpiFromVendors } from '@utils';
import { usePurposes } from './usePurposes.hook';
import { useVendors } from './useVendors.hook';

export const useSpiState = ({ selectedVendorsIds, regulationId, enabled }: { selectedVendorsIds: string[]; regulationId?: string; enabled: boolean }) => {
  const { data: vendors } = useVendors();
  const { data: purposes } = usePurposes();

  const spiGroups = useMemo(() => {
    if (!vendors || !purposes || !enabled) return [];

    const getPurpose = (id: string) => purposes.find(p => p.id === id);
    const allSelectedVendors = vendors.filter(({ id }) => selectedVendorsIds.includes(id));
    const selectedSpiIds = Array.from(extractSpiFromVendors(allSelectedVendors, purposes, regulationId as Regulations));

    const getSpiInfo = (spiPurposeId: string) => {
      const purpose = getPurpose(spiPurposeId);
      return {
        id: spiPurposeId,
        regulationsId: purpose.regulations_id,
        description: purpose.description,
        vendorsNames: allSelectedVendors.filter(v => v.spi_purposes_id.includes(spiPurposeId)).map(v => v.name),
      };
    };

    return selectedSpiIds.map(getSpiInfo);
  }, [selectedVendorsIds, vendors, purposes, regulationId, enabled]);

  return {
    spiGroups,
  };
};
