import React, { useEffect } from 'react';
import { connect } from '@didomi/ui-foundation';
import * as utility from '@didomi/utility';
import { useActiveOrganization, UtilityProvider } from '@didomi/utility-react';
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { RouterProvider } from 'react-router-dom';
// import { makeServer } from '@mocks';
import { ApiError, ERROR_CONFIG, reportSpaError } from '@utils';
import presetConsentNotice from './presetConsentNotice';
import { router } from './routes';

// Setup Twind for consent-notices only
connect('.consent-notices-root', { presets: [presetConsentNotice()] });

// Uncomment this line to use Mirage to mock the server requests
// makeServer();

// Handle queries errors
const queryCache = new QueryCache({
  onError: (error: Error) => reportSpaError(new ApiError(error), ERROR_CONFIG),
});

// Handle mutations errors
const mutationCache = new MutationCache({
  onError: (error: Error) => reportSpaError(new ApiError(error), ERROR_CONFIG),
});

const queryClient = new QueryClient({
  queryCache,
  mutationCache,
  defaultOptions: {
    queries: {
      retry: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      staleTime: Infinity,
    },
  },
});

const OrganizationRouterProvider = () => {
  const { organizationId } = useActiveOrganization();
  return <RouterProvider router={router({ basename: `/${organizationId}/consent-notices` })} />;
};

const Root = () => {
  useEffect(() => {
    // Clear the queryClient cache when the app is unmounted
    return () => queryClient.clear();
  }, []);

  return (
    <UtilityProvider utility={utility}>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools />
        <OrganizationRouterProvider />
      </QueryClientProvider>
    </UtilityProvider>
  );
};

export { Root };
