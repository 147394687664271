import React from 'react';
import { DidomiCheckbox, DidomiLargePush, DidomiLink, DidomiRadio, DidomiRadioGroup, DidomiSwitch } from '@didomi/ui-atoms-react';
import { useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useLocalConsentNoticeConfigFacade } from '@facade-hooks';
import { ACCESS_POLICIES_CONFIG } from '@utils';
import { IntegrationDescription } from '../IntegrationDescription/IntegrationDescription';

export const IntegrationGpp = () => {
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const { gpp, changeGppEnablement, changeGppMspaSignatory, changeGppMspaServiceProviderMode, changeGppMspaOptOutMode, changeGppMspaGenerateUsnatString } =
    useLocalConsentNoticeConfigFacade();

  const areMspaSubfieldsDisabled = !gpp.isEnabled || !gpp.isMspaSignatory;

  return (
    <SelectionCardCheckbox
      title="Global Privacy Platform Framework"
      data-testid="gpp"
      description={
        <IntegrationDescription
          compatibleWith={['GDPR', 'US']}
          content={
            <p>
              The Global Privacy Platform (GPP) streamlines compliance with regional privacy regulations for digital advertising by providing a flexible transport layer for user
              consent and preference signaling. It reduces costs, adapts to regional differences, and enhances transparency and control for users globally.
            </p>
          }
        />
      }
      illustration="integrations-iab"
      checked={gpp.isEnabled}
      disabled={!isCMPEditor}
      onValueChange={changeGppEnablement}
    >
      <DidomiCheckbox
        disabled={!gpp.isEnabled || !isCMPEditor}
        checked={gpp.isMspaSignatory}
        hideErrorMessage
        label="MSPA Signatory"
        onValueChange={e => changeGppMspaSignatory(e.detail)}
      />

      <div className="pl-m text-body-small">
        <p>To configure this MSPA settings, your organization must be MSPA signatory.</p>
        <DidomiLink className="block mb-xxs" isUnderlined href="#TODO" text="Read more about MSPA" />

        <ul className="list-disc list-inside">
          <li className="mb-xxs">
            <DidomiSwitch
              data-testid="mspa-service-provider-mode"
              valueSelected={true}
              valueNotSelected={false}
              value={gpp.isMspaServiceProviderMode}
              className="!w-auto"
              label-variant="no-box"
              hideErrorMessage
              disabled={areMspaSubfieldsDisabled || !isCMPEditor}
              onValueChange={e => changeGppMspaServiceProviderMode(e.detail)}
            >
              Service Provider Mode
            </DidomiSwitch>
          </li>
          <li className="mb-xxs">
            <DidomiSwitch
              data-testid="mspa-opt-out-mode"
              valueSelected={true}
              valueNotSelected={false}
              value={gpp.isMspaOptOutMode}
              className="!w-auto"
              label-variant="no-box"
              hideErrorMessage
              disabled={areMspaSubfieldsDisabled || !isCMPEditor}
              onValueChange={e => changeGppMspaOptOutMode(e.detail)}
            >
              Opt-Out Option Mode
            </DidomiSwitch>
          </li>
          <li>
            <span>US Regulation approach: Only MSPA signatories can use the US national section to encode the user&apos;s opt-out status.</span>
            <DidomiRadioGroup
              data-testid="mspa-generate-string-across-us"
              value={gpp.isMspaGenerateUsnatString ? 'across' : 'specific'}
              className="!block mt-xxs pl-xs"
              orientation="vertical"
              disabled={areMspaSubfieldsDisabled || !isCMPEditor}
              onValueChange={e => changeGppMspaGenerateUsnatString(e.detail === 'across')}
            >
              <DidomiRadio value="across">Generate national string across the United States</DidomiRadio>
              <DidomiRadio value="specific">Only generate national string when state specific string is not supported</DidomiRadio>
            </DidomiRadioGroup>
          </li>
        </ul>
      </div>

      <DidomiLargePush className="w-full" highlighted-text="Global Privacy Platform" text="Documentation" icon="export" href="#TODO" target="_blank" />
    </SelectionCardCheckbox>
  );
};
