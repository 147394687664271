import React, { MouseEvent } from 'react';
import { DidomiSwitch } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { useId } from 'react-id-generator'; // Replace this with default useId in React 18

type SelectableTitleProps = {
  className?: string;
  'data-testid'?: string;
  selected?: boolean;
  disabled?: boolean;
  title: string;
  onSelectionChanged?: (enabled: boolean) => void;
};

/**
 * SelectableTitle renders a title with an associated switch button.
 * The switch button can be toggled to enable or disable a specific feature or option.
 * @param {string} [className] - Optional additional class name to be applied to the container div element.
 * @param {string} [title] - The title to display in the switch button.
 * @param {string} [dataTestId] - Optional data-testid attribute to be applied to the switch button.
 * @param {boolean} [disabled] - A boolean flag indicating if the switch button is disabled or not. Default is false.
 * @param {boolean} [selected] - A boolean flag indicating if the switch button is initially selected or not. Default is false.
 * @param {(enabled: boolean) => void} [onSelectionChanged] - Optional callback function to be called when the switch button selection state changes.
 */
export const SelectableTitle = ({ className = '', title, 'data-testid': dataTestId, selected, disabled, onSelectionChanged }: SelectableTitleProps) => {
  const [switchTitleId] = useId(1, 'select-switch-');

  const handleSelectionChange = (event: MouseEvent) => {
    event.preventDefault();
    onSelectionChanged(!selected);
  };

  return (
    <div className={className}>
      <button
        className="leading-none !outline-none focus-visible:(ring-[3px] ring-primary-blue-2)"
        disabled={disabled}
        aria-labelledby={switchTitleId}
        onClick={handleSelectionChange}
      >
        <DidomiSwitch
          data-testid={dataTestId}
          className="!w-auto"
          labelVariant="no-box"
          disabled={disabled}
          value={selected}
          valueSelected={true}
          valueNotSelected={false}
          hideErrorMessage
        >
          <h3
            id={switchTitleId}
            className={tx('cursor-pointer text-left text-h3 font-semibold font-serif', {
              'text-primary-blue-6': selected && !disabled,
              'text-neutral-gray-0': !selected || disabled,
            })}
          >
            {title}
          </h3>
        </DidomiSwitch>
      </button>
    </div>
  );
};
