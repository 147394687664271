import { Platform, Regulation } from '@didomi/cmp-generator';

export type RegulationState = {
  id: Regulation;
  archived: boolean;
  geos: Geo[];
  name: string;
  platforms: Platform[];
  regulation_type: RegulationType;
  user_rights: UserRight[];
  release_status: 'stable' | 'release-candidate' | 'beta' | 'alpha';
  frameworks: string[];
};

export enum RegulationFilterStatus {
  ALL = 'all',
  ENABLED = 'enabled',
  DISABLED = 'disabled',
}

export type RegulationCountry = {
  id: string;
  name: string;
  didomi_territory: string;
  iso_territory: string;
  regions: RegulationRegion[];
  iso_sub_territory: string;
  iso_intermediate_territory: string | null;
};

export type RegulationRegion = {
  id: string;
  name: string;
};

export interface RegulationUiModel extends RegulationState {
  ui_status: 'visible' | 'hidden' | 'coming-soon';
}

type RegulationType = 'optin' | 'optout' | 'mixed' | 'disclose';

export type Geo = {
  country: RegulationCountry;
  region: RegulationRegion;
};

type UserRight = {
  id: string;
  name: string;
  expiration_time: number;
};

export enum Frameworks {
  TCF = 'tcf',
  GCM = 'gcm',
  GPP = 'gpp',
  CUSTOM = 'custom',
}
