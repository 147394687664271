import { AxiosError } from 'axios';
import { useQuery, UseQueryOptions } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, RegulationCountry } from '@types';

const enrichWithCode = (country: RegulationCountry) => ({ ...country, code: country.id.toLowerCase() });

/**
 * Fetches the full list of countries from the Regulation Service
 * */
export const useRegulationsCountries = (options?: UseQueryOptions<PaginatedResponse<RegulationCountry & { code: string }>>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();

  const fetchCountries = async () => {
    const url = new URL(`${CONFIG.environmentConfig.apiBaseUrl}compliance/v1/countries`);
    const response = await axiosWithInterceptors.get<PaginatedResponse<RegulationCountry>>(url.toString());
    const paginatedCountriesResponse = response.data;

    return {
      ...paginatedCountriesResponse,
      data: paginatedCountriesResponse.data.map(enrichWithCode),
    };
  };

  return useQuery<PaginatedResponse<RegulationCountry & { code: string }>, AxiosError>(['countries'], fetchCountries, options);
};
