import React, { useState } from 'react';
import { DidomiBaseInput, DidomiChip, DidomiIconButton, DidomiSkeleton } from '@didomi/ui-atoms-react';
import { tx } from '@twind/core';
import { ConsentNoticeConfig } from '@types';
import { isValidDomain, VALIDATION_PATTERNS } from '@utils';

type ConsentNoticeTargetsInputProps = {
  className?: string;
  consentNoticeConfig: Partial<ConsentNoticeConfig>;
  organizationTargets?: string[];
  disabled?: boolean;
  isLoadingTargets?: boolean;
  onUpdateTargets?: (newTargets: string[]) => void;
};

export const ConsentNoticeTargetsInput = ({
  className,
  consentNoticeConfig,
  disabled,
  organizationTargets = [],
  isLoadingTargets,
  onUpdateTargets,
}: ConsentNoticeTargetsInputProps) => {
  const [targetInputValue, setTargetInputValue] = useState('');
  const [targetInputError, setTargetInputError] = useState('');
  const requiresAppId = consentNoticeConfig?.platform === 'app' || consentNoticeConfig?.platform === 'ctv';

  /* istanbul ignore next */
  const handleAddTarget = (event: React.KeyboardEvent<HTMLDidomiBaseInputElement>) => {
    if (event.key === 'Enter') {
      addTarget();
    }
  };

  const stripBeginningOfDomain = (domain: string, stripSection: string): string => {
    const domainsWithoutHttp = domain.split(stripSection);
    return domainsWithoutHttp[domainsWithoutHttp?.length - 1];
  };

  const addTarget = () => {
    const currenTargets = consentNoticeConfig?.targets || [];
    let newTarget = targetInputValue.trim().toLocaleLowerCase();

    if (newTarget.startsWith('http://')) {
      newTarget = stripBeginningOfDomain(newTarget, 'http://');
    }
    if (newTarget.startsWith('https://')) {
      newTarget = stripBeginningOfDomain(newTarget, 'https://');
    }

    if (currenTargets.includes(newTarget)) {
      return setTargetInputError('This domain is already added');
    }

    if (requiresAppId && !VALIDATION_PATTERNS.targetAppId.test(newTarget)) {
      return setTargetInputError('Invalid mobile app ID');
    }

    if (!requiresAppId && !isValidDomain(newTarget)) {
      const errorText = newTarget.includes('/') ? 'Subpaths are not allowed' : 'Invalid domain';
      return setTargetInputError(errorText);
    }

    if (organizationTargets.some(target => target === newTarget)) {
      return setTargetInputError('This domain is already used in other notice');
    }

    onUpdateTargets([...currenTargets, newTarget]);
    setTargetInputError('');
    setTargetInputValue('');
  };

  const removeTarget = (target: string) => {
    const currenTargets = consentNoticeConfig?.targets;
    const newTargets = currenTargets.filter(t => t !== target);
    onUpdateTargets(newTargets);
  };

  return (
    <div className={className}>
      <DidomiSkeleton isLoading={isLoadingTargets} class="w-full">
        <DidomiBaseInput
          value={targetInputValue}
          onValueChange={e => {
            setTargetInputError('');
            setTargetInputValue(e.detail);
          }}
          title="Target domain"
          aria-label="Target domain"
          data-testid="target-domain"
          hideErrorMessage={isLoadingTargets || disabled}
          error={targetInputError}
          disabled={disabled}
          onKeyDown={/* istanbul ignore next */ e => handleAddTarget(e)}
          placeholder={requiresAppId ? 'Id' : 'http://www...'}
        >
          <DidomiIconButton
            title="Add target"
            size="small"
            slot="right"
            icon="new-create"
            onClick={addTarget}
            disabled={!!targetInputError || !targetInputValue || disabled}
          ></DidomiIconButton>
        </DidomiBaseInput>
      </DidomiSkeleton>
      {!disabled && (
        <div className={tx('flex flex-wrap gap-xxxs', { '-mt-xs': !targetInputError })}>
          {consentNoticeConfig?.targets?.map(target => (
            <DidomiChip variation="basic-outline" key={target} label={target} rightIcon="close-sm" onRightIconClick={() => removeTarget(target)} />
          ))}
        </div>
      )}
    </div>
  );
};
