import React from 'react';
import { generateUniqueId } from '@didomi/helpers';
import { DidomiRadio } from '@didomi/ui-atoms-react';
import { SelectionCard, SelectionCardProps } from '@components';

interface SelectionCardRadioProps extends SelectionCardProps {
  name?: string;
  value: string;
}

export const SelectionCardRadio = ({
  id = generateUniqueId('selection-card-radio'),
  title,
  name,
  description,
  checked,
  disabled,
  className,
  isLoading,
  'data-testid': dataTestId,
  illustration,
  illustrationContent,
  children,
  value,
}: SelectionCardRadioProps) => {
  const inputId = id + '-radio';

  return (
    <SelectionCard
      id={id}
      title={title}
      description={description}
      checked={checked}
      disabled={disabled}
      className={className}
      isLoading={isLoading}
      data-testid={dataTestId}
      illustration={illustration}
      illustrationContent={illustrationContent}
      input={
        <DidomiRadio
          data-testid={dataTestId ? dataTestId + '-radio' : null}
          title={title}
          name={name}
          value={value}
          radioId={inputId}
          onClick={/* istanbul ignore next */ e => e.stopPropagation()}
        />
      }
      inputId={inputId}
    >
      {children}
    </SelectionCard>
  );
};
