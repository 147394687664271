import React from 'react';
import { DidomiNumberInputCustomEvent, DidomiSelectCustomEvent } from '@didomi/ui-atoms';
import { DidomiCardContainer, DidomiHintbox, DidomiNumberInput, DidomiSelect, DidomiSelectOptions, DidomiSelectOption } from '@didomi/ui-atoms-react';

type ConsentDurationSettingsProps = {
  className?: string;
  consentDuration?: number;
  consentDurationUnit?: string;
  deniedConsentDuration?: number;
  deniedConsentDurationCustom?: boolean;
  deniedConsentDurationUnit?: string;
  disabled?: boolean;
  onConsentDurationChange?: (duration: number) => void;
  onConsentDurationUnitChange?: (durationUnit: string) => void;
  onDeniedConsentDurationTypeChange?: (event: boolean) => void;
  onDeniedConsentDurationChange?: (deniedDuration: number) => void;
  onDeniedConsentDurationUnitChange?: (deniedDurationUnit: string) => void;
};

export const ConsentDurationSettings = ({
  className = '',
  disabled,
  consentDuration,
  consentDurationUnit,
  deniedConsentDuration,
  deniedConsentDurationCustom,
  deniedConsentDurationUnit,
  onConsentDurationChange,
  onConsentDurationUnitChange,
  onDeniedConsentDurationTypeChange,
  onDeniedConsentDurationChange,
  onDeniedConsentDurationUnitChange,
}: ConsentDurationSettingsProps) => {
  const handleConsentDurationChange = (newDurationEvent: CustomEvent<string>) => {
    onConsentDurationChange(parseInt(newDurationEvent.detail, 10));
  };

  const handleConsentDurationUnitChange = (newDurationUnitEvent: DidomiSelectCustomEvent<string>) => {
    onConsentDurationUnitChange(newDurationUnitEvent.detail);
  };

  const handleDeniedConsentDurationTypeChange = (newDurationTypeEvent: DidomiSelectCustomEvent<string>) => {
    onDeniedConsentDurationTypeChange(newDurationTypeEvent.detail === 'true');
  };

  const handleDeniedConsentDurationChange = (newDeniedDurationEvent: DidomiNumberInputCustomEvent<string>) => {
    onDeniedConsentDurationChange(parseInt(newDeniedDurationEvent.detail, 10));
  };

  const handleDeniedConsentDurationUnitChange = (newDeniedDurationUnitEvent: DidomiSelectCustomEvent<string>) => {
    onDeniedConsentDurationUnitChange(newDeniedDurationUnitEvent.detail);
  };

  return (
    <section className={`w-full ${className}`}>
      <h2 className="font-bold text-h2 text-secondary-cobalt-blue-4 mb-xs">Consent duration</h2>
      <DidomiCardContainer>
        <div className="w-full" data-testid="generic-duration">
          <h3 className="font-semibold font-serif text-h3 text-primary-blue-6 mb-xxs">Generic duration</h3>
          <DidomiHintbox className="mb-xxs">
            Set the duration of the consent collected by the notice. Consent will be recollected after that duration. For example, we recommend 6 months in France (as per the CNIL
            recommendations).
          </DidomiHintbox>
          <div className="flex gap-2 items-end w-1/2 pr-s">
            <DidomiNumberInput
              value={consentDuration}
              onValueChange={handleConsentDurationChange}
              label="Duration"
              placeholder="24"
              className="w-full"
              hideErrorMessage
              min="0"
              disabled={disabled}
              data-testid="consent-duration-input"
            />
            <DidomiSelect
              className="w-full"
              value={consentDurationUnit}
              onValueChange={handleConsentDurationUnitChange}
              placeholder="Month"
              title="Duration unit"
              aria-label="Duration unit"
              hideErrorMessage
              disabled={disabled}
              data-testid="consent-duration-unit-input"
              allowAutomaticSize
            >
              <DidomiSelectOptions>
                <DidomiSelectOption value="days" label="Days"></DidomiSelectOption>
                <DidomiSelectOption value="months" label="Months"></DidomiSelectOption>
                <DidomiSelectOption value="seconds" label="Seconds"></DidomiSelectOption>
              </DidomiSelectOptions>
            </DidomiSelect>
          </div>
        </div>
        <hr className="w-full my-m" />
        <div data-testid="denied-generic-duration">
          <h3 className="font-semibold font-serif text-h3 text-primary-blue-6 mb-xxs">Denied consent duration</h3>
          <DidomiHintbox className="mb-xxs">
            Set a custom duration after which consent should be recollected when the user denies all purposes and vendors based on consent.
          </DidomiHintbox>
          <div className="flex gap-m items-end">
            <DidomiSelect
              className="!w-1/2"
              value={deniedConsentDurationCustom?.toString()}
              onValueChange={handleDeniedConsentDurationTypeChange}
              hideErrorMessage
              label="Options"
              data-testid="denied-consent-duration-type"
              disabled={disabled}
              allowAutomaticSize
            >
              <DidomiSelectOptions>
                <DidomiSelectOption value="false" label="Same as generic duration"></DidomiSelectOption>
                <DidomiSelectOption value="true" label="Custom duration"></DidomiSelectOption>
              </DidomiSelectOptions>
            </DidomiSelect>
            <div className="flex w-1/2 gap-2 items-end">
              <DidomiNumberInput
                value={!deniedConsentDurationCustom ? consentDuration : deniedConsentDuration}
                onValueChange={handleDeniedConsentDurationChange}
                label="Duration"
                placeholder="24"
                className="w-full"
                hideErrorMessage
                min="0"
                disabled={disabled || !deniedConsentDurationCustom}
                data-testid="denied-consent-duration-input"
              />
              <DidomiSelect
                className="w-full"
                value={!deniedConsentDurationCustom ? consentDurationUnit : deniedConsentDurationUnit}
                onValueChange={handleDeniedConsentDurationUnitChange}
                placeholder="Month"
                title="Duration unit"
                aria-label="Duration unit"
                data-testid="denied-consent-duration-unit"
                hideErrorMessage
                disabled={disabled || !deniedConsentDurationCustom}
                allowAutomaticSize
              >
                <DidomiSelectOptions>
                  <DidomiSelectOption value="days" label="Days"></DidomiSelectOption>
                  <DidomiSelectOption value="months" label="Months"></DidomiSelectOption>
                  <DidomiSelectOption value="seconds" label="Seconds"></DidomiSelectOption>
                </DidomiSelectOptions>
              </DidomiSelect>
            </div>
          </div>
        </div>
      </DidomiCardContainer>
    </section>
  );
};
