import React, { createContext, useContext } from 'react';
import { Regulation } from '@didomi/cmp-generator';
import { useParams } from 'react-router-dom';
import { useLocalConsentNoticeConfigFacadeLogic } from './useLocalConsentNoticeConfigFacadeLogic.hook';

const LocalNoticeConfigContext = createContext<ReturnType<typeof useLocalConsentNoticeConfigFacadeLogic>>(null);

interface LocalNoticeContextContainerProps {
  regulationId?: string;
  children: React.ReactNode;
}

/**
 * Provider for the local consent notice configuration context.
 * It should be used:
 * - at the top level of the component tree to provide access to the local consent notice configuration facade logic
 * - at the regulation level to provide access to the local consent notice configuration facade logic for a specific regulation
 */
export const LocalNoticeConfigProvider = ({ children, regulationId }: LocalNoticeContextContainerProps) => {
  let { noticeId } = useParams();
  const facade = useLocalConsentNoticeConfigFacadeLogic(noticeId, regulationId as Regulation);

  return <LocalNoticeConfigContext.Provider value={facade}>{children}</LocalNoticeConfigContext.Provider>;
};

/**
 * Custom hook that provides access to the local consent notice configuration context.
 * It allows to access to the facade logic while keeping only few instances of the useLocalConsentNoticeConfigFacadeLogic hook(so we avoid unnecessary computations).
 */
export const useLocalConsentNoticeConfigFacade = () => {
  const context = useContext(LocalNoticeConfigContext);

  if (!context) {
    throw new Error('useLocalNoticeContext must be used within a LocalNoticeContext');
  }

  return context;
};
