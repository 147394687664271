import React, { useState } from 'react';
import { produce } from '@didomi/helpers';
import { useSPAAssetsUrl } from '@didomi/helpers-react';
import { DidomiHintbox, DidomiLargePush, DidomiSwitch } from '@didomi/ui-atoms-react';
import { useCustomFeature, useFeatureFlag, useHasAccessPolicies } from '@didomi/utility-react';
import { SelectionCardCheckbox } from '@components';
import { useEditLocalConsentNoticeConfig, useLocalConsentNotice, useLocalConsentNoticeConfig } from '@hooks';
import { ConfirmConsentStringActivationModal } from '@modals';
import { ACCESS_POLICIES_CONFIG, produceListOfChanges } from '@utils';

interface ConsentStringSettingsProps {
  noticeId: string;
}

export const ConsentStringSettings = ({ noticeId }: ConsentStringSettingsProps) => {
  const [confirmConsentStringActivation, setConfirmConsentStringActivation] = useState(false);

  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);
  const [hasTokenSignatureFlag] = useFeatureFlag('didomi_token_signature' as any);
  const [isDidomiTokenEnforced] = useCustomFeature('enforce_didomi_token_v2');

  const { data: localConsentNoticeConfig, isLoading } = useLocalConsentNoticeConfig(noticeId);
  const { data: localConsentNotice } = useLocalConsentNotice(noticeId);
  const { mutateAsync: updateLocalConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);
  const ASSETS_URL = useSPAAssetsUrl('@didomi-spa/consent-notices');

  const updateConsentString = (isSelected: boolean) => {
    // Update `enable_didomi_token_signature` with `enable_didomi_token_v2` field change - but only if `didomi_token_signature` flag is enabled
    const configChanges = [{ path: `enable_didomi_token_v2`, value: isSelected }, ...(hasTokenSignatureFlag ? [{ path: 'enable_didomi_token_signature', value: isSelected }] : [])];
    updateLocalConfig(produceListOfChanges(configChanges, localConsentNoticeConfig));
  };

  const updateConsentStringSignature = (isSelected: boolean) => {
    updateLocalConfig(produce(localConsentNoticeConfig, 'enable_didomi_token_signature', isSelected));
  };

  const handleConfirmedConsentStringActivation = () => {
    setConfirmConsentStringActivation(false);
    updateConsentString(true);
  };

  const handleConsentStringCheckboxChange = (isSelected: boolean) => {
    if (isSelected) {
      setConfirmConsentStringActivation(true);
    } else {
      updateConsentString(false);
    }
  };

  const isConsentStringEnabled = localConsentNoticeConfig?.enable_didomi_token_v2 || isDidomiTokenEnforced;

  return (
    <>
      <SelectionCardCheckbox
        title="Didomi Consent String"
        data-testid="didomi-consent-string"
        disabled={isLoading || !isCMPEditor || isDidomiTokenEnforced}
        checked={isConsentStringEnabled}
        onValueChange={handleConsentStringCheckboxChange}
        description={
          <>
            Store the user status in a binary format to reduce the size of cookies stored on websites.
            <br /> Enabling this option will trigger consent re-collection for notices that are already live.
          </>
        }
        illustrationContent={<img src={`${ASSETS_URL}/assets/illustrations/consent-string.svg`} width={90} alt="" />}
      >
        <div className="flex flex-wrap justify-between gap-xs">
          {isDidomiTokenEnforced && (
            <DidomiHintbox className="w-full" titleText="The Didomi Consent String is enforced">
              Your organization has enabled The Didomi Consent String for all consent notices.
            </DidomiHintbox>
          )}

          <DidomiLargePush
            className="shrink-0"
            highlighted-text="Didomi Consent String"
            text="Documentation"
            icon="export"
            href="https://developers.didomi.io/cmp/web-sdk/consent-notice/didomi-consent-string"
            target="_blank"
          ></DidomiLargePush>

          {hasTokenSignatureFlag && (
            <DidomiSwitch
              className="!w-auto"
              data-testid="enable-string-token-signature"
              value={localConsentNoticeConfig?.enable_didomi_token_signature}
              disabled={isLoading || !isCMPEditor || !isConsentStringEnabled}
              valueSelected={true}
              valueNotSelected={false}
              hideErrorMessage
              onValueChange={e => updateConsentStringSignature(e.detail)}
            >
              Enable Signature for the Didomi Consent String
            </DidomiSwitch>
          )}
        </div>
      </SelectionCardCheckbox>

      <ConfirmConsentStringActivationModal
        isOpen={confirmConsentStringActivation}
        noticeName={localConsentNotice?.name}
        onConfirm={handleConfirmedConsentStringActivation}
        onCancel={() => setConfirmConsentStringActivation(false)}
      />
    </>
  );
};
