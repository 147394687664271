import React from 'react';
import { DidomiRightPanelTab } from '@didomi/ui-atoms-react';
import { useParams } from 'react-router-dom';
import { ConsentNoticePreview, CustomizationGlobalLayer } from '@components';
import { useLocalConsentNoticeConfig, useCustomizationStepOptions, useLocalRegulationConfig, CustomizationStep } from '@hooks';
import { CustomizationSpecificContent } from '../../Customizations/CustomizationSpecificContent/CustomizationSpecificContent';

const CONTENT_EDITOR_OPTIONS = [
  { name: 'Global', 'data-testid': 'global-options-header', slotName: 'global-options' },
  { name: 'Specific settings', 'data-testid': 'specific-options-header', slotName: 'specific-options' },
];

/**
 * Content editor tab
 */
export const ContentEditor = (): JSX.Element => {
  const { noticeId } = useParams();

  const { data: localConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);
  const { activeRegulationIds } = useLocalRegulationConfig(noticeId);

  const {
    customizationStep,
    contentEditorStep,
    contentEditorLayer,
    previewSize,
    trafficLanguage,
    setPreviewSize,
    setTrafficLanguage,
    setContentEditorStep,
    previewRegulation,
    setPreviewRegulation,
  } = useCustomizationStepOptions({
    defaultPreviewSize: localConsentNoticeConfig?.platform === 'amp' ? 'small' : 'large',
    defaultTraffic: localConsentNoticeConfig?.config?.languages?.default,
    activeRegulationIds,
  });

  return (
    <div className="h-full flex gap-l min-h-[720px]">
      <div className="w-full" data-skeleton>
        {/* Load preview iframe only if component is visible */}
        {customizationStep === CustomizationStep['content-editor'] && (
          <ConsentNoticePreview
            id="content-editor"
            config={localConsentNoticeConfig}
            layer={contentEditorLayer}
            previewSize={previewSize}
            trafficLanguage={trafficLanguage}
            setPreviewSize={setPreviewSize}
            setTrafficLanguage={setTrafficLanguage}
            activeRegulationIds={activeRegulationIds}
            previewRegulation={previewRegulation}
            setPreviewRegulation={setPreviewRegulation}
          />
        )}
      </div>

      <DidomiRightPanelTab
        headers={CONTENT_EDITOR_OPTIONS}
        activeTab={contentEditorStep}
        onActiveTabChange={e => {
          e.stopImmediatePropagation?.();
          setContentEditorStep(e.detail?.active);
        }}
        className="slidein min-w-[432px]"
      >
        <div slot="global-options" className="p-s">
          <CustomizationGlobalLayer noticeId={noticeId} />
        </div>
        <div slot="specific-options" className="p-s">
          <CustomizationSpecificContent noticeId={noticeId} />
        </div>
      </DidomiRightPanelTab>
    </div>
  );
};
