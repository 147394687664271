import { Purpose } from '@types';

export const isIABPurpose = (purpose: Purpose) => {
  return purpose.namespaces?.iab > 0 || purpose.namespaces?.iab2 > 0 || purpose.namespaces?.iab2_special_feature > 0;
};

export const isIAB2Purpose = (purpose: Purpose) => {
  return purpose.namespaces?.iab2 > 0 || purpose.namespaces?.iab2_special_feature > 0;
};

export const selectIAB2Purposes = (purposes: Purpose[]) => purposes.filter(isIAB2Purpose);
