import React from 'react';
import { produce } from '@didomi/helpers';
import { useCustomFeature, useHasAccessPolicies, useReleaseFlag, useFeatureFlag } from '@didomi/utility-react';
import { useParams } from 'react-router-dom';
import { ConsentDurationSettings, CrossDeviceConsentSettings, ConsentRecollectionSettings, LegitimateInterestSettings } from '@components';
import { useLocalConsentNoticeConfig, useEditLocalConsentNoticeConfig } from '@hooks';
import { ACCESS_POLICIES_CONFIG, matchDefaultRegulationId, produceListOfChanges } from '@utils';

/**
 * Consent settings tab
 */
export const ConsentSettings = (): JSX.Element => {
  const { noticeId } = useParams();
  const [hasConsentDuration] = useReleaseFlag('consent-notices-duration');
  const [hasCrossDeviceCustomFeature] = useCustomFeature('consent-notices-cross-device');
  const [hasCrossDevicePremiumFeature] = useFeatureFlag('cross_device');
  const { hasAccess: isCMPEditor } = useHasAccessPolicies(ACCESS_POLICIES_CONFIG.CMP_EDITOR);

  const { data: localConsentNoticeConfig, isLoading: loadingLocalConsentNoticeConfig } = useLocalConsentNoticeConfig(noticeId);

  const { mutateAsync: updateLocalNoticeConfig } = useEditLocalConsentNoticeConfig(localConsentNoticeConfig?.id);

  const defaultGdprIndex = localConsentNoticeConfig?.regulation_configurations?.findIndex(matchDefaultRegulationId('gdpr'));
  const gdprDefaultConfig = localConsentNoticeConfig?.regulation_configurations[defaultGdprIndex];

  const handleConsentDurationChange = newDuration => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'consent_duration', newDuration));
  };

  const handleConsentDurationUnitChange = newDurationUnit => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'consent_duration_unit', newDurationUnit));
  };

  const handleDeniedConsentDurationTypeChange = newDurationType => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'denied_consent_duration_custom', newDurationType));
  };

  const handleDeniedConsentDurationChange = newDuration => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'denied_consent_duration', newDuration));
  };

  const handleDeniedConsentDurationUnitChange = newDurationUnit => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'denied_consent_duration_unit', newDurationUnit));
  };

  const handleCrossDeviceEnabledChange = (isEnabled: boolean) => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'cross_device_enabled', isEnabled));
  };

  const handleCrossDeviceTimeoutChange = (timeout: number) => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'cross_device_timeout', timeout));
  };

  const handleEnableConsentRecollection = (isSelected: boolean, newDate: string) => {
    const changesToApply = [
      { path: 'enable_ignore_consent_before', value: isSelected },
      {
        path: 'ignore_consent_before',
        value: isSelected ? newDate : null,
      },
    ];

    updateLocalNoticeConfig(produceListOfChanges(changesToApply, localConsentNoticeConfig));
  };

  const handleIgnoreConsentBeforeChange = newDate => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'ignore_consent_before', newDate));
  };

  const handleRecollectionDaysChange = (newDays: number) => {
    // TODO: once consent-settings is refactored to be regulation-scoped stop patching root-level config
    const changes = [{ path: `config.notice.daysBeforeShowingAgain`, value: newDays }];
    if (localConsentNoticeConfig.regulation_configurations[defaultGdprIndex].config.notice?.daysBeforeShowingAgain !== undefined) {
      changes.push({ path: `regulation_configurations[${defaultGdprIndex}].config.notice.daysBeforeShowingAgain`, value: newDays });
    }
    updateLocalNoticeConfig(produceListOfChanges(changes, localConsentNoticeConfig));
  };

  const handleLIChangeInNotice = (isEnabled: boolean) => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'notice_deny_applies_to_li', !isEnabled));
  };

  const handleLIChangeInPreference = (isEnabled: boolean) => {
    updateLocalNoticeConfig(produce(localConsentNoticeConfig, 'preferences_deny_applies_to_li', !isEnabled));
  };

  const daysBeforeShowingAgain = Number(gdprDefaultConfig?.config?.notice?.daysBeforeShowingAgain || localConsentNoticeConfig?.config?.notice?.daysBeforeShowingAgain) || 0;

  return (
    <>
      <div className="w-full pb-xs flex flex-col gap-l" data-testid="consent-settings" data-loading={loadingLocalConsentNoticeConfig}>
        {hasConsentDuration && (
          <ConsentDurationSettings
            disabled={!isCMPEditor}
            consentDuration={localConsentNoticeConfig?.consent_duration}
            consentDurationUnit={localConsentNoticeConfig?.consent_duration_unit}
            deniedConsentDuration={localConsentNoticeConfig?.denied_consent_duration}
            deniedConsentDurationUnit={localConsentNoticeConfig?.denied_consent_duration_unit}
            deniedConsentDurationCustom={localConsentNoticeConfig?.denied_consent_duration_custom}
            onConsentDurationChange={handleConsentDurationChange}
            onConsentDurationUnitChange={handleConsentDurationUnitChange}
            onDeniedConsentDurationChange={handleDeniedConsentDurationChange}
            onDeniedConsentDurationTypeChange={handleDeniedConsentDurationTypeChange}
            onDeniedConsentDurationUnitChange={handleDeniedConsentDurationUnitChange}
          />
        )}

        {hasCrossDeviceCustomFeature && (
          <CrossDeviceConsentSettings
            crossDeviceEnabled={localConsentNoticeConfig?.cross_device_enabled}
            crossDeviceTimeout={localConsentNoticeConfig?.cross_device_timeout}
            canEnableCrossDeviceSettings={hasCrossDevicePremiumFeature}
            onEnabledChange={handleCrossDeviceEnabledChange}
            onTimeoutChange={handleCrossDeviceTimeoutChange}
            disabled={!isCMPEditor}
          />
        )}

        <ConsentRecollectionSettings
          disabled={loadingLocalConsentNoticeConfig || !isCMPEditor}
          enableIgnoreConsentBefore={localConsentNoticeConfig?.enable_ignore_consent_before}
          ignoreConsentBefore={localConsentNoticeConfig?.ignore_consent_before}
          daysBeforeShowingAgain={daysBeforeShowingAgain}
          onEnableConsentRecollectionChange={handleEnableConsentRecollection}
          onIgnoreConsentBeforeChange={handleIgnoreConsentBeforeChange}
          onRecollectionDaysChange={handleRecollectionDaysChange}
        />

        <LegitimateInterestSettings
          noticeLiEnabled={!localConsentNoticeConfig?.notice_deny_applies_to_li}
          preferencesLiEnabled={!localConsentNoticeConfig?.preferences_deny_applies_to_li}
          onKeepLegitimateInterestEnabledInNotice={handleLIChangeInNotice}
          onKeepLegitimateInterestEnabledInPreference={handleLIChangeInPreference}
        />
      </div>
    </>
  );
};
