import { Platforms, SDKConfigGenerator, Frameworks } from '@didomi/cmp-generator';
import { useActiveOrganization } from '@didomi/utility-react';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, UseMutationOptions, useQueryClient } from 'react-query';
import { useAxiosWithLogOut } from '@hooks';
import { PaginatedResponse, ConsentNotice } from '@types';

/**
 * Creates a notice, makes sure to update the list of notices with the edited notice value
 * */
export const useCreateConsentNotice = (options?: UseMutationOptions<AxiosResponse<ConsentNotice>, AxiosError, { platform: string; preset: Frameworks; gdprGeos: string[] }>) => {
  const axiosWithInterceptors = useAxiosWithLogOut();
  const { organizationId } = useActiveOrganization();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ platform, preset, gdprGeos }: { platform: Platforms; preset: Frameworks; gdprGeos: string[] }) => {
      const requestBody = {
        name: 'New Consent Notice',
        organization_id: organizationId,
        config: SDKConfigGenerator.getDefaultNoticeConfigPayload(platform, gdprGeos, preset),
      };
      return axiosWithInterceptors.post(`${CONFIG.environmentConfig.apiBaseUrl}widgets/notices?$translations=true$with[]=config`, requestBody, {
        headers: {
          v: '2',
        },
      });
    },
    {
      ...options,
      onSuccess: (newConsentNotice: AxiosResponse<ConsentNotice>, variables, context) => {
        const consentNoticesGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notices', organizationId]);
        /* istanbul ignore if */
        if (consentNoticesGet) {
          queryClient.removeQueries(['consent-notices', organizationId]);
        }

        const consentNoticeGet: PaginatedResponse<ConsentNotice> = queryClient.getQueryData(['consent-notice', newConsentNotice.data.id]);

        /* istanbul ignore if */
        if (consentNoticeGet) {
          queryClient.removeQueries(['consent-notice', newConsentNotice.data.id]);
          queryClient.removeQueries(['consent-notice-local', newConsentNotice.data.id]);
        }

        if (options?.onSuccess) {
          options.onSuccess(newConsentNotice, variables, context);
        }
      },
    },
  );
};
