import React from 'react';
import { DidomiIllustration, DidomiModal, DidomiModalHeader, DidomiModalActions, DidomiButton } from '@didomi/ui-atoms-react';
import { useBlocker } from '@hooks';

type CreateNoticeExitPromptModalProps = {
  'data-cy'?: string;
  title: string;
  isBlock: boolean;
  subTitle?: string;
  description?: string;
  basePath?: string;
  whitelist?: RegExp[];
};

export const CreateNoticeExitPromptModal = ({
  'data-cy': dataCy,
  isBlock,
  title = 'Please pay attention',
  subTitle = 'Please wait',
  whitelist,
  description,
}: CreateNoticeExitPromptModalProps) => {
  const { isBlocked, proceedWithNavigation, continueInThePage } = useBlocker({ isBlock, whitelist });

  return (
    <DidomiModal data-cy={dataCy} isOpen={isBlocked} permanent variant="slim">
      <DidomiIllustration name="warning-modal" style={{ marginBottom: 24 }} />
      <DidomiModalHeader modalTitle={title} modalSubTitle={subTitle} modalDescription={description} variant="warning" />
      <DidomiModalActions>
        <DidomiButton variant="secondary" onClick={proceedWithNavigation}>
          Yes I quit
        </DidomiButton>
        <DidomiButton onClick={continueInThePage}>Continue</DidomiButton>
      </DidomiModalActions>
    </DidomiModal>
  );
};
