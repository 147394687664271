import { ConsentNoticeConfigRegulation } from '@types';

export const matchRegulationId =
  (id: string, shouldMatch = true) =>
  (reg: ConsentNoticeConfigRegulation) => {
    return shouldMatch ? reg.regulation_id === id : reg.regulation_id !== id;
  };

export const matchDefaultRegulationId = (id: string) => (reg: ConsentNoticeConfigRegulation) => {
  return reg.is_default_regulation_config && reg.regulation_id === id;
};
