import React from 'react';
import {
  DidomiBackButtonLink,
  DidomiButton,
  DidomiEmptyState,
  DidomiErrorState,
  DidomiFiltersBar,
  DidomiIcon,
  DidomiIconButton,
  DidomiLoader,
  DidomiPaginator,
  DidomiSecondaryHeader,
  DidomiSkeleton,
  DidomiTable,
  DidomiTableBody,
  DidomiTableHeaderRow,
  DidomiTableHeading,
  DidomiTableRow,
  DidomiTableTd,
  DidomiTableTh,
  DidomiTooltip,
} from '@didomi/ui-atoms-react';
import { useSnackbar } from '@didomi/utility-react';
import { NavLink, useNavigate } from 'react-router-dom';
import { tw } from 'twind';
import { InAppHelper, TextWithHighlighterAndTooltip, WhatIsAVendorsList } from '@components';
import { useConsentNoticeTemplates, useDuplicateConsentNoticeTemplate, usePaginatedFilteredItems, useTableState } from '@hooks';
import { LoadingModal } from '@modals';
import { ConsentNoticeTemplate, ConsentNoticeTemplateSortableField } from '@types';
import { formatDate, getTableResultsText } from '@utils';

const SEARCH_FIELDS = [
  { field: 'name' },
  { field: 'id' },
  {
    field: 'updated_at',
    transform: formatDate,
  },
  { field: 'deployed_at', transform: formatDate },
];

/**
 * List of Vendors Lists
 */
export const VendorsListPage = (): JSX.Element => {
  const navigate = useNavigate();
  const { displaySnackbar } = useSnackbar();

  const {
    isLoading,
    isRefetching,
    data: { data: consentNoticeTemplates, total: totalConsentNoticeTemplates } = {},
    error: errorLoadingConsentNoticeTemplates,
  } = useConsentNoticeTemplates();

  const { mutate: duplicateTemplate, isLoading: isDuplicating } = useDuplicateConsentNoticeTemplate({
    onSuccess: () => displaySnackbar('Copy of the template has been created', { icon: 'check' }),
    onError: () => displaySnackbar('An error occurred while duplicating the template', { variant: 'error' }),
  });

  const loadingConsentNoticeTemplates = isLoading || isRefetching;

  const {
    limit,
    page: currPage,
    sortConfig,
    search,
    changeLimit,
    changePage,
    changeSorting,
    changeSearch,
  } = useTableState<ConsentNoticeTemplateSortableField>({
    defaultSorting: {
      field: 'name',
      dir: 'asc',
    },
  });

  const { data: displayConsentNoticeTemplates, total: paginationTotal } = usePaginatedFilteredItems<ConsentNoticeTemplate, ConsentNoticeTemplateSortableField>(
    consentNoticeTemplates,
    {
      search,
      searchFields: SEARCH_FIELDS,
      limit,
      page: currPage,
      sortConfig,
    },
  );

  const noConsentNoticeTemplates = !consentNoticeTemplates?.length;
  const noDisplayConsentNoticeTemplates = !displayConsentNoticeTemplates?.length;

  const resultsText = getTableResultsText(paginationTotal, totalConsentNoticeTemplates);

  return (
    <section className="p-l pb-unsafe-bottom-space min-h-full box-border !flex flex-col">
      <DidomiSkeleton data-testid="page-skeleton" isLoading={false} variant="layout" className="h-full w-full">
        <DidomiSecondaryHeader titleText="Manage vendor lists" className="block mb-m">
          <div slot="back-button">
            <DidomiBackButtonLink text="Back to Consent Notices" class="cursor-pointer" onClick={() => navigate(`/`)} />
          </div>
          <div slot="actions" className="flex gap-m">
            <InAppHelper subtitle="Good to know!" title="What is a Vendors List?" icon="read">
              <WhatIsAVendorsList />
            </InAppHelper>
          </div>
        </DidomiSecondaryHeader>

        <hr className="h-0 mb-m border-t-1 border-neutral-gray-3" />

        {!noConsentNoticeTemplates && (
          <div className="w-full h-full flex flex-col">
            <div className="flex justify-end flex-wrap-reverse gap-xs items-center mb-xs">
              <DidomiFiltersBar
                data-testid="filter-bar"
                className="flex-1"
                leftText={resultsText}
                showSearch
                searchValue={search}
                collapsibleFilters={false}
                onSearchTextChange={e => changeSearch(e.detail)}
                placeholderTextFilter="Search template..."
              />
              <DidomiButton data-tracking="add-template-button" iconRight="new-create" onClick={() => navigate(`/vendors-list/new`)}>
                Create a template
              </DidomiButton>
            </div>
            {noDisplayConsentNoticeTemplates && (
              <DidomiEmptyState illustration-name="traces-no-match-found" className="h-full">
                No results
              </DidomiEmptyState>
            )}
            {!noDisplayConsentNoticeTemplates && (
              <>
                <DidomiSkeleton variant="layout" isLoading={loadingConsentNoticeTemplates}>
                  <DidomiTable
                    data-testid={loadingConsentNoticeTemplates ? 'loading-notices-table' : 'notices-table'}
                    fixedLayout
                    className="mb-4"
                    loading={loadingConsentNoticeTemplates}
                    sortable
                    sortBy={sortConfig.field}
                    sortDirection={sortConfig.dir}
                    onSortChange={changeSorting}
                  >
                    <DidomiTableHeading>
                      <DidomiTableHeaderRow>
                        <DidomiTableTh sortId="name" data-testid="sortName" style={{ flexBasis: 80 }}>
                          Name
                        </DidomiTableTh>
                        <DidomiTableTh data-testid="links">Links</DidomiTableTh>
                        <DidomiTableTh sortId="updated_at" data-testid="sortLastSaved">
                          LAST EDIT
                        </DidomiTableTh>
                        <DidomiTableTh cellAlign="right"></DidomiTableTh>
                      </DidomiTableHeaderRow>
                    </DidomiTableHeading>
                    <DidomiTableBody>
                      {displayConsentNoticeTemplates?.map(({ id, name, updated_at, notices_id }) => (
                        <DidomiTableRow key={id} selectionValue={id} data-testid={id} data-cy={id}>
                          <DidomiTableTd style={{ flexBasis: 80 }}>
                            <div data-skeleton={loadingConsentNoticeTemplates} className="w-full">
                              <NavLink to={`/vendors-list/${id}`} className="underline">
                                <TextWithHighlighterAndTooltip searchText={search} text={name} />
                              </NavLink>
                              <div className="flex items-center gap-xxxs text-primary-blue-4 text-[11px] leading-[14px] mt-2">
                                ID: <TextWithHighlighterAndTooltip searchText={search} text={id} />
                              </div>
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div data-skeleton={loadingConsentNoticeTemplates} className="flex truncate w-full">
                              {notices_id.length > 0 && (
                                <>
                                  <div className={tw('mr-xxs inline text-primary-blue-4', { 'text-primary-pink-4': search })}>
                                    <DidomiIcon className="" data-skeleton={loadingConsentNoticeTemplates} name="link" />
                                  </div>
                                  linked to {notices_id.length} {notices_id.length === 1 ? 'notice' : 'notices'}
                                </>
                              )}

                              {notices_id.length === 0 && '--'}
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd>
                            <div data-skeleton={loadingConsentNoticeTemplates} className="truncate w-full">
                              <TextWithHighlighterAndTooltip searchText={search} text={formatDate(updated_at)} />
                            </div>
                          </DidomiTableTd>
                          <DidomiTableTd cellAlign="right">
                            <div className="flex px-xxxs gap-xxs">
                              <DidomiTooltip content="Edit template" placement="top">
                                <DidomiIconButton type="button" title="Edit template" variant="rounded" icon="edit" onClick={() => navigate(`/vendors-list/${id}`)} />
                              </DidomiTooltip>
                              <DidomiTooltip content="Duplicate template" placement="top">
                                <DidomiIconButton type="button" title="Duplicate template" variant="rounded" icon="copy" onClick={() => duplicateTemplate({ id, name })} />
                              </DidomiTooltip>
                            </div>
                          </DidomiTableTd>
                        </DidomiTableRow>
                      ))}
                    </DidomiTableBody>
                  </DidomiTable>
                </DidomiSkeleton>
                <DidomiPaginator
                  data-testid="templates-paginator"
                  className="self-end"
                  page={currPage}
                  itemCount={paginationTotal}
                  size={limit}
                  onPageSizeChange={changeLimit}
                  onPageChange={changePage}
                  disabled={loadingConsentNoticeTemplates}
                />
              </>
            )}
          </div>
        )}

        {noConsentNoticeTemplates && errorLoadingConsentNoticeTemplates && (
          <div className="w-full h-full">
            <DidomiErrorState illustration-name="list-cannot-be-loaded" className="h-full border-1 border-dashed border-neutral-gray-5 rounded-lg flex-1">
              <div slot="title">There was an error loading the template list</div>
            </DidomiErrorState>
          </div>
        )}
        {noConsentNoticeTemplates && isLoading && !errorLoadingConsentNoticeTemplates && (
          <div className="w-full h-full flex flex-col items-center justify-center">
            <DidomiLoader className="mb-xs" />
            <div className="text-body-normal text-primary-blue-5">Loading templates</div>
          </div>
        )}
        <LoadingModal isOpen={isDuplicating} title="Duplicating the template..." />
        {noConsentNoticeTemplates && !isLoading && !errorLoadingConsentNoticeTemplates && (
          <div className="w-full h-full">
            <DidomiEmptyState
              illustration-name="traces-no-result-found"
              className="h-full"
              actionName="Create template"
              actionIconRight="new-create"
              onActionClick={() => navigate(`/vendors-list/new`)}
            >
              <div slot="title">It&apos;s empty here!</div>
              You don&apos;t have any template created.
            </DidomiEmptyState>
          </div>
        )}
      </DidomiSkeleton>
    </section>
  );
};
