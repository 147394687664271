import { AxiosInstance } from 'axios';
import { PaginatedResponse } from '@types';

/**
 * Fetches all items from an endpoint by going through all the pages of the endpoint and concatenating the results.
 *
 * @param {AxiosInstance} - The instance of axios to run the call with.
 * @param {string} baseURL - The base endpoint URL (without pagination)
 * @param {number} limit - The maximum number of items to fetch per page.
 * @param {number} [skip=0] - The current skip number.
 * @param {Array} [data=[]] - The accumulated data from all pages.
 * @returns {Promise<Array>} A promise that resolves to an array of all items from the endpoint.
 */
export const fetchAllItems = async <T>(axiosInstance: AxiosInstance, baseURL: string, limit = 5000, skip = 0, data: T[] = []): Promise<T[]> => {
  const url = new URL(baseURL);
  url.searchParams.set('$limit', limit.toString());
  url.searchParams.set('$skip', skip.toString());

  const response = await axiosInstance.get<PaginatedResponse<T>>(url.toString());
  const newData = data.concat(response.data.data);

  if (newData.length < response.data.total) {
    return fetchAllItems(axiosInstance, baseURL, limit, skip + limit, newData);
  }

  return newData;
};
