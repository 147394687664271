import { ERROR_CONFIG } from './errorConfig';

// Check if the error message contains 'jwt' - if yes, we assume that error is related to JWT token expiration
const isJwtError = error => {
  return error.response?.data?.message?.includes('jwt');
};

// Emit the SPA error to the error hub
export const reportSpaError = (error: Error, { dsn, environment, release }: typeof ERROR_CONFIG) => {
  // Early exit - do not report JWT expiration errors
  if (isJwtError(error)) {
    return;
  }

  const detail = { error, dsn, environment, release };
  const errorEvent = new CustomEvent('on-spa-error', { detail });

  window.dispatchEvent(errorEvent);
};
