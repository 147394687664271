import React from 'react';
import { DidomiButton, DidomiSecondaryHeader, DidomiBackButtonLink, DidomiIconButton } from '@didomi/ui-atoms-react';

type PageHeaderProps = {
  title?: string;
  backText?: string;
  description?: string | React.ReactChild;
  titleContent?: React.ReactChild;
  isLoading?: boolean;
  inAppContent?: React.ReactChild;
  saveDisabled?: boolean;
  saveVisible?: boolean;
  withSeparator?: boolean;
  cancelText?: string;
  saveText?: string;
  saveIcon?: string;
  onSave: () => void;
  onCancel: () => void;
  onPreview?: () => void;
};

/**
 * Generic page header
 */
export const PageHeader = ({
  title,
  titleContent,
  description,
  backText,
  inAppContent,
  saveDisabled,
  saveVisible = true,
  withSeparator,
  saveText = 'Save',
  saveIcon = 'save',
  isLoading,
  onSave,
  onCancel,
  onPreview,
}: PageHeaderProps): JSX.Element => {
  return (
    <>
      <DidomiSecondaryHeader titleText={title} className="block mb-m">
        {titleContent && <div slot="header-title">{titleContent}</div>}
        <div slot="back-button">
          <DidomiBackButtonLink text={backText} class="cursor-pointer" onClick={onCancel}></DidomiBackButtonLink>
        </div>
        <div slot="description">
          <span>{description}</span>
        </div>
        <div slot="actions" className="flex gap-xs">
          {onPreview && <DidomiIconButton icon="preview" title="Preview notice" disabled={isLoading} onClick={onPreview} />}
          {saveVisible && (
            <DidomiButton data-testid="save-page-changes" variant="top" iconRight={saveIcon} disabled={!!saveDisabled || isLoading} onClick={onSave}>
              {saveText}
            </DidomiButton>
          )}
          {inAppContent && inAppContent}
        </div>
      </DidomiSecondaryHeader>
      {withSeparator && <hr className="h-0 mb-m border-t-1 border-neutral-gray-3" />}
    </>
  );
};
